import React from "react";
import Select, { components } from "react-select";

import { AddIcon } from "../../../assets/icons/iconsProvider";
import Button from "../Buttons/Button";
import { printerDropdownStyle } from "../../../assets/scss/style/printerdropdownstyle";
import { buttonNameConstants, buttonTypeConstants } from "../../../constants";

const PirnterDropdown = (props) => {
  const {
    value,
    options,
    disabled,
    className,
    placeholder,
    isClearable,
    defaultValue,
    isSearchable,
    handleChange,
    defaultPrinter,
    noOptionsMessage,
    dropdownIndicator = true,
    printerType,
  } = props;

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>
        <div className="w-100 d-flex flex-column p-3 gap-3">
          <span className="fw-bold connected-printer-heading">
            {`Connected ${printerType} Printer`}
          </span>
          <span
            className={`text-muted ${
              !defaultPrinter?.id && "text-decoration-underline"
            }`}
          >
            {defaultPrinter?.id
              ? defaultPrinter.printerName
              : "No Printer Found"}
          </span>
          <span className="fw-bold connected-printer-heading">
            Available Printers
          </span>
        </div>

        {props.children}
      </components.MenuList>
    );
  };
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center gap-4">
        <span className="text-muted">{children}</span>
        <span className="fw-bold">{defaultPrinter?.id ? "Connected" : ""}</span>
      </div>
    </components.SingleValue>
  );
  return (
    <Select
      className={`w-100 ${className}`}
      options={options}
      isDisabled={disabled}
      getOptionLabel={(e) => e.label}
      getOptionValue={(e) => e.value}
      isSearchable={isSearchable}
      placeholder={placeholder}
      components={
        dropdownIndicator
          ? {
              IndicatorSeparator: () => null,
              MenuList: SelectMenuButton,
              SingleValue: SingleValue,
            }
          : {
              IndicatorSeparator: () => null,
              DropdownIndicator: () => "",
              MenuList: SelectMenuButton,
              SingleValue: SingleValue,
            }
      }
      styles={printerDropdownStyle}
      onChange={handleChange}
      noOptionsMessage={() => {
        return noOptionsMessage;
      }}
      isClearable={isClearable}
      defaultValue={defaultValue}
      hideSelectedOption={true}
      value={{
        value: -1,
        label: `${
          defaultPrinter?.id
            ? `${defaultPrinter.printerName}`
            : "Select Printer..."
        }`,
      }}
    />
  );
};

export default PirnterDropdown;

import React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Row, Col } from "react-bootstrap";

import { Formik, Form } from "formik";
import Button from "../../../../shared/components/Buttons/Button";
import { deviceActions } from "../../../../redux/actions/device.action";
import SelectField from "../../../../shared/components/selectField/SelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import { addPaymentDeviceSchema } from "../../../../shared/validationSchema/validationSchema";
import Topbar from "../../../../shared/components/topbar/Topbar";
import {
  buttonTypeConstants,
  buttonNameConstants,
  routesPathConstants,
  globalConstants,
  deviceConstants,
} from "../../../../constants";
import { isSpinnerEnabled } from "../../../../shared/utility";

//-------AddNewDevice component start here
const AddNewDevice = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  //-------Props
  const { addDevice, checkDeviceStatus, updateDevice, spinnerArray } = props;
  //-------Stores Reducer
  const { stores } = props.store;
  //-------initial state for form
  const initialValues = {
    serialNumber: state?.data?.serialNumber || globalConstants.EMPTY_STRING,
    poiId: state?.data?.poiId || globalConstants.EMPTY_STRING,
    store: state?.data?.store.id || globalConstants.EMPTY_STRING,
  };

  //-------handle submit for add new device form
  const handleSubmit = (data) => {
    const findStore = stores.find((store) => {
      if (store.id === data.store) {
        return {
          id: store.id,
          storeName: store.storeName,
        };
      }
    });
    const formData = {
      ...data,
      store: { id: findStore?.id, storeName: findStore?.storeName },
    };

    if (state?.isEdit) {
      updateDevice(state?.data?.id, formData);
    } else {
      addDevice(formData);
    }
  };

  //-------handle cancel button
  const handleCancelButton = () => {
    navigate(routesPathConstants.ADMIN_DEVICES);
  };

  //-------check device status
  const handleCheckStatus = (serialNumber) => {
    if (serialNumber) {
      checkDeviceStatus(serialNumber);
    }
  };

  return (
    <>
      <Topbar
        title={state?.isEdit ? "Edit Device" : "New Device"}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.ADMIN_DEVICES,
        }}
      />
      <Row className="m-0 p-0 w-100">
        <Formik
          initialValues={initialValues}
          validate={addPaymentDeviceSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form className="m-0">
              <Row className="m-0">
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="serialNumber"
                    label="Serial Number"
                    placeHolder="Enter Serial Number"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="poiId"
                    label="Nickname"
                    placeHolder="Enter Nickname"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectField
                    label="Store"
                    name="store"
                    placeHolder={"Select Store"}
                    options={stores?.map((store) => ({
                      value: store.id,
                      label: store.storeName,
                    }))}
                  />
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-end align-items-end mb-3"
                >
                  <Button
                    type="button"
                    className={`w-50 ${
                      !values.serialNumber && "disable-button"
                    }`}
                    label={buttonNameConstants.CHECK_STATUS}
                    handleClick={() => handleCheckStatus(values.serialNumber)}
                    isDisabled={
                      !values.serialNumber ||
                      isSpinnerEnabled(
                        spinnerArray,
                        deviceConstants.ADD_DEVICE
                      ) ||
                      isSpinnerEnabled(
                        spinnerArray,
                        deviceConstants.UPDATE_DEVICE
                      )
                    }
                  ></Button>
                </Col>
                <Col md={6} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      handleCancelButton();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                    isDisabled={
                      isSpinnerEnabled(
                        spinnerArray,
                        deviceConstants.ADD_DEVICE
                      ) ||
                      isSpinnerEnabled(
                        spinnerArray,
                        deviceConstants.UPDATE_DEVICE
                      )
                    }
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={
                      state?.isEdit
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.SAVE
                    }
                    isDisabled={
                      isSpinnerEnabled(
                        spinnerArray,
                        deviceConstants.ADD_DEVICE
                      ) ||
                      isSpinnerEnabled(
                        spinnerArray,
                        deviceConstants.UPDATE_DEVICE
                      )
                    }
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
}; //-------AddNewDevice component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  store: state.store,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addDevice: (data) => dispatch(deviceActions.addDevice(data)),
  checkDeviceStatus: (serialNumber) =>
    dispatch(deviceActions.checkDeviceStatus(serialNumber)),
  updateDevice: (id, data) => dispatch(deviceActions.updateDevice(id, data)),
});

//-------Export AddNewDevice Component
export default connect(mapStateToProps, mapDispatchToProps)(AddNewDevice);

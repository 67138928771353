import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";

import { Formik, Form } from "formik";
import { companyActions } from "../../../../redux/actions";
import Topbar from "../../../../shared/components/topbar/Topbar";
import Button from "../../../../shared/components/Buttons/Button";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import { addCompanyValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  buttonTypeConstants,
  buttonNameConstants,
  routesPathConstants,
  globalConstants,
  companyContants,
} from "../../../../constants";
import SelectFieldCity from "../../../../shared/components/selectField/SelectFieldCity";
import SelectFieldState from "../../../../shared/components/selectField/SelectFieldState";
import InputGenericField from "../../../../shared/components/inputGenericField/InputGenericField";
import InputPasswordField from "../../../../shared/components/inputPasswordField/InputPasswordField";
import { isSpinnerEnabled } from "../../../../shared/utility";
import { decryptObject } from "../../../../system/encryption";

//-------AddNewCompany component start here
const AddNewCompany = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  //-------Props
  const { addNewCompany, updateCompany, spinnerArray } = props;
  //-------Stores Reducer
  const { stores } = props.store;
  //-------initial state for form
  const initialValues = {
    city: state?.data?.city || globalConstants.EMPTY_STRING,
    state: state?.data?.state || globalConstants.EMPTY_STRING,
    email: state?.data?.email || globalConstants.EMPTY_STRING,
    line1: state?.data?.line1 || globalConstants.EMPTY_STRING,
    password:
      (state?.isEdit &&
        decryptObject(
          state?.data?.password,
          process.env.REACT_APP_PASSWORD_ENCRYPTED_KEY
        )) ||
      globalConstants.EMPTY_STRING,
    line2: state?.data?.line2 || globalConstants.EMPTY_STRING,
    phoneNumber: state?.data?.phoneNumber || globalConstants.EMPTY_STRING,
    companyName: state?.data?.companyName || globalConstants.EMPTY_STRING,
    isDummyCompany: state?.data?.isDummyCompany || false,
    zip: state?.data?.zip || globalConstants.EMPTY_STRING,
    ownerNamePlaceholder:
      state?.data?.ownerNamePlaceholder || globalConstants.EMPTY_STRING,
    domainUrl: state?.data?.domainUrl || globalConstants.EMPTY_STRING,
  };

  //-------handle submit for add new device form
  const handleSubmit = (data) => {
    delete data["domainUrl"];
    if (state?.isEdit) {
      updateCompany({ ...data, id: state?.data?.id });
    } else {
      addNewCompany(data);
    }
  };

  //-------handle cancel button
  const handleCancelButton = () => {
    navigate(routesPathConstants.ADMIN_COMPANIES);
  };

  return (
    <>
      <Topbar
        title={state?.isEdit ? "Edit Company" : "New Company"}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.ADMIN_COMPANIES,
        }}
      />
      <Row className="m-0 p-0 w-100">
        <Formik
          initialValues={initialValues}
          validate={addCompanyValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form className="m-0">
              <Row className="m-0">
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="companyName"
                    label="Company Name"
                    placeHolder="Enter Company Name"
                    disabled={state.isEdit}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputGenericField
                    name="phoneNumber"
                    type="tel"
                    placeHolder="(000) 000-0000"
                    label="Phone Number"
                    inputMask="(999) 999-9999"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="email"
                    label="Email"
                    placeHolder="Enter Email"
                    disabled={state?.isEdit}
                  />
                </Col>
                <Col md={6} className="mb-3 d-flex gap-2">
                  <InputPasswordField
                    name="password"
                    label="Password"
                    placeHolder="Enter Password"
                    className="w-100"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="line1"
                    type="address"
                    placeHolder="Enter Street 1"
                    label="Street 1"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="line2"
                    type="address"
                    placeHolder="Enter Street 2"
                    label="Street 2"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectFieldCity defaultValue={values.city} />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectFieldState />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField name="zip" placeHolder="00000" label="Zip" />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="ownerNamePlaceholder"
                    placeHolder="Enter Owner Name"
                    label="Owner Name"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="domainUrl"
                    label="Domain Url"
                    placeHolder={state?.data?.domainUrl || "Auto Generated"}
                    disabled={true}
                  />
                </Col>
                <Col md={6}></Col>
                <Col md={6} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      handleCancelButton();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    isDisabled={
                      isSpinnerEnabled(
                        spinnerArray,
                        companyContants.CREATE_DUMMY_COMPANY
                      ) ||
                      isSpinnerEnabled(
                        spinnerArray,
                        companyContants.UPDATE_COMPANY
                      )
                    }
                    className="w-100"
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={
                      state?.isEdit
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.SAVE
                    }
                    isDisabled={
                      isSpinnerEnabled(
                        spinnerArray,
                        companyContants.CREATE_DUMMY_COMPANY
                      ) ||
                      isSpinnerEnabled(
                        spinnerArray,
                        companyContants.UPDATE_COMPANY
                      )
                    }
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
}; //-------AddNewCompany component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  store: state.store,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addNewCompany: (data) => dispatch(companyActions.addNewCompany(data)),
  updateCompany: (id, data) => dispatch(companyActions.updateCompany(id, data)),
});

//-------Export AddNewCompany Component
export default connect(mapStateToProps, mapDispatchToProps)(AddNewCompany);

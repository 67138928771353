import { connect } from "react-redux";
import React, { Fragment, useEffect, useRef } from "react";
import DraftConfirmationModal from "../../views/SellAndTrade/Shared/DraftConfirmationModal";
import ToastPrintLabelModal from "../../shared/components/ToastPrintLabel/ToastPrintLabelModal";
import LoadingBar from "react-top-loading-bar";

const MainWrapper = ({
  children,
  spinnerArray,
  isPrintLabelModal,
  isDraftConfirmationModal,
}) => {
  const loadingBarRef = useRef(null);

  useEffect(() => {
    if (spinnerArray?.length) {
      loadingBarRef.current.continuousStart();
    } else {
      loadingBarRef.current.complete();
    }
  }, [spinnerArray]);

  return (
    <Fragment>
      <LoadingBar
        color="#6a18ed"
        ref={loadingBarRef}
        height={3}
        shadow={true}
      />
      {isDraftConfirmationModal ? <DraftConfirmationModal /> : ""}
      {isPrintLabelModal ? <ToastPrintLabelModal /> : ""}
      <div>
        <div>{children}</div>
      </div>
    </Fragment>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
  isDraftConfirmationModal: state.transaction.isDraftConfirmationModal,
  isPrintLabelModal: state.printer.isPrintLabelModal,
});

export default connect(mapStateToProps, null)(MainWrapper);

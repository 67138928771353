export const printerConstants = {
  GET_AVAILABLE_PRINTERS: "GET_AVAILABLE_PRINTERS",
  DEFAULT_PRINTER: "DEFAULT_PRINTER",
  ADD_NEW_PRINTER: "ADD_NEW_PRINTER",
  RECEIPT_PRINTER: "RECEIPT_PRINTER",
  ZEBRA_PRINTER: "LABEL_PRINTER",
  NEW_PRODUCT_LABEL: "* NEW *",
  PRE_OWNED_PRODUCT_LABEL: "* PRE-OWNED *",
  RECEIPT_ITEM_MAX_LENGTH: 22,
  SPACE: " ",
  DOUBLE_SPACE_AFTER_ITEM: 2,
  SINGLE_SPACE_AFTER_ITEM: 1,
  RECEIPT_PRICE_MAX_LENGTH: 9,
  RECEIPT_QTY_MAX_LENGTH: 4,
  RECEIPT_ROW_LENGTH: 42,
  LEFT_ITEM_LENGTH: 20,
  RIGHT_ITEM_LENGTH: 20,
  SET_PRINT_LABEL_MODAL: "SET_PRINT_LABEL_MODAL",
  LOCAL_DEVICE_IP: "localDeviceIp",
};

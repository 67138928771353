import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Row, Col } from "react-bootstrap";

import { Formik, Form } from "formik";
import Button from "../../../../shared/components/Buttons/Button";
import { deviceActions } from "../../../../redux/actions/device.action";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import { addPaymentDeviceSchema } from "../../../../shared/validationSchema/validationSchema";
import Topbar from "../../../../shared/components/topbar/Topbar";
import {
  buttonTypeConstants,
  buttonNameConstants,
  routesPathConstants,
  globalConstants,
} from "../../../../constants";

//-------EditDevice component start here
const EditDevice = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  //-------Props
  const { checkDeviceStatus, updateDeviceName, currentStore } = props;

  //-------initial state for form
  const initialValues = {
    serialNumber: state?.data?.serialNumber || globalConstants.EMPTY_STRING,
    poiId: state?.data?.poiId || globalConstants.EMPTY_STRING,
    store: state?.data?.store || globalConstants.EMPTY_STRING,
  };

  //-------handle submit for add new device form
  const handleSubmit = (data) => {
    updateDeviceName(state?.data?.id, data);
  };

  //-------handle cancel button
  const handleCancelButton = () => {
    navigate(routesPathConstants.SETTINGS_DEVICES);
  };

  //-------check device status
  const handleCheckStatus = (serialNumber) => {
    if (serialNumber) {
      checkDeviceStatus(serialNumber);
    }
  };

  useEffect(() => {
    if (state.currentStore.id !== currentStore.id) {
      navigate(routesPathConstants.SETTINGS_DEVICES);
    } // eslint-disable-next-line
  }, [currentStore]);
  return (
    <>
      <Topbar
        title={"Edit Device"}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.SETTINGS_DEVICES,
        }}
      />
      <Row className="m-0 p-0 w-100">
        <Formik
          initialValues={initialValues}
          validate={addPaymentDeviceSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form className="m-0">
              <Row className="m-0">
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="serialNumber"
                    label="Serial Number"
                    placeHolder="Enter Serial Number"
                    disabled={true}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="poiId"
                    label="Nickname"
                    placeHolder="Enter Nickname"
                  />
                </Col>
                <Col md={6} className="mb-3"></Col>
                <Col
                  md={6}
                  className="d-flex justify-content-end align-items-end mb-3"
                >
                  <Button
                    type="button"
                    className={`w-50 ${
                      !values.serialNumber && "disable-button"
                    }`}
                    label={buttonNameConstants.CHECK_STATUS}
                    handleClick={() => handleCheckStatus(values.serialNumber)}
                    isDisabled={!values.serialNumber && true}
                  ></Button>
                </Col>
                <Col md={6} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      handleCancelButton();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.UPDATE}
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
}; //-------EditDevice component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  checkDeviceStatus: (serialNumber) =>
    dispatch(deviceActions.checkDeviceStatus(serialNumber)),
  updateDeviceName: (id, data) =>
    dispatch(deviceActions.updateDeviceName(id, data)),
});

//-------Export EditDevice Component
export default connect(mapStateToProps, mapDispatchToProps)(EditDevice);

import React from "react";

//-------ErrorTextMessage component start here
const ErrorTextMessage = (props) => {
  //-------Return ErrorTextMessage Component
  return <div className="error-message-text">{props.children}</div>;
}; //-------ErrorTextMessage component end heres

//-------Export ErrorTextMessage Component
export default ErrorTextMessage;

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Button from "../../../../src/shared/components/Buttons/Button";
import { useNavigate } from "react-router";
import { inventoryActions, transactionActions } from "../../../redux/actions";
import SearchField from "../../../../src/shared/components/searchField/SearchField";
import CustomTooltip from "../../../../src/shared/components/toolTipComponent/CustomTooltip";
import Topbar from "../../../../src/shared/components/topbar/Topbar";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  buttonNameConstants,
  routesPathConstants,
  transactionConstants,
  transactionDefaultData,
  globalConstants,
  tradeTransactionDefaultData,
  inventoryConstants,
} from "../../../constants";
import {
  EditIcon,
  SearchIcon,
  BlueBoxIcon,
  CrossIcon,
  DeleteIcon,
} from "../../../assets/icons/iconsProvider";
import DataTableComponent from "../../../../src/shared/components/table/ReactDataTableComponent";
import {
  convertToFixedPrecision,
  downloadCSV,
  formatDate,
  getSumOfPaymentHistory,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
} from "../../../shared/utility";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import DatetimePickerDropdown from "../../../shared/components/datetimePickerDropdown/DatetimePickerDropdown";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------draft component start here
const Drafts = (props) => {
  const {
    currentStore,
    getAllDraft,
    transaction,
    setNewTradeData,
    setTransactionData,
    deleteDraft,
    spinnerArray,
    setAddBatchInventory,
    setIsBatchInventoryModal,
    setAddTradeBatchInventory,
  } = props;
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  let numOfRenders = useRef(0);
  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: "daily",
  });
  const [option, setOption] = useState({
    label: globalConstants.DRAFT,
    value: globalConstants.DRAFT,
  });
  const [category, setCategory] = useState("");
  const { EMPTY_STRING } = globalConstants;
  //-------draft reducer
  const { allDraft } = transaction;
  const calculation = (row) => {
    const paymentStatus =
      row.totalAmountToPay - getSumOfPaymentHistory(row.paymentHistory)
        ? globalConstants.EMPTY_STRING
        : transactionConstants.PAYMENT_COMPLETE;
    if (paymentStatus == globalConstants.EMPTY_STRING) {
      if (row.PaymentType === globalConstants.CASH) {
        const percentage = (row.currentAmountToPay * 100) / row.totalCashOffer;
        const tradePercentage = (row.totalTradeOffer * percentage) / 100;
        const tradeValue = row.totalTradeOffer - tradePercentage;
        return tradeValue;
      } else {
        const percentage = (row.currentAmountToPay * 100) / row.totalTradeOffer;
        const cashPercentage = (row.totalCashOffer * percentage) / 100;
        const cashValue = row.totalCashOffer - cashPercentage;
        return cashValue;
      }
    } else {
      return 0;
    }
  };
  //-------Handle draft edit click
  const handleTrasactionData = (row) => {
    const dataToRetrive = {
      ...transactionDefaultData,
      ...row,
    };
    setTransactionData(dataToRetrive, currentStore?.id);
    navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
  };
  const handleEditData = (row) => {
    if (getSumOfPaymentHistory(row?.paymentHistory)) {
      const newObject = {
        ...tradeTransactionDefaultData,
        ...row,
        inventory: row?.inventory,
        ...row,
        tax: row?.PaymentType == globalConstants.CASH ? row.taxForTrade : 0,
        store: row.store,
        customer: row.customer,
        subTotal:
          row?.PaymentType == globalConstants.CASH
            ? row.totalTradeOffer - row.taxForTrade
            : row.totalCashOffer,
        totalAmountToPay:
          convertToFixedPrecision(calculation(row)) +
          getSumOfPaymentHistory(row.paymentHistory),
        paymentMethod:
          row.totalAmountToPay - getSumOfPaymentHistory(row.paymentHistory)
            ? globalConstants.EMPTY_STRING
            : transactionConstants.PAYMENT_COMPLETE,

        amountToPay: convertToFixedPrecision(calculation(row)),
        poiId: globalConstants.EMPTY_STRING,
        giftCard: globalConstants.EMPTY_OBJECT,
        userPaymentType:
          row?.PaymentType == globalConstants.CASH
            ? globalConstants.CREDIT
            : globalConstants.CASH,
      };
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE_PAYMENT, {
        state: {
          currentStore: currentStore,
        },
      });
      setNewTradeData(newObject, currentStore.id);
    } else {
      const returnObject = {
        ...tradeTransactionDefaultData,
        ...row,
        inventory: row?.inventory,
        note: globalConstants.EMPTY_STRING,
        tax: row?.Tax,
        subTotal: row?.SubTotal,
        creditUsed: row?.creditUsed,
        taxPercentage: 0,
        discountedValue: 0,
        currentAmountToPay: row?.currentAmountToPay,
        isNoteField: false,
        isTaxApplied: true,
        totalAmountToPay: row?.totalAmountToPay,
        amountToPay: row?.currentAmountToPay,
        discountMethod: false,
        isDiscountField: false,
        id: row?.id || globalConstants.EMPTY_STRING,
        store: row?.store || globalConstants.EMPTY_STRING,
        customer: row?.customer || globalConstants.EMPTY_STRING,
        PaymentType:
          row?.PaymentType.charAt(0).toLowerCase() +
            row?.PaymentType.slice(1) || globalConstants.CASH,
        paymentMethod: globalConstants.EMPTY_STRING,
        TransactionType: row?.TransactionType || transactionConstants.PROCESSED,
        paymentHistory: row?.paymentHistory || {
          [transactionConstants.CASH]: 0,
          [transactionConstants.CREDIT_CARD]: 0,
          [transactionConstants.GIFT_CARD]: 0,
          [transactionConstants.STORE_CREDIT]: 0,
        },
      };
      const sendToData = {
        ...returnObject,
        userPaymentType:
          row?.PaymentType.charAt(0).toLowerCase() + row?.PaymentType.slice(1),
        customer: row?.customer,
        store: row?.store || "",
        paymentMethod: globalConstants.EMPTY_STRING,
      };
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE, {
        state: { currentStore: currentStore },
      });
      setNewTradeData(sendToData, currentStore?.id);
      setAddTradeBatchInventory(row?.addBatchInventory ?? []);
    }
  };
  const handleDraftInventoryEdit = (row) => {
    navigate(routesPathConstants.INVENTORY_ITEMS);
    setIsBatchInventoryModal(true);
    setAddBatchInventory(row.inventory);
  };

  const filterColumnforExport = (allData) => {
    return allData.map((row) => {
      const cartQuantity =
        row.inventory?.reduce(
          (acc, curr) =>
            acc + Number(curr.quantity || curr.price?.quantity || 0),
          0
        ) || 0;

      const exportObject = {
        SaleId: row.id,
        CreatedOn: row.createdOn,
        TransactionType: row.TransactionType,
        PaymentType: row.PaymentType,
        CartQuantity: cartQuantity,
        Customer: row.customer.name,
        TotalAmountPaid: row.totalAmountToPay,
      };
      return exportObject;
    });
  };
  const handleDeleteDraft = (id, toast) => {
    const storeDetail = {
      storeId: currentStore?.id,
      type: option?.value,
      startDate: timeFrame.start,
      endDate: timeFrame.end,
    };
    const transactionId = {
      transactionId: id,
    };
    deleteDraft(transactionId, storeDetail, toast);
  };
  const draftHeader = [
    {
      id: "SaleID",
      name: "Sale ID",
      selector: (row) => row.id,
      cell: (row) => <span className="users-cell-max-size">{row.id}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "Createdon",
      name: "Created on",
      selector: (row) => formatDate(row.createdOn),
      cell: (row) => (
        <span className="users-cell-max-size">{formatDate(row.createdOn)}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "transactionType",
      name: (
        <TableCustomHeader
          id="transactionType"
          label="Transactio..."
          tooltopText="Transaction Type"
        />
      ),
      selector: (row) => row.TransactionType,
      cell: (row) => (
        <span className="users-cell-max-size">
          {row.id.substring(0, 3) === globalConstants.TYPE_ID_TRD
            ? transactionConstants.TRADE
            : row.id.substring(0, 3) === globalConstants.TYPE_ID_TRA
            ? transactionConstants.SALE
            : transactionConstants.BATCH}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "PaymentType",
      name: "Payment",
      selector: (row) => row.PaymentType,
      cell: (row) => (
        <span className="users-cell-max-size">{row?.PaymentType ?? "-"}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "cartQuantity",
      name: "Cart QTY",
      selector: (row) => {
        return parseToThousandSeperatorWholeNumber(
          row.quantity ||
            row.inventory?.reduce((a, b) => a + Number(b.quantity), 0) ||
            row.inventory?.reduce((a, b) => a + Number(b.price?.quantity), 0) ||
            0
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "Customer",
      name: "Customer",
      selector: (row) => row.customer.name,
      cell: (row) => (
        <span className="users-cell-max-size">
          {row?.customer?.name ?? EMPTY_STRING}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "Amount",
      name: "Amount",
      selector: (row) => row.totalAmountToPay,
      cell: (row) => (
        <span className="users-cell-max-size">
          ${parseToThousandSeperatorDecimalNumber(row?.totalAmountToPay ?? 0)}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        const idPrefix = row.id.substring(0, 3);
        return (
          <>
            <TableCustomHeader
              id={"deleteTransaction" + idPrefix}
              label={
                <button
                  id={`modalTool${row.id}`}
                  className="action-Button-Wrapper"
                  onClick={() => {
                    handleDeleteDraft(row.id, true);
                  }}
                >
                  <DeleteIcon className="cursor-pointer text-primary" />
                </button>
              }
              tooltopText="Delete Transaction"
            />
            <TableCustomHeader
              id={"reterieveTransaction" + idPrefix}
              label={
                <button
                  id={`modalTool${row.id}`}
                  className="action-Button-Wrapper ps-0 pe-0"
                  onClick={() => {
                    handleDeleteDraft(row.id);
                    if (idPrefix === globalConstants.TYPE_ID_TRD) {
                      handleEditData(row);
                    } else if (idPrefix === globalConstants.TYPE_ID_TRA) {
                      handleTrasactionData(row);
                    } else {
                      handleDraftInventoryEdit(row);
                    }
                  }}
                >
                  <EditIcon className="cursor-pointer text-primary" />
                </button>
              }
              tooltopText={`Retrieve ${
                idPrefix === globalConstants.TYPE_ID_TRD
                  ? transactionConstants.TRADE
                  : idPrefix === globalConstants.TYPE_ID_TRA
                  ? transactionConstants.SALE
                  : transactionConstants.INVENTORY
              }`}
            />
          </>
        );
      },
      sortable: false,
    },
  ];
  const [columns, setColumns] = useState([]);

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };
  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };

  // //-------useEffects

  const handleFilterData = (e) => {
    const filterList = allDraft.filter((row) => {
      const parts = [
        row.TransactionType,
        row.id,
        row.PaymentType,
        row.totalAmountToPay,
        row.customer?.name,
        row.customer?.mobile,
        row.customer?.email,
        row.customer?.id,
        formatDate(row.createdOn),
      ]
        .filter((part) => part !== undefined)
        .map((part) => String(part).toLowerCase());
      if (Array.isArray(row.inventory)) {
        row.inventory.forEach((inventoryItem) => {
          [
            "product_name",
            "sku",
            "category_name",
            "itemName",
            "category",
          ].forEach((property) => {
            if (inventoryItem[property]) {
              parts.push(String(inventoryItem[property]).toLowerCase());
            }
          });
        });
      }
      const filterString = parts.join(" ");
      const includesSearchText = filterString.includes(
        String(searchText).toLowerCase()
      );

      return includesSearchText && searchText !== globalConstants.EMPTY_STRING;
    });
    numOfRenders.current++;
    return filterList;
  };

  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      // getAllDraft(currentStore?.id, option?.value, start, end, category);
      getAllDraft(
        currentStore?.id,
        option?.value,
        timeFrame.start,
        timeFrame.end
        // category
      );
      setColumns(draftHeader);
    }
  }, [timeFrame.start, timeFrame.end, currentStore, option]);

  const clearSearchText = () => {
    setSearchText("");
  };
  //-------Return draft Component
  return (
    <>
      <Topbar title="Drafts"></Topbar>

      <Row className="m-0 ">
        <Col
          md={12}
          className="d-flex justify-content-center gap-3 align-items-center mb-2"
        >
          {isSpinnerEnabled(
            spinnerArray,
            transactionConstants.GET_ALL_DRAFT
          ) && (
            <>
              <DropdownSkeleton width="30vw" />
              <DropdownSkeleton width="40vw" />
              <DropdownSkeleton />
            </>
          )}
          <div
            className={`w-100 d-flex align-items-center gap-3 ${
              isSpinnerEnabled(spinnerArray, transactionConstants.GET_ALL_DRAFT)
                ? "opacity-0 hide-filter-dropdowns "
                : "opacity-100"
            }  `}
          >
            <DatetimePickerDropdown getTimeFrame={getTimeFrame} />{" "}
            <SearchField
              onChange={(e) => setSearchText(e.target.value)}
              IconImage={SearchIcon}
              BlueBoxIcon={BlueBoxIcon}
              placeHolder="Search"
              value={searchText}
              CrossIcon={searchText ? CrossIcon : ""}
              clearSearchText={clearSearchText}
            />{" "}
            <Button
              type="button"
              className="w-100 export-button-max-width"
              handleClick={() => {
                downloadCSV(
                  searchText
                    ? filterColumnforExport(handleFilterData())
                    : filterColumnforExport(allDraft)
                );
              }}
              label={buttonNameConstants.EXPORT_CSV}
            ></Button>
          </div>
        </Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          className="customer-table-height"
          key={numOfRenders.current}
          columns={columns}
          data={searchText ? handleFilterData() : allDraft}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={searchText ? handleFilterData()?.length : allDraft?.length}
        />
      </Row>
    </>
  );
}; //-------draft component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
  transaction: state.transaction,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllDraft: (storeId, type, start, end, cat) =>
    dispatch(transactionActions.getAllDraft(storeId, type, start, end, cat)),
  setNewTradeData: (data, storeId) =>
    dispatch(transactionActions.setNewTradeData(data, storeId)),
  setTransactionData: (data, storeId) =>
    dispatch(transactionActions.setTransactionData(data, storeId)),
  deleteDraft: (transactionId, storeDetail, toast) => {
    dispatch(transactionActions.deleteDraft(transactionId, storeDetail, toast));
  },
  setAddBatchInventory: (data) =>
    dispatch({
      type:
        inventoryConstants.SET_ADD_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: data,
    }),
  setIsBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type: inventoryConstants.SET_IS_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  setAddTradeBatchInventory: (data) =>
    dispatch({
      type:
        transactionConstants.SET_ADD_TRADE_BATCH_INVENTORY +
        globalConstants.SUCCESS,
      data: data,
    }),
});

//-------Export draft Component
export default connect(mapStateToProps, mapDispatchToProps)(Drafts);

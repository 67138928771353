import React from "react";

const AGGridToolTip = (props) => {
  return (
    <div
      className={`custom-tooltip ${props.className || ""}`}
      style={{ backgroundColor: props.color || "#6a18ed" }}
    >
      {props.type === "skuTooltip" ? (
        <div className="d-flex flex-column gap-1">
          {props.value.map((skuNumber) => (
            <p>{skuNumber}</p>
          ))}
        </div>
      ) : props.type === "actionTooltip" ? (
        props.ActionButtons(props)
      ) : (
        props.value
      )}
    </div>
  );
};

export default AGGridToolTip;

import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequestWithData,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

// --------------------------------------------------globalMargin portion-------------------------------------------------
//-------addGlobalMargin
const addGlobalMargin = (storeId, data) => {
  return postRequest(`tradein-margin/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------addCustomMarkup
const addMarkupPercentage = (storeId, data) => {
  return postRequest(`tradein-markup/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const addCategoryMargin = (storeId, data) => {
  return postRequest(`tradein-margin/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------deleteCategoryMargin
const deleteCategoryMargin = (storeId, id) => {
  return deleteRequestWithData(`tradein-margin/${storeId}`, id).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------deleteCustomMarkup
const deleteCustomMarkup = (storeId, id) => {
  return deleteRequestWithData(`tradein-markup/${storeId}`, id).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------updateCategoryMargin
const updateCategoryMargin = (storeId, data) => {
  return putRequest(`tradein-margin/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------updateCustomMarkup
const updateCustomMarkup = (storeId, data) => {
  return putRequest(`tradein-markup/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const addMonthlyBudget = (storeId, data) => {
  return putRequest(`store-budget/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
// --------------------------------------discount ----------------------------------------------------------------------------------------------
const addCategoryTypeDiscount = (storeId, data) => {
  return postRequest(`discount/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------deleteCategoryTypeDiscount
const deleteCategoryTypeDiscount = (storeId, discountId) => {
  return deleteRequestWithData(`discount/${storeId}`, discountId).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
const updateCategoryTypeDiscount = (storeId, data) => {
  return putRequest(`discount/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Get getAllDiscount
const getAllDiscount = (storeId) => {
  return getRequest(`discount/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Markup Discount
const getMarkup = (storeId) => {
  return getRequest(`tradein-markup/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
const getMargin = (storeId) => {
  return getRequest(`tradein-margin/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Export customer Services
export const tradeInDiscountService = {
  addGlobalMargin,
  addCategoryMargin,
  deleteCategoryMargin,
  updateCategoryMargin,
  addMonthlyBudget,
  addCategoryTypeDiscount,
  deleteCategoryTypeDiscount,
  updateCategoryTypeDiscount,
  getAllDiscount,
  addMarkupPercentage,
  deleteCustomMarkup,
  updateCustomMarkup,
  getMarkup,
  getMargin,
};

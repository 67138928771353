import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import TextareaField from "../../../shared/components/textareaField/TextareaField";
import Button from "../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
  toastType,
} from "../../../constants";
import { addReceiptLogoValidationSchema } from "../../../shared/validationSchema/validationSchema";
import { LogoImageReplaceIcon } from "../../../assets/icons/iconsProvider";
import LogoImage from "../../../assets/images/Rectangle31.png";
import CheckboxField from "../../../shared/components/checkBox/CheckboxField";
import { storeActions } from "../../../redux/actions";
import Topbar from "../../../shared/components/topbar/Topbar";
import { customToast, resizeImageBlob } from "../../../shared/utility";

const ReceiptAndLabels = (props) => {
  const { uploadLogo, store, updateReceiptDetails } = props;

  //-------store reducer
  const { currentStore } = store;

  //------Usestate
  const [storeLogoImage, setStoreLogoImage] = useState(
    currentStore?.logo || LogoImage
  );
  const [logoToSend, setLogoToSend] = useState(false);

  //------UseRef
  const hiddenFileInput = useRef(null);

  //-------initial state
  const initialValues = {
    logo: storeLogoImage,
    returnPolicy: currentStore?.returnPolicy || globalConstants.EMPTY_STRING,
    showOnLabels: currentStore?.showOnLabels || false,
    showOnReceipt: currentStore?.showOnReceipt || false,
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };
  //------handle upload button
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
              // const width = img.width;
              // const maxWidth = 256;

              // if (width >= maxWidth) {
              //   customToast(toastMessages.LOGO_SIZE_ERROR, toastType.ERROR);
              //   // You can also reset the input or take other actions based on your application needs.
              // } else {
              setStoreLogoImage(URL.createObjectURL(file));
              setLogoToSend(file);
              // }
            };
          };
          reader.readAsDataURL(file);
        }
      } else {
        customToast(
          "Only .jpg, .jpeg and .png files are allowed",
          toastType.ERROR
        );
        e.target.value = null;
      }
    }
  };

  //------Handle submit
  const handleSubmit = async (data) => {
    if (logoToSend) {
      const formData = new FormData();
      const resizedImage = await resizeImageBlob(logoToSend, 200);
      formData.append("file", resizedImage);
      uploadLogo(currentStore?.id, formData, data);
    } else {
      updateReceiptDetails(currentStore?.id, {
        ...data,
        logo: currentStore?.logo,
      });
    }
  };

  useEffect(() => {
    if (currentStore) {
      setStoreLogoImage(currentStore?.logo || LogoImage);
      setLogoToSend(false);
    }
  }, [currentStore]);
  return (
    <>
      <Topbar title="Receipt/Logo"></Topbar>
      <Row className="m-0">
        <Col md={10}>
          <Formik
            initialValues={initialValues}
            validate={addReceiptLogoValidationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ resetForm, values }) => (
              <Form>
                <Row className="m-0">
                  <Col xs={12}>
                    <p className="receipt-label-heading">Store Logo</p>
                  </Col>
                  <Col md={2}>
                    <div className="receipt-logo-image-wrapper">
                      <img
                        src={values.logo}
                        alt="logo"
                        className="receipt-logo-image"
                      />
                      <input
                        type="file"
                        className="d-none"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageChange}
                        ref={hiddenFileInput}
                      />
                      <button
                        onClick={handleReplaceButtonClick}
                        type="button"
                        className="replace-logo-button"
                      >
                        <LogoImageReplaceIcon className="replace-icon" />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={10}
                    className="d-flex flex-column justify-content-end align-items-start"
                  >
                    <CheckboxField name="showOnLabels" label="Show on Labels" />
                    <CheckboxField
                      name="showOnReceipt"
                      label="Show on Receipt"
                    />
                  </Col>
                  <Col xs={12} className="refund-heading-wrapper">
                    <p className="receipt-label-heading">
                      Refund Policy (Receipt)
                    </p>
                  </Col>
                  <Col md={12} className="mb-3">
                    <TextareaField
                      name="returnPolicy"
                      placeHolder="Refund Policy"
                    />
                  </Col>
                  <Col md={4}></Col>
                  <Col md={8} className="mb-3 d-flex gap-2">
                    <Button
                      type="button"
                      label={buttonNameConstants.CANCEL}
                      handleClick={() => {
                        resetForm();
                        setStoreLogoImage(currentStore?.logo || LogoImage);
                        setLogoToSend(false);
                      }}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      className="w-100"
                    ></Button>
                    <Button
                      type="submit"
                      className="w-100"
                      label={
                        currentStore?.logo
                          ? buttonNameConstants.UPDATE
                          : buttonNameConstants.SAVE
                      }
                    ></Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};
//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  store: state.store,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  uploadLogo: (id, logoPath, data) =>
    dispatch(storeActions.uploadLogo(id, logoPath, data)),
  updateReceiptDetails: (id, data) =>
    dispatch(storeActions.updateReceiptDetails(id, data)),
});

//-------Export Login Component
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptAndLabels);

import React from "react";
import { Formik, Form } from "formik";
import { Col, Modal, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import { addCustomTypeValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";

const AddCustomType = ({
  storeId,
  AddCustomType,
  typeModalFormData,
  isCustomTypeModal,
  setTypeModalFormData,
  toggleCustomTypeModal,
  updateCustomProductTypes,
}) => {
  const initialValues = {
    customType: typeModalFormData?.productType || globalConstants.EMPTY_STRING,
  };

  const handleHideModal = () => {
    toggleCustomTypeModal();
    setTypeModalFormData(globalConstants.EMPTY_STRING);
  };

  const handleAddCustomTypeSubmit = (values) => {
    if (typeModalFormData?.id) {
      updateCustomProductTypes(
        storeId,
        {
          productType: values.customType,
          storeId: storeId,
          isCustom: true,
          isActive: true,
          id: typeModalFormData.id,
        },
        handleHideModal
      );
    } else {
      AddCustomType(
        storeId,
        {
          productType: values.customType,
          storeId: storeId,
          isCustom: true,
        },
        handleHideModal
      );
    }
  };

  return (
    <Modal
      centered
      size="md"
      animation={true}
      backdrop="static"
      show={isCustomTypeModal}
      onHide={handleHideModal}
      className={`add-inventory-modal`}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            {typeModalFormData?.id ? "Update Custom Type" : "Add Custom Type"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body d-flex ">
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddCustomTypeSubmit}
          validate={addCustomTypeValidationSchema}
        >
          {({ values }) => (
            <Form className="w-100">
              <Row className="m-0 w-100">
                <Col
                  md={3}
                  className="d-flex align-items-center text-muted mb-3 field-label-name"
                >
                  Create Type:
                </Col>
                <Col md={9} className="mb-3">
                  <InputTextField name="customType" placeHolder="Type..." />
                </Col>
                <Col md={12} className="d-flex align-items-center gap-3">
                  <Button
                    type="button"
                    className={"w-100"}
                    handleClick={handleHideModal}
                    label={buttonNameConstants.CANCEL}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                  />
                  <Button
                    className={"w-100"}
                    type="submit"
                    label={
                      typeModalFormData?.id
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.ADD
                    }
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddCustomType;

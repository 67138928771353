export const customerConstants = {
  GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
  ADD_CUSTOMER: "ADD_CUSTOMER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  BULK_UPLOAD_CUSTOMERS: "BULK_UPLOAD_CUSTOMERS",
  EMPTY_CUSTOMER_BY_ID: "EMPTY_CUSTOMER_BY_ID",
  GET_CUSTOMER_BY_ID: "GET_CUSTOMER_BY_ID",
  ADD_PRODUCT_NOTIFICATION: "ADD_PRODUCT_NOTIFICATION",
  CUSTOMERS_IMPORTED_SUCCESSFULLY: " Customers were successfully imported",
  CUSTOMERS_NOT_IMPORTED: " Customers were not imported.",
};

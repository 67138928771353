import React, { useEffect, useState } from "react";
import { globalConstants } from "../../../constants";

const ToggleButtonDual = ({ Disabled, handleButtonClick, activeButton }) => {
  // const [activeButton, setActiveButton] = useState(globalConstants.CASH);

  return (
    <div className="toggle-button-container">
      <button
        className={`toggle-button-inner ${
          activeButton === globalConstants.CASH
            ? "light-purple-button-toggle"
            : ""
        }`}
        onClick={() => handleButtonClick(globalConstants.CASH)}
      >
        Cash
      </button>
      <button
        disabled={Disabled}
        className={`toggle-button-inner ${
          activeButton === globalConstants.CREDIT
            ? "light-purple-button-toggle"
            : ""
        }`}
        onClick={() => handleButtonClick(globalConstants.CREDIT)}
      >
        Store Credit
      </button>
    </div>
  );
};
export default ToggleButtonDual;

import { useState } from "react";
import { Row, Col } from "react-bootstrap";

import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../assets/icons/iconsProvider";
import SelectDropDown from "../../components/selectDropdown/SelectDropdown";

const defaultLocalGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

const DataTablePaginationComponent = (props) => {
  const {
    data = [],
    setRows,
    paginationServer = false,
    paginationRowsPerPageOptions = [5, 10, 25, 50, 100],
    onChangePage,
    onChangeRowsPerPage,
    gridState = defaultLocalGridState,
    setGridState,
    total,
  } = props;

  const [localGridState, setLocalGridState] = useState(gridState);

  const pageSizes = [];
  paginationRowsPerPageOptions.forEach((option) => {
    pageSizes.push({ value: option, label: `${option}` });
  });

  const getPageCount = () => {
    return Math.ceil(total / localGridState.pageSize);
  };

  const canGetNextPage = () => {
    return localGridState.pageIndex < getPageCount() - 1;
  };

  const canGetPreviousPage = () => {
    return localGridState.pageIndex > 0;
  };

  const onPageSizeChange = (event) => {
    const pageIndex = localGridState.pageIndex;
    const pageSize = event.value;

    if (paginationServer && onChangeRowsPerPage) {
      onChangeRowsPerPage();
      setGridState({
        ...localGridState,
        pageIndex: pageIndex,
        pageSize: pageSize,
      });
    } else if (data.length) {
      const pageStart = pageIndex * pageSize;
      let pageEnd = data.length;
      if (pageEnd - pageStart > pageSize) {
        pageEnd = pageStart + pageSize;
      }

      setRows(data.slice(pageStart, pageEnd));
      setLocalGridState({
        ...localGridState,
        pageIndex: pageIndex,
        pageSize: pageSize,
      });
    }
  };

  const onPageIndexChange = (e) => {
    const value = Number(e.target.value);
    if (value > 0 && value <= getPageCount()) {
      const pageIndex = value - 1;
      if (paginationServer && onChangePage) {
        onChangePage();
        setGridState({ ...localGridState, pageIndex: pageIndex });
      } else if (data.length) {
        const pageStart = pageIndex * localGridState.pageSize;
        let pageEnd = pageStart + localGridState.pageSize;
        if (pageEnd > data.length) {
          pageEnd = data.length;
        }

        setRows(data.slice(pageStart, pageEnd));
        setLocalGridState({ ...localGridState, pageIndex: pageIndex });
      }
    }
  };

  const onGetNextPage = () => {
    const pageIndex = localGridState.pageIndex + 1;

    if (paginationServer && onChangePage) {
      onChangePage();
      setGridState({ ...localGridState, pageIndex: pageIndex });
    } else if (data.length) {
      const pageStart = pageIndex * localGridState.pageSize;
      let pageEnd = pageStart + localGridState.pageSize;
      if (pageEnd > data.length) {
        pageEnd = data.length;
      }

      setRows(data.slice(pageStart, pageEnd));
      setLocalGridState({ ...localGridState, pageIndex: pageIndex });
    }
  };

  const onGetPreviousPage = () => {
    const pageIndex = localGridState.pageIndex - 1;

    if (paginationServer && onChangePage) {
      onChangePage();
      setGridState({ ...localGridState, pageIndex: pageIndex });
    } else if (data.length) {
      const pageStart = pageIndex * localGridState.pageSize;
      let pageEnd = pageStart + localGridState.pageSize;
      if (pageEnd > data.length) {
        pageEnd = data.length;
      }

      setRows(data.slice(pageStart, pageEnd));
      setLocalGridState({ ...localGridState, pageIndex: pageIndex });
    }
  };

  return (
    <Row className="m-0 react-data-table-footer-wrapper position-sticky">
      <Col
        xs={12}
        md={4}
        className="d-flex justify-content-start align-items-center"
      >
        Viewing {localGridState.pageIndex * localGridState.pageSize + 1} -{" "}
        {(localGridState.pageIndex + 1 - 1) * localGridState.pageSize +
          localGridState.pageSize}{" "}
        of {total ? total : "0"} items
      </Col>
      <Col
        xs={12}
        md={8}
        className="d-flex justify-content-end align-items-center gap-2"
      >
        Items per page:{" "}
        <SelectDropDown
          options={pageSizes}
          defaultPageSize={{
            value: localGridState.pageSize,
            label: localGridState.pageSize,
          }}
          handleChange={onPageSizeChange}
        />{" "}
        <span className="d-flex justify-content-center align-items-center gap-3 ms-4">
          <button
            type="button"
            className="arrow-buttons"
            onClick={() => onGetPreviousPage()}
            disabled={!canGetPreviousPage()}
          >
            <LeftArrowIcon />
          </button>
          Page{" "}
          <input
            min={1}
            max={10}
            className="page-index-inputField"
            type="number"
            value={localGridState.pageIndex + 1}
            onChange={onPageIndexChange}
          />{" "}
          of {getPageCount()}
          <button
            type="button"
            className="arrow-buttons"
            onClick={() => onGetNextPage()}
            disabled={!canGetNextPage()}
          >
            <RightArrowIcon />
          </button>
        </span>
      </Col>
    </Row>
  );
};

export default DataTablePaginationComponent;

import Select from "react-select";
import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import {
  customToast,
  importTagFunc,
  importStringArrayFunc,
} from "../../../../shared/utility";
import { importSelectFieldDropdownStyle } from "../../../../assets/scss/style";
import Button from "../../../../shared/components/Buttons/Button";
import { UploadCsvIcon } from "../../../../assets/icons/iconsProvider";
import {
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
  inventoryConstants,
  toastMessages,
  toastType,
} from "../../../../constants";
import moment from "moment";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../../system/globalEnums";
import { inventoryActions } from "../../../../redux/actions";
import { inventoryService } from "../../../../services";

const { EMPTY_STRING } = globalConstants;
const { INVALID_IMPORT_VALUES } = inventoryConstants;

const ImportInventory = (props) => {
  const {
    currentStore,
    importInventoryModal,
    toggleImportInventoryModal,
    importCsvFileInventory,
    activateImportInventorySpinner,
    importCsvFileResponse,
  } = props;

  //-------useState
  const [csvData, setCsvData] = useState("");
  const [columnMapping, setColumnMapping] = useState({});
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const predefinedColumns = [
    "SKU",
    "Product Name",
    "Product Type",
    "Category",
    "UPC",
    "Tags",
    "Condition",
    "Quantity",
    "Cost of Goods",
    "Sell Price",
  ];
  const requiredColumns = [
    "Product Name",
    "Product Type",
    "Category",
    "Condition",
    "Quantity",
    "Cost of Goods",
    "Sell Price",
  ];

  const handleHideModal = () => {
    toggleImportInventoryModal();
    setTimeout(() => {
      setCsvData("");
      setColumnMapping({});
    }, 500);
  };

  const handleStartImport = async () => {
    const arrayAfterMapping = createMappedArray(
      csvData,
      predefinedColumns,
      columnMapping
    );

    let validateResponse = validateCsvData(arrayAfterMapping);
    if (validateResponse.isInvalid) {
      customToast(validateResponse.message, toastType.ERROR);
      return;
    }

    const arrayAfterCleaning = cleanCsvData([
      ...validateResponse.validInventory,
    ]);

    const arrayToSend = arrayAfterCleaning.map((product, index) => {
      return {
        sku: product["SKU"]
          ? importStringArrayFunc(product["SKU"])
          : [inventoryConstants.CSV_IMPORT_FILE],
        upc: product["UPC"] ? product["UPC"] : globalConstants.EMPTY_STRING,
        product_id: inventoryConstants.CSV_IMPORT_FILE,
        product_name: product["Product Name"],
        category_name: product["Category"],
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
        date_added: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        price: {
          unit_purchase_price: product["Cost of Goods"],
          unit_sell_price: product["Sell Price"],
          quantity: product["Quantity"],
          type: product["Condition"],
          marketPrice: 0,
        },
        productType: product["Product Type"],
        additionalCheckList: [],
        tags: importTagFunc(product["Tags"]),
        cardRarity: "",
        cardNumber: "",
        apiSource: inventoryConstants.CSV_IMPORT_FILE,
        tcgPlayerUrl: globalConstants.EMPTY_STRING,
        epid: globalConstants.EMPTY_STRING,
        subcategory: globalConstants.EMPTY_STRING,
        description: globalConstants.EMPTY_STRING,
      };
    });
    let batches = createBatchesArray(arrayToSend);

    handleHideModal();
    activateImportInventorySpinner({
      isVisible: true,
      message: "Importing Inventory...",
    });

    let responseData = {
      reqInvalidInventory: [],
      reqNewInventory: [],
      reqExistingInventory: [],
      updatedInventory: [],
    };

    // do while is for retrying failed batches
    let count = 0;
    do {
      if (count !== 0) {
        batches = createBatchesArray(responseData.reqInvalidInventory);
        responseData.reqInvalidInventory = [];
      }

      count++;
      for (let index = 0; index < batches.length; index++) {
        const batch = batches[index];
        await inventoryService.importFileInventory(batch).then(
          (response) => {
            responseData = {
              reqInvalidInventory: [
                ...(responseData?.reqInvalidInventory || []),
                ...(response?.reqInvalidInventory || []),
              ],
              reqNewInventory: [
                ...(responseData?.reqNewInventory || []),
                ...(response?.reqNewInventory || []),
              ],
              reqExistingInventory: [
                ...(responseData?.reqExistingInventory || []),
                ...(response?.reqExistingInventory || []),
              ],
              updatedInventory: [
                ...(responseData?.updatedInventory || []),
                ...(response?.updatedInventory || []),
              ],
            };
          },
          (error) => {
            responseData = {
              ...responseData,
              reqInvalidInventory: [
                ...responseData.reqInvalidInventory,
                ...batch.map((inv) => ({
                  ...inv,
                  errorMessage: inventoryConstants.IMPORT_INVENTORY_ERROR,
                })),
              ],
            };
            // customToast(error, toastType.ERROR);
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } while (
      responseData.reqInvalidInventory.some((inv) => {
        return inv.errorMessage === inventoryConstants.IMPORT_INVENTORY_ERROR;
      }) &&
      count < inventoryConstants.IMPORT_FAILED_BATCHES_RETRIES
    );

    importCsvFileResponse(
      currentStore,
      responseData,
      undefined,
      validateResponse
    );
  };

  return (
    <Modal
      centered
      animation={true}
      backdrop="static"
      onHide={handleHideModal}
      show={importInventoryModal}
      size={csvData ? "lg" : "md"}
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Import Inventory
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        {csvData ? (
          <>
            <p className="p-0 m-0">* = Required Field</p>
            <Row className="m-0 px-0">
              <div className="column-mapping-table-wrapper container-scrollbar">
                <Row className="m-0 py-3 border-bottom px-0 import-inventory-column-header-wrapper">
                  <Col md={6} className="d-flex align-items-center">
                    <span className="import-inventory-columns-heading">
                      Inventory Columns
                    </span>
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <span className="import-inventory-columns-heading">
                      Your Column Header
                    </span>
                  </Col>
                </Row>
                {predefinedColumns.map((predefinedColumn, index) => (
                  <Row key={index} className="m-0 py-2 border-bottom px-0">
                    <Col md={6} className="d-flex align-items-center">
                      <span className="predefinded-column-label">
                        {predefinedColumn}
                        {requiredColumns.includes(predefinedColumn) && "*"}
                      </span>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <Select
                        options={Object.keys(csvData[0]).map((csvColumn) => ({
                          value: csvColumn,
                          label: csvColumn,
                        }))}
                        value={{
                          value: columnMapping[predefinedColumn] || "",
                          label:
                            columnMapping[predefinedColumn] || "Select Column",
                        }}
                        onChange={(selectedOption) =>
                          handleColumnMapping(
                            predefinedColumn,
                            selectedOption.value,
                            setColumnMapping
                          )
                        }
                        styles={importSelectFieldDropdownStyle}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="w-100"
                      />
                    </Col>
                  </Row>
                ))}
              </div>
              <Col
                md={12}
                className="mt-4 d-flex justify-content-end align-items-center gap-3 px-0"
              >
                <Button
                  type="button"
                  label={buttonNameConstants.BACK}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  handleClick={() => setCsvData("")}
                />
                <Button
                  type="button"
                  label={buttonNameConstants.START_IMPORT}
                  handleClick={() => handleStartImport()}
                  isDisabled={requiredColumns.find((col) => {
                    return !columnMapping.hasOwnProperty(col);
                  })}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <label
                className="d-flex flex-column justify-content-center align-items-center gap-2 upload-csv-wrapper"
                style={{ height: "fit-content" }}
              >
                <UploadCsvIcon />
                Click or drag file to this area to upload
                <CSVReader
                  inputId="CSVReader"
                  key={fileInputKey}
                  inputStyle={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    opacity: 0,
                  }}
                  onFileLoaded={(data, fileInfo) =>
                    handleFileLoaded(
                      data,
                      fileInfo,
                      predefinedColumns,
                      setCsvData,
                      setColumnMapping
                    )
                  }
                  parserOptions={{ header: true, skipEmptyLines: true }}
                  accept=".csv"
                />
              </label>
            </Col>
            <Col md={7}></Col>
            <Col
              md={5}
              className="mt-3 d-flex justify-content-end align-items-center"
            >
              <Button
                type="button"
                label={buttonNameConstants.CANCEL}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                handleClick={() => handleHideModal()}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateImportInventorySpinner: (data) =>
    dispatch(inventoryActions.activateImportInventorySpinner(data)),
  importCsvFileResponse: (
    currentStore,
    responseData,
    undefined,
    validateResponse
  ) =>
    dispatch(
      inventoryActions.importCsvFileResponse(
        currentStore,
        responseData,
        undefined,
        validateResponse
      )
    ),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(ImportInventory);

// following functions are used in import customer component
const validateCsvData = (dataArray) => {
  let isInvalid = false;
  let invalidInventory = [];
  let tempInv = {};
  let handleRes = (message) => {
    return {
      isInvalid,
      message,
    };
  };
  const handleAddInvalidInventory = (index, errorMessage) => {
    tempInv = dataArray.splice(index, 1);
    invalidInventory.push({
      ...tempInv[0],
      errorMessage: errorMessage,
    });
  };

  // filtering out duplicate skus products
  // let skusArr = dataArray.map((row) => row.SKU);
  // skusArr.forEach((sku, index) => {
  //   if (sku !== "" && skusArr.indexOf(sku) !== skusArr.lastIndexOf(sku)) {
  //     // as the original dataArray is spliced so to point to the correct index
  //     handleAddInvalidInventory(
  //       index - invalidInventory.length,
  //       "Invalid Value: File has two records with same SKU."
  //     );
  //   }
  // });

  // if value of the required field is missing (Product Name, Product Type, Category, Quantity, Cost of Goods, Sell Price)
  let notNullMessage = [];
  let invalidValueMessage = [];
  let initialInvalidArrLength = invalidInventory.length;
  let lowerCaseCondition = null;
  const { INVALID_IMPORT_VALUES } = inventoryConstants;
  [...dataArray].forEach((inv, index) => {
    notNullMessage = [];
    invalidValueMessage = [];
    if (inv["Product Name"] === EMPTY_STRING) {
      notNullMessage.push("Product Name");
    }
    if (inv["Product Type"] === EMPTY_STRING) {
      notNullMessage.push("Product Type");
    }
    if (inv["Category"] === EMPTY_STRING) {
      notNullMessage.push("Category");
    }
    if (inv["Condition"] === EMPTY_STRING) {
      notNullMessage.push("Condition");
    }
    if (inv["Quantity"] === EMPTY_STRING) {
      notNullMessage.push("Quantity");
    }
    if (inv["Cost of Goods"] === EMPTY_STRING) {
      notNullMessage.push("Cost of Goods");
    }
    if (inv["Sell Price"] === EMPTY_STRING) {
      notNullMessage.push("Sell Price");
    }

    // if value of the required field is invalid (Quantity, Cost of Goods, Sell Price, Condition)
    if (isNaN(inv["Quantity"]) || Number(inv["Quantity"]) === 0) {
      invalidValueMessage.push("Quantity");
    }
    if (
      inv["Cost of Goods"] !== null &&
      inv["Cost of Goods"] !== "null" &&
      isNaN(inv["Cost of Goods"])
    ) {
      invalidValueMessage.push("Cost of Goods");
    }
    if (
      inv["Sell Price"] !== null &&
      inv["Sell Price"] !== "null" &&
      isNaN(inv["Sell Price"])
    ) {
      invalidValueMessage.push("Sell Price");
    }
    lowerCaseCondition = String(inv["Condition"]).toLocaleLowerCase();
    if (
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.LOOSE &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.NEW &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.BOX_ONLY &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.NEAR_MINT &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.LIGHTLY_PLAYED &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.MODERATELY_PLAYED &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.HEAVILY_PLAYED &&
      inv["Condition"] !== PRODUCT_CONDITIONS_ENUMS.DAMAGED &&
      lowerCaseCondition !== INVALID_IMPORT_VALUES.COMPLETE_IN_BOX &&
      lowerCaseCondition !== INVALID_IMPORT_VALUES.CIB &&
      lowerCaseCondition !== INVALID_IMPORT_VALUES.COMPLETE &&
      lowerCaseCondition !== INVALID_IMPORT_VALUES.LOOSE &&
      lowerCaseCondition !== INVALID_IMPORT_VALUES.NEW &&
      lowerCaseCondition !== INVALID_IMPORT_VALUES.VIDEOGAME &&
      lowerCaseCondition !== INVALID_IMPORT_VALUES.TRADINGCARD
    ) {
      invalidValueMessage.push("Condition");
    }

    if (notNullMessage.length || invalidValueMessage.length) {
      handleAddInvalidInventory(
        index - (invalidInventory.length - initialInvalidArrLength),
        (notNullMessage.length
          ? "Missing Required Field: " + notNullMessage.join("; ")
          : EMPTY_STRING) +
          (invalidValueMessage.length
            ? " Invalid Value: " + invalidValueMessage.join("; ")
            : EMPTY_STRING)
      );
    }
  });

  return {
    isInvalid: false,
    message: "",
    validInventory: dataArray,
    invalidInventory,
  };
};

const cleanCsvData = (dataArray) => {
  // cleaning CSV Data
  let condition = EMPTY_STRING;
  let updatedCondition = EMPTY_STRING;
  let productType = EMPTY_STRING;
  let updatedProductType = EMPTY_STRING;
  dataArray.forEach((data) => {
    // converts the condition (completeInBox, CIB, complete to Complete)
    condition = data.Condition.toLowerCase();
    updatedCondition = "";
    switch (true) {
      case condition === INVALID_IMPORT_VALUES.COMPLETE_IN_BOX ||
        condition === INVALID_IMPORT_VALUES.CIB ||
        condition === INVALID_IMPORT_VALUES.COMPLETE:
        updatedCondition = PRODUCT_CONDITIONS_ENUMS.COMPLETE;
        break;
      case condition.includes(INVALID_IMPORT_VALUES.LOOSE):
        updatedCondition = PRODUCT_CONDITIONS_ENUMS.LOOSE;
        break;
      case condition.includes(INVALID_IMPORT_VALUES.NEW):
        updatedCondition = PRODUCT_CONDITIONS_ENUMS.NEW;
        break;
      default:
        updatedCondition = data.Condition;
        break;
    }
    data.Condition = updatedCondition;

    // converts the product type to other if it's not video game or trading card
    productType = data["Product Type"]
      .replaceAll(" ", EMPTY_STRING)
      .toLowerCase();
    updatedProductType = "";
    switch (true) {
      case productType.includes(INVALID_IMPORT_VALUES.VIDEOGAME):
        updatedProductType = PRODUCT_TYPES_ENUMS.VIDEO_GAME;
        break;
      case productType.includes(INVALID_IMPORT_VALUES.TRADINGCARD):
        updatedProductType = PRODUCT_TYPES_ENUMS.TRADING_CARD;
        break;
      default:
        updatedProductType = PRODUCT_TYPES_ENUMS.OTHER;
        break;
    }
    data["Product Type"] = updatedProductType;

    // converts null to 0 for sell price and COGS price
    if (
      data["Sell Price"] === "0" ||
      data["Sell Price"] === null ||
      data["Sell Price"] === "null" ||
      data["Sell Price"] === ""
    ) {
      data["Sell Price"] = 0;
    }
    if (
      data["Cost of Goods"] === "0" ||
      data["Cost of Goods"] === null ||
      data["Cost of Goods"] === "null" ||
      data["Cost of Goods"] === ""
    ) {
      data["Cost of Goods"] = 0;
    }

    // converts consecutive apostrophes ('') into single apostrophes (apostrophes: ''_'' not quotations: ‘‘_’’)
    data["Product Name"] = String(data["Product Name"]).replaceAll("''", "'");
  });

  return dataArray;
};

export const handleColumnMapping = (
  predefinedColumn,
  selectedCsvColumn,
  setColumnMapping
) => {
  setColumnMapping((prevMapping) => ({
    ...prevMapping,
    [predefinedColumn]: selectedCsvColumn,
  }));
};

export const handleFileLoaded = (
  data,
  fileInfo,
  predefinedColumns,
  setCsvData,
  setColumnMapping
) => {
  // handle empty file
  if (!data.length) {
    customToast(toastMessages.NO_RECORD_FOUND, toastType.ERROR);
    return;
  }
  if (fileInfo.type === "text/csv") {
    const defaultMapping = {};
    const csvColumns = Object.keys(data[0]);

    csvColumns.forEach((csvColumn) => {
      if (predefinedColumns.includes(csvColumn)) {
        defaultMapping[csvColumn] = csvColumn;
      }
    });
    setCsvData(data);
    setColumnMapping(defaultMapping);
  } else {
    customToast(toastMessages.ONLY_CSV_ALLOWED, toastType.ERROR);
  }
};

export const createMappedArray = (
  csvData,
  predefinedColumns,
  columnMapping
) => {
  return csvData.map((row) => {
    const mappedRow = {};
    predefinedColumns.forEach((predefinedColumn) => {
      const csvColumn = columnMapping[predefinedColumn];
      if (csvColumn && row.hasOwnProperty(csvColumn)) {
        mappedRow[predefinedColumn] = row[csvColumn];
      }
    });
    return mappedRow;
  });
};

const createBatchesArray = (arrayToSend) => {
  const batches = [];
  if (arrayToSend.length > inventoryConstants.IMPORT_BATCH_INVENTORY_SIZE) {
    const batchSize = inventoryConstants.IMPORT_BATCH_INVENTORY_SIZE;
    const numberOfBatches = Math.ceil(arrayToSend.length / batchSize);
    for (let i = 0; i < numberOfBatches; i++) {
      const batch = arrayToSend.slice(i * batchSize, (i + 1) * batchSize);
      batches.push(batch);
    }
  } else {
    batches.push(arrayToSend);
  }
  return batches;
};

import { stringifyObject, updateObject } from "../../shared/utility";
import {
  globalConstants,
  transactionConstants,
  transactionDefaultData,
  tradeTransactionDefaultData,
} from "../../constants";
import { setItemToStorage } from "../../system/storage";

//-------InitialState of the reducer
const initialState = {
  allTransaction: {},
  allDraft: [],
  transactionData: transactionDefaultData,
  newTradeData: tradeTransactionDefaultData,
  isAddCustomerModal: false,
  giftCardDetails: "",
  searchTransaction: [],
  returnId: [],
  duplicateHistoryRecords: [],
  tempSku: 0,
  isDraftConfirmationModal: false,
  addTradeBatchInventory: [],
  isTradeBatchInventoryModal: false,
};

//-------Export transaction Reducer
export const transaction = (state = initialState, action) => {
  switch (action.type) {
    //-------Get all transaction success case
    case transactionConstants.GET_ALL_TRANSACTION + globalConstants.SUCCESS:
      return updateObject(state, {
        allTransaction: action.data || {},
      });
    //-------Get all draft success case
    case transactionConstants.GET_ALL_DRAFT + globalConstants.SUCCESS:
      return updateObject(state, {
        allDraft: [...(action?.data.draftTransactions || [])],
      });
    //-------Get all transaction failure case
    case transactionConstants.GET_ALL_TRANSACTION + globalConstants.FAILURE:
      return updateObject(state, {
        allTransaction: {},
      });
    //-------Get all transaction failure case
    case transactionConstants.SET_TRANSACTION_DATA:
      return updateObject(state, {
        transactionData: action.data,
      });
    //-------SET_NEW_TRADE_DATA
    case transactionConstants.SET_NEW_TRADE_DATA:
      return updateObject(state, {
        newTradeData: action.data,
      });
    //-------Get all transaction failure case
    case transactionConstants.TOGGLE_ADD_CUSTOMER_MODAL:
      return updateObject(state, {
        isAddCustomerModal: action.data,
      });
    //-------Get all transaction failure case
    case transactionConstants.GET_GIFT_CARD_DETAILS + globalConstants.SUCCESS:
      return updateObject(state, {
        giftCardDetails: action.data,
      });
    //-------GET_SEARCH_TRANSACTION success case
    case transactionConstants.GET_SEARCH_TRANSACTION + globalConstants.SUCCESS:
      return updateObject(state, {
        searchTransaction: action.data || [],
      });
    //-------GET_SEARCH_TRANSACTION failure case
    case transactionConstants.GET_SEARCH_TRANSACTION + globalConstants.FAILURE:
      return updateObject(state, {
        searchTransaction: [],
      });
    //-------GET_RETURN_ID success case
    case transactionConstants.GET_RETURN_ID + globalConstants.SUCCESS:
      return updateObject(state, {
        returnId: action.data || [],
      });
    //-------GET_RETURN_ID failure case
    case transactionConstants.GET_RETURN_ID + globalConstants.FAILURE:
      return updateObject(state, {
        returnId: [],
      });
    //-------GET_RETURN_ID failure case
    case transactionConstants.BULK_UPLOAD_HISTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        duplicateHistoryRecords: action.data,
      });
    //-------// temp sku
    case transactionConstants.TEMP_SKU + globalConstants.SUCCESS:
      return updateObject(state, {
        tempSku: action.data ? action.data : state.tempSku + 1,
      });
    //-------// temp sku
    case transactionConstants.SET_DRAFT_CONFIRMATION:
      return updateObject(state, {
        isDraftConfirmationModal: action.data || false,
      }); //-------set addBatchInventory
    case transactionConstants.SET_ADD_TRADE_BATCH_INVENTORY +
      globalConstants.SUCCESS:
      return updateObject(state, {
        addTradeBatchInventory: [...action.data],
      });
    //-------set isBatchInventoryModal
    case transactionConstants.SET_IS_TRADE_BATCH_INVENTORY +
      globalConstants.SUCCESS:
      return updateObject(state, {
        isTradeBatchInventoryModal: action.data,
      });
    //-------Default State
    default:
      return state;
  }
};

import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";

import {
  AsendingSortingIcon,
  DesendingSortingIcon,
  DefaultSortingIcon,
} from "../../../assets/icons/iconsProvider";
import { globalConstants } from "../../../constants";
import PaginationComponent from "./PaginationComponent";
import Button from "../Buttons/Button";
import { buttonNameConstants, buttonTypeConstants } from "../../../constants";
import { DeleteIcon, PrintIcon } from "../../../assets/icons/iconsProvider";

const defaultLocalGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

const DataTableComponent = (props) => {
  const {
    columns,
    data = [],
    keyField,
    title,
    responsive = true,
    striped = false,
    highlightOnHover = false,
    pointerOnHover = false,
    dense = false,
    noTableHead = false,
    persistTableHead = true,
    noDataComponent = (
      <div className="w-100 d-flex justify-content-center align-items-center p-3 no-data">
        {props.isLoading ? "Loading..." : "No data found"}
      </div>
    ),
    disabled = false,
    direction,
    onRowClicked,
    onRowDoubleClicked,
    onRowMouseEnter,
    onRowMouseLeave,
    onColumnOrderChange,
    defaultSortFieldId,
    defaultSortAsc = true,
    sortIcon,
    sortServer = false,
    onSort,
    selectableRows = false,
    clearSelectedRows,
    selectedRows,
    handleRowSelectionChange,
    expandableRows = false,
    paginationServer = false,
    paginationRowsPerPageOptions = [5, 10, 25, 50, 100],
    onChangePage,
    onChangeRowsPerPage,
    noHeader = true,
    fixedHeader = true,
    subHeader = false,
    gridState = defaultLocalGridState,
    setGridState,
    total,
    key,
    handleDeleteAllClick,
    handlePrintLabelClick,
    isPagination = true,
    className = "",
  } = props;

  const [rows, setRows] = useState(data);

  const pageSizes = [];
  paginationRowsPerPageOptions.forEach((option) => {
    pageSizes.push({ value: option, label: `${option}` });
  });

  const formattedColumns = columns.map((column) => {
    if (column.sortable) {
      return {
        ...column,
        name: (
          <div className=" d-flex w-100">
            {column.name}
            <div className="ml-2 d-flex justify-content-center align-items-center">
              {column.sortDirection === null && (
                <DefaultSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.ASC && (
                <AsendingSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.DESC && (
                <DesendingSortingIcon className="ms-1 cursor-pointer" />
              )}
            </div>
          </div>
        ),
      };
    } else {
      return column;
    }
  });

  useEffect(() => {
    if (data.length) {
      setRows(data.slice(0, gridState.pageSize));
    } else {
      setRows(data);
    }
  }, [data, gridState.pageSize]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Col xs={12} className="h-100">
        <div className="w-100">
          <DataTable
            className={className}
            columns={formattedColumns}
            data={rows}
            keyField={keyField}
            title={title}
            responsive={responsive}
            striped={striped}
            highlightOnHover={highlightOnHover}
            pointerOnHover={pointerOnHover}
            dense={dense}
            noTableHead={noTableHead}
            persistTableHead={persistTableHead}
            noDataComponent={noDataComponent}
            disabled={disabled}
            direction={direction}
            onRowClicked={onRowClicked}
            onRowDoubleClicked={onRowDoubleClicked}
            onRowMouseEnter={onRowMouseEnter}
            onRowMouseLeave={onRowMouseLeave}
            onColumnOrderChange={onColumnOrderChange}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc}
            sortIcon={sortIcon}
            sortServer={sortServer}
            onSort={onSort}
            selectedRows={selectedRows}
            selectableRows={selectableRows}
            clearSelectedRows={clearSelectedRows}
            onSelectedRowsChange={handleRowSelectionChange}
            expandableRows={expandableRows}
            noHeader={noHeader}
            fixedHeader={fixedHeader}
            subHeader={subHeader}
            subHeaderComponent={
              <div className="d-flex gap-2 ">
                <Button
                  label={buttonNameConstants.DELETE}
                  className="w-100"
                  buttonType={buttonTypeConstants.RED_BUTTON}
                  IconImage={DeleteIcon}
                  type="button"
                  handleClick={() => handleDeleteAllClick()}
                />
                <Button
                  label={buttonNameConstants.PRINT_LABEL}
                  className="w-100"
                  type="button"
                  IconImage={PrintIcon}
                  buttonType={buttonTypeConstants.LIGHT_PURPLE}
                  handleClick={() => handlePrintLabelClick()}
                />
              </div>
            }
            subHeaderAlign="right"
          />
        </div>
      </Col>
      {isPagination && (
        <PaginationComponent
          data={data}
          setRows={setRows}
          paginationServer={paginationServer}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          gridState={gridState}
          setGridState={setGridState}
          total={total}
        ></PaginationComponent>
      )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isLoading: state.system.isLoading,
});

//-------Export System Users Component
export default connect(mapStateToProps, null)(DataTableComponent);

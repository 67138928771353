import React, { Suspense } from "react";
import RouterWrapper from "./router/RouterWrapper";
import { BrowserRouter as Router } from "react-router-dom";

import PageRedireaction from "./PageRedireaction";

const App = () => {
  return (
    <Suspense fallback={null}>
      <Router>
        <PageRedireaction />
        <RouterWrapper />
      </Router>
    </Suspense>
  );
};

export default App;

import {
  putRequest,
  getRequest,
  postRequest,
  deleteRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Add new company
const addNewCompany = (data) => {
  return postRequest(`company`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Get all Comapnies from server
const getAllCompanies = (isAllCompanies) => {
  return getRequest(
    `company${isAllCompanies ? "?isAllCompanies=true" : ""}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
}; //-------Get Company By Id
const getCompanyById = (companyId) => {
  return getRequest(`company/${companyId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Delete Company
const deleteCompany = (comapnyId) => {
  return deleteRequest(`company/${comapnyId}`).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update company
const updateCompany = (data) => {
  return putRequest(`company`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export company Services
export const companyService = {
  deleteCompany,
  addNewCompany,
  updateCompany,
  getCompanyById,
  getAllCompanies,
};

import React from "react";
import { Formik, Form } from "formik";
import { Col, Modal, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import IconSelectField from "../../../../shared/components/selectField/IconSelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";
import { addCustomSubCategoryValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";

const AddCustomSubCategory = ({
  storeId,
  customTypes,
  customCategories,
  AddCustomSubCategory,
  subCategoryModalFormData,
  isCustomSubCategoryModal,
  updateCustomSubCategories,
  setSubCategoryModalFormData,
  toggleCustomSubCategoryModal,
}) => {
  const initialValues = {
    type:
      customCategories.find((item) => {
        return item.id === subCategoryModalFormData?.parentCategoryId;
      })?.productTypeId || globalConstants.EMPTY_STRING,
    categoryName:
      subCategoryModalFormData?.parentCategoryId ||
      globalConstants.EMPTY_STRING,
    subCategoryName:
      subCategoryModalFormData?.name || globalConstants.EMPTY_STRING,
  };

  const handleHideModal = () => {
    toggleCustomSubCategoryModal();
    setSubCategoryModalFormData(globalConstants.EMPTY_STRING);
  };

  const handleAddCustomSubCategorySubmit = (values) => {
    if (subCategoryModalFormData?.id) {
      updateCustomSubCategories(
        storeId,
        {
          name: values.subCategoryName,
          storeId: storeId,
          parentCategoryId: values.categoryName,
          parentCategoryName: customCategories.find(
            (category) => category.id === values.categoryName
          ).name,
          parentProductTypeName: customTypes.find(
            (type) => type.id === values.type
          ).productType,
          parentProductTypeId: values.type,
          isCustom: true,
          isActive: true,
          id: subCategoryModalFormData.id,
        },
        handleHideModal
      );
    } else {
      AddCustomSubCategory(
        storeId,
        {
          name: values.subCategoryName,
          storeId: storeId,
          parentCategoryId: values.categoryName,
          parentProductTypeName: customTypes.find(
            (type) => type.id === values.type
          ).productType,
          parentProductTypeId: values.type,
          parentCategoryName: customCategories.find(
            (category) => category.id === values.categoryName
          ).name,
          isCustom: true,
        },
        handleHideModal
      );
    }
  };

  const handleTypeChange = (selectedType, formikProps) => {
    formikProps.setValues("categoryName", "");
  };

  return (
    <Modal
      size="md"
      centered
      animation={true}
      backdrop="static"
      onHide={handleHideModal}
      show={isCustomSubCategoryModal}
      className={`add-inventory-modal`}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            {subCategoryModalFormData?.id
              ? "Update Custom Sub-Category"
              : "Add Custom Sub-Category"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body d-flex ">
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddCustomSubCategorySubmit}
          validate={addCustomSubCategoryValidationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form className="w-100">
              <Row className="m-0 w-100">
                <Col
                  md={5}
                  className="d-flex align-items-center text-muted mb-3 field-label-name"
                >
                  Select Type:
                </Col>
                <Col md={7} className="mb-3">
                  <IconSelectField
                    name="type"
                    options={
                      customTypes
                        ?.map((item) => ({
                          value: item.id,
                          label: item.productType,
                        }))
                        .filter(
                          (item) => item.label !== PRODUCT_TYPES_ENUMS.ALL
                        ) || []
                    }
                    placeHolder="Type"
                    isClearable={false}
                    customOnChange={(selectedType) =>
                      handleTypeChange(selectedType, {
                        values,
                        setValues: setFieldValue,
                      })
                    }
                  />
                </Col>
                <Col
                  md={5}
                  className="d-flex align-items-center text-muted mb-3 field-label-name"
                >
                  Select Category:
                </Col>
                <Col md={7} className="mb-3">
                  <IconSelectField
                    name="categoryName"
                    options={
                      customCategories
                        .filter((item) => item.productTypeId === values.type)
                        ?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        })) || []
                    }
                    placeHolder="Category"
                    isClearable={false}
                  />
                </Col>
                <Col
                  md={5}
                  className="d-flex align-items-center text-muted mb-3 field-label-name"
                >
                  Create Sub-Category:
                </Col>
                <Col md={7} className="mb-3">
                  <InputTextField
                    name="subCategoryName"
                    placeHolder="Sub-Category..."
                  />
                </Col>
                <Col md={12} className="d-flex align-items-center gap-3">
                  <Button
                    type="button"
                    className={"w-100"}
                    handleClick={handleHideModal}
                    label={buttonNameConstants.CANCEL}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                  />
                  <Button
                    className={"w-100"}
                    type="submit"
                    label={
                      subCategoryModalFormData?.id
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.ADD
                    }
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddCustomSubCategory;

import { globalConstants, inventoryConstants } from "../../constants";
import { updateObject } from "../../shared/utility";

//-------InitialState of the inventory reducer
const initialState = {
  allInventory: {},
  inventoryHistory: {},
  inventorySummary: {},
  allCategories: [],
  priceChartingProductInfo: "",
  inventoryProducts: [],
  productTags: [],
  isAddModalOpen: false,
  thresholdData: [],
  priceChangesData: [],
  isInventoryImporting: { isVisible: false, message: "" },
  addBatchInventory: [],
  isBatchInventoryModal: false,
};

//-------Export Inventory Reducer
export const inventory = (state = initialState, action) => {
  switch (action.type) {
    //-------get all inventory success
    case inventoryConstants.GET_ALL_INVENTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        allInventory: action?.data,
      });
    //-------get all inventory failure
    case inventoryConstants.GET_ALL_INVENTORY + globalConstants.FAILURE:
      return updateObject(state, {
        allInventory: {},
      });
    //-------get  inventory history
    case inventoryConstants.GET_INVENTORY_HISTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        inventoryHistory: action.data,
      });
    case inventoryConstants.GET_INVENTORY_HISTORY + globalConstants.FAILURE:
      return updateObject(state, {
        inventoryHistory: {},
      });
    //-------invenory summary success
    case inventoryConstants.INVENTORY_HISTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        inventorySummary: action.data,
      });
    //-------invenory summary success
    case inventoryConstants.INVENTORY_HISTORY + globalConstants.FAILURE:
      return updateObject(state, {
        inventorySummary: {},
      });
    //-------get all categories success
    case inventoryConstants.GET_CATEGORIES + globalConstants.SUCCESS:
      return updateObject(state, {
        allCategories: action.data,
      });
    //-------get all categories failure
    case inventoryConstants.GET_CATEGORIES + globalConstants.FAILURE:
      return updateObject(state, {
        allCategories: [],
      });
    //-------get GET_PRICECHARTING_PRODUCT_BY_ID success
    case inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID +
      globalConstants.SUCCESS:
      return updateObject(state, {
        priceChartingProductInfo: action.data,
      });
    //-------get GET_PRICECHARTING_PRODUCT_BY_ID failure
    case inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID +
      globalConstants.FAILURE:
      return updateObject(state, {
        priceChartingProductInfo: globalConstants.EMPTY_STRING,
      });
    //-------empty porduct object
    case inventoryConstants.EMPTY_THE_OBJECT:
      return updateObject(state, {
        priceChartingProductInfo: globalConstants.EMPTY_STRING,
        inventoryProducts: [],
      });

    //-------get inventory products sucess
    case inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID +
      globalConstants.SUCCESS:
      return updateObject(state, {
        inventoryProducts: action.data,
      });
    //-------get inventory products failure
    case inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID +
      globalConstants.FAILURE:
      return updateObject(state, {
        inventoryProducts: action.data,
      });
    //-------get tags sucess
    case inventoryConstants.GET_TAGS + globalConstants.SUCCESS:
      return updateObject(state, {
        productTags: action.data,
      });
    //-------get tags failure
    case inventoryConstants.GET_TAGS + globalConstants.FAILURE:
      return updateObject(state, {
        productTags: action.data,
      });
    //-------toggle Modal successfully
    case inventoryConstants.TOGGLE_ADD_MODAL:
      return updateObject(state, {
        isAddModalOpen: action.data,
      });
    //-------toggle Modal successfully
    case inventoryConstants.SET_POKEMON_OBJECT:
      return updateObject(state, {
        priceChartingProductInfo: action.data,
      });
    //-------get threshold data
    case inventoryConstants.GET_PRICE_CHANGE_THRESHOLD +
      globalConstants.SUCCESS:
      return updateObject(state, {
        thresholdData: action.data,
      });
    //-------get threshold data
    case inventoryConstants.GET_PRICE_CHANGE_DATA + globalConstants.SUCCESS:
      return updateObject(state, {
        priceChangesData: action.data,
      });

    //-------set addBatchInventory
    case inventoryConstants.SET_ADD_BATCH_INVENTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        addBatchInventory: [...action.data],
      });
    //-------set isBatchInventoryModal
    case inventoryConstants.SET_IS_BATCH_INVENTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        isBatchInventoryModal: action.data,
      });
    //-------set spinner on importing inventory
    case inventoryConstants.IS_INVENTORY_IMPORTING:
      return updateObject(state, {
        isInventoryImporting: action.data,
      });
    //-------Default State
    default:
      return state;
  }
};

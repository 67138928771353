import React, { useRef } from "react";
import { Tooltip } from "react-tooltip";

const ClickableTooltip = ({
  children,
  anchorSelect,
  id,
  key,
  tooltipRef,
  className,
  offset = 0,
  openOnClick = false,
  place = "bottom-end",
}) => {
  return (
    <Tooltip
      ref={tooltipRef}
      anchorSelect={anchorSelect}
      place={place}
      noArrow={true}
      clickable
      openOnClick={openOnClick}
      className={`tooltip px-0 ${className}`}
      id={id}
      key={key}
      offset={offset}
    >
      {children}
    </Tooltip>
  );
};

export default ClickableTooltip;

import { updateObject } from "../../shared/utility";
import { dashboardConstants, globalConstants } from "../../constants";

//-------InitialState of the device reducer
const initialState = {
  atAGlance: "",
  storeTransactionHistory: [],
};

//-------Export Device Reducer
export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    //-------Get all devices success case
    case dashboardConstants.GET_AT_A_GLANCE + globalConstants.SUCCESS:
      return updateObject(state, {
        atAGlance: action.data,
      });

    //-------Get all devices success case
    case dashboardConstants.GET_STORE_HISTORY + globalConstants.SUCCESS:
      return updateObject(state, {
        storeTransactionHistory: action.data,
      });
    //-------Default State
    default:
      return state;
  }
};

import { custom, success } from "./actionsUtilities";
import { userService } from "../../services";
import {
  routesPathConstants,
  systemConstants,
  toastMessages,
  toastType,
  userConstants,
  storeConstants,
  authenticationConstants,
} from "../../constants";
import { systemActions } from "./system.action";
import { customToast } from "../../shared/utility";
import { storeActions } from "./store.action";
import { getUserInfoFromToken, encryptObject } from "../../system/encryption";
import { setItemToStorage } from "../../system/storage";
import { authenticationActions } from "./authentication.action";

//-------Get all users
const getAllUsers = (id) => {
  return (dispatch) => {
    dispatch(
      custom(userConstants.GET_ALL_USERS, systemConstants.SPINNER_ACTIVATE)
    );
    userService
      .getAllUsers(id, false)
      .then(
        (response) => {
          dispatch(success(response, userConstants.GET_ALL_USERS));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            userConstants.GET_ALL_USERS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get user by id
const getUserById = (id) => {
  return (dispatch) => {
    dispatch(
      custom(userConstants.GET_USER_BY_ID, systemConstants.SPINNER_ACTIVATE)
    );
    userService
      .getUserById(id)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            userConstants.GET_USER_BY_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add new user
const addNewUser = (data) => {
  return (dispatch) => {
    dispatch(custom(userConstants.ADD_USER, systemConstants.SPINNER_ACTIVATE));
    userService
      .addNewUser(data)
      .then(
        (response) => {
          customToast(
            toastMessages.EMPLOYEE_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.SETTINGS_USERS)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(userConstants.ADD_USER, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

//-------Update user
const updateUser = (data) => {
  return (dispatch) => {
    dispatch(
      custom(userConstants.UPDATE_USER, systemConstants.SPINNER_ACTIVATE)
    );
    userService
      .updateUser(data, false)
      .then(
        (response) => {
          customToast(
            toastMessages.EMPLOYEE_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.SETTINGS_USERS)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(userConstants.UPDATE_USER, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

//-------Delete user
const deleteUser = (id, storeId, currentUser) => {
  return (dispatch) => {
    dispatch(
      custom(userConstants.DELETE_USER, systemConstants.SPINNER_ACTIVATE)
    );
    userService
      .deleteUser(id, storeId, false)
      .then(
        () => {
          if (currentUser?.id === id) {
            dispatch(authenticationActions.logout());
          }
          customToast(
            toastMessages.EMPLOYEE_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getAllUsers(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(userConstants.DELETE_USER, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

const uploadLogo = (logoPath, data) => {
  return (dispatch) => {
    dispatch(
      custom(storeConstants.UPLOAD_LOGO, systemConstants.SPINNER_ACTIVATE)
    );
    userService
      .uploadLogo(logoPath)
      .then(
        (response) => {
          const filePath = response?.data?.data;
          const userData = { ...data, logo: filePath };
          dispatch(updateUserInformation(userData));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(storeConstants.UPLOAD_LOGO, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

const updateUserInformation = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        storeConstants.UPDATE_RECEIPT_DETAILS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    userService
      .updateUserInformation(data, false)
      .then(
        (response) => {
          customToast(
            toastMessages.USER_INFORMATION_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          // Refreshing the logged-in user's JWT token since the previous token is now invalid due to changes in user information.
          if (response.accessToken) {
            const encryptedPayload = encryptObject({
              ...response,
            });
            setItemToStorage(process.env.REACT_APP_PAYLOAD, encryptedPayload);

            const user = getUserInfoFromToken(response.accessToken);
            dispatch(
              success(
                { ...response, user: user },
                authenticationConstants.USER_LOGIN
              )
            );
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            storeConstants.UPDATE_RECEIPT_DETAILS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const importUser = (data, setIsModal) => {
  return (dispatch) => {
    dispatch(custom(null, systemConstants.SPINNER_ACTIVATE));
    userService
      .importUser(data)
      .then(
        (response) => {
          customToast(response.message, toastType.SUCCESS);
          setIsModal(false);
          dispatch(storeActions.getStores());
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            storeConstants.UPDATE_RECEIPT_DETAILS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Authentication Actions
export const userActions = {
  addNewUser,
  getAllUsers,
  getUserById,
  deleteUser,
  updateUser,
  uploadLogo,
  updateUserInformation,
  importUser,
};

import {
  postRequest,
  getRequest,
  deleteRequest,
  putRequest,
  uploadLogoRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get all users from server
const getAllUsers = (id, isSystemUser) => {
  return getRequest(`users/${isSystemUser}/?storeId=${id}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get user by id from server
const getUserById = (id) => {
  return getRequest(`users`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add new user
const addNewUser = (data) => {
  return postRequest("register", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update user
const updateUser = (data, isSystemUser) => {
  return putRequest(`users/${isSystemUser}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete Users
const deleteUser = (id, storeId, isSystemUser) => {
  return deleteRequest(`users/${isSystemUser}/${id}/?storeId=${storeId}`).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const uploadLogo = (data) => {
  return uploadLogoRequest("upload", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const updateUserInformation = (data, isSystemUser) => {
  return putRequest(`users/${isSystemUser}`, data).then(
    (response) => response.data.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const importUser = (data) => {
  return putRequest(`register`, data).then(
    (response) => response.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export user Services
export const userService = {
  getAllUsers,
  addNewUser,
  getUserById,
  deleteUser,
  updateUser,
  uploadLogo,
  updateUserInformation,
  importUser,
};

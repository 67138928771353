import { updateObject } from "../../shared/utility";
import { globalConstants, tradeInDiscount } from "../../constants";

//-------InitialState of the reducer
const initialState = {
  allDiscount: [],
  markupPresets: [],
  tradeinMargin: {},
};

//-------Export tradeInDiscount Reducer
export const tradeDiscount = (state = initialState, action) => {
  switch (action.type) {
    //-------get GET_ALL_DISCOUNT success
    case tradeInDiscount.GET_ALL_DISCOUNT + globalConstants.SUCCESS:
      return updateObject(state, {
        allDiscount: action.data,
      });
    //-------get GET_ALL_DISCOUNT success
    case tradeInDiscount.GET_ALL_MARKUP + globalConstants.SUCCESS:
      return updateObject(state, {
        markupPresets: action.data,
      });
    case tradeInDiscount.GET_ALL_MARGIN + globalConstants.SUCCESS:
      return updateObject(state, {
        tradeinMargin: action.data,
      });
    default:
      return state;
  }
};

import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";

//-------TextareaField component start here
const TextareaField = (props) => {
  const { name, placeHolder, label } = props;

  //-------Return TextareaField Component
  return (
    <div>
      <label className="field-label" htmlFor={name}>
        {label && label}
      </label>
      <div className="textarea-field-wrapper">
        <Field
          className="input-textfield text-area-field"
          as="textarea"
          maxLength={500}
          id={name}
          name={name}
          placeholder={placeHolder}
          autoComplete="off"
        />
      </div>
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------TextareaField component end here
};

//-------Export InputTextField Component
export default TextareaField;

import React from "react";
import { AddIcon, CloseIcon } from "../../../assets/icons/iconsProvider";

const ProductTag = ({ label, selected, onClick }) => {
  return (
    <div className={`tag ${selected ? "selected" : ""}`} onClick={onClick}>
      {label}
      <span>
        {selected ? (
          <CloseIcon className="add-tag-icon" />
        ) : (
          <AddIcon className="add-tag-icon" />
        )}
      </span>
    </div>
  );
};

export default ProductTag;

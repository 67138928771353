import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";

import { inventoryService } from "../../../../services";
import Button from "../../../../shared/components/Buttons/Button";
import {
  handleCashOfferValues,
  handleTradeOfferValues,
  customToast,
  formatDate,
  getTypeOrCategoryObject,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  getMarginDropdownValue,
} from "../../../../shared/utility";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  toastType,
  inventoryConstants,
  transactionConstants,
  categoryDropdownConstants,
} from "../../../../constants";
import {
  itemOrganizationActions,
  systemActions,
  transactionActions,
} from "../../../../redux/actions";
import { addTradeCustomItemValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import SelectField from "../../../../shared/components/selectField/SelectField";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import InputTextArea from "../../../../shared/components/inputTextArea/InputTextArea";
import IconSelectField from "../../../../shared/components/selectField/IconSelectField";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../../system/globalEnums";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";

const AddTradeCustomItem = (props) => {
  const {
    inventory,
    transaction,
    currentStore,
    tradeDiscount,
    addItemInCart,
    customItemModal,
    generateTempSku,
    activateSpinner,
    itemOrganization,
    deactivateSpinner,
    toggleCustomItemModal,
  } = props;

  const { productTags } = inventory;

  const { tempSku } = transaction;

  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;
  const { tradeinMargin } = tradeDiscount;

  const [ratio, setRatio] = useState(
    tradeinMargin?.globalMargin?.tradeinMarginPercentage /
      tradeinMargin?.globalMargin?.cashMarginPercentage
  );
  const [customProductSelectedTags, setCustomProductSelectedTags] = useState(
    []
  );

  //-------onselectedtagchange
  const onCustomProductSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag.label);
    setCustomProductSelectedTags(selectedTagList);
  };
  //-------initial values for custom item
  const initialValues = {
    quantity: 1,
    cashOffer: globalConstants.EMPTY_STRING,
    condition: globalConstants.EMPTY_STRING,
    tradeOffer: globalConstants.EMPTY_STRING,
    subcategory: globalConstants.EMPTY_STRING,
    description: globalConstants.EMPTY_STRING,
    productType: globalConstants.EMPTY_STRING,
    product_name: globalConstants.EMPTY_STRING,
    inStockPrice: globalConstants.EMPTY_STRING,
    category_name: globalConstants.EMPTY_STRING,
  };

  //-------handle hide modal
  const handleHideModal = () => {
    toggleCustomItemModal();
  };

  //-------get Sku Number
  const getSkuNumber = async () => {
    return inventoryService.getSkuNumber(currentStore?.id).then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };

  const handleCustomItemSubmit = async (values) => {
    generateTempSku();
    const date_added = formatDate(new Date());
    const product_id = Math.floor(Math.random() * 100000).toString();
    activateSpinner(transactionConstants.ADD_CUSTOM_ITEM);
    // const skuNumber = await getSkuNumber();

    const { inStockPrice, quantity, ...otherValues } = values;
    const miscellaneousItem = {
      ...otherValues,
      upc: "",
      tags: [
        ...(customProductSelectedTags || []),
        inventoryConstants.CUSTOM_ITEM_TAG,
      ],
      max: 9999,
      imgUrl: "",
      cardNumber: "",
      cardRarity: "",
      category_name: getTypeOrCategoryObject(
        customCategories,
        values.category_name
      )?.name,
      productType: getTypeOrCategoryObject(customTypes, values.productType)
        ?.productType,
      subcategory:
        getTypeOrCategoryObject(customSubCategories, values.subcategory)
          ?.name || "",
      date_added: date_added,
      additionalCheckList: [],
      epid: globalConstants.EMPTY_STRING,
      apiSource: inventoryConstants.CUSTOM_ITEM,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      product_id: inventoryConstants.CUSTOM_ITEM,
      sku: [`${inventoryConstants.CUSTOM_ITEM} ${tempSku}`],
      price: {
        unit_sell_price: values.inStockPrice,
        quantity: values.quantity,
        type: values.condition,
        marketPrice: 0,
      },
      ratio: ratio,
      store: { id: currentStore.id, name: currentStore.storeName },
      // condition: selectedCondition.value,
    };
    addItemInCart(miscellaneousItem);

    handleHideModal();
    deactivateSpinner(transactionConstants.ADD_CUSTOM_ITEM);
  };

  // const handleTradeChangeValue = (e, setFieldValue) => {
  //   const value = parseFloat(Number(e).toFixed(2));
  //   const percentValue = parseFloat(value / ratio).toFixed(2);
  //   setFieldValue("tradeOffer", value);
  //   setFieldValue("cashOffer", percentValue);
  // };

  const handleTypeChange = (option, setFieldValue, values) => {
    setFieldValue("productType", option.value);
    setFieldValue("condition", globalConstants.EMPTY_STRING);
    setFieldValue("category_name", globalConstants.EMPTY_STRING);
    setFieldValue("subcategory", globalConstants.EMPTY_STRING);

    let tempRes = getMarginDropdownValue(
      0,
      tradeinMargin,
      option.label,
      {
        consoleName: globalConstants.EMPTY_STRING,
        genre: globalConstants.EMPTY_STRING,
      },
      false
    );
    setRatio(
      tempRes.marginObject.tradeinMarginPercentage /
        tempRes.marginObject.cashMarginPercentage
    );
  };

  const handleCategoryChange = (option, setFieldValue, values) => {
    setFieldValue("category_name", option.value);
    setFieldValue("subcategory", globalConstants.EMPTY_STRING);
    let tempRes = getMarginDropdownValue(
      0,
      tradeinMargin,
      getTypeOrCategoryObject(customTypes, values.type)?.productType,
      {
        consoleName: option.label,
        genre: globalConstants.EMPTY_STRING,
      },
      false
    );
    setRatio(
      tempRes.marginObject.tradeinMarginPercentage /
        tempRes.marginObject.cashMarginPercentage
    );
  };

  const handleSubCategoryChange = (option, setFieldValue, values) => {
    setFieldValue("subcategory", option.value);
    let tempRes = getMarginDropdownValue(
      0,
      tradeinMargin,
      getTypeOrCategoryObject(customTypes, values.type)?.productType,
      {
        consoleName: getTypeOrCategoryObject(
          customCategories,
          values.category_name
        )?.name,
        genre: option.label,
      },
      false
    );
    setRatio(
      tempRes.marginObject.tradeinMarginPercentage /
        tempRes.marginObject.cashMarginPercentage
    );
  };

  useEffect(() => {
    setRatio(
      tradeinMargin?.globalMargin?.tradeinMarginPercentage /
        tradeinMargin?.globalMargin?.cashMarginPercentage
    );
  }, [tradeinMargin]);

  return (
    <Modal
      show={customItemModal}
      size="lg"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Trade Custom Item
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body p-1">
        <Formik
          initialValues={initialValues}
          onSubmit={handleCustomItemSubmit}
          validate={addTradeCustomItemValidationSchema}
        >
          {({ resetForm, setFieldValue, values }) => (
            <Form>
              <Row className="m-0">
                <Col md={9} className="mb-3 ps-md-0">
                  <InputTextField
                    name="product_name"
                    label="Title"
                    placeHolder="Enter Title"
                    className="border"
                  />
                </Col>
                <Col md={3} className="mb-3 pe-md-0">
                  <InputNumberField
                    label="Quantity"
                    name="quantity"
                    placeHolder="0"
                    className="add-inventory-quantity-field"
                    isConvertToDecimal={false}
                    step={"1"}
                    isHandleKeyDown={true}
                    isBorder={true}
                  />
                </Col>
                <Col md={12} className="mb-3 px-0">
                  <InputTextArea
                    name="description"
                    label="Description (Optional)"
                    placeHolder="Enter Description"
                    className={"border"}
                  />
                </Col>
                <Col md={12} className="add-inventory-hero-wrapper mb-3">
                  <Row className="m-0">
                    <Col md={12} className="mb-2 tab-heading text-muted">
                      Product Categorization
                    </Col>
                    <Col md={3} className="mb-2">
                      <IconSelectField
                        label="Type"
                        options={getCustomTypesOptionList(customTypes).filter(
                          (item) => item.label !== PRODUCT_TYPES_ENUMS.ALL
                        )}
                        isClearable={false}
                        name="productType"
                        customOnChange={(option) =>
                          handleTypeChange(option, setFieldValue, values)
                        }
                      />
                    </Col>
                    <Col md={3} className="mb-2">
                      <IconSelectField
                        label="Category"
                        options={getCustomCategoriesOptionList(
                          customCategories,
                          values.productType
                        )}
                        name="category_name"
                        customOnChange={(option) =>
                          handleCategoryChange(option, setFieldValue, values)
                        }
                        isClearable={false}
                      />
                    </Col>
                    <Col md={3} className="mb-2">
                      <IconSelectField
                        label="Sub-Category"
                        options={getCustomSubCategoriesOptionList(
                          customSubCategories,
                          values.category_name,
                          null,
                          values.productType,
                          false,
                          customCategories,
                          customTypes
                        )}
                        name="subcategory"
                        isClearable={false}
                        customOnChange={(option) =>
                          handleSubCategoryChange(option, setFieldValue, values)
                        }
                      />
                    </Col>
                    <Col md={3} className="mb-2">
                      <IconSelectField
                        label="Condition"
                        options={
                          getTypeOrCategoryObject(
                            customTypes,
                            values.productType
                          )?.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
                            ? [
                                ...inventoryConstants.VIDEO_GAME_CONDITION,
                                {
                                  value: PRODUCT_CONDITIONS_ENUMS.USED,
                                  label: PRODUCT_CONDITIONS_ENUMS.USED,
                                },
                              ]
                            : getTypeOrCategoryObject(
                                customTypes,
                                values.productType
                              )?.productType ===
                              PRODUCT_TYPES_ENUMS.TRADING_CARD
                            ? [
                                ...inventoryConstants.TRADING_CARD_CONDITION,
                                ...inventoryConstants.OTHER_CONDITION,
                              ]
                            : inventoryConstants.OTHER_CONDITION
                        }
                        placeHolder="Condition..."
                        name="condition"
                        isClearable={false}
                      />
                    </Col>
                    <Col md={12} className="mb-3">
                      <InputTextField
                        name="upc"
                        label="UPC (Optional)"
                        placeHolder="111000294854"
                        className="border"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="add-inventory-hero-wrapper mb-3">
                  <Row className="m-0">
                    <Col md={12} className="mb-2 tab-heading text-muted">
                      Product Tags
                    </Col>
                    <Col md={12}>
                      <ProductTagList
                        tagsList={[]}
                        suggestedArrayList={productTags}
                        onSelectTagsChange={onCustomProductSelectTagsChange}
                        customProductTags={true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="add-inventory-hero-wrapper mb-3">
                  <Row className="m-0">
                    <Col md={6}>
                      <Row className="m-0 w-100 h-100">
                        <Col md={12} className="mb-2 tab-heading text-muted">
                          Pricing
                        </Col>
                        <Col md={12} className="mb-2 mt-auto">
                          <InputNumberField
                            label="Stock Price"
                            name="inStockPrice"
                            placeHolder="0.00"
                            className="add-inventory-quantity-field"
                            isBorder={true}
                            IconImage={DollarIcon}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="m-0 h-100 w-100">
                        <Col
                          md={12}
                          className="mb-2 d-flex justify-content-between align-items-center w-100 gap-3"
                        >
                          <span
                            className="text-nowrap"
                            style={{ width: "100px" }}
                          >
                            Cash Offer
                          </span>
                          <InputNumberField
                            name="cashOffer"
                            placeHolder="0.00"
                            className="add-inventory-quantity-field w-100"
                            isBorder={true}
                            IconImage={DollarIcon}
                            handleChange={(e) =>
                              handleCashOfferValues(e, setFieldValue, ratio)
                            }
                            outerWrapperClassName="w-100"
                          />
                        </Col>
                        <Col
                          md={12}
                          className="mb-2 d-flex mt-auto justify-content-between align-items-center w-100 gap-3"
                        >
                          <span
                            className="text-nowrap"
                            style={{ width: "100px" }}
                          >
                            Trade Offer
                          </span>
                          <InputNumberField
                            name="tradeOffer"
                            placeHolder="0.00"
                            className="add-inventory-quantity-field w-100"
                            isBorder={true}
                            IconImage={DollarIcon}
                            handleChange={(e) =>
                              handleTradeOfferValues(e, setFieldValue, ratio)
                            }
                            outerWrapperClassName="w-100"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-end gap-2 ms-auto px-0"
                >
                  <Button
                    label={buttonNameConstants.CANCEL}
                    className="w-100"
                    type="button"
                    handleClick={() => handleHideModal()}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                  />
                  <Button
                    label={buttonNameConstants.ADD_TO_CART}
                    className="w-100"
                    type="submit"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  transaction: state.transaction,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  generateTempSku: () => dispatch(transactionActions.generateTempSku()),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeCustomItem);

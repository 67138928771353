import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";

import { userActions } from "../../../../redux/actions";
import Button from "../../../../shared/components/Buttons/Button";
import SelectField from "../../../../shared/components/selectField/SelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import { importUserValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  userConstants,
  globalConstants,
  buttonNameConstants,
} from "../../../../constants";

const ImportUser = (props) => {
  let { isModal, setIsModal, importUser, currentStore } = props;
  const initialValues = {
    email: globalConstants.EMPTY_STRING,
    role: userConstants.USER_ROLES[1].value,
  };
  //   handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    importUser({ storeId: currentStore.id, ...values }, setIsModal);
  };

  return (
    <Modal
      show={isModal}
      size={"md"}
      onHide={() => {
        setIsModal(false);
      }}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">Import Users</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Formik
          initialValues={initialValues}
          validate={importUserValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputTextField label="User Email" type="email" name="email" />
              <SelectField
                label="User Role"
                name="role"
                placeHolder={"Select Role"}
                options={userConstants.USER_ROLES}
              />
              <Button
                type="submit"
                className="w-100 mt-3"
                label={buttonNameConstants.SAVE}
                // isDisabled={isSubmitting}
              ></Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  importUser: (data, setIsModal) =>
    dispatch(userActions.importUser(data, setIsModal)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(ImportUser);

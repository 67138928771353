export const testZebraPrintDesign = [
  `^XA\n`,
  `^CFA,20\n`,
  `^FO155,90\n`,
  `^FDCollect Pos\n`,
  `^FS\n`,
  `^CFA,40\n`,
  `^FO110,130\n`,
  `^FDTEST PRINT\n`,
  `^FS\n`,
  `^XZ\n`,
];

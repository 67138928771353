import pokemon from "pokemontcgsdk";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { inventoryService } from "../../../services";
import AddTradeBatch from "./AddTradeBatch/AddTradeBatch";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import AddQuickTradeIn from "./AddQuickTradeIn/AddQuickTradeIn";
import CustomerImage from "../../../assets/images/customerImage.svg";
import AddTradeCustomItem from "./AddTradeCustomItem/AddTradeCustomItem";
import SearchTradeInventory from "./SearchTrandeInventory/SearchTradeInventory";
import AddNewCustomerModal from "../NewTransaction/AddNewCustomer/AddNewCustomerModal";
import CustomerDropdown from "../../../shared/components/selectDropdown/CustomerDropdown";
import ToggleButtonDual from "../../../shared/components/toggleButtonDual/toggleButtonDual";
import ProductDetailCard from "../../../shared/components/productDetailCard/ProductDetailCard";
import ProductCartCardTrade from "../../../shared/components/ProductDetailCardTrade/ProductCartCardTrade";
import {
  customToast,
  parseJsonObject,
  stringifyObject,
  getSumOfPaymentHistory,
  convertToFixedPrecision,
  getCountOfTradeTotalItems,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
  parseToNumber,
} from "../../../shared/utility";
import { inventoryActions, transactionActions } from "../../../redux/actions";
import AddTrade from "./AddTrade/AddTrade";
import {
  toastType,
  toastMessages,
  globalConstants,
  inventoryConstants,
  routesPathConstants,
  buttonTypeConstants,
  buttonNameConstants,
  transactionConstants,
  categoryDropdownConstants,
  tradeTransactionDefaultData,
} from "../../../constants";

import {
  AddIcon,
  CashIcon,
  DeleteIcon,
  ParkSaleIcon,
  ResetCartIcon,
  RetriveSaleIcon,
} from "../../../assets/icons/iconsProvider";
import ConfirmationModal from "../../Inventory/Items/ConfirmationModal/ConfirmationModal";

//-------NewTransaction component start here
const NewTrade = (props) => {
  const {
    customTypes,
    allCustomers,
    newTradeData,
    newTradePark,
    currentStore,
    tradeDiscount,
    toggleAddModal,
    setNewTradeData,
    customCategories,
    addTradeBatchInventory,
    setAddTradeBatchInventory,
    isAddCustomerModal,
    getScryFallProductById,
    toggleAddCustomerModal,
    setPokemonProductInReducer,
    isTradeBatchInventoryModal,
    getPricechartingProductById,
    setIsTradeBatchInventoryModal,
    getTags,
  } = props;

  //-------usestate
  const cartRef = useRef(null);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [isNoteField, setIsNoteField] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [addTradeModal, setAddTradeModal] = useState(false);
  const [customItemModal, setCustomItemModal] = useState(false);
  const [productReturnList, setProductReturnList] = useState([]);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  const [quickTradeInModal, setQuickTradeInModal] = useState(false);
  let cartItemsCount = useRef(
    newTradeData?.inventory?.length || addTradeBatchInventory.length || 0
  );
  const asyncSelectRef = useRef();
  const calculateTax = (subtotal) => {
    return 0;
    // return (subtotal * taxRate) / 100;
  };
  //-------Toggle confirmation modal
  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  //-------handle conformation actions
  const handleConfirmYesButtonClick = () => {
    resetTradePage();
    toggleConfirmationModal();
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  //------Toggle custom item modal
  const toggleCustomItemModal = () => {
    setCustomItemModal(!customItemModal);
  };

  //-------handle custom item click
  const handleCustomItemClick = () => {
    toggleCustomItemModal();
  };
  //------Toggle quick trade in modal
  const toggleQuickTradeInModal = () => {
    setQuickTradeInModal(!quickTradeInModal);
  };

  //-------handle  quick trade in click
  const handleQuickTradeInClick = () => {
    toggleQuickTradeInModal();
  };

  const handleResetCart = () => {
    if (newTradeData?.inventory?.length || addTradeBatchInventory?.length) {
      toggleConfirmationModal();
    }
  };

  //-------handle card click
  const handleProductCardClick = (product) => {
    if (product) {
      if (product?.sourceApi === inventoryConstants.PRICECHARTING_API) {
        getPricechartingProductById(product, product?.id);
      }
      if (product?.sourceApi === inventoryConstants.POKEMON_API) {
        pokemon.card.find(product?.id).then((card) => {
          setPokemonProductInReducer(product, card || "");
        });
      }
      if (product?.sourceApi === inventoryConstants.SCRYFALL_API) {
        getScryFallProductById(product, product?.id);
      }
    }

    handleAddTradeModalOpen();
  };

  // Function to scroll the container to the bottom
  const scrollToBottom = () => {
    if (cartRef.current) {
      cartRef.current.scrollTop = cartRef.current.scrollHeight;
    }
  };

  const handleTradeData = (data) => {
    setNewTradeData(data, currentStore?.id);
  };

  const handleDeleteCartItem = (sku) => {
    handleTradeData({
      ...newTradeData,
      inventory: newTradeData?.inventory?.filter((item) => {
        return item.sku !== sku && item;
      }),
    });
  };

  //-------get Sku Number
  const getSkuNumber = async () => {
    return inventoryService.getSkuNumber(currentStore?.id).then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };

  const handleQuantityChange = (skuNumber, newQuantity) => {
    const updatedCartItems = newTradeData?.inventory?.map((item) => {
      if (item.sku === skuNumber) {
        // Assuming price is an object and has a quantity property you want to update
        return { ...item, price: { ...item.price, quantity: newQuantity } };
      }
      return item;
    });

    handleTradeData({
      ...newTradeData,
      inventory: updatedCartItems,
    });
  };

  const handleAddTradeModalOpen = () => {
    toggleAddModal(true);
    toggleAddTradeModal();
  };

  const resetTradePage = () => {
    setSelectedCustomerFlag(false);
    handleTradeData(tradeTransactionDefaultData);
    setAddTradeBatchInventory([], currentStore?.id);
    setProductsList([]);
  };

  //-------Toggle Add Inventoty Item Modal
  const toggleAddTradeModal = () => setAddTradeModal(!addTradeModal);
  const addToCart = async (addToCartData, isBatchTrade = false) => {
    if (isBatchTrade) {
      handleTradeData(addToCartData);
    } else {
      handleTradeData(await getUpdatedCartItems(newTradeData, addToCartData));
    }

    getTags(currentStore?.id);
  };

  const handleButtonClick = (buttonName) => {
    handleTradeData({ ...newTradeData, PaymentType: buttonName });
  };
  const subtotal = () => {
    if (
      Array.isArray(newTradeData?.inventory) &&
      newTradeData?.inventory.length > 0
    ) {
      return newTradeData?.inventory.reduce((acc, item) => {
        const offer =
          newTradeData.PaymentType === globalConstants.CASH
            ? item.cashOffer
            : item.tradeOffer;
        return acc + offer * (item.price?.quantity || 0);
      }, 0);
    }
    return 0;
  };
  const subtotalCash = () => {
    if (
      Array.isArray(newTradeData?.inventory) &&
      newTradeData?.inventory.length > 0
    ) {
      return newTradeData?.inventory.reduce((acc, item) => {
        const offer = item.cashOffer;

        return acc + offer * (item.price?.quantity || 0);
      }, 0);
    }
    return 0;
  };
  const subtotalTrade = () => {
    if (
      Array.isArray(newTradeData?.inventory) &&
      newTradeData?.inventory.length > 0
    ) {
      return newTradeData?.inventory.reduce((acc, item) => {
        const offer = item.tradeOffer;
        return acc + offer * (item.price?.quantity || 0);
      }, 0);
    }
    return 0;
  };
  const handlePayClick = async (trasactionTypeCondition) => {
    if (
      newTradeData.PaymentType === globalConstants.CREDIT &&
      (!newTradeData.customer || !newTradeData?.customer?.id)
    ) {
      customToast(toastMessages.PLEASE_SELECT_A_CUSTOMER, toastType.ERROR);
      return;
    }
    // handle component unmounting
    cartItemsCount.current = 0;
    let isInvalid = newTradeData?.inventory?.some((inv) => {
      return Number(inv.cashOffer) === 0 || Number(inv.tradeOffer) === 0;
    });
    if (isInvalid) {
      customToast("Trade Offer & Cash Offer Cannot be 0", toastType.ERROR);
      return;
    }
    if (
      newTradeData?.inventory?.length > 0 ||
      (addTradeBatchInventory.length &&
        trasactionTypeCondition === transactionConstants.DRAFT)
    ) {
      newTradeData.inventory.forEach((item) => {
        const offerValue =
          newTradeData.PaymentType === globalConstants.CASH
            ? item.cashOffer
            : item.tradeOffer;
        if (item.price) {
          item.price.unit_purchase_price = offerValue;
        } else {
          item.price = { unit_purchase_price: offerValue };
        }
      });
      const dataToSend = {
        ...newTradeData,
        userPaymentType: newTradeData.PaymentType,
        customer: newTradeData.customer.id
          ? newTradeData.customer
          : { id: "", name: "" },
        store: currentStore || "",
        paymentMethod: globalConstants.EMPTY_STRING,
        totalCashOffer: newTradeData?.totalCashOffer,
        totalTradeOffer: newTradeData?.totalTradeOffer,
        amountToPay: parseToNumber(newTradeData.amountToPay),
      };
      if (trasactionTypeCondition === transactionConstants.DRAFT) {
        const tradeId =
          newTradeData?.id || (await transactionActions.getTradeId());
        const parkTradeData = {
          ...dataToSend,
          id: tradeId,
          store: { id: currentStore?.id, name: currentStore?.storeName },
          addBatchInventory: addTradeBatchInventory,
        };
        newTradePark(parkTradeData, currentStore?.id);
        resetTradePage();
      } else {
        handleTradeData(dataToSend);
        navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE_PAYMENT, {
          state: {
            currentStore: currentStore,
          },
        });
      }
    } else {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    }
  };
  const handleAddCustomerClick = () => {
    toggleAddCustomerModal(true);
  };
  const updateCashTradeOffer = (
    skuNumber,
    activeButton,
    newPrice,
    marketPrice
  ) => {
    const updatedCartItems = newTradeData?.inventory?.map((item) => {
      if (item.sku === skuNumber) {
        if (activeButton === globalConstants.CASH) {
          if (marketPrice) {
            const TradeOfferCalculated = Number(newPrice * item.ratio).toFixed(
              2
            );
            return {
              ...item,
              tradeOffer: TradeOfferCalculated,
              cashOffer: newPrice,
            };
          } else {
            const applyRatio = convertToFixedPrecision(newPrice * item.ratio);
            return { ...item, tradeOffer: applyRatio, cashOffer: newPrice };
          }
        } else if (activeButton === globalConstants.CREDIT) {
          if (marketPrice) {
            const cashOfferCalculated = Number(newPrice / item.ratio).toFixed(
              2
            );

            return {
              ...item,
              cashOffer: cashOfferCalculated,
              tradeOffer: newPrice,
            };
          } else {
            const applyRatio = convertToFixedPrecision(newPrice / item.ratio);
            return { ...item, cashOffer: applyRatio, tradeOffer: newPrice };
          }
        }
      }
      return item;
    });
    handleTradeData({
      ...newTradeData,
      inventory: updatedCartItems,
    });
  };
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  useEffect(() => {
    if (
      productReturnList?.length === 1 &&
      productReturnList[0].value.upc === searchText &&
      !isTradeBatchInventoryModal
    ) {
      handleProductCardClick(productReturnList[0].value);
    } else {
      const productValues =
        productReturnList?.map((product) => product.value) || [];
      // Now you can use productValues to set your products list
      setProductsList(productValues);
    } // eslint-disable-next-line
  }, [productReturnList]);

  useEffect(() => {
    handleTradeData({
      ...newTradeData,
      SubTotal: convertToFixedPrecision(subtotal()),
      tax:
        newTradeData.PaymentType === globalConstants.CREDIT
          ? convertToFixedPrecision(calculateTax(subtotal()))
          : 0,
      totalAmountToPay:
        newTradeData.PaymentType === globalConstants.CASH
          ? convertToFixedPrecision(subtotal())
          : convertToFixedPrecision(
              Number(subtotal() + calculateTax(subtotal())).toFixed(2)
            ),
      amountToPay:
        newTradeData.amountToPay > 0
          ? newTradeData.PaymentType === globalConstants.CASH
            ? convertToFixedPrecision(subtotal()) -
              getSumOfPaymentHistory(newTradeData.paymentHistory)
            : convertToFixedPrecision(
                Number(subtotal() + calculateTax(subtotal())).toFixed(2)
              ) - getSumOfPaymentHistory(newTradeData.paymentHistory)
          : newTradeData.PaymentType === globalConstants.CASH
          ? convertToFixedPrecision(subtotal())
          : convertToFixedPrecision(
              Number(subtotal() + calculateTax(subtotal())).toFixed(2)
            ),
      totalCashOffer: convertToFixedPrecision(subtotalCash()),
      totalTradeOffer: convertToFixedPrecision(
        Number(subtotalTrade() + calculateTax(subtotalTrade())).toFixed(2)
      ),
      taxForTrade: convertToFixedPrecision(calculateTax(subtotalTrade())),
    });
    // handle component unmounting
    cartItemsCount.current =
      newTradeData?.inventory?.length || addTradeBatchInventory.length || 0;
    // eslint-disable-next-line
  }, [
    newTradeData.inventory,
    newTradeData.PaymentType,
    addTradeBatchInventory,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // handleTradeData(allTradeConstants);
    // eslint-disable-next-line
  }, [currentStore]);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      handleTradeData({
        ...newTradeData,
        customer: {
          ...allCustomers[0],
          name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
        },
        PaymentType: globalConstants.CREDIT,
      });
      setSelectedCustomerFlag(false);
    }

    setCustomersList(
      allCustomers?.map((customer) => ({
        value: stringifyObject({
          ...customer,
          name: customer.firstName + " " + customer.lastName,
        }),
        label: (
          <div className="d-flex align-items-center gap-2 ">
            <span>
              <img
                src={CustomerImage}
                className="new-sale-customer-image"
                alt="customer"
              />
            </span>
            {`${customer.firstName} ${customer.lastName}`}
          </div>
        ),
      }))
    ); // eslint-disable-next-line
  }, [allCustomers]);

  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
  }, [isAddCustomerModal]);

  // handle component unmounting
  useEffect(() => {
    if (newTradeData.paymentMethod === transactionConstants.PAYMENT_COMPLETE) {
      handleTradeData(tradeTransactionDefaultData);
    }

    // return () => {
    //   if (cartItemsCount.current > 0) {
    //     handleDraftModal(transactionConstants.TRADE_DRAFT_MODAL);
    //   }
    // };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [newTradeData?.inventory?.length]);

  //------return new transaction component
  return (
    <>
      <Row className="m-0">
        <Col md={7}>
          <Topbar title="New Transaction" sellTradeToggleButton={true} />
          <Row className="m-0">
            <Col md={12}>
              <div className="d-flex mb-3 justify-content-between align-items-center gap-2">
                <Button
                  label={buttonNameConstants.QUICK_TRADE_ID}
                  handleClick={handleQuickTradeInClick}
                  className="w-100"
                />
                {addTradeBatchInventory.length ? (
                  <Button
                    label={buttonNameConstants.CONTINUE_BATCH}
                    // IconImage={AddIcon}
                    handleClick={() => {
                      setIsTradeBatchInventoryModal(
                        !isTradeBatchInventoryModal
                      );
                    }}
                    className="w-100"
                  />
                ) : (
                  <Button
                    label={buttonNameConstants.ADD_BATCH}
                    // IconImage={AddIcon}
                    handleClick={() => {
                      setIsTradeBatchInventoryModal(
                        !isTradeBatchInventoryModal
                      );
                    }}
                    className="w-100"
                  />
                )}
                <Button
                  label={buttonNameConstants.CUSTOM_ITEM}
                  IconImage={AddIcon}
                  handleClick={handleCustomItemClick}
                  className="w-100"
                />
              </div>
            </Col>
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center "
            >
              <span className="transaction-heading">Add Items</span>
            </Col>
            <Col md={12} className="mb-3 m-0 ">
              <SearchTradeInventory
                customTypes={customTypes}
                setSearchText={setSearchText}
                asyncSelectRef={asyncSelectRef}
                setProductsList={setProductsList}
                customCategories={customCategories}
                productReturn={setProductReturnList}
                isTradeBatchInventoryModal={isTradeBatchInventoryModal}
              />
            </Col>

            {productsList?.length > 0 && (
              <Col md={12} className="mt-2">
                <div className="d-flex flex-column gap-2 searched-products-wrapper-trade container-scrollbar">
                  {productsList?.map((product, index) => (
                    <ProductDetailCard
                      handleCardClick={handleProductCardClick}
                      productName={product.name}
                      skuNumber={product.id}
                      productType={product.productType}
                      price={product.price}
                      productObject={product}
                      key={index}
                    />
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={5} className="sale-cart-section px-3 py-3 overflow-hidden">
          <Row className="m-0 px-0 h-100 d-flex flex-column align-items-center">
            <Row className="m-0 px-0">
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center px-0"
              >
                <span className="transaction-heading">Cart</span>
                <div className=" d-flex justify-content-center align-items-center">
                  <Button
                    IconImage={ResetCartIcon}
                    label={buttonNameConstants.RESET}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    handleClick={handleResetCart}
                  />
                  <Button
                    label={buttonNameConstants.PARK_TRADE}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    IconImage={ParkSaleIcon}
                    handleClick={() =>
                      handlePayClick(transactionConstants.DRAFT)
                    }
                  />
                  <Button
                    label={buttonNameConstants.RETRIVE_TRADE}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_GHOST}
                    IconImage={RetriveSaleIcon}
                    handleClick={() => {
                      navigate(routesPathConstants.SELL_AND_TRADE_DRAFTS);
                    }}
                    className="pe-1"
                  />
                </div>
              </Col>
              {newTradeData?.customer?.id ? (
                <Col md={12} className="my-3 rounded-2 py-2 bg-white">
                  <div className="w-100 d-flex align-items-center selected-customer">
                    <img src={CustomerImage} alt="customer" />
                    <div className="d-flex flex-column justify-content-center ms-2">
                      <span className="customer-name-heading">{`${
                        newTradeData?.customer?.firstName || "Customer"
                      } ${newTradeData?.customer?.lastName || "Name"}`}</span>
                      <span>
                        {newTradeData?.customer?.mobile || "(000) 000-0000"}
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center  align-items-center customer-credit ms-auto">
                      <span>Store Credit</span>
                      <span>
                        $
                        {parseToThousandSeperatorDecimalNumber(
                          newTradeData?.customer?.currentBalance || 0
                        )}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="cart-card-delete-button"
                      onClick={() => {
                        handleTradeData({
                          ...newTradeData,
                          PaymentType: globalConstants.CASH,
                          customer: { id: "", name: "" },
                        });
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </Col>
              ) : (
                <Col md={12} className="mt-1 px-0">
                  <CustomerDropdown
                    options={customersList}
                    handleChange={(selectedOption) => {
                      if (!selectedOption || selectedOption === "") {
                        handleTradeData({
                          ...newTradeData,
                          PaymentType: globalConstants.CASH,
                          customer: { id: "", name: "" },
                        });
                      } else {
                        handleTradeData({
                          ...newTradeData,
                          PaymentType: globalConstants.CREDIT,
                          customer: selectedOption?.value
                            ? parseJsonObject(selectedOption?.value)
                            : { id: "", name: "" },
                        });
                        // setActiveButton(globalConstants.CREDIT);
                      }
                    }}
                    value={
                      newTradeData?.customer?.id
                        ? {
                            value: stringifyObject(newTradeData?.customer),
                            label: (
                              <div className="d-flex align-items-center gap-2 ">
                                <span>
                                  <img
                                    src={CustomerImage}
                                    className="new-sale-customer-image"
                                    alt="customer"
                                  />
                                </span>
                                {`${newTradeData?.customer.firstName} ${newTradeData?.customer.lastName}`}
                              </div>
                            ),
                          }
                        : ""
                    }
                    noOptionsMessage="No Customer Found"
                    placeholder="Customer Name, Phone, Email"
                    SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                    dropdownIndicator={false}
                    handleAddCustomerClick={handleAddCustomerClick}
                    selectedCustomerFlag={selectedCustomerFlag}
                  />
                </Col>
              )}
            </Row>
            <Col md={12} className="mt-1 px-0">
              <div
                ref={cartRef}
                className={`cart-cards-wrapper container-scrollbar ${
                  isNoteField && newTradeData?.customer?.id
                    ? "trade-both-fields-open"
                    : isNoteField || newTradeData?.customer?.id
                    ? "trade-one-fields-open"
                    : ""
                }`}
              >
                {newTradeData?.inventory?.map((cartItem) => (
                  <ProductCartCardTrade
                    productName={cartItem.product_name}
                    skuNumber={cartItem.sku}
                    price={cartItem.price?.unit_sell_price}
                    handleDeleteCartItem={handleDeleteCartItem}
                    quantity={cartItem.price?.quantity}
                    max={9999}
                    handleQuantityChange={handleQuantityChange}
                    cashOffer={cartItem.cashOffer}
                    tradeOffer={cartItem.tradeOffer}
                    activeButton={newTradeData.PaymentType}
                    updateCashTradeOffer={updateCashTradeOffer}
                    marketPrice={cartItem.price?.marketPrice}
                    minCashOffer={cartItem.minCashOffer}
                    minTradeOffer={cartItem.minTradeOffer}
                    category={cartItem.category_name}
                    totalItems={cartItem?.price?.totalItems}
                  />
                ))}
              </div>
            </Col>
            <Row className="m-0 px-0 mt-auto">
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center mt-2 px-0"
              >
                <span className="opacity-50">ADD</span>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <button
                    type="button"
                    className={`sale-extra-button ${
                      isNoteField && "active-link"
                    }`}
                    onClick={() => {
                      setIsNoteField(true);
                    }}
                  >
                    Note
                  </button>
                </div>
              </Col>

              {isNoteField && (
                <Col md={12} className="mt-2 d-flex justify-content-between">
                  <textarea
                    value={newTradeData.note}
                    onChange={(e) => {
                      handleTradeData({
                        ...newTradeData,
                        note: e.target.value,
                      });
                    }}
                    className="input-textfield text-area-field note-area-field"
                  />
                  <button
                    type="button"
                    className="cart-card-delete-button"
                    onClick={() => {
                      handleTradeData({ ...newTradeData, note: "" });
                      setIsNoteField(false);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </Col>
              )}
              <Col className="mt-2  m-0 px-0" md={12}>
                <ToggleButtonDual
                  activeButton={newTradeData.PaymentType}
                  handleButtonClick={handleButtonClick}
                />
              </Col>
              <Col md={12} className="px-0">
                <button
                  type="button"
                  className="d-flex justify-content-between align-items-center w-100 mt-3 main-purple-button"
                  onClick={() => handlePayClick()}
                >
                  <div>
                    <CashIcon />
                    <span className="ms-1">Pay</span>
                    <span className="ms-1">
                      {getCountOfTradeTotalItems(
                        newTradeData?.inventory || 0,
                        "quantity"
                      )}{" "}
                      items
                    </span>
                  </div>
                  <span>
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      newTradeData.SubTotal || 0
                    )}
                  </span>
                </button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

      {addTradeModal && (
        <AddTrade
          addToCart={addToCart}
          customTypes={customTypes}
          tradeDiscount={tradeDiscount}
          addTradeModal={addTradeModal}
          customCategories={customCategories}
          toggleAddTradeModal={toggleAddTradeModal}
        />
      )}
      {customItemModal && (
        <AddTradeCustomItem
          addItemInCart={addToCart}
          tradeDiscount={tradeDiscount}
          customItemModal={customItemModal}
          toggleCustomItemModal={toggleCustomItemModal}
        />
      )}
      {quickTradeInModal && (
        <AddQuickTradeIn
          addItemInCart={addToCart}
          tradeDiscount={tradeDiscount}
          quickTradeInModal={quickTradeInModal}
          toggleQuickTradeInModal={toggleQuickTradeInModal}
        />
      )}
      {isTradeBatchInventoryModal && (
        <AddTradeBatch
          addToCart={addToCart}
          customTypes={customTypes}
          tradeDiscount={tradeDiscount}
          customCategories={customCategories}
        />
      )}
      <AddNewCustomerModal
        selectedCustomer={selectedCustomer}
        addCustomerModal={addCustomerModal}
      />
      <ConfirmationModal
        message={toastMessages.RESET_CART_WARNING}
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
}; //-------NewTransaction component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customer: state.customer,
  inventory: state.inventory,
  transaction: state.transaction,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  allCustomers: state.customer.allCustomers,
  newTradeData: state.transaction.newTradeData,
  customTypes: state.itemOrganization.customTypes,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
  customCategories: state.itemOrganization.customCategories,
  addTradeBatchInventory: state.transaction.addTradeBatchInventory,
  isTradeBatchInventoryModal: state.transaction.isTradeBatchInventoryModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  toggleAddModal: (value) => dispatch(inventoryActions.toggleAddModal(value)),
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  setNewTradeData: (data, storeId) =>
    dispatch(transactionActions.setNewTradeData(data, storeId)),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  newTradePark: (data, storeId) =>
    dispatch(transactionActions.newTradePark(data, storeId)),
  setIsTradeBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type:
        transactionConstants.SET_IS_TRADE_BATCH_INVENTORY +
        globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  setAddTradeBatchInventory: (data, storeId) =>
    dispatch(transactionActions.setAddTradeBatchInventory(data, storeId)),
  getTags: (storeId) => dispatch(inventoryActions.getTags(storeId)),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(NewTrade);

export const getUpdatedCartItems = (prevCartItems, addToCartData) => {
  // const itemIndex = prevCartItems?.inventory?.findIndex((item) => {
  //   let isExist =
  //     (isArraySame(item.sku, addToCartData.sku) &&
  //       !item.sku.includes(inventoryConstants.CUSTOM_ITEM)) ||
  //     (!item.sku.find((sku) =>
  //       sku.includes(transactionConstants.QUICK_TRADE)
  //     ) &&
  //       findProductByCondition(
  //         [item],
  //         addToCartData.price.type,
  //         addToCartData.tags,
  //         addToCartData.cardRarity,
  //         addToCartData.apiSource,
  //         addToCartData.product_name,
  //         addToCartData.category_name,
  //         addToCartData.productType
  //       ));

  //   return isExist;
  // });

  // if (itemIndex > -1) {
  //   prevCartItems.inventory[itemIndex] = {
  //     ...prevCartItems.inventory[itemIndex],
  //     price: {
  //       ...prevCartItems.inventory[itemIndex].price,
  //       quantity:
  //         Number(prevCartItems.inventory[itemIndex].price.quantity) +
  //         Number(addToCartData.price.quantity),
  //     },
  //     max:
  //       prevCartItems.inventory[itemIndex].max + addToCartData.price.quantity,
  //   };

  //   return {
  //     ...prevCartItems,
  //   };
  // } else {
  // New item, add to the cart
  let addToCartDataWithMax = {
    ...addToCartData,
    max: 9999,
    minCashOffer: addToCartData.cashOffer,
    minTradeOffer: addToCartData.tradeOffer,
    sku: [transactionConstants.AUTO_GENERATED],
  };
  return {
    ...prevCartItems,
    inventory: [...prevCartItems?.inventory, addToCartDataWithMax],
    // };
  };
};

import React, { useEffect, useState, useRef } from "react";
import Topbar from "../../../../shared/components/topbar/Topbar";
import { Col, Row } from "react-bootstrap";
import ProductImage from "../../../../assets/images/productimage.png";
import { Formik, Form } from "formik";
import Modal from "react-bootstrap/Modal";
import pokemon from "pokemontcgsdk";

import {
  toastType,
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  globalConstants,
  routesPathConstants,
  inventoryConstants,
} from "../../../../constants";
import Button from "../../../../shared/components/Buttons/Button";
import { editInventoryValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import { useLocation, useNavigate } from "react-router-dom";
import { inventoryActions, systemActions } from "../../../../redux/actions";
import { connect } from "react-redux";
import {
  stringToSlug,
  convertToUsd,
  convertToFixedPrecision,
  handleViewEbaySales,
  uploadCustomProductImage,
  customToast,
  handleShowNintendoCondition,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import { PRODUCT_CONDITIONS_ENUMS } from "../../../../system/globalEnums";
import {
  DollarIcon,
  PercentageIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";
import PrintLabelModal from "../printingLabelModal/PrintLabelModal";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import CustomTooltip from "../../../../shared/components/toolTipComponent/CustomTooltip";

pokemon.configure({ apiKey: process.env.REACT_APP_POKEMON_API_KEY });

const EditInventory = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [productUrl, setProductUrl] = useState("");
  const [productDataObject, setProductDataObject] = useState({});
  const [showIframe, setShowIframe] = useState(false);
  const handleCloseIfram = () => setShowIframe(false);
  const handleShowIfram = () => setShowIframe(true);

  const [printLabelModal, setPrintLabelModal] = useState(false);

  //-------Toggle Print Label Modal
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };

  const {
    updateInventory,
    currentStore,
    inventory,
    quantityShrink,
    getPricechartingProductById,
    getScryFallProductById,
    activateSpinner,
    deactivateSpinner,
    emptyPriceChartingProductObject,
  } = props;

  //------UseRef
  const hiddenFileInput = useRef(null);

  const { priceChartingProductInfo } = inventory;
  const { state } = useLocation();
  const navigate = useNavigate();
  const [typeCondition, setTypeCondition] = useState(state?.data?.price?.type);
  const [shrinkValue, setShrinkValue] = useState("");
  const [updatedInventory, setUpdatedInventory] = useState("");

  const initialValues = {
    unitsellprice:
      state?.data?.price?.unit_sell_price !== null &&
      state?.data?.price?.unit_sell_price !== undefined
        ? parseToDecimalNumber(state.data.price.unit_sell_price)
        : globalConstants.EMPTY_STRING,

    costPerItem:
      state?.data?.price?.unit_purchase_price !== null &&
      state?.data?.price?.unit_purchase_price !== undefined
        ? convertToFixedPrecision(state.data.price.unit_purchase_price)
        : globalConstants.EMPTY_STRING,

    profit:
      state?.data?.price?.unit_sell_price !== null &&
      state?.data?.price?.unit_sell_price !== undefined
        ? convertToFixedPrecision(
            state?.data?.price?.unit_sell_price -
              state?.data?.price?.unit_purchase_price
          )
        : 0,

    margin:
      state?.data?.price?.unit_sell_price !== null &&
      state?.data?.price?.unit_sell_price !== undefined
        ? convertToFixedPrecision(
            ((state?.data?.price?.unit_sell_price -
              state?.data?.price?.unit_purchase_price) /
              state?.data?.price?.unit_sell_price) *
              100
          )
        : 0,
    //   // unitsOnline: state?.data?.price?.quantity || globalConstants.EMPTY_STRING,
    unitsOnHand:
      state?.data?.price?.quantity !== null &&
      state?.data?.price?.quantity !== undefined
        ? state.data.price.quantity
        : globalConstants.EMPTY_STRING,
  };

  const [productImageUrl, setProductImageUrl] = useState(
    state?.data?.imgUrl || globalConstants.EMPTY_STRING
  );

  const handleCancelButton = () => {
    navigate(routesPathConstants.INVENTORY);
  };
  const handleModalNoButton = () => {
    updateInventory(currentStore?.id, updatedInventory);
  };
  const handleModalYesButton = () => {
    updateInventory(currentStore?.id, updatedInventory);
    const shrinkInventoryObject = {
      ...state.data,
      shrinkQuantity: shrinkValue,
    };
    quantityShrink([shrinkInventoryObject]);
  };
  const handleSubmit = (data) => {
    if (data.unitsOnHand < state?.data?.price?.quantity) {
      setShrinkValue(state?.data?.price?.quantity - data.unitsOnHand);
      handleShow();
      const updateInventoryObject = {
        id: state?.data?.id,
        margin: data.margin,
        imgUrl: productImageUrl,
        price: {
          quantity: data.unitsOnHand,
          unit_purchase_price: data.costPerItem,
          unit_sell_price: data.unitsellprice,
          marketPrice: isNaN(convertToUsd(productDataObject?.marketPrice))
            ? "0.00"
            : state?.data?.cardRarity
            ? productDataObject?.marketPrice
            : parseToDecimalNumber(
                convertToUsd(productDataObject?.marketPrice ?? 0)
              ),
          profit: data.profit,
        },
      };
      setUpdatedInventory(updateInventoryObject);
    } else {
      const updateInventoryObject = {
        id: state?.data?.id,
        margin: data.margin,
        imgUrl: productImageUrl,
        price: {
          quantity: data.unitsOnHand,
          unit_purchase_price: data.costPerItem,
          unit_sell_price: data.unitsellprice,
          marketPrice: isNaN(convertToUsd(productDataObject?.marketPrice))
            ? "0.00"
            : state?.data?.cardRarity
            ? productDataObject?.marketPrice
            : parseToDecimalNumber(
                convertToUsd(productDataObject?.marketPrice ?? 0)
              ),
          profit: data.profit,
        },
      };
      updateInventory(currentStore?.id, updateInventoryObject);
    }
  };

  useEffect(() => {
    if (state?.data.apiSource === inventoryConstants.PRICECHARTING_API) {
      getPricechartingProductById({}, state?.data?.product_id);
    } else if (state?.data.apiSource === inventoryConstants.SCRYFALL_API) {
      getScryFallProductById({}, state?.data?.product_id);
    } else if (state?.data.apiSource === inventoryConstants.POKEMON_API) {
      pokemon.card.find(state?.data?.product_id).then((card) => {
        setProductDataObject({
          marketPrice:
            card?.tcgplayer?.prices[state?.data.cardRarity]?.market || 0,
          productUrl: card?.tcgplayer?.url ?? globalConstants.EMPTY_STRING,
        });
      });
    }

    return () => {
      emptyPriceChartingProductObject();
    };
  }, []);
  useEffect(() => {
    // ------handleConditionChange
    const handleConditionChange = (typeCondition) => {
      if (priceChartingProductInfo) {
        //-------when complete condition is selected
        if (typeCondition === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
          setProductDataObject({
            marketPrice:
              priceChartingProductInfo?.productObject?.["cib-price"] || 0,
            suggestedSellPrice:
              priceChartingProductInfo?.productObject?.["retail-cib-sell"] || 0,
          });
        }
        //-------when new condition is selected
        else if (typeCondition === PRODUCT_CONDITIONS_ENUMS.NEW) {
          setProductDataObject({
            marketPrice: priceChartingProductInfo?.productObject?.["new-price"],
            suggestedSellPrice:
              priceChartingProductInfo?.productObject?.["retail-new-sell"] || 0,
          });
        }
        //-------when loose condition is selected
        else if (typeCondition === PRODUCT_CONDITIONS_ENUMS.LOOSE) {
          setProductDataObject({
            marketPrice:
              priceChartingProductInfo?.productObject?.["loose-price"] || 0,
            suggestedSellPrice:
              priceChartingProductInfo?.productObject?.["retail-loose-sell"] ||
              0,
          });
        }
        //-------when boxonly condition is selected
        else if (typeCondition === PRODUCT_CONDITIONS_ENUMS.BOX_ONLY) {
          setProductDataObject({
            marketPrice:
              priceChartingProductInfo?.productObject?.["box-only-price"] || 0,
            suggestedSellPrice: 0,
          });
        }
        //-------when manual only condition is selected
        else if (typeCondition === PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY) {
          setProductDataObject({
            marketPrice:
              priceChartingProductInfo?.productObject?.["manual-only-price"] ||
              0,
            suggestedSellPrice: 0,
          });
        }
      }
    };
    // --------handle market price in case of pokemon and scryfall api
    if (state.data.apiSource === inventoryConstants.PRICECHARTING_API) {
      handleConditionChange(typeCondition);
    } else if (state.data.apiSource === inventoryConstants.SCRYFALL_API) {
      setProductDataObject({
        marketPrice:
          priceChartingProductInfo?.productObject?.prices[
            state?.data.cardRarity
          ] || 0,
        productUrl:
          priceChartingProductInfo?.productObject?.purchase_uris?.tcgplayer ||
          globalConstants.EMPTY_STRING,
      });
    }
  }, [priceChartingProductInfo]);

  const handlePrintButtonClick = () => {
    togglePrintLabelModal();
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  //------handle upload button
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = async () => {
              activateSpinner(inventoryConstants.UPLOAD_IMAGE);
              const productImage = await uploadCustomProductImage(file);
              setProductImageUrl(productImage);
              deactivateSpinner(inventoryConstants.UPLOAD_IMAGE);
            };
          };
          reader.readAsDataURL(file);
        }
      } else {
        customToast(
          "Only .jpg, .jpeg and .png files are allowed",
          toastType.ERROR
        );
        e.target.value = null;
      }
    }
  };

  return (
    <>
      <Topbar
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.INVENTORY,
        }}
        exportButton={true}
        buttonType={buttonTypeConstants.LIGHT_PURPLE}
        title="Inventory Detail - Edit"
        label={buttonNameConstants.PRINT_PRODUCT_LABEL}
        handleClick={handlePrintButtonClick}
      ></Topbar>
      <Row className="m-0 w-100  container-scrollbar user-information-wrapper edit-inventory-wrapper  pb-3">
        <Col className="p-0">
          <Formik
            initialValues={initialValues}
            validate={editInventoryValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values }) => (
              <Form className="main-row">
                <Row className="m-0 mb-3  ">
                  <Col className="edit-invetory-left" md={7} sm={12}>
                    <Row className="p-0 m-0 left-container">
                      <Row className="Img-container m-0 p-0">
                        <Col md={3} className="img-Div d-flex pe-0">
                          <div className="add-inventory-product-image">
                            <img src={productImageUrl || ProductImage} />
                            <input
                              type="file"
                              className="d-none"
                              accept=".png, .jpg, .jpeg"
                              onChange={handleImageChange}
                              ref={hiddenFileInput}
                            />
                            <button
                              onClick={handleReplaceButtonClick}
                              type="button"
                              className="replace-logo-button"
                            >
                              <LogoImageReplaceIcon className="replace-icon" />
                            </button>
                          </div>
                        </Col>
                        <Col md={9} sm={12} className="d-flex flex-column p-0 ">
                          <span className="saga-Text">
                            {state?.data?.product_name}
                          </span>
                          <div className="p-0 d-flex flex-wrap gap-2">
                            <span className="skitchin-Items category-skitchin">
                              {state?.data?.category_name}
                            </span>
                            {state?.data?.subcategory && (
                              <span className="skitchin-Items category-skitchin">
                                {state?.data?.subcategory}
                              </span>
                            )}
                          </div>
                          <Col
                            md={
                              state.data.apiSource !==
                                inventoryConstants.PRICECHARTING_API &&
                              state?.data?.cardRarity
                                ? 12
                                : 6
                            }
                            className="mt-4 d-flex"
                          >
                            <CategoriesDropdown
                              options={[
                                {
                                  value: globalConstants.EMPTY_STRING,
                                  label: globalConstants.EMPTY_STRING,
                                },
                              ]}
                              disabled={true}
                              handleChange={() => {}}
                              noOptionsMessage="Empty Box"
                              placeholder={handleShowNintendoCondition(
                                state?.data?.category_name,
                                state?.data?.price?.type
                              )}
                              SelectIconImage={
                                categoryDropdownConstants.BOX_ICON
                              }
                              className={"pe-2"}
                            />
                            {state.data.apiSource !==
                              inventoryConstants.PRICECHARTING_API &&
                              state?.data?.cardRarity && (
                                <CategoriesDropdown
                                  options={[
                                    {
                                      value: globalConstants.EMPTY_STRING,
                                      label: globalConstants.EMPTY_STRING,
                                    },
                                  ]}
                                  disabled={true}
                                  handleChange={() => {}}
                                  noOptionsMessage="Empty Box"
                                  placeholder={state?.data?.cardRarity}
                                  SelectIconImage={
                                    categoryDropdownConstants.BOX_ICON
                                  }
                                />
                              )}
                          </Col>
                        </Col>
                        <Col md={3} className="pe-0 mt-3 sku-Text ">
                          SKU:
                        </Col>
                        <Col md={9} className="p-0 mt-3 sku-Num-Text">
                          {skuToolTip(state)}
                        </Col>
                        <Col
                          md={12}
                          className="py-2 d-flex flex-wrap align-items-center gap-2"
                        >
                          Tags
                          {state?.data?.tags
                            ?.filter(
                              (tag) =>
                                tag !== inventoryConstants.CUSTOM_ITEM_TAG
                            )
                            ?.map((tagItem) => (
                              <div className={`tag selected pe-3 me-0`}>
                                {tagItem}
                              </div>
                            )) || ""}
                        </Col>
                        <Row className="mt-4 pricing-Div m-0">
                          <Col md={12} className="pricing-Text p-0">
                            Pricing
                          </Col>
                          <Col md={3} className="mt-4 p-0">
                            In Stock Price:
                          </Col>
                          <Col md={5} className="mt-4 p-0"></Col>
                          <Col md={4} className=" ">
                            <InputNumberField
                              name="unitsellprice"
                              placeHolder="$74.99"
                              type="number"
                              disabled={false}
                              IconImage={DollarIcon}
                            />
                          </Col>

                          <Row className="mt-4 p-0 m-0">
                            <Col md={4} className=" ps-0">
                              <InputNumberField
                                name="costPerItem"
                                label="Cost per Item: "
                                placeHolder="$74.99"
                                type="number"
                                IconImage={DollarIcon}
                              />
                            </Col>
                            <Col md={4} className="">
                              <InputTextField
                                name="profit"
                                placeHolder="0.00"
                                label={"Profit:"}
                                IconImage={DollarIcon}
                                disabled={true}
                              />
                            </Col>
                            <Col md={4} className="">
                              <InputTextField
                                name="margin"
                                placeHolder="0.00"
                                label={"Profit Margin"}
                                LeftIconImage={PercentageIcon}
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        </Row>
                        <Row className="mt-4 pricing-Div m-0 ">
                          <Col md={12} className="pricing-Text p-0">
                            Quantity
                          </Col>
                          <Col md={6} className="mt-4 ps-0">
                            <InputNumberField
                              name="unitsOnHand"
                              placeHolder="Units On-Hand"
                              type="number"
                              isConvertToDecimal={false}
                              isHandleKeyDown={true}
                            />
                          </Col>
                          <Col md={6} className=" mt-4 ">
                            {/* <InputNumberField
                            name="unitsOnline"
                            placeHolder="Units Avaible Online"
                            type="number"
                          /> */}
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                  </Col>
                  <Col
                    md={5}
                    sm={12}
                    className="edit-invetory-right mt-4 mt-md-0"
                  >
                    <Row className="right-container m-0 p-0">
                      <Row className="m-0 completeBoxDiv">
                        <p className="para-Text">
                          {" "}
                          {state?.data?.product_name}
                        </p>
                        <div className="p-0 d-flex flex-wrap gap-2">
                          <span className="skitchin-Items">
                            {state?.data?.category_name}
                          </span>
                          {state?.data?.subcategory && (
                            <span className="skitchin-Items">
                              {state?.data?.subcategory}
                            </span>
                          )}
                        </div>
                        <p className="complete-box">
                          {state?.data?.price?.type}
                        </p>
                        <span className="complete-number">
                          {skuToolTip(state)}
                        </span>
                      </Row>
                      <Row className="m-0  market-price-div">
                        <Row className="d-flex m-0 p-0 justify-content-between">
                          <Col className="price-market-text" md={6}>
                            Market Price:
                          </Col>
                          <Col
                            className="price-market-amount d-flex justify-content-end"
                            md={6}
                          >
                            $
                            {isNaN(convertToUsd(productDataObject?.marketPrice))
                              ? "0.00"
                              : state?.data?.cardRarity
                              ? productDataObject?.marketPrice
                              : parseToThousandSeperatorDecimalNumber(
                                  convertToUsd(
                                    productDataObject?.marketPrice ?? 0
                                  )
                                )}
                          </Col>
                        </Row>
                        <Row className="d-flex m-0 p-0 mt-2 justify-content-between">
                          <Col className="suggest-text " md={6}>
                            Suggested Sell Price:
                          </Col>
                          <Col
                            className="suggest-amount d-flex justify-content-end"
                            md={6}
                          >
                            $
                            {isNaN(
                              convertToUsd(
                                productDataObject?.suggestedSellPrice
                              )
                            )
                              ? "0.00"
                              : parseToThousandSeperatorDecimalNumber(
                                  convertToUsd(
                                    productDataObject?.suggestedSellPrice ?? 0
                                  )
                                )}
                          </Col>
                        </Row>
                        <Col className="p-0 mt-3 d-flex gap-2 ms-auto">
                          {state?.data?.apiSource ===
                          inventoryConstants.PRICECHARTING_API ? (
                            <>
                              <Button
                                handleClick={() => {
                                  handleViewEbaySales(
                                    state?.data?.product_name,
                                    state?.data?.category_name,
                                    state?.data?.subcategory
                                  );
                                }}
                                type="button"
                                label={buttonNameConstants.EBAY_SALES}
                                buttonType={buttonTypeConstants.GHOST_BUTTON_2}
                                className="w-100"
                              ></Button>
                              <Button
                                type="button"
                                handleClick={() => {
                                  setProductUrl(
                                    `${
                                      process.env
                                        .REACT_APP_PRICECHARTING_IFRAME_URL
                                    }${stringToSlug(
                                      state?.data?.category_name
                                    )}/${stringToSlug(
                                      state?.data?.product_name
                                    )}`
                                  );
                                  handleShowIfram();
                                }}
                                label={buttonNameConstants.PRICE_CHARTING}
                                buttonType={buttonTypeConstants.BLUE_BUTTON}
                                className="w-100"
                              ></Button>
                            </>
                          ) : (
                            <Button
                              type="button"
                              // handleClick={() => {
                              //   state?.data?.apiSource ===
                              //     inventoryConstants.POKEMON_API &&
                              //     setProductUrl(productDataObject.productUrl);
                              //   handleShowIfram();
                              // }}
                              handleClick={() => {
                                if (
                                  state?.data?.apiSource ===
                                  inventoryConstants.POKEMON_API
                                ) {
                                  window.open(
                                    productDataObject.productUrl,
                                    "_blank"
                                  );
                                }
                              }}
                              label={buttonNameConstants.VIEW_TCG_PLAYER}
                              buttonType={buttonTypeConstants.BLUE_BUTTON}
                              className="ms-auto w-50"
                              isDisabled={!productDataObject.productUrl}
                            ></Button>
                          )}
                        </Col>
                      </Row>
                      <Row className="m-0 product-stats">
                        <Col className="product-stats-text">
                          Your Product Stats
                        </Col>

                        <div className="detail-div">
                          <p>Total Units Sold:</p>
                          <span>{state?.data?.price?.totalUnitsSold}</span>
                        </div>
                        <div className="detail-div">
                          <p>Average Cost of Goods:</p>
                          <span>
                            {" "}
                            $
                            {parseToThousandSeperatorDecimalNumber(
                              state?.data?.price?.averageBuyPrice
                            )}
                          </span>
                        </div>
                        <div className="detail-div">
                          <p>Average Sold Price:</p>
                          <span>
                            $
                            {parseToThousandSeperatorDecimalNumber(
                              state?.data?.price?.averageSellPrice
                            )}
                          </span>
                        </div>
                        <div className="detail-div">
                          <p>Max Buy Price</p>
                          <span>
                            {parseToThousandSeperatorDecimalNumber(
                              state?.data?.price?.maxBuyPrice
                            ) || "0.00"}
                          </span>
                        </div>
                      </Row>
                    </Row>
                  </Col>
                  <Col md={7}></Col>
                  <Col md={5} className="mt-3 pb-4 pe-0 d-flex gap-2 ms-auto">
                    <Button
                      type="button"
                      label={buttonNameConstants.CANCEL}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      className="w-100"
                      handleClick={() => {
                        handleCancelButton();
                      }}
                    ></Button>
                    <Button
                      type="submit"
                      className="w-100"
                      label={buttonNameConstants.SAVE}
                    ></Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Modal
          size="md"
          animation={true}
          centered
          backdrop="static"
          show={show}
          onHide={handleClose}
        >
          <Modal.Body>
            {" "}
            <Row className="m-0 p-0">
              <Col
                md={3}
                className="img-Div-stolen d-flex p-0 box-shadow-default"
              >
                <img
                  src={state?.data?.imgUrl ? state.data.imgUrl : ProductImage}
                  alt=""
                />
              </Col>
              <Col md={9} className="m-0 pe-0">
                <Row className="m-0 p-0 h-100">
                  <Col md={12} className="p-0 mt-2 stolen-card-text ">
                    Was {state?.data?.product_name} lost or stolen?
                  </Col>
                  <Col md={12} className="mt-auto p-0 d-flex gap-2 ">
                    <Button
                      type="button"
                      label={buttonNameConstants.NO}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      className="w-100"
                      handleClick={() => {
                        // handleCancelButton();
                        handleModalNoButton();
                        handleClose();
                      }}
                    ></Button>
                    <Button
                      type="button"
                      className="w-100"
                      handleClick={() => {
                        // setShrinkValue(buttonNameConstants.YES);
                        handleModalYesButton();
                        handleClose();
                      }}
                      label={buttonNameConstants.YES}
                    ></Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          size="xl"
          animation={true}
          centered
          backdrop="static"
          show={showIframe}
          onHide={handleCloseIfram}
          // className="iframe-edit-inventory"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <iframe
              title="Product Iframe"
              src={productUrl}
              className="iframe-website-edit-inventory  w-100"
            />
          </Modal.Body>
        </Modal>
        <PrintLabelModal
          printLabelModal={printLabelModal}
          togglePrintLabelModal={togglePrintLabelModal}
          // selectedRows={selectedRows}
          singlePrintArray={[state?.data]}
        />
      </Row>
    </>
  );
};

//-------EditInventory component ends here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  updateInventory: (storeId, data) =>
    dispatch(inventoryActions.updateInventory(storeId, data)),

  quantityShrink: (data) => dispatch(inventoryActions.quantityShrink(data)),
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export EditInventory Component
export default connect(mapStateToProps, mapDispatchToProps)(EditInventory);

const skuToolTip = (state) => {
  return (
    state?.data?.sku?.length && (
      <>
        <p id="sku-drop" className="w-25 cursor-pointer text-nowrap">
          #{state.data.sku[0]}
        </p>
        <CustomTooltip
          anchorSelect={`#${"sku-drop"}`}
          place="bottom-start"
          className={"tooltip-for-text sku-tooltip"}
        >
          {state.data.sku.map((sku) => (
            <p className="p-0 m-0">{sku}</p>
          ))}
        </CustomTooltip>
      </>
    )
  );
};

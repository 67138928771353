import React from "react";
import Skeleton from "react-loading-skeleton";

const SummaryCardSkeleton = () => {
  return (
    <div className="transaction-overview-card">
      <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
      <Skeleton className="heading-skeleton" />
    </div>
  );
};

export default SummaryCardSkeleton;

import { connect } from "react-redux";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { inventoryActions, systemActions } from "../../../../redux/actions";
import Button from "../../../../shared/components/Buttons/Button";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import ProductImage from "../../../../assets/images/productimage.png";
import {
  customToast,
  convertToUsd,
  stringToSlug,
  convertToFixedPrecision,
  handleViewTCGPlayer,
  handleViewPriceCharting,
  handleViewEbaySales,
  getPricechartingProductImage,
  uploadCustomProductImage,
  onChangeStockPrice,
  changePriceBaseOnTags,
  getShortestSku,
  handleConditionsAndTags,
  handleShowNintendoCondition,
  getMarginDropdownValue,
  handleMarginChange,
  getPresetStockPrice,
  handleConditionChange,
  handleTagList,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  categoryDropdownConstants,
  toastType,
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
  inventoryConstants,
} from "../../../../constants";
import {
  DollarIcon,
  SidebarToggleIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import { addNewTradeValidationSchema } from "../../../../shared/validationSchema/validationSchema";

const AddTradeBatchEdit = (props) => {
  //-------Destructure props
  const {
    addTradeModal,
    toggleAddTradeModal,
    inventory,
    emptyPriceChartingProductObject,
    getInventoryByPricechartingId,
    currentStore,
    isAddModalOpen,
    addToCart,
    tradeDiscount,
    activateSpinner,
    deactivateSpinner,
    editTradeObject,
    setIsEdit,
    setEditTradeObject,
    priceChartingProductInfo,
  } = props;
  //-------inventory reducer
  const {
    // priceChartingProductInfo,
    inventoryProducts,
    productTags,
  } = inventory;

  //-------preset prices
  const { markupPresets, tradeinMargin } = tradeDiscount;
  //------UseRef
  const hiddenFileInput = useRef(null);

  //-------useState
  const [productType, setProductType] = useState(PRODUCT_TYPES_ENUMS.ALL);
  const [selectedTags, setSelectedTags] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [productCondition, setProductCondition] = useState();
  const [productTagsList, setProductTagsList] = useState([]);
  const [productUrl, setProductUrl] = useState("");
  const [marginTypeObject, setMarginTypeObject] = useState();
  const [cashOfferValue, setCashOfferValue] = useState("");
  const [tradeOfferValue, setTradeOfferValue] = useState("");
  const [initialRatio, setInitialRatio] = useState("");
  const [addSelectedTags, setAddSelectedTags] = useState([]);
  const [initialGlobalRatio, setInitialGlobalRatio] = useState("");
  const [productDetail, setProductDetail] = useState("");
  const [margin, setMargin] = useState("");
  const [pricechartingProductImage, setPricechartingProductImage] =
    useState("");

  const [productDataObject, setProductDataObject] = useState({
    productId: globalConstants.EMPTY_STRING,
    productName: globalConstants.EMPTY_STRING,
    consoleName: globalConstants.EMPTY_STRING,
    marketPrice: 0,
    suggestedSellPrice: 0,
    costOfGoods: 0,
    inStorePrice: 0,
    inStockPrice: 0,
    inStockQuantity: 0,
    skuNumber: 0,
    gameStopBuyPrice: 0,
    genre: globalConstants.EMPTY_STRING,
    upc: globalConstants.EMPTY_STRING,
    averageBuyPrice: 0,
    averageSellPrice: 0,
    maxBuyPrice: 0,
    cardNumber: 0,
    tcgPlayerUrl: globalConstants.EMPTY_STRING,
    imgUrl: globalConstants.EMPTY_STRING,
    totalUnitsSold: 0,
    epid: globalConstants.EMPTY_STRING,
  });
  // const ratio =
  //   marginTypeObject?.marginObject?.cashMarginPercentage /
  //   marginTypeObject?.marginObject?.tradeinMarginPercentage;
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );

  //-------initial values
  const initialValues = {
    quantity: editTradeObject?.inStockQuantity || 1,
    box:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX)
        ? true
        : false,
    manual:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      !selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL)
        ? true
        : productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE &&
          selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)
        ? true
        : false,
    cables:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES)
        ? false
        : true,
    controller:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      !selectedTags?.includes(
        inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
      ),
    costOfGoods: productDataObject.costOfGoods || globalConstants.EMPTY_STRING,
    inStockPrice:
      editTradeObject?.inStockPrice ||
      productDataObject?.inStorePrice ||
      productDataObject?.inStockPrice ||
      globalConstants.EMPTY_STRING,

    cashOffer: cashOfferValue || globalConstants.EMPTY_STRING,
    tradeOffer: tradeOfferValue || globalConstants.EMPTY_STRING,
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  //------handle upload button
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = async () => {
              activateSpinner(inventoryConstants.UPLOAD_IMAGE);
              const productImageUrl = await uploadCustomProductImage(file);
              setProductDataObject({
                ...productDataObject,
                imgUrl: productImageUrl,
              });
              deactivateSpinner(inventoryConstants.UPLOAD_IMAGE);
            };
          };
          reader.readAsDataURL(file);
        }
      } else {
        customToast(
          "Only .jpg, .jpeg and .png files are allowed",
          toastType.ERROR
        );
        e.target.value = null;
      }
    }
  };

  const getMarketPrice = () => {
    if (selectedRarity) {
      return Number(productDataObject?.marketPrice ?? 0);
    } else {
      return Number(convertToUsd(productDataObject.marketPrice));
    }
  };

  //------match tags of a poduct
  const isTagsEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  };

  //-------onselectedtagchange
  const onSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag);
    const selectedTagListTags = selectedTags.map((tag) => tag.label);
    changePriceBaseOnTags(
      selectedTagListTags,
      productDataObject,
      setProductDataObject,
      priceChartingProductInfo,
      inventoryConstants,
      PRODUCT_CONDITIONS_ENUMS,
      TRADING_CARD_CATEGORIES_ENUMS,
      productCondition
    );

    setSelectedTags(selectedTagListTags);
    setAddSelectedTags(selectedTagList);
  };

  //-------handle inventory submit
  const handleAddTradeSubmit = async (values) => {
    if (!productDataObject?.productName) {
      customToast("Please select some product", toastType.ERROR);
    } else {
      const sendToData = {
        ...editTradeObject,
        id: editTradeObject?.id,
        productId: productDataObject.productId,
        productName: productDataObject.productName,
        consoleName: productDataObject.consoleName,
        marketPrice: getMarketPrice() || 0,
        suggestedSellPrice: productDataObject.suggestedSellPrice,
        costOfGoods: values.costOfGoods,
        inStockPrice: values.inStockPrice,
        inStockQuantity: values.quantity,
        skuNumber: 0,
        gameStopBuyPrice: editTradeObject.gameStopBuyPrice,
        genre: productDataObject.genre,
        upc: productDataObject.upc,
        averageBuyPrice: productDataObject.averageBuyPrice,
        averageSellPrice: productDataObject.averageSellPrice,
        maxBuyPrice: productDataObject.maxBuyPrice,
        cashPercentagePerItem: editTradeObject.cashPercentagePerItem,
        tradePercentagePerItem: editTradeObject.tradePercentagePerItem,
        imgUrl: productDataObject?.imgUrl,
        tcgPlayerUrl: productDataObject.tcgPlayerUrl,
        epid: productDataObject.epid,
        productType: productType,
        productCondition: productCondition?.value,
        productConditionList: conditionList,
        completePrice: editTradeObject.completePrice,
        loosePrice: editTradeObject.loosePrice,
        newPrice: editTradeObject.newPrice,
        boxOnlyPrice: editTradeObject.boxOnlyPrice,
        manualPrice: editTradeObject.manualPrice,
        apiSource: priceChartingProductInfo?.productMetaData?.sourceApi,
        raritiesList: editTradeObject?.raritiesList || [],
        rarity: selectedRarity || [],
        cashOffer: values.cashOffer,
        tradeOffer: values.tradeOffer,
        tags: addSelectedTags?.map((tag) => tag),
        editedRatio: initialGlobalRatio
          ? initialGlobalRatio.ratio
          : initialRatio,
        tradeInMarginTypeObject: marginTypeObject,
      };
      addToCart(sendToData);
    }
    toggleAddTradeModal();
    clearAddInventoryForm();
    setIsEdit(false);
  };

  useEffect(() => {
    setProductDataObject({
      ...productDataObject,
      imgUrl: pricechartingProductImage,
    }); // eslint-disable-next-line
  }, [pricechartingProductImage]);

  //------handleConditionChange
  useEffect(() => {
    const marketPrice = getMarketPrice();
    if (marketPrice === 0) {
      const ratio =
        marginTypeObject?.marginObject?.cashMarginPercentage /
        marginTypeObject?.marginObject?.tradeinMarginPercentage;
      setInitialRatio(ratio);
    } else {
      const initialCashValue =
        marketPrice *
        (marginTypeObject?.marginObject?.cashMarginPercentage / 100);
      const initialTradeValue =
        marketPrice *
        (marginTypeObject?.marginObject?.tradeinMarginPercentage / 100);
      const initialRatio = initialTradeValue / initialCashValue;
      setInitialRatio(initialRatio);
    } // eslint-disable-next-line
  }, [marginTypeObject]);

  //-------hide modal
  const handleHideModal = () => {
    setEditTradeObject("");
    setIsEdit(false);
    toggleAddTradeModal();
    clearAddInventoryForm();
  };

  //-------empty product data object
  const emptyProductDataObject = () => {
    setProductDataObject({
      productId: globalConstants.EMPTY_STRING,
      productName: globalConstants.EMPTY_STRING,
      consoleName: globalConstants.EMPTY_STRING,
      marketPrice: 0,
      suggestedSellPrice: 0,
      costOfGoods: 0,
      inSorePrice: 0,
      inStockPrice: 0,
      inStockQuantity: 0,
      skuNumber: 0,
      gameStopBuyPrice: 0,
      genre: globalConstants.EMPTY_STRING,
      upc: globalConstants.EMPTY_STRING,
      averageBuyPrice: 0,
      averageSellPrice: 0,
      maxBuyPrice: 0,
      cardNumber: 0,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      imgUrl: globalConstants.EMPTY_STRING,
      totalUnitsSold: 0,
      epid: globalConstants.EMPTY_STRING,
    });
  };

  //------find product details
  const findInventoryProductDetail = (condition) => {
    if (inventoryProducts?.length > 0) {
      const findProduct = inventoryProducts.find((product) => {
        if (
          product.price.type === condition &&
          isTagsEqual(product.tags || [], selectedTags || [])
        ) {
          if (
            priceChartingProductInfo?.productMetaData?.sourceApi ===
              inventoryConstants.POKEMON_API ||
            priceChartingProductInfo?.productMetaData?.sourceApi ===
              inventoryConstants.SCRYFALL_API
          ) {
            if (product?.cardRarity === selectedRarity?.label) {
              return product;
            }
          } else {
            return product;
          }
        }
      });
      setProductDataObject({
        ...productDataObject,
        inStockQuantity: findProduct?.price?.quantity || 0,
        skuNumber: findProduct?.sku || 0,
        inStorePrice: findProduct?.price?.unit_sell_price || 0,
        inStockPrice:
          findProduct?.price?.unit_sell_price ||
          getPresetStockPrice(
            productType,
            productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
              productDataObject.consoleName ===
                TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
              ? convertToUsd(productDataObject.marketPrice)
              : productDataObject.marketPrice,
            markupPresets,
            productDataObject
          ),
        averageBuyPrice: findProduct?.price?.averageBuyPrice || 0,
        averageSellPrice: findProduct?.price?.averageSellPrice || 0,
        maxBuyPrice: findProduct?.price?.maxBuyPrice || 0,
        totalUnitsSold: findProduct?.price?.totalUnitsSold || 0,
      });
      return;
    }
    setProductDataObject({
      ...productDataObject,
      inStorePrice: 0,
      inStockPrice: getPresetStockPrice(
        productType,
        productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
          productDataObject.consoleName ===
            TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          ? convertToUsd(productDataObject.marketPrice)
          : productDataObject.marketPrice,
        markupPresets,
        productDataObject
      ),
    });
  };

  //-------clear form
  const clearAddInventoryForm = () => {
    emptyPriceChartingProductObject();
    emptyProductDataObject();
    setProductCondition("");
    setConditionList([]);
    setProductTagsList([]);
    setProductUrl("");
    setSelectedRarity("");
    setPricechartingProductImage("");
    setAddSelectedTags([]);
    setSelectedTags([]);
    setProductDetail("");
  };

  const handleRaritiesChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedRarity(selectedOption);
      setProductDataObject({
        ...productDataObject,
        marketPrice: selectedOption?.value?.value,
      });
    }
  };

  //-------UseEffect
  useEffect(() => {
    clearAddInventoryForm(); // eslint-disable-next-line
  }, [isAddModalOpen]);

  useEffect(() => {
    if (productCondition?.value) {
      findInventoryProductDetail(productCondition?.value);
    } // eslint-disable-next-line
  }, [selectedTags, selectedRarity, productCondition]);

  //-------update product condition and type
  useEffect(() => {
    if (priceChartingProductInfo?.productObject) {
      if (
        priceChartingProductInfo.productMetaData.sourceApi ===
        inventoryConstants.PRICECHARTING_API
      ) {
        getPricechartingProductImage(
          `${process.env.REACT_APP_PRICECHARTING_IFRAME_URL}${stringToSlug(
            priceChartingProductInfo?.productObject["console-name"]
          )}/${stringToSlug(
            priceChartingProductInfo?.productObject["product-name"]
          )}`,
          setPricechartingProductImage
        );
        if (
          priceChartingProductInfo.productMetaData.genre ===
          TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
        ) {
          setSelectedRarity("");
          setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
          setConditionList(inventoryConstants.TRADING_CARD_CONDITION);

          setProductDataObject({
            ...productDataObject,
            productName: priceChartingProductInfo.productObject["product-name"],
            consoleName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
            marketPrice: editTradeObject?.marketPrice || 0,
            suggestedSellPrice:
              priceChartingProductInfo.productObject["retail-loose-sell"] || 0,
            gameStopBuyPrice:
              priceChartingProductInfo.productObject["gamestop-price"],
            upc:
              priceChartingProductInfo.productObject.upc ||
              globalConstants.EMPTY_STRING,
            genre: priceChartingProductInfo.productMetaData.genre,
            productId: priceChartingProductInfo.productObject.id,
            cardNumber: 0,
            tcgPlayerUrl: globalConstants.EMPTY_STRING,
            imgUrl: globalConstants.EMPTY_STRING,
            epid:
              priceChartingProductInfo.productObject?.epid ||
              globalConstants.EMPTY_STRING,
          });
          getInventoryByPricechartingId(currentStore?.id, {
            id: priceChartingProductInfo.productObject.id,
            categoryName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
            productName: priceChartingProductInfo.productObject["product-name"],
          });
        } else {
          if (
            priceChartingProductInfo.productMetaData.genre ===
            TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
          ) {
            handleConditionsAndTags(
              editTradeObject?.consoleName,
              editTradeObject.genre,
              productTags,
              setConditionList,
              setProductCondition,
              setProductTagsList,
              inventoryConstants.VIDEO_GAME_SYSTEM_CONDITION
            );
          } else {
            handleConditionsAndTags(
              editTradeObject?.consoleName,
              editTradeObject.genre,
              productTags,
              setConditionList,
              setProductCondition,
              setProductTagsList,
              inventoryConstants.VIDEO_GAME_CONDITION
            );
          }
          setSelectedRarity("");
          setProductType(PRODUCT_TYPES_ENUMS.VIDEO_GAME);
          setProductDataObject({
            ...productDataObject,
            productName: priceChartingProductInfo.productObject["product-name"],
            consoleName: priceChartingProductInfo.productObject["console-name"],
            marketPrice: editTradeObject?.marketPrice,
            suggestedSellPrice: getSuggestedSellPrice(productCondition),
            gameStopBuyPrice:
              priceChartingProductInfo.productObject["gamestop-price"],
            upc:
              priceChartingProductInfo.productObject.upc ||
              globalConstants.EMPTY_STRING,
            genre: priceChartingProductInfo.productMetaData.genre,
            productId: priceChartingProductInfo.productObject.id,
            skuNumber: 0,
            averageBuyPrice: 0,
            averageSellPrice: 0,
            maxBuyPrice: 0,
            cardNumber: 0,
            tcgPlayerUrl: globalConstants.EMPTY_STRING,
            imgUrl: globalConstants.EMPTY_STRING,
            epid:
              priceChartingProductInfo.productObject?.epid ||
              globalConstants.EMPTY_STRING,
          });
          getInventoryByPricechartingId(currentStore?.id, {
            id: priceChartingProductInfo.productObject.id,
            categoryName:
              priceChartingProductInfo?.productObject["console-name"],
            productName: priceChartingProductInfo.productObject["product-name"],
          });
        }
      } else if (
        priceChartingProductInfo.productMetaData.sourceApi ===
          inventoryConstants.POKEMON_API ||
        priceChartingProductInfo.productMetaData.sourceApi ===
          inventoryConstants.SCRYFALL_API
      ) {
        setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
        setConditionList(inventoryConstants.TRADING_CARD_CONDITION);
        setProductTagsList([]);
        // setProductCondition(inventoryConstants.TRADING_CARD_CONDITION[0]);
        if (
          priceChartingProductInfo.productMetaData.sourceApi ===
          inventoryConstants.POKEMON_API
        ) {
          const pokemonProductName = `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set.name} - ${priceChartingProductInfo.productObject.number}/${priceChartingProductInfo.productObject.set.printedTotal})`;
          getInventoryByPricechartingId(currentStore?.id, {
            id: priceChartingProductInfo.productObject.id,
            categoryName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
            productName: pokemonProductName,
          });
          setProductDataObject({
            ...productDataObject,
            productName: pokemonProductName,
            consoleName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
            upc:
              priceChartingProductInfo.productObject.upc ||
              globalConstants.EMPTY_STRING,
            genre: priceChartingProductInfo.productMetaData.genre,
            productId: priceChartingProductInfo.productObject.id,
            marketPrice: editTradeObject?.marketPrice || 0,
            suggestedSellPrice: getSuggestedSellPrice(productCondition),
            cardNumber: priceChartingProductInfo.productObject.number,
            imgUrl:
              priceChartingProductInfo.productObject.images.small ||
              globalConstants.EMPTY_STRING,
            tcgPlayerUrl: priceChartingProductInfo.productObject.tcgplayer.url,
            // costOfGoods: 0,
            // inStockPrice: 0,
            // inStockQuantity: 0,
            // skuNumber: 0,
            // gameStopBuyPrice: 0,
            // averageBuyPrice: 0,
            // averageSellPrice: 0,
            // maxBuyPrice: 0,
            // epid: globalConstants.EMPTY_STRING,
          });
        } else if (
          priceChartingProductInfo.productMetaData.sourceApi ===
          inventoryConstants.SCRYFALL_API
        ) {
          // setRarities(raritiesList);
          // setSelectedRarity(raritiesList[0]);
          getInventoryByPricechartingId(currentStore?.id, {
            id: priceChartingProductInfo.productObject.id,
            categoryName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
            productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
          });
          setProductDataObject({
            ...productDataObject,
            productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
            consoleName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
            upc:
              priceChartingProductInfo.productObject.upc ||
              globalConstants.EMPTY_STRING,
            genre: priceChartingProductInfo.productMetaData.genre,
            productId: priceChartingProductInfo.productObject.id,
            marketPrice: editTradeObject?.marketPrice || 0,
            suggestedSellPrice: getSuggestedSellPrice(productCondition),
            cardNumber: priceChartingProductInfo.productObject.collector_number,
            tcgPlayerUrl:
              priceChartingProductInfo.productObject.purchase_uris?.tcgplayer ||
              globalConstants.EMPTY_STRING,
            costOfGoods: 0,
            inSorePrice: 0,
            inStockPrice: 0,
            inStockQuantity: 0,
            skuNumber: 0,
            gameStopBuyPrice: 0,
            averageBuyPrice: 0,
            averageSellPrice: 0,
            maxBuyPrice: 0,
            imgUrl:
              priceChartingProductInfo.productObject?.image_uris?.small ||
              globalConstants.EMPTY_STRING,
            epid: globalConstants.EMPTY_STRING,
          });
        }
      }
    }
    setProductUrl("");
    // eslint-disable-next-line
  }, [priceChartingProductInfo.productObject]);

  // const onChangeTag = (condition, row) => {
  //   const defaultTags =
  //     row?.genre === TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
  //       ? productTags.systemsDefaultTags
  //       : productTags.gamesDefaultTags;

  //   const tagListMap = {
  //     [PRODUCT_CONDITIONS_ENUMS.COMPLETE]: defaultTags?.complete,
  //     [PRODUCT_CONDITIONS_ENUMS.LOOSE]: defaultTags?.loose,
  //     [PRODUCT_CONDITIONS_ENUMS.BOX_ONLY]: defaultTags?.boxOnly,
  //     [PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY]: defaultTags?.manualOnly,
  //   };

  //   const selectedTags = tagListMap[condition] || [];
  //   setProductTagsList(selectedTags);
  // };

  const getSuggestedSellPrice = (selectedOption) => {
    if (selectedOption?.value) {
      if (selectedOption.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
        return priceChartingProductInfo?.productObject["retail-cib-sell"] || 0;
      } else if (selectedOption.value === PRODUCT_CONDITIONS_ENUMS.NEW) {
        return priceChartingProductInfo?.productObject["retail-new-sell"] || 0;
      } else if (selectedOption.value === PRODUCT_CONDITIONS_ENUMS.LOOSE) {
        return (
          priceChartingProductInfo?.productObject["retail-loose-sell"] || 0
        );
      } else if (
        selectedOption.value === PRODUCT_CONDITIONS_ENUMS.BOX_ONLY ||
        selectedOption.value === PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY
      ) {
        return 0;
      }
    }
    return 0; // Default value if no valid condition is selected
  };

  const handleMarginSelectChange = (selectedOption, setFieldValue) => {
    setInitialGlobalRatio("");
    handleMarginChange(
      setMargin,
      getMarketPrice(),
      editTradeObject?.productType,
      setFieldValue,
      selectedOption,
      tradeinMargin,
      productDataObject,
      setMarginTypeObject
    );
  };

  useEffect(() => {
    setTradeOfferValue(editTradeObject?.tradeOffer);
    setCashOfferValue(editTradeObject?.cashOffer);
    const productCondition = {
      value: editTradeObject?.productCondition,
      label: handleShowNintendoCondition(
        editTradeObject.consoleName,
        editTradeObject?.productCondition
      ),
    };
    setProductCondition(productCondition);
    setProductTagsList(
      handleTagList(
        productTags,
        editTradeObject?.genre,
        productCondition.value,
        ""
      )
    );
    // onChangeTag(editTradeObject?.productCondition, editTradeObject);

    setRarities(
      editTradeObject?.raritiesList?.length > 0
        ? editTradeObject?.raritiesList
        : ""
    );
    setSelectedRarity(
      editTradeObject?.rarity?.label ? editTradeObject?.rarity : ""
    );

    setProductDetail(editTradeObject);
  }, [editTradeObject, pricechartingProductImage, priceChartingProductInfo]);

  useEffect(() => {
    if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
      findInventoryProductDetail(PRODUCT_CONDITIONS_ENUMS.COMPLETE);
    } else if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.NEAR_MINT) {
      findInventoryProductDetail(PRODUCT_CONDITIONS_ENUMS.NEAR_MINT);
    } // eslint-disable-next-line
  }, [inventoryProducts]);

  useEffect(() => {
    var marginDropDownObject = editTradeObject.tradeInMarginTypeObject;
    setMarginTypeObject(marginDropDownObject);
  }, [editTradeObject]);

  const HandleCashOfferValues = (InputValue, setFieldValue) => {
    const newCashOffer = parseFloat(InputValue); // Assuming InputValue is the new cash offer value
    setFieldValue("cashOffer", newCashOffer);
    const calculateRatio =
      marginTypeObject?.marginObject?.tradeinMarginPercentage /
      marginTypeObject?.marginObject?.cashMarginPercentage;
    const newTradeValue = newCashOffer * calculateRatio;
    setFieldValue("tradeOffer", parseToDecimalNumber(newTradeValue, true));
  };

  const HandleTradeOfferValues = (InputValue, setFieldValue) => {
    const newTradeValue = parseFloat(InputValue);
    setFieldValue("tradeOffer", newTradeValue);
    const calculateRatio =
      marginTypeObject?.marginObject?.tradeinMarginPercentage /
      marginTypeObject?.marginObject?.cashMarginPercentage;
    const newCashOffer = newTradeValue / calculateRatio;
    setFieldValue("cashOffer", parseToDecimalNumber(newCashOffer, true));
  };

  // for ratio
  const globalTradeinMargin = tradeinMargin?.globalMargin;
  useEffect(() => {
    const marketPrice = convertToFixedPrecision(productDataObject.marketPrice);
    let percentageObject = {
      cashMarginPercentage:
        currentStore?.globalTradeinMargin?.cashMarginPercentage || 0,
      tradeinMarginPercentage:
        currentStore?.globalTradeinMargin?.tradeinMarginPercentage || 0,
    };
    if (marketPrice === 0) {
      const ratio =
        currentStore?.globalTradeinMargin?.cashMarginPercentage /
        currentStore?.globalTradeinMargin?.tradeinMarginPercentage;
      let globalInitialRatioObject = {
        ratio: ratio,
        globalMarginObject: percentageObject,
      };

      setInitialGlobalRatio(globalInitialRatioObject);
    } else {
      const initialCashValue =
        marketPrice * (globalTradeinMargin?.cashMarginPercentage / 100);
      const initialTradeValue =
        marketPrice * (globalTradeinMargin?.tradeinMarginPercentage / 100);
      const initialRatio = initialTradeValue / initialCashValue;
      let globalInitialRatioObject = {
        ratio: initialRatio,
        globalMarginObject: percentageObject,
      };
      setInitialGlobalRatio(globalInitialRatioObject);
    } // eslint-disable-next-line
  }, [globalTradeinMargin, productDataObject]);
  return (
    <Modal
      show={addTradeModal}
      size="lg"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className={`add-inventory-modal ${productUrl ? "iframe-opened" : ""}`}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleAddTradeSubmit}
        validate={addNewTradeValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Header
              closeButton
              className="add-inventory-modal-header pt-0 pb-1"
            >
              <Modal.Title>
                <span className="add-inventory-modal-header-name">
                  Edit Batch Trade-In
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-inventory-modal-body  d-flex ">
              <Row className={`m-0 ${productUrl ? "w-50" : "w-100"}`}>
                <Col xs={12} className="add-inventory-hero-wrapper px-0">
                  <Row className="m-0">
                    <Col md={8} className="d-flex gap-3">
                      <div className="add-inventory-product-image">
                        <img
                          src={productDataObject?.imgUrl || ProductImage}
                          alt="product"
                        />
                        <input
                          type="file"
                          className="d-none"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageChange}
                          ref={hiddenFileInput}
                        />
                        <button
                          onClick={handleReplaceButtonClick}
                          type="button"
                          className="replace-logo-button"
                        >
                          <LogoImageReplaceIcon className="replace-icon" />
                        </button>
                      </div>
                      <div className="d-flex flex-column justify-content-between align-items-start product-content-wrapper">
                        <span>
                          {productDataObject.productName || "Product Name"}
                        </span>
                        <span>
                          {productDataObject.consoleName || "Console Name"}
                        </span>
                        <span>
                          {`#${
                            getShortestSku(productDataObject.skuNumber) ||
                            "Auto Generated"
                          }
                        `}
                        </span>
                        <span>{`In Stock: ${productDataObject.inStockQuantity}`}</span>
                      </div>
                    </Col>
                    {priceChartingProductInfo?.productMetaData?.sourceApi ===
                    inventoryConstants.PRICECHARTING_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_EBAY_SALES}
                          className="w-100"
                          handleClick={() =>
                            handleViewEbaySales(
                              productDataObject?.productName,
                              productDataObject?.consoleName,
                              productDataObject?.genre
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_PRICE_CHARTING}
                          className="w-100"
                          handleClick={() =>
                            handleViewPriceCharting(
                              priceChartingProductInfo,
                              setProductUrl
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                      </Col>
                    ) : priceChartingProductInfo?.productMetaData?.sourceApi ===
                        inventoryConstants.SCRYFALL_API ||
                      priceChartingProductInfo?.productMetaData?.sourceApi ===
                        inventoryConstants.POKEMON_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_TCG_PLAYER}
                          className="w-100"
                          handleClick={() =>
                            handleViewTCGPlayer(productDataObject)
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                          isDisabled={!productDataObject?.tcgPlayerUrl}
                        />
                      </Col>
                    ) : null}
                    <Col
                      md={12}
                      className="d-flex justify-content-start flex-wrap gap-3 mt-3 pt-2"
                    >
                      <span className="quantity-top-margin">Quantity:</span>
                      <InputNumberField
                        name="quantity"
                        placeHolder="0"
                        className="add-inventory-quantity-field input-background"
                        isConvertToDecimal={false}
                        step={"1"}
                        isHandleKeyDown={true}
                      />
                      <CategoriesDropdown
                        options={conditionList}
                        handleChange={(selectedOption) =>
                          handleConditionChange(
                            selectedOption,
                            setProductCondition,
                            priceChartingProductInfo,
                            productTags,
                            setProductTagsList,
                            setProductDataObject,
                            productDataObject,
                            setSelectedTags
                          )
                        }
                        noOptionsMessage="No Condition Found"
                        placeholder="Condition"
                        SelectIconImage={
                          categoryDropdownConstants.INVENTORY_LIST_ICON
                        }
                        isClearable={false}
                        className="add-inventory-dropdowns-category condition-dropdown"
                        value={productCondition}
                      />
                      {productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
                      productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ? (
                        <CategoriesDropdown
                          options={rarities || []}
                          handleChange={(selectedOption) =>
                            handleRaritiesChange(selectedOption)
                          }
                          noOptionsMessage="No Printing Found"
                          placeholder="Printings"
                          SelectIconImage={
                            categoryDropdownConstants.INVENTORY_LIST_ICON
                          }
                          isClearable={false}
                          className="add-inventory-dropdowns-category condition-dropdown"
                          value={selectedRarity}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                    {productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                    productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                      <Col
                        md={12}
                        className="d-flex align-items-center gap-3 my-2"
                      >
                        {productDataObject.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.SYSTEM ? (
                          productCondition?.value ===
                          PRODUCT_CONDITIONS_ENUMS.COMPLETE ? (
                            <>
                              <CheckboxField name="box" label="Box" />
                              <CheckboxField name="cables" label="Cables" />
                              <CheckboxField
                                name="controller"
                                label="Controller"
                              />
                            </>
                          ) : (
                            ""
                          )
                        ) : [
                            ...inventoryConstants.NINTENDO_CATEGORIES,
                            ...inventoryConstants.SKYLANDER_CATEGORIES,
                          ].includes(editTradeObject?.consoleName) ? (
                          ""
                        ) : (
                          <CheckboxField name="manual" label="Manual" />
                        )}
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col
                      md={12}
                      className="my-2 d-flex align-items-center gap-4"
                    >
                      <ProductTagList
                        tagsList={productTagsList || []}
                        suggestedArrayList={productTags}
                        onSelectTagsChange={onSelectTagsChange}
                        productDetail={productDetail}
                        selectedManual={{
                          isChecked: values.manual,
                          value:
                            productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
                              : productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
                              : "",
                        }}
                        selectedBox={{
                          isChecked: values.box,
                          value:
                            productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE
                              ? inventoryConstants.PRODUCT_TAGS.WITH_BOX
                              : "",
                        }}
                        selectedWires={{
                          isChecked: values.cables,
                          value:
                            productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_WIRES
                              : "",
                        }}
                        selectedController={{
                          isChecked: values.controller,
                          value:
                            productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE
                              ? inventoryConstants.PRODUCT_TAGS
                                  .MISSING_CONTROLLER
                              : "",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} className="m-0 p-0 mt-4">
                  <Row className="m-0">
                    <Col
                      md={4}
                      className="add-inventory-prices-detail-wrapper d-flex flex-column align-items-lg-stretch gap-3"
                    >
                      <div>
                        <span>Total units Sold</span>
                        <span>{`${productDataObject.totalUnitsSold}`}</span>
                      </div>
                      <div>
                        <span>GameStop Buy Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          convertToUsd(productDataObject.gameStopBuyPrice || 0)
                        )}`}</span>
                      </div>
                      <div>
                        <span>Avg. Buy Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          productDataObject.averageBuyPrice || 0
                        )}`}</span>
                      </div>
                      <div>
                        <span>Max Buy Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          productDataObject.maxBuyPrice || 0
                        )}`}</span>
                      </div>
                      <div>
                        <span>Avg. Sell Price</span>
                        <span>{`$${parseToThousandSeperatorDecimalNumber(
                          productDataObject.averageSellPrice || 0
                        )}`}</span>
                      </div>
                    </Col>
                    <Col md={8}>
                      <Row className="m-0 h-100">
                        <Col xs={12} className="d-flex flex-column gap-1">
                          <div className="w-100 d-flex justify-content-between add-inventory-price-heading">
                            <span>Market Price: </span>
                            <span>{`$${parseToThousandSeperatorDecimalNumber(
                              getMarketPrice()
                            )}`}</span>
                          </div>
                          <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                            <span>Suggested sell price: </span>
                            <span>
                              {`$${convertToUsd(
                                productDataObject.suggestedSellPrice
                              )}`}
                            </span>
                          </div>
                        </Col>

                        <Col xs={12} className="d-flex flex-column gap-3">
                          <div className="add-inventory-stock-price-wrapper">
                            <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                              <span className="addTrade-gray-text">
                                Margin{" "}
                              </span>
                            </div>
                            <div className="mt-3 mb-3 col-md-5">
                              <CategoriesDropdown
                                options={[
                                  inventoryConstants.MARGIN_TYPE_GLOBAL,
                                  inventoryConstants.MARGIN_TYPE_STANDARD,
                                ]}
                                disabled={
                                  getMarginDropdownValue(
                                    getMarketPrice(),
                                    tradeinMargin,
                                    productType,
                                    productDataObject
                                  )?.marginType ===
                                  inventoryConstants.MARGIN_TYPE_GLOBAL
                                }
                                handleChange={(selectedOption) =>
                                  handleMarginSelectChange(
                                    selectedOption,
                                    setFieldValue
                                  )
                                }
                                noOptionsMessage="No Condition Found"
                                placeholder="Global"
                                SelectIconImage={
                                  categoryDropdownConstants.INVENTORY_LIST_ICON
                                }
                                isClearable={false}
                                defaultValue={
                                  editTradeObject?.tradeInMarginTypeObject
                                    ?.marginType
                                }
                                className="add-inventory-dropdowns-category condition-dropdown remove-minWidth-dropdown"
                                // value={marginTypeObject?.marginType}
                              />
                            </div>
                            <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span className="addTrade-gray-text">
                                Cash Offer:{" "}
                              </span>
                              <InputNumberField
                                name="cashOffer"
                                placeHolder="0"
                                className="add-inventory-price-field input-background"
                                IconImage={DollarIcon}
                                handleChange={(value) => {
                                  HandleCashOfferValues(value, setFieldValue);
                                }}
                              />
                            </div>
                            <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span className="addTrade-gray-text">
                                Trade Offer:{" "}
                              </span>
                              <InputNumberField
                                name="tradeOffer"
                                placeHolder="0"
                                className="add-inventory-price-field input-background "
                                IconImage={DollarIcon}
                                handleChange={(value) => {
                                  HandleTradeOfferValues(value, setFieldValue);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} className="d-flex flex-column gap-3">
                          <div className="add-inventory-stock-price-wrapper">
                            <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                              <span className="addTrade-gray-text">
                                Stock Price{" "}
                              </span>
                            </div>
                            <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span>In-Store: </span>
                              <InputNumberField
                                name="inStockPrice"
                                placeHolder="0"
                                className="add-inventory-price-field input-background"
                                IconImage={DollarIcon}
                                handleChange={(newValue) => {
                                  let marketPrice = getMarketPrice();
                                  onChangeStockPrice(
                                    newValue,
                                    setFieldValue,
                                    marketPrice,
                                    "",
                                    marginTypeObject
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="d-flex align-items-end gap-2">
                          <Button
                            label={buttonNameConstants.CANCEL}
                            className="w-100"
                            type="button"
                            handleClick={() => handleHideModal()}
                            buttonType={buttonTypeConstants.GHOST_BUTTON}
                          />
                          <Button
                            label={buttonNameConstants.SAVE}
                            className="w-100"
                            type="submit"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {productUrl && (
                <Row className="m-0 w-50">
                  <Col md={12}>
                    {productUrl && (
                      <iframe
                        title="Product Iframe"
                        src={productUrl}
                        className="iframe-website"
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        )}
      </Formik>
      {productUrl && (
        <button
          type="button"
          onClick={() => {
            setProductUrl("");
          }}
          className="iframe-toogle-button"
        >
          <SidebarToggleIcon className={"iframe-toggle-icon"} />
        </button>
      )}
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  currentStore: state.store.currentStore,
  isAddModalOpen: state.inventory.isAddModalOpen,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  addInventory: (data) => dispatch(inventoryActions.addInventory(data)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeBatchEdit);

import React from "react";
import Skeleton from "react-loading-skeleton";

const DashboardCardSkeleton = () => {
  return (
    <div className="dashboard-mini-inner-card d-flex flex-column justify-content-center gap-2">
      <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
      <Skeleton className="heading-skeleton dashboard-card-heading" />
    </div>
  );
};

export default DashboardCardSkeleton;

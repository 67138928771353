import { getDecryptPayload } from "./encryption";
import { authenticationConstants, globalConstants } from "../constants";

//-------Function to return Authentication Header with JWT Token
export const authenticationHeader = () => {
  const payload = getDecryptPayload();
  const userToken = payload?.accessToken || globalConstants.EMPTY_STRING;

  if (userToken && userToken.length > 0) {
    return {
      Authorization: `${authenticationConstants.BEARER} ${userToken}`,
    };
  }
  return globalConstants.EMPTY_OBJECT;
};

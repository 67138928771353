import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
const DatePickerContainer = ({
  IconImage,
  className,
  label,
  onDateChange,
  value,
  minDate,
}) => {
  return (
    <Row className="m-0">
      {label && <label className="field-label-date-picker">{label}</label>}
      <Col className="p-0 position-relative mb-3 mb-md-0">
        {IconImage && <IconImage className="date-icon-image-container" />}
        <DatePicker
          className={className}
          selected={value}
          onChange={onDateChange}
          minDate={minDate}
        />
      </Col>
    </Row>
  );
};

export default DatePickerContainer;

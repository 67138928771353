import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import React, { useEffect, useState, useRef } from "react";

import CashPayment from "./CashPayment";
import CardPayment from "./CardPayment";
import PaymentComplete from "./PaymentComplete";
import GiftCardPayment from "./GiftCardPayment";
import Topbar from "../../../../shared/components/topbar/Topbar";
import Button from "../../../../shared/components/Buttons/Button";
import CustomerImage from "../../../../assets/images/customerImage.svg";
import AddNewCustomerModal from "../AddNewCustomer/AddNewCustomerModal";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../../system/globalEnums";
import { printReceiptData } from "../../../../shared/Printers/ReceiptPrinters/printReceipt";
import CustomerDropdown from "../../../../shared/components/selectDropdown/CustomerDropdown";
import { prepareReceiptForPrinter } from "../../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinter";
import {
  deviceActions,
  systemActions,
  transactionActions,
} from "../../../../redux/actions";
import {
  customToast,
  getShortestSku,
  parseJsonObject,
  stringifyObject,
  handleZeroOnBlur,
  getCountOfTotalItems,
  getSumOfPaymentHistory,
  convertToFixedPrecision,
  parseToNumber,
  parseToThousandSeperatorDecimalNumber,
  parseToDecimalNumber,
} from "../../../../shared/utility";
import {
  toastType,
  toastMessages,
  globalConstants,
  routesPathConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  transactionDefaultData,
  categoryDropdownConstants,
} from "../../../../constants";
import {
  CashIcon,
  DeleteIcon,
  GiftCardIcon,
  CreditcardIcon,
  StoreCreditIcon,
} from "../../../../assets/icons/iconsProvider";

const NewTransactionPayment = (props) => {
  const {
    user,
    printer,
    customer,
    transaction,
    currentStore,
    storeDevices,
    sendEmailOrSMS,
    getStoreDevices,
    activateSpinner,
    giftCardDetails,
    deactivateSpinner,
    handleNewParkSale,
    newSaleTransaction,
    isAddCustomerModal,
    getGiftCardDetails,
    setTransactionData,
    emptyGiftCardDetails,
    cancelCardTransaction,
    toggleAddCustomerModal,
  } = props;
  const navigate = useNavigate();
  const { transactionData } = transaction;
  const { state } = useLocation();

  //-------useRef
  let cartItemsCount = useRef(transactionData?.inventory?.length ?? 0);
  const [changeOwed, setChangeOwed] = useState(0);
  const [customersList, setCustomersList] = useState([]);
  const [amountTendered, setAmountTendered] = useState(0);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(
    transactionData?.customer?.id && transactionData?.customer
  );
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  //handle amount tendered change
  const handleAmountTenderedChange = (e) => {
    const maxLength = 15;
    const amount = e;
    let owedAmount = 0;
    if (amount.length >= maxLength) {
      return;
    } else if (Number(amount) >= Number(transactionData?.currentAmountToPay)) {
      setAmountTendered(amount);
      owedAmount = parseToNumber(amount) - transactionData?.currentAmountToPay;
      setChangeOwed(parseToNumber(owedAmount));
    } else {
      setAmountTendered(amount);
      setChangeOwed(0);
    }
  };
  //-------Customer reducer
  const { allCustomers } = customer; //------printer reducer
  const { defaultReceiptPrinter } = printer;

  const defaultPaymentHistory = {
    [transactionConstants.CASH]: 0,
    [transactionConstants.CREDIT_CARD]: 0,
    [transactionConstants.GIFT_CARD]: 0,
    [transactionConstants.STORE_CREDIT]: 0,
  };

  const handleFixedAmountButtonClick = (amount) => {
    let owedAmount = 0;
    const tenderedAmount = Number(amountTendered) + amount;

    setAmountTendered(tenderedAmount.toFixed(2));
    if (tenderedAmount > transactionData?.currentAmountToPay) {
      owedAmount =
        Number(tenderedAmount).toFixed(2) -
        Number(transactionData?.currentAmountToPay).toFixed(2);
      setChangeOwed(parseToNumber(owedAmount));
    } else {
      setChangeOwed(0);
    }
  };

  const checkAmountToPay = () => {
    if (
      transactionData?.currentAmountToPay >
      convertToFixedPrecision(
        transactionData?.totalAmountToPay -
          parseToNumber(getSumOfPaymentHistory(transactionData.paymentHistory))
      )
    ) {
      customToast(toastMessages.AMOUNT_TO_PAY_LESS, toastType.ERROR);
      return true;
    } else if (transactionData?.currentAmountToPay <= 0) {
      customToast(toastMessages.AMOUNT_TO_PAY_GREATER, toastType.ERROR);
      return true;
    }
  };

  //-------add transaction transaction
  const addTransaction = async (paymentType, poiId) => {
    activateSpinner(transactionConstants.MAKE_TRANSACTION);
    const transactionId =
      transactionData?.id || (await transactionActions.getTransactionId());
    deactivateSpinner(transactionConstants.MAKE_TRANSACTION);
    setTransactionId(transactionId);
    if (transactionId) {
      const dataToSend = {
        creditUsed: 0,
        id: transactionId,
        PaymentType: paymentType,
        Tax: parseToDecimalNumber(transactionData?.tax),
        note: transactionData.note,
        activeCardPaymentType:
          paymentType === transactionConstants.CREDIT_CARD
            ? currentStore.activeCardPaymentType
            : STORE_CARD_PAYMENT_TYPE_ENUMS.NULL,
        SubTotal: transactionData?.subTotal,
        inventory: transactionData.inventory,
        isNoteField: transactionData.isNoteField,
        isTaxApplied: transactionData.isTaxApplied,
        poiId: poiId || globalConstants.EMPTY_STRING,
        discountMethod: transactionData.discountMethod,
        discount: transactionData.discountedValue || 0,
        paymentHistory: transactionData?.paymentHistory,
        isDiscountField: transactionData.isDiscountField,
        discountedValue: transactionData.discountedValue,
        totalAmountToPay: transactionData?.totalAmountToPay,
        cashChangeOwed: parseToNumber(
          transactionData.cashChangeOwed + changeOwed
        ),
        giftCardPaymentHistory: transactionData?.giftCardPaymentHistory,
        cashTenderedAmount: parseToNumber(
          Number(transactionData.cashTenderedAmount) + Number(amountTendered)
        ),
        store: {
          id: transactionData?.store?.id,
          name: transactionData?.store?.storeName,
        },
        companyId: currentStore.companyId,
        customer: transactionData.customer
          ? transactionData.customer
          : {
              name: globalConstants.EMPTY_STRING,
              id: globalConstants.EMPTY_STRING,
            },
        TransactionType:
          parseToNumber(
            parseToNumber(
              paymentType === transactionConstants.GIFT_CARD
                ? giftCardDetails.giftCardBalance >=
                  transactionData?.currentAmountToPay
                  ? parseToNumber(transactionData.currentAmountToPay)
                  : giftCardDetails.giftCardBalance
                : paymentType === transactionConstants.STORE_CREDIT
                ? parseToNumber(transactionData.currentAmountToPay) >
                  parseToNumber(transactionData?.customer?.currentBalance)
                  ? parseToNumber(transactionData?.customer?.currentBalance)
                  : parseToNumber(transactionData.currentAmountToPay)
                : transactionData.currentAmountToPay
            ) +
              parseToNumber(
                getSumOfPaymentHistory(
                  transactionData?.paymentHistory || defaultPaymentHistory
                )
              )
          ) === parseToNumber(transactionData?.totalAmountToPay)
            ? transactionConstants.PROCESSED
            : transactionConstants.DRAFT,
        currentAmountToPay:
          paymentType === transactionConstants.STORE_CREDIT
            ? parseToNumber(transactionData.currentAmountToPay) >
              parseToNumber(transactionData?.customer?.currentBalance)
              ? parseToNumber(transactionData?.customer?.currentBalance)
              : parseToNumber(transactionData.currentAmountToPay)
            : parseToNumber(transactionData.currentAmountToPay),
        giftCard:
          paymentType !== transactionConstants.GIFT_CARD
            ? {}
            : giftCardDetails
            ? {
                giftCardChargeAmount:
                  giftCardDetails.giftCardBalance >=
                  transactionData?.currentAmountToPay
                    ? parseToNumber(transactionData.currentAmountToPay)
                    : giftCardDetails.giftCardBalance,
                giftCardBarcode: giftCardDetails.giftCardBarcode,
                giftCardTotalBalance: giftCardDetails.giftCardBalance,
              }
            : globalConstants.EMPTY_OBJECT,
        paymentTypeOrder: transactionData.paymentTypeOrder || [],
      };

      newSaleTransaction(
        dataToSend,
        transactionData,
        handlePaymentMethod,
        handlePrintReceipt
      );
    }
  };

  const handleAddCustomerClick = () => {
    toggleAddCustomerModal(true);
  };

  //-------handle amount to pay change
  const handleAmountToPayChange = (value) => {
    setTransactionData(
      { ...transactionData, currentAmountToPay: value },
      currentStore?.id
    );
  };

  const handleCashPayment = () => {
    setAmountTendered(0);
    setChangeOwed(0);
    addTransaction(transactionConstants.CASH);
  };

  const handleCreditCardPayment = (value) => {
    setTransactionData(
      { ...transactionData, paymentDevice: value },
      currentStore?.id
    );
    addTransaction(transactionConstants.CREDIT_CARD, value);
  };

  const handleThirdPartyPayment = () => {
    addTransaction(transactionConstants.CREDIT_CARD);
  };

  const handleStoreCreditPayment = () => {
    addTransaction(transactionConstants.STORE_CREDIT);
  };

  const handleGiftCardPayment = () => {
    addTransaction(transactionConstants.GIFT_CARD);
  };

  const handlePaymentMethod = (paymentMethod) => {
    setTransactionData(
      { ...transactionData, paymentMethod: paymentMethod },
      currentStore?.id
    );
  };

  const handleTransactionCancel = () => {
    cancelCardTransaction(
      {
        poiId: transactionData.paymentDevice,
        transactionId: transactionId,
      },
      transactionData
    );
  };

  const handlePrintReceipt = (transactionData, giftReceipt) => {
    const printData = prepareReceiptForPrinter(
      user,
      transactionData.id,
      transactionData.paymentHistory,
      transactionData.store,
      transactionData.customer,
      transactionData.inventory,
      transactionData.discount,
      transactionData.subTotal,
      transactionData.tax,
      transactionData.totalAmountToPay,
      transactionData.cashTenderedAmount,
      transactionData.cashChangeOwed,
      transactionData.giftCardPaymentHistory,
      false,
      giftReceipt
    );
    printReceiptData(printData, defaultReceiptPrinter);
  };

  const handleParkSaleClick = async () => {
    activateSpinner(transactionConstants.MAKE_TRANSACTION);
    const transactionId =
      transactionData?.id || (await transactionActions.getTransactionId());
    deactivateSpinner(transactionConstants.MAKE_TRANSACTION);
    handleNewParkSale(
      { ...transactionData, id: transactionId },
      transactionData.store
    );
  };
  const selectedCustomerCheck = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  const handleShowDraftModal = () => {
    // handle component unmounting
    cartItemsCount.current = 0;
  };

  useEffect(() => {
    if (!transactionData) {
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
    } // eslint-disable-next-line
  }, [transactionData]);

  useEffect(() => {
    setCustomersList(
      allCustomers?.map((customer) => ({
        value: stringifyObject({
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          mobile: customer.mobile,
          currentBalance: customer.currentBalance,
          store: {
            id: transactionData.store.id,
            name: transactionData.store.storeName,
          },
          id: customer.id,
          name: `${customer.firstName} ${customer.lastName}`,
        }),
        label: (
          <div className="d-flex align-items-center gap-2 ">
            <span>
              <img
                src={CustomerImage}
                className="new-sale-customer-image"
                alt="customer"
              />
            </span>
            {`${customer.firstName} ${customer.lastName}`}
          </div>
        ),
      }))
    ); // eslint-disable-next-line
  }, [allCustomers]);
  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      setTransactionData(
        {
          ...transactionData,
          customer: {
            ...allCustomers[0],
            name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
          },
        },
        currentStore?.id
      );
    }
    setSelectedCustomerFlag(false); // eslint-disable-next-line
  }, [allCustomers]);

  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
  }, [isAddCustomerModal]);

  useEffect(() => {
    getStoreDevices(transactionData?.store?.id); // eslint-disable-next-line
  }, []);

  // // handle component unmounting
  // useEffect(() => {
  //   return () => {
  //     if (cartItemsCount.current > 0) {
  //       handleDraftModal(transactionConstants.TRANSACTION_DRAFT_MODAL);
  //     }
  //   }; // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (
      transactionData?.paymentMethod === transactionConstants.PAYMENT_COMPLETE
    ) {
      handleShowDraftModal();
    } // eslint-disable-next-line
  }, [transactionData.paymentMethod]);

  useEffect(() => {
    if (state.currentStore.id !== currentStore.id) {
      handleShowDraftModal();
      setTransactionData(transactionDefaultData, currentStore?.id);
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
    } // eslint-disable-next-line
  }, [currentStore]);

  useEffect(() => {
    if (transactionData.inventory.length <= 0) {
      handleShowDraftModal();
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
    } // eslint-disable-next-line
  }, [transactionData]);

  return (
    <>
      <Topbar
        title="Payment"
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION,
        }}
        isNewSalePayment={true}
        totalCartItems={
          getCountOfTotalItems(transactionData?.inventory || 0, "quantity") || 0
        }
        handleParkSale={handleParkSaleClick}
        isTransactionComplete={
          transactionData?.paymentMethod ===
          transactionConstants.PAYMENT_COMPLETE
            ? true
            : false
        }
        setTransactionData={setTransactionData}
        showDraftModal={handleShowDraftModal}
        isParkSaleDisabled={
          transactionData?.paymentMethod ===
          transactionConstants.PAYMENT_COMPLETE
        }
        currentStore={currentStore}
      />
      <Row className="m-0">
        <Col md={6} className="payment-cart-wrapper container-scrollbar">
          <div className="payment-cart ">
            <div className="">
              {transactionData?.inventory?.map((cartItem, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center w-100 mt-1 "
                >
                  <div className="d-flex align-items-center gap-2">
                    <span className="opacity-75">{cartItem.quantity} x</span>
                    <span className="payment-cart-productname">
                      {cartItem.itemName}
                    </span>
                    <span className="opacity-50">
                      #{getShortestSku(cartItem.sku)}
                    </span>
                  </div>
                  <span className="payment-cart-productname">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      cartItem.priceAfterDiscount
                    )}
                  </span>
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-between align-items-center pt-3 border-top w-100 mt-4">
              <div className="d-flex align-items-center gap-2">
                <span className="subtotal-heading">Subtotal</span>
              </div>
              <span className="payment-cart-productname">
                $
                {parseToThousandSeperatorDecimalNumber(
                  transactionData?.subTotal
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mt-1">
              <div className="d-flex align-items-center gap-2">
                <span className="subtotal-heading">
                  Discount(
                  {`${transactionData?.discountedValue}${
                    transactionData?.discountMethod ? "%" : "$"
                  }`}
                  )
                </span>
              </div>
              <span className="payment-cart-productname">
                $
                {parseToThousandSeperatorDecimalNumber(
                  transactionData?.discount
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mt-1">
              <div className="d-flex align-items-center gap-2">
                <span className="subtotal-heading">
                  Tax({transactionData?.store?.defaultTax || 0}%)
                </span>
              </div>
              <span className="payment-cart-productname">
                ${parseToThousandSeperatorDecimalNumber(transactionData?.tax)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mt-2 border-top pt-2">
              <div className="d-flex align-items-center gap-2">
                <span className="subtotal-heading d-flex justify-centent-center align-items-center gap-3">
                  SALE TOTAL{" "}
                  <span className="opacity-75">
                    {getCountOfTotalItems(
                      transactionData?.inventory || 0,
                      "quantity"
                    ) || 0}{" "}
                    Items
                  </span>
                </span>
              </div>
              <span className="payment-cart-productname">
                ${Number(transactionData?.totalAmountToPay).toFixed(2)}
              </span>
            </div>
            {transactionData &&
              transactionData.paymentTypeOrder &&
              (() => {
                let remainingTotal = parseToNumber(
                  transactionData.totalAmountToPay
                );
                return transactionData.paymentTypeOrder.map(
                  (paymentType, index) => {
                    const paymentAmount =
                      transactionData.paymentHistory[paymentType];

                    if (paymentAmount > 0) {
                      remainingTotal -= paymentAmount;
                      return (
                        <>
                          <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-2">
                            <div className="d-flex align-items-center gap-2">
                              <span className="subtotal-heading">
                                {paymentType ===
                                transactionConstants.CREDIT_CARD
                                  ? buttonNameConstants.CREDIT_CARD
                                  : paymentType ===
                                    transactionConstants.GIFT_CARD
                                  ? buttonNameConstants.GIFT_CARD_PAYMENT
                                  : paymentType ===
                                    transactionConstants.STORE_CREDIT
                                  ? buttonNameConstants.STORE_CREDIT
                                  : buttonNameConstants.CASH}
                              </span>
                            </div>
                            <span className="payment-cart-productname">
                              $
                              {parseToThousandSeperatorDecimalNumber(
                                paymentAmount
                              )}
                            </span>
                          </div>
                          {/* Subtotal after this payment */}
                          {paymentType === transactionConstants.CASH && (
                            <>
                              <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                <div className="d-flex align-items-center gap-2">
                                  <span className="subtotal-heading gift-card-history-heading">
                                    Tendered Amount:
                                  </span>
                                </div>
                                <span className="payment-cart-productname gift-card-history-heading">
                                  $
                                  {parseToThousandSeperatorDecimalNumber(
                                    transactionData.cashTenderedAmount
                                  )}
                                </span>
                              </div>
                              <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                <div className="d-flex align-items-center gap-2">
                                  <span className="subtotal-heading gift-card-history-heading">
                                    Change Owed:
                                  </span>
                                </div>
                                <span className="payment-cart-productname gift-card-history-heading">
                                  $
                                  {parseToThousandSeperatorDecimalNumber(
                                    transactionData.cashChangeOwed
                                  )}
                                </span>
                              </div>
                            </>
                          )}

                          {paymentType === transactionConstants.GIFT_CARD &&
                            transactionData.giftCardPaymentHistory?.map(
                              (item) => (
                                <>
                                  <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <span className="subtotal-heading gift-card-history-heading">
                                        {item?.giftCardName}
                                      </span>
                                    </div>
                                    <span className="payment-cart-productname gift-card-history-heading">
                                      $
                                      {parseToThousandSeperatorDecimalNumber(
                                        item.giftCardChargeAmount
                                      )}
                                    </span>
                                  </div>
                                  <div className="d-flex w-100 justify-content-between align-items-center mt-2 pt-1">
                                    <div className="d-flex align-items-center gap-2">
                                      <span className="subtotal-heading gift-card-history-heading">
                                        Remaining Balance
                                      </span>
                                    </div>
                                    <span className="payment-cart-productname gift-card-history-heading">
                                      $
                                      {parseToThousandSeperatorDecimalNumber(
                                        item.remainingBalance
                                      )}
                                    </span>
                                  </div>
                                </>
                              )
                            )}

                          {remainingTotal <= 0 ? (
                            ""
                          ) : (
                            <div className="d-flex justify-content-between align-items-center w-100 mt-2 border-top pt-2">
                              <div className="d-flex align-items-center gap-2">
                                <span className="subtotal-heading d-flex justify-centent-center align-items-center gap-3">
                                  SALE TOTAL{" "}
                                  <span className="opacity-75">
                                    {getCountOfTotalItems(
                                      transactionData?.inventory || 0,
                                      "quantity"
                                    ) || 0}{" "}
                                    Items
                                  </span>
                                </span>
                              </div>
                              <span className="payment-cart-productname">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  remainingTotal
                                )}
                              </span>
                            </div>
                          )}
                        </>
                      );
                    }
                    return null;
                  }
                );
              })()}
          </div>
        </Col>
        <Col md={6}>
          {transactionData?.paymentMethod ===
          transactionConstants.CASH_PAYMENT ? (
            <CashPayment
              changeOwed={changeOwed}
              amountTendered={amountTendered}
              transactionData={transactionData}
              setAmountTendered={setAmountTendered}
              handleCashPayment={handleCashPayment}
              setChangeOwed={setChangeOwed}
              setPaymentMethod={handlePaymentMethod}
              amountToPay={transactionData?.currentAmountToPay}
              handleAmountTenderedChange={handleAmountTenderedChange}
              handleFixedAmountButtonClick={handleFixedAmountButtonClick}
            />
          ) : transactionData?.paymentMethod ===
            transactionConstants.CREDIT_CARD_PAYMENT ? (
            <CardPayment
              currentStore={currentStore}
              storeDevices={storeDevices}
              transactionData={transactionData}
              setPaymentMethod={handlePaymentMethod}
              paymentDevice={transactionData.paymentDevice}
              amountToPay={transactionData?.currentAmountToPay}
              handleThirdPartyPayment={handleThirdPartyPayment}
              handleCreditCardPayment={handleCreditCardPayment}
              handleTransactionCancel={handleTransactionCancel}
            />
          ) : transactionData?.paymentMethod ===
            transactionConstants.GIFT_CARD_PAYMENT ? (
            <GiftCardPayment
              giftCardDetails={giftCardDetails}
              transactionData={transactionData}
              setPaymentMethod={handlePaymentMethod}
              amountToPay={transactionData?.currentAmountToPay}
              getGiftCardDetails={getGiftCardDetails}
              emptyGiftCardDetails={emptyGiftCardDetails}
              handleGiftCardPayment={handleGiftCardPayment}
            />
          ) : transactionData?.paymentMethod ===
            transactionConstants.PAYMENT_COMPLETE ? (
            <PaymentComplete
              transactionData={transactionData}
              handleClick={() => {}}
              handlePrintReceipt={handlePrintReceipt}
              setTransactionData={setTransactionData}
              sendEmailOrSMS={sendEmailOrSMS}
              handleShowDraftModal={handleShowDraftModal}
              currentStore={currentStore}
            />
          ) : (
            <div className="payment-div-wrapper">
              <Row className="m-0 w-100">
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center"
                >
                  <span className="amount-to-pay-heading">Amount to Pay</span>
                  <div className="amount-to-paid-field-wrapper">
                    <span className="dollar-icon-alignment">$</span>
                    <input
                      type="number"
                      className="amount-to-paid-field"
                      value={transactionData?.currentAmountToPay}
                      step={0.01}
                      onChange={(e) => {
                        handleAmountToPayChange(e.target.value);
                      }}
                      onBlur={(e) =>
                        handleZeroOnBlur(e, 0, (value) => {
                          handleAmountToPayChange(e.target.value);
                        })
                      }
                      max={
                        transactionData?.totalAmountToPay -
                        getSumOfPaymentHistory(
                          transactionData?.paymentHistory ||
                            defaultPaymentHistory
                        )
                      }
                      min={1}
                    />
                  </div>
                </Col>
                {!transactionData?.customer?.id ? (
                  <Col md={12} className="mt-3">
                    <CustomerDropdown
                      options={customersList}
                      value={selectedCustomer}
                      dropdownIndicator={false}
                      noOptionsMessage="No Customer Found"
                      placeholder="Customer Name, Phone, Email"
                      handleAddCustomerClick={handleAddCustomerClick}
                      SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                      handleChange={(selectedOption) => {
                        setSelectedCustomer(selectedOption);
                        setTransactionData(
                          {
                            ...transactionData,
                            customer:
                              parseJsonObject(selectedOption?.value) || "",
                          },
                          currentStore?.id
                        );
                      }}
                      selectedCustomerFlag={selectedCustomerFlag}
                    />
                  </Col>
                ) : (
                  <Col md={12} className="mt-3">
                    <div className="selected-customer-wrapper d-flex justify-content-between align-items-center">
                      <img src={CustomerImage} alt="customer" />
                      <div className="d-flex flex-column justify-content-center selected-customer-wrapper">
                        <span>{`${
                          transactionData?.customer?.firstName || "Customer"
                        } ${
                          transactionData?.customer?.lastName || "Name"
                        }`}</span>
                        <span>
                          {transactionData?.customer?.mobile ||
                            "(000) 555-0101"}
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-center  align-items-center customer-credit-wrapper">
                        <span>Store Credit</span>
                        <span>
                          $
                          {parseToThousandSeperatorDecimalNumber(
                            transactionData?.customer?.currentBalance || 0
                          )}
                        </span>
                      </div>
                      {!parseToNumber(
                        transactionData?.paymentHistory[
                          transactionConstants.STORE_CREDIT
                        ]
                      ) > 0 && (
                        <button
                          type="button"
                          className="cart-card-delete-button"
                          onClick={() => {
                            setSelectedCustomer("");
                            setTransactionData(
                              {
                                ...transactionData,
                                customer: "",
                              },
                              currentStore?.id
                            );
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                    </div>
                  </Col>
                )}
                <Col
                  md={12}
                  className="mt-3 d-flex justify-content-between gap-2"
                >
                  <Button
                    className="w-100"
                    IconImage={CashIcon}
                    label={buttonNameConstants.CASH}
                    handleClick={() => {
                      const checkAmount = checkAmountToPay();
                      if (!checkAmount) {
                        handlePaymentMethod(transactionConstants.CASH_PAYMENT);
                      }
                    }}
                  />
                  <Button
                    className="w-100"
                    IconImage={CreditcardIcon}
                    label={buttonNameConstants.CREDIT_CARD}
                    handleClick={() => {
                      const checkAmount = checkAmountToPay();
                      if (!checkAmount) {
                        handlePaymentMethod(
                          transactionConstants.CREDIT_CARD_PAYMENT
                        );
                      }
                    }}
                    isDisabled={transactionData?.subTotal === 0}
                  />
                  <Button
                    className="w-100"
                    IconImage={GiftCardIcon}
                    label={buttonNameConstants.GIFT_CARD_PAYMENT}
                    handleClick={() => {
                      const checkAmount = checkAmountToPay();
                      if (!checkAmount) {
                        handlePaymentMethod(
                          transactionConstants.GIFT_CARD_PAYMENT
                        );
                      }
                    }}
                    isDisabled={transactionData?.subTotal === 0}
                  />
                </Col>
                {transactionData?.customer && (
                  <Col md={12} className="mt-3">
                    <Button
                      IconImage={StoreCreditIcon}
                      label={buttonNameConstants.STORE_CREDIT}
                      handleClick={() => {
                        const checkAmount = checkAmountToPay();
                        if (!checkAmount) {
                          handleStoreCreditPayment();
                        }
                      }}
                      buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                      isDisabled={
                        Number(transactionData?.customer.currentBalance) ===
                          0 || transactionData?.subTotal === 0
                      }
                    />
                  </Col>
                )}
                {/* {transactionData?.customer && (
                  <Col md={12}>
                    <span className="d-flex align-items-center gap-2">
                      <input
                        className="checkbox-field"
                        type="checkbox"
                        checked={isStoreCreditApplied}
                        onChange={() =>
                          setIsStoreCreditApplied(!isStoreCreditApplied)
                        }
                      />
                      <span>Apply Store Credit</span>
                    </span>
                  </Col>
                )}
                {isStoreCreditApplied && (
                  <Col md={12} className="mt-3">
                    <SimpleNumberField
                      IconImage={DollarIcon}
                      onChange={setStoreCreditValue}
                      className="discount-number-field store-credit-input"
                      value={isStoreCreditApplied ? storeCreditValue : 0}
                      maxValue={
                        transactionData?.customer?.currentBalance || 9999
                      }
                    />
                  </Col>
                )} */}
              </Row>
            </div>
          )}
        </Col>
      </Row>
      <AddNewCustomerModal
        selectedCustomer={selectedCustomerCheck}
        addCustomerModal={addCustomerModal}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  printer: state.printer,
  customer: state.customer,
  transaction: state.transaction,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
  storeDevices: state.device.storeDevices,
  giftCardDetails: state.transaction.giftCardDetails,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setTransactionData: (data, storeId) =>
    dispatch(transactionActions.setTransactionData(data, storeId)),
  newSaleTransaction: (
    data,
    transactionObject,
    handlePaymentMethod,
    handlePrintReceipt
  ) =>
    dispatch(
      transactionActions.newSaleTransaction(
        data,
        transactionObject,
        handlePaymentMethod,
        handlePrintReceipt
      )
    ),
  getStoreDevices: (storeId) =>
    dispatch(deviceActions.getStoreDevices(storeId)),
  getGiftCardDetails: (storeId, barcode) =>
    dispatch(transactionActions.getGiftCardDetails(storeId, barcode)),
  emptyGiftCardDetails: () =>
    dispatch(transactionActions.emptyGiftCardDetails()),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  cancelCardTransaction: (data, transactionObject) =>
    dispatch(transactionActions.cancelCardTransaction(data, transactionObject)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  parkSale: (data) => dispatch(transactionActions.parkSale(data)),
  handleNewParkSale: (data, currentStore) =>
    dispatch(transactionActions.handleNewParkSale(data, currentStore)),
  sendEmailOrSMS: (data) => dispatch(transactionActions.sendEmailOrSMS(data)),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
});

//-------Export transaction Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTransactionPayment);

import React from "react";
import { Tooltip } from "react-tooltip";
import { SET_BY_MARGIN_ENUMS } from "../../../system/globalEnums";

const AppliesToCategories = ({
  anchorSelect,
  id,
  key,
  className,
  openOnClick = false,
  categories = [],
  groupType = null,
}) => {
  return (
    <Tooltip
      anchorSelect={anchorSelect}
      place={"bottom-end"}
      noArrow={true}
      clickable
      openOnClick={openOnClick}
      className={`tooltip px-0 ${className}`}
      id={id}
      key={key}
    >
      {categories.length > 0 &&
        categories.map((category, index) => (
          <div className="applies-to-category" key={index}>
            <li>
              {" "}
              {groupType === SET_BY_MARGIN_ENUMS.TYPE.value
                ? category.productType
                : groupType === SET_BY_MARGIN_ENUMS.CATEGORY.value
                ? category.categoryName
                : category.categoryName + " - " + category.parentCategoryName}
            </li>
          </div>
        ))}
    </Tooltip>
  );
};

export default AppliesToCategories;

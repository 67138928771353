import React from "react";
import { connect } from "react-redux";

import {
  toastMessages,
  tradeTransactionDefaultData,
  transactionConstants,
  globalConstants,
  transactionDefaultData,
  inventoryConstants,
} from "../../../constants";
import {
  systemActions,
  transactionActions,
  inventoryActions,
} from "../../../redux/actions";
import ConfirmationModal from "../../Inventory/Items/ConfirmationModal/ConfirmationModal";
import { handleAddBatchInventoryPark } from "../../../shared/utility";

const DraftConfirmationModal = (props) => {
  const {
    currentStore,
    newTradeData,
    newTradePark,
    transactionData,
    setNewTradeData,
    activateSpinner,
    handleDraftModal,
    deactivateSpinner,
    handleNewParkSale,
    setTransactionData,
    isDraftConfirmationModal,
    addBatchInventory,
    setAddBatchInventory,
    setIsBatchInventoryModal,
    addBatchInventoryDraft,
  } = props;

  const handleTransactionPark = async () => {
    activateSpinner(transactionConstants.MAKE_TRANSACTION);
    const transactionId =
      transactionData?.id || (await transactionActions.getTransactionId());
    handleNewParkSale(
      {
        ...transactionData,
        id: transactionId,
        activeCardPaymentType: currentStore.activeCardPaymentType,
      },
      currentStore
    );
    deactivateSpinner(transactionConstants.MAKE_TRANSACTION);
  };

  const handleTradePark = async () => {
    activateSpinner(transactionConstants.MAKE_TRADE_TRANSACTION);
    const tradeId = newTradeData?.id || (await transactionActions.getTradeId());
    if (newTradeData?.inventory?.length > 0) {
      newTradeData.inventory.forEach((item) => {
        const offerValue =
          newTradeData.PaymentType === globalConstants.CASH
            ? item.cashOffer
            : item.tradeOffer;
        if (item.price) {
          item.price.unit_purchase_price = offerValue;
        } else {
          item.price = { unit_purchase_price: offerValue };
        }
      });
    }
    const parkTradeData = {
      ...newTradeData,
      id: tradeId,
      store: { id: currentStore?.id, name: currentStore?.storeName },
      customer: newTradeData.customer
        ? newTradeData.customer
        : { id: "", name: "" },
      addBatchInventory: addBatchInventory,
    };
    newTradePark(parkTradeData, currentStore?.id);
    deactivateSpinner(transactionConstants.MAKE_TRADE_TRANSACTION);
  };

  const handleYesButtonClick = () => {
    if (
      isDraftConfirmationModal === transactionConstants.TRANSACTION_DRAFT_MODAL
    ) {
      handleTransactionPark();
    } else if (
      isDraftConfirmationModal === transactionConstants.TRADE_DRAFT_MODAL
    ) {
      handleTradePark();
    } else if (
      isDraftConfirmationModal ===
      transactionConstants.ADD_BATCH_INVENTORY_DRAFT_MODAL
    ) {
      handleAddBatchInventoryPark(
        addBatchInventory,
        currentStore,
        addBatchInventoryDraft
      );
    } else if (
      isDraftConfirmationModal ===
      transactionConstants.ADD_BATCH_TRADE_DRAFT_MODAL
    ) {
      setIsBatchInventoryModal(false);
    }
    handleDraftModal(false);
  };

  const handleNoButtonClick = () => {
    if (
      isDraftConfirmationModal === transactionConstants.TRANSACTION_DRAFT_MODAL
    ) {
      setTransactionData(transactionDefaultData, currentStore?.id);
    } else if (
      isDraftConfirmationModal === transactionConstants.TRADE_DRAFT_MODAL
    ) {
      setNewTradeData(tradeTransactionDefaultData, currentStore?.id);
      setAddBatchInventory([]);
    } else if (
      isDraftConfirmationModal ===
      transactionConstants.ADD_BATCH_INVENTORY_DRAFT_MODAL
    ) {
      setAddBatchInventory([]);
      setIsBatchInventoryModal(false);
    } else if (
      isDraftConfirmationModal ===
      transactionConstants.ADD_BATCH_TRADE_DRAFT_MODAL
    ) {
      setAddBatchInventory([]);
      setIsBatchInventoryModal(false);
    }
    handleDraftModal(false);
  };

  return (
    <ConfirmationModal
      isConfirmationModal={isDraftConfirmationModal}
      handleNoButtonClick={() => handleNoButtonClick()}
      handleYesButtonClick={() => {
        handleYesButtonClick();
      }}
      toggleConfirmationModal={() => {}}
      message={
        isDraftConfirmationModal ===
        transactionConstants.TRANSACTION_DRAFT_MODAL
          ? toastMessages.TRANSACTION_PAGE_LEAVE_MESSAGE
          : isDraftConfirmationModal === transactionConstants.TRADE_DRAFT_MODAL
          ? toastMessages.TRADE_PAGE_LEAVE_MESSAGE
          : toastMessages.ADD_BATCH_INVENTORY_OR_TRADE_LEAVE_MESSAGE
      }
    />
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  isDraftConfirmationModal: state.transaction.isDraftConfirmationModal,
  transactionData: state.transaction.transactionData,
  newTradeData: state.transaction.newTradeData,
  addBatchInventory: state.inventory.addBatchInventory,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  handleNewParkSale: (data, currentStore) =>
    dispatch(transactionActions.handleNewParkSale(data, currentStore)),
  setTransactionData: (data, storeId) =>
    dispatch(transactionActions.setTransactionData(data, storeId)),
  setNewTradeData: (data, storeId) =>
    dispatch(transactionActions.setNewTradeData(data, storeId)),
  newTradePark: (data, storeId) =>
    dispatch(transactionActions.newTradePark(data, storeId)),
  setAddBatchInventory: (data) =>
    dispatch({
      type:
        inventoryConstants.SET_ADD_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: data,
    }),
  setIsBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type: inventoryConstants.SET_IS_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  addBatchInventoryDraft: (data) =>
    dispatch(inventoryActions.addBatchInventoryDraft(data)),
});

//-------Export transaction Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftConfirmationModal);

import React from "react";
import { Country, State, City } from "country-state-city";

import { countryCodesConstants, globalConstants } from "../../../constants";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";
import { selectFieldStyle } from "../../../assets/scss/style";

const SelectFieldState = (props) => {
  const {
    name = "state",
    placeHolder = "Select State",
    label = "State",
    isSearchable = true,
    handleInputChange,
    defaultValue = globalConstants.EMPTY_STRING,
  } = props;
  const options = [
    ...State.getStatesOfCountry(countryCodesConstants.USA),
    ...State.getStatesOfCountry(countryCodesConstants.CANADA),
  ].map((state) => ({
    label: state.name,
    value: state.name,
  }));

  return (
    <>
      <div>
        <label className="field-label" htmlFor={name}>
          {label && label}
        </label>
        <div>
          <Field name={name}>
            {({ field, form, value }) => (
              <Select
                defaultValue={options.filter(
                  (option) => option.value === form.initialValues.state
                )}
                options={options}
                isSearchable={isSearchable}
                placeholder={placeHolder}
                styles={selectFieldStyle}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(selectedOption) =>
                  form.setFieldValue(field.name, selectedOption.value)
                }
                onInputChange={(inputValue) => {
                  handleInputChange && handleInputChange(inputValue);
                }}
              />
            )}
          </Field>
        </div>
        <ErrorMessage name={name} component={ErrorTextMessage} />
      </div>
    </>
  );
};

export default SelectFieldState;

import React, { useState } from "react";
import { useNavigate } from "react-router";

import {
  BackArrowIcon,
  ImportCSVIcon,
} from "../../../assets/icons/iconsProvider";
import Button from "../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  transactionDefaultData,
} from "../../../constants";

const Topbar = (props) => {
  const {
    title = "Dummy Title",
    backNavigation = { isVisible: false, route: null },
    exportButton = props.exportButton,
    label,
    isDismissAll,
    buttonType,
    IconImage,
    handleClick,
    countChip,
    sellTradeToggleButton = false,
    isNewSale = false,
    isNewSalePayment = false,
    handleParkSale,
    totalCartItems = 0,
    totalCustomers = 0,
    importCsvButton = false,
    handleImportCsvClick,
    isTransactionComplete = false,
    setTransactionData,
    showDraftModal = () => {},
    isParkSaleDisabled = false,
    handleDismissAllClick = () => {},
    currentStore,
  } = props;
  const navigate = useNavigate();

  const handleBackNavigationClick = () => {
    showDraftModal();
    if (isTransactionComplete) {
      setTransactionData(transactionDefaultData, currentStore?.id);
    }
    navigate(backNavigation.route);
  };

  const handleSaleButtonClick = () => {
    navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION);
  };

  const handleTradeButtonClick = () => {
    navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE);
  };

  return (
    <div className="container-fluid mt-2 mb-2 topbar">
      <div className="row m-0">
        <div className="col-md-12 container-fluid d-flex align-items-center px-0">
          <div className="row w-100 m-0">
            <div className="col-sm-6 px-0  topbar-title-container">
              {backNavigation.isVisible ? (
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  onClick={handleBackNavigationClick}
                >
                  <BackArrowIcon className="cursor-pointer" />
                  <h1 className="p-0 m-0 topbar-title text-nowrap">{title}</h1>
                </div>
              ) : (
                <h1 className="p-0 m-0 topbar-title text-nowrap">{title}</h1>
              )}
              {countChip === true ? (
                <div className="customer-count-chip text-nowrap">{`Total ${totalCustomers}`}</div>
              ) : (
                ""
              )}
              {sellTradeToggleButton && (
                <div className="topbar-toggle-button-wrapper">
                  <button
                    type="button"
                    className={`topbar-toggle-button ${
                      isNewSale && "active-Button"
                    }`}
                    onClick={() => handleSaleButtonClick()}
                  >
                    New Sale
                  </button>
                  <button
                    type="button"
                    className={`topbar-toggle-button ${
                      !isNewSale && "active-Button"
                    }`}
                    onClick={() => handleTradeButtonClick()}
                  >
                    New Trade
                  </button>
                  <div
                    className={`topbar-toggle-button-background ${
                      !isNewSale && "new-trade-active"
                    }`}
                  ></div>
                </div>
              )}
              {isNewSalePayment && (
                <div className="d-flex justify-content-center align-items-center ms-3 text-nowrap">
                  <div className="item-count-chip">{totalCartItems} Items</div>
                  {typeof handleParkSale === "function" && (
                    <Button
                      type="button"
                      label={buttonNameConstants.PARK_SALE}
                      buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                      handleClick={handleParkSale}
                      isDisabled={isParkSaleDisabled}
                    />
                  )}
                </div>
              )}
              {isDismissAll && (
                <div className="d-flex justify-content-center align-items-center ms-3 text-nowrap">
                  <Button
                    type="button"
                    label={buttonNameConstants.DISMISS_ALL}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    handleClick={handleDismissAllClick}
                  />
                </div>
              )}
            </div>

            <div className="col-sm-6 px-0 d-flex justify-content-end align-items-center gap-2">
              {importCsvButton ? (
                <Button
                  label={buttonNameConstants.IMPORT_CSV}
                  buttonType={buttonTypeConstants.LIGHT_PURPLE}
                  IconImage={ImportCSVIcon}
                  handleClick={handleImportCsvClick}
                />
              ) : (
                ""
              )}
              {exportButton === true ? (
                <Button
                  label={label}
                  buttonType={buttonType}
                  IconImage={IconImage}
                  handleClick={handleClick}
                />
              ) : (
                ""
              )}
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;

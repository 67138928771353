import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import React, { useState, useRef } from "react";

import { customToast } from "../../shared/utility";
import LogoImage from "../../assets/images/UserInfo.jpg";
import { AddIcon } from "../../assets/icons/iconsProvider";
import Topbar from "../../shared/components/topbar/Topbar";
import Button from "../../shared/components/Buttons/Button";
import { userActions } from "../../redux/actions/user.action";
import InputTextField from "../../shared/components/inputTextField/InputTextField";
import InputGenericField from "../../shared/components/inputGenericField/InputGenericField";
import InputPasswordField from "../../shared/components/inputPasswordField/InputPasswordField";
import {
  updateUserPinSchema,
  updateUserPasswordSchema,
  updateUserInfoValidationSchema,
} from "../../shared/validationSchema/validationSchema";
import {
  toastType,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  systemUserConstants,
} from "../../constants";

const UserInformation = (props) => {
  const { uploadLogo, store, updateUserInformation, user } = props;

  //-------store reducer
  const { currentStore } = store;

  //------Usestate
  const [storeLogoImage, setStoreLogoImage] = useState(user?.logo || LogoImage);
  const [logoToSend, setLogoToSend] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  //------UseRef
  const hiddenFileInput = useRef(null);

  const userInformationInitialValues = {
    companyUserId: user?.companyUserId,
    id: user?.id || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    logo: storeLogoImage,
    stores: "",
    companyId: user?.companyId,
  };

  const securityInfoInitialValues = {
    companyId: user?.companyId,
    companyUserId: user?.companyUserId,
    oldPassword: "",
    password: "",
    repeatPassword: "",
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };
  //------handle upload button
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
              // const width = img.width;
              // const maxWidth = 256;

              // if (width >= maxWidth) {
              //   customToast(toastMessages.IMAGE_SIZE_ERROR, toastType.ERROR);
              //   // You can also reset the input or take other actions based on your application needs.
              // } else {
              setStoreLogoImage(URL.createObjectURL(file));
              setLogoToSend(file);
              // }
            };
          };
          reader.readAsDataURL(file);
        }
      } else {
        customToast(
          "Only .jpg, .jpeg and .png files are allowed",
          toastType.ERROR
        );
        e.target.value = null;
      }
    }
  };

  //------Handle submit
  const handleUserInfoSubmit = async (data) => {
    const dataToSend = {
      id: data?.id,
      role: user?.role,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      logo: data?.logo,
      password: globalConstants.EMPTY_STRING,
      companyId: data?.companyId,
      companyUserId: data?.companyUserId,
      stores: [
        {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
      ],
    };
    if (logoToSend) {
      const formData = new FormData();
      formData.append("file", logoToSend);
      uploadLogo(formData, dataToSend);
    } else {
      updateUserInformation({
        ...dataToSend,
        logo: user?.logo,
      });
    }
  };

  const handleUserSecuritySubmit = (data) => {
    const dataToSend = {
      id: user?.id,
      role: user?.role,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      logo: user?.logo,
      oldPassword: data?.oldPassword,
      password: data?.password,
      companyId: data?.companyId,
      companyUserId: data?.companyUserId,
      stores: [
        {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
      ],
    };
    updateUserInformation({
      ...dataToSend,
    });

    setIsChangePassword(false);
  };

  return (
    <>
      <Topbar title="User Information "></Topbar>
      <Row className="m-0 user-information-wrapper container-scrollbar pb-5">
        <Col md={10}>
          <Row className="m-0">
            <Col md={5}>
              <Formik
                initialValues={userInformationInitialValues}
                validate={updateUserInfoValidationSchema}
                enableReinitialize
                onSubmit={handleUserInfoSubmit}
              >
                {({ resetForm, values }) => (
                  <Form>
                    <Row className="m-0">
                      <Col xs={12} className="mb-3">
                        <p className="receipt-label-heading">
                          Personal Information
                        </p>
                      </Col>
                      <Col md={12} className="mb-3">
                        <div className="user-info-image-wrapper">
                          <img
                            src={values.logo}
                            alt="logo"
                            className="user-logo-image"
                          />
                          <input
                            type="file"
                            className="d-none"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleImageChange}
                            ref={hiddenFileInput}
                          />
                          <button
                            onClick={handleReplaceButtonClick}
                            type="button"
                            className="replace-logo-button replace-user-image-buuton"
                          >
                            <AddIcon className="replace-icon" />
                          </button>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <Row className="m-0">
                          {user?.companyId !==
                            systemUserConstants.ADMIN_SYSTEMUSERS && (
                            <Col md={12} className="mb-3">
                              <InputTextField
                                name="companyUserId"
                                label="Employee ID"
                                placeHolder="Employee ID"
                                disabled={true}
                              />
                            </Col>
                          )}
                          <Col md={12} className="mb-3">
                            <InputTextField
                              name="firstName"
                              label="First Name"
                              placeHolder="Enter First Name"
                            />
                          </Col>
                          <Col md={12} className="mb-3">
                            <InputTextField
                              name="lastName"
                              label="Last Name"
                              placeHolder="Enter Last Name"
                            />
                          </Col>
                          <Col md={12} className="mb-3">
                            <InputTextField
                              name="email"
                              label="Email"
                              placeHolder="Enter Email"
                              disabled={true}
                            />
                          </Col>
                          <Col md={12} className="mb-3">
                            <InputGenericField
                              name="phoneNumber"
                              type="tel"
                              placeHolder="Enter Phone Number"
                              label="Phone Number"
                              inputMask="(999) 999-9999"
                            />
                          </Col>
                          <Col md={12}></Col>
                          <Col md={12} className="mb-3 d-flex gap-2">
                            <Button
                              type="button"
                              label={buttonNameConstants.CANCEL}
                              handleClick={() => {
                                resetForm();
                              }}
                              buttonType={buttonTypeConstants.GHOST_BUTTON}
                              className="w-100"
                            ></Button>
                            <Button
                              type="submit"
                              className="w-100"
                              label={buttonNameConstants.SAVE}
                            ></Button>
                          </Col>
                          <Col xs={12}>
                            <div className="password-fields-wrapper"></div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
            <Col md={5} className="border-start">
              <Formik
                initialValues={securityInfoInitialValues}
                validate={
                  user?.companyId !== systemUserConstants.ADMIN_SYSTEMUSERS
                    ? updateUserPinSchema
                    : updateUserPasswordSchema
                }
                enableReinitialize
                onSubmit={handleUserSecuritySubmit}
              >
                {({ resetForm, values }) => (
                  <Form>
                    <Row className="m-0">
                      <Col xs={12}>
                        <p className="receipt-label-heading">Security</p>
                      </Col>
                      {isChangePassword && (
                        <>
                          <Col md={12} className="mb-3">
                            <InputPasswordField
                              name="oldPassword"
                              label={
                                user?.companyId !==
                                systemUserConstants.ADMIN_SYSTEMUSERS
                                  ? "Old Pin"
                                  : "Old Password"
                              }
                              placeHolder={
                                user?.companyId !==
                                systemUserConstants.ADMIN_SYSTEMUSERS
                                  ? "Enter Old Pin"
                                  : "Enter Old Password"
                              }
                              maxLength={
                                user?.companyId !==
                                systemUserConstants.ADMIN_SYSTEMUSERS
                                  ? 6
                                  : 50
                              }
                            />
                          </Col>
                          <Col md={12} className="mb-3"></Col>
                        </>
                      )}

                      <Col md={12} className="mb-3">
                        <InputPasswordField
                          name="password"
                          label={
                            user?.companyId !==
                            systemUserConstants.ADMIN_SYSTEMUSERS
                              ? "New Pin"
                              : "New Password"
                          }
                          placeHolder={
                            user?.companyId !==
                            systemUserConstants.ADMIN_SYSTEMUSERS
                              ? "Enter New Pin"
                              : "Enter New Password"
                          }
                          maxLength={
                            user?.companyId !==
                            systemUserConstants.ADMIN_SYSTEMUSERS
                              ? 6
                              : 50
                          }
                        />
                      </Col>
                      <Col md={12} className="mb-3"></Col>
                      {isChangePassword && (
                        <>
                          <Col md={12} className="mb-3">
                            <InputPasswordField
                              name="repeatPassword"
                              label={
                                user?.companyId !==
                                systemUserConstants.ADMIN_SYSTEMUSERS
                                  ? "Repeat Pin"
                                  : "Repeat Password"
                              }
                              placeHolder={
                                user?.companyId !==
                                systemUserConstants.ADMIN_SYSTEMUSERS
                                  ? "Repeat Pin"
                                  : "Repeat Password"
                              }
                              maxLength={
                                user?.companyId !==
                                systemUserConstants.ADMIN_SYSTEMUSERS
                                  ? 6
                                  : 50
                              }
                            />
                          </Col>
                          <Col md={12} className="mb-3"></Col>
                        </>
                      )}
                      {!isChangePassword && (
                        <>
                          <Col md={12} className="mb-3">
                            <Button
                              type="button"
                              className="w-100"
                              label={
                                user?.companyId !==
                                systemUserConstants.ADMIN_SYSTEMUSERS
                                  ? buttonNameConstants.CHANGE_PIN
                                  : buttonNameConstants.CHANGE_PASSWORD
                              }
                              handleClick={() => {
                                setIsChangePassword(true);
                              }}
                            ></Button>
                          </Col>
                          <Col md={12} className="mb-3"></Col>
                        </>
                      )}
                      {isChangePassword && (
                        <Col md={12} className="mb-3 d-flex gap-2">
                          <Button
                            type="button"
                            label={buttonNameConstants.CANCEL}
                            handleClick={() => {
                              resetForm();
                              setStoreLogoImage(user?.logo || LogoImage);
                              setIsChangePassword(false);
                              setLogoToSend(false);
                            }}
                            buttonType={buttonTypeConstants.GHOST_BUTTON}
                            className="w-100"
                          ></Button>

                          <Button
                            type="submit"
                            className="w-100"
                            label={buttonNameConstants.APPLY}
                          ></Button>
                        </Col>
                      )}
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  store: state.store,
  user: state.authentication.user,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  uploadLogo: (logoPath, data) =>
    dispatch(userActions.uploadLogo(logoPath, data)),
  updateUserInformation: (data) =>
    dispatch(userActions.updateUserInformation(data)),
});

//-------Export Login Component
export default connect(mapStateToProps, mapDispatchToProps)(UserInformation);

import React from "react";
import { Col, Row } from "react-bootstrap";

import SelectDropDown from "../selectDropdown/SelectDropdown";
import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../assets/icons/iconsProvider";
import Skeleton from "react-loading-skeleton";

const AgGridPaginationComponent = ({
  gridState,
  updateGridState,
  isDisablePaginationActions,
}) => {
  const onPageSizeChange = (option) => {
    updateGridState({
      ...gridState,
      pageIndex: 1,
      pageSize: option.value,
    });
  };

  const pageSizes = [];
  gridState?.pageSizeOptions?.forEach((option) => {
    pageSizes.push({ value: option, label: `${option}` });
  });

  const getPageCount = () => {
    return Math.ceil(gridState.totalRecords / gridState.pageSize);
  };

  const canGetNextPage = () => {
    return gridState.pageIndex < getPageCount();
  };

  const canGetPreviousPage = () => {
    return gridState.pageIndex > 1;
  };

  const onGetNextPage = () => {
    const pageIndex = Number(gridState.pageIndex) + 1;

    updateGridState({ ...gridState, pageIndex: pageIndex });
  };

  const onGetPreviousPage = () => {
    const pageIndex = Number(gridState.pageIndex) - 1;
    updateGridState({ ...gridState, pageIndex: pageIndex });
  };

  return (
    <Row className="m-0 mt-1 react-data-table-footer-wrapper position-sticky">
      <Col
        xs={12}
        md={4}
        className="d-flex justify-content-start align-items-center"
      >
        Viewing {gridState.pageStartRecordNumber} -{" "}
        {gridState.totalRecords < gridState.pageEndRecordNumber
          ? gridState.totalRecords
          : gridState.pageEndRecordNumber}{" "}
        of {gridState.totalRecords} items
      </Col>
      <Col
        xs={12}
        md={8}
        className="d-flex justify-content-end align-items-center gap-2"
      >
        Items per page:{" "}
        <SelectDropDown
          options={pageSizes}
          defaultPageSize={{
            value: gridState.pageSize,
            label: gridState.pageSize,
          }}
          handleChange={onPageSizeChange}
          isDisabled={isDisablePaginationActions}
        />{" "}
        <span className="d-flex justify-content-center align-items-center gap-3 ms-4">
          <button
            type="button"
            className="arrow-buttons"
            onClick={() => onGetPreviousPage()}
            disabled={!canGetPreviousPage() || isDisablePaginationActions}
          >
            <LeftArrowIcon />
          </button>
          Page{" "}
          <input
            min={1}
            max={10}
            className="page-index-inputField"
            type="number"
            value={gridState.pageIndex}
            onChange={() => {}}
            disabled={true}
          />{" "}
          of {getPageCount()}
          <button
            type="button"
            className="arrow-buttons"
            onClick={() => onGetNextPage()}
            disabled={!canGetNextPage() || isDisablePaginationActions}
          >
            <RightArrowIcon />
          </button>
        </span>
      </Col>
    </Row>
  );
};

export default AgGridPaginationComponent;

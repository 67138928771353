import React, { useEffect, useState } from "react";

import { DeleteIcon } from "../../../assets/icons/iconsProvider";
import VerticalNumberField from "../inputNumberField/VerticalNumberField";
import { globalConstants, transactionConstants } from "../../../constants";
import {
  getShortestSku,
  isBatchTradeSku,
  isQuickTradeSku,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";

const ProductCartCardTrade = ({
  productName,
  skuNumber,
  price,
  handleDeleteCartItem,
  quantity,
  max,
  handleQuantityChange,
  discountPercentage,
  actualPrice,
  handleDiscountChange,
  isDiscountApplied,
  activeButton,
  cashOffer,
  tradeOffer,
  updateCashTradeOffer,
  marketPrice,
  category,
  totalItems,
}) => {
  const [unit_purchase_price, setUnit_purchase_price] = useState("");
  const handleLocalQuantityChange = (sku, newQuantity) => {
    handleQuantityChange(sku, newQuantity);
  };

  const handlePriceChange = (newPrice) => {
    // const newPrice = convertToFixedPrecision(event.target.value);
    setUnit_purchase_price(newPrice);
    updateCashTradeOffer(skuNumber, activeButton, newPrice, marketPrice);
  };
  useEffect(() => {
    let updatedPurchase = cashOffer;
    if (activeButton === globalConstants.CASH) {
      updatedPurchase = cashOffer;
    } else {
      updatedPurchase = tradeOffer;
    }
    const priceValue = parseToDecimalNumber(updatedPurchase);
    setUnit_purchase_price(priceValue);
  }, [activeButton]);

  return (
    <div className="cart-card d-flex align-items-center gap-2 justify-content-between mb-2">
      <VerticalNumberField
        sku={skuNumber}
        quantity={quantity}
        maxValue={category !== transactionConstants.QUICK_TRADE ? max : 1}
        onChange={handleLocalQuantityChange}
      />
      <div className="cart-card-name-wrapper cart-card-name-without-discount d-flex flex-column gap-1">
        <span>
          {productName}{" "}
          {category == transactionConstants.QUICK_TRADE &&
            `(${totalItems} Items)`}
        </span>

        <span>{category !== transactionConstants.QUICK_TRADE && category}</span>
        {!isQuickTradeSku(skuNumber) && (
          <span>
            {isBatchTradeSku(skuNumber)
              ? "Auto Generated"
              : `#${getShortestSku(skuNumber)}`}
          </span>
        )}
      </div>
      {discountPercentage > 0 ? (
        <div className="cart-card-discount-wrapper d-flex justify-content-center align-items-center gap-2">
          {isDiscountApplied && (
            <span className="text-decoration-line-through">${actualPrice}</span>
          )}
          <span
            className={`cursor-pointer ${
              !isDiscountApplied
                ? "opacity-50 text-decoration-line-through"
                : "discounted-amount"
            }`}
            onClick={() => handleDiscountChange(skuNumber)}
          >
            -{discountPercentage}%
          </span>
        </div>
      ) : (
        ""
      )}
      <div className=" d-flex justify-content-center align-items-center gap-2">
        <div className="amount-to-paid-field-wrapper product-cart-card-price">
          <span className="dollar-icon-alignment">$</span>
          <input
            type="number"
            className="amount-to-paid-field"
            value={unit_purchase_price}
            step={0.01}
            // onChange={handlePriceChange}
            onChange={(e) => {
              handlePriceChange(e.target.value);
            }}
            onBlur={(e) => {
              const inputValue = parseToThousandSeperatorDecimalNumber(
                e.target.value
              );
              handlePriceChange(inputValue);
            }}
            max={99999}
            min={1}
          />
        </div>
        <button
          type="button"
          className="cart-card-delete-button"
          onClick={() => handleDeleteCartItem(skuNumber)}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};

export default ProductCartCardTrade;

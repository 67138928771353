import { updateObject } from "../../shared/utility";
import { deviceConstants, globalConstants } from "../../constants";

//-------InitialState of the device reducer
const initialState = {
  allDevices: [],
  storeDevices: [],
};

//-------Export Device Reducer
export const device = (state = initialState, action) => {
  switch (action.type) {
    //-------Get all devices success case
    case deviceConstants.GET_ALL_DEVICES + globalConstants.SUCCESS:
      return updateObject(state, {
        allDevices: action.data,
      });
    //-------Get all devices failure case
    case deviceConstants.GET_ALL_DEVICES + globalConstants.FAILURE:
      return updateObject(state, {
        allDevices: [],
      });
    //-------Get all devices failure case
    case deviceConstants.GET_STORE_DEVICES + globalConstants.SUCCESS:
      return updateObject(state, {
        storeDevices: action.data,
      });
    //-------Get all devices failure case
    case deviceConstants.GET_STORE_DEVICES + globalConstants.FAILURE:
      return updateObject(state, {
        storeDevices: [],
      });
    //-------Default State
    default:
      return state;
  }
};

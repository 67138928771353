import pokemon from "pokemontcgsdk";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import _ from "lodash"; // Import lodash here
import React, { useCallback, useEffect, useRef, useState } from "react";

import AddTradeBatchFooter from "./AddTradeBatchFooter";
import AddTradeBatchEdit from "../AddTrade/AddTradeBatchEdit";
import { DeleteIcon, EditIcon } from "../../../../assets/icons/iconsProvider";
import TableCustomHeader from "../../../../shared/components/table/TableCustomHeader";
import SearchInventory from "../../../Inventory/Items/SearchInventory/SearchInventory";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import EditableReactDataTableComponent from "../../../../shared/components/table/EditableReactDataTableComponent";
import {
  toastType,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  transactionConstants,
  buttonTypeConstants,
  TRADE_BATCH_DEFAULT_DATA,
} from "../../../../constants";
import {
  systemActions,
  inventoryActions,
  transactionActions,
} from "../../../../redux/actions";
import {
  formatDate,
  customToast,
  stringToSlug,
  usdToOriginal,
  getProductIfExist,
  handleEmptyOnFocus,
  mergeBatchProducts,
  calculateOfferPrices,
  handleRaritiesChange,
  getProductSubCategory,
  convertToFixedPrecision,
  onChangeProductCondition,
  handleBulkConditionChange,
  setDefaultProductDataObject,
  batchPricechartingProductImage,
  parseToThousandSeperatorDecimalNumber,
  parseToDecimalNumber,
} from "../../../../shared/utility";
import { getUpdatedCartItems } from "../NewTrade";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";
import Button from "../../../../shared/components/Buttons/Button";

const AddTradeBatch = (props) => {
  //-------Destructure props
  const {
    addToCart,
    transaction,
    customTypes,
    spinnerArray,
    currentStore,
    newTradeData,
    markupPresets,
    tradeDiscount,
    generateTempSku,
    activateSpinner,
    customCategories,
    deactivateSpinner,
    globalTradeinMargin,
    addTradeBatchInventory,
    getScryFallProductById,
    priceChartingProductInfo,
    setAddTradeBatchInventory,
    setPokemonProductInReducer,
    getPricechartingProductById,
    emptyPriceChartingProductObject,
    setIsTradeBatchInventoryModal,
    isTradeBatchInventoryModal,
  } = props;
  const { tempSku } = transaction;
  const { tradeinMargin } = tradeDiscount;
  const handleAddTradeBatchInventory = (data) => {
    setAddTradeBatchInventory(data, currentStore?.id);
  };
  //-------column headers for the batch trade table
  const columnHeaders = [
    {
      id: "title",
      name: "Title",
      selector: (row) => row.productName,
      cell: (row) => {
        return (
          <>
            <TableCustomHeader
              id={`tool${row.id}`}
              tooltopText={row.productName}
              label={
                <span className="inventory-cell-max-size product-title-max-width">
                  {row.productName}
                </span>
              }
            />
          </>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "condition",
      name: "Condition",
      selector: (row) => row.productCondition,
      cell: (row) => {
        return (
          <SelectDropDown
            menuPlacement="bottom"
            options={row.productConditionList}
            noOptionsMessage="No Type Found"
            placeHolder="Type"
            defaultPageSize={{
              value: row?.productCondition,
              label: row?.productCondition,
            }}
            handleChange={(e) =>
              onChangeProductCondition(
                markupPresets,
                row.id,
                e.value,
                true,
                addTradeBatchInventory,
                handleAddTradeBatchInventory,
                tradeinMargin,
                handleBatchInventoryPercentage
              )
            }
            menuPortalTarget=""
            value={{
              value: row?.productCondition,
              label: row?.productCondition,
            }}
          />
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "rarity",
      name: "Printing",
      selector: (row) => row.rarity,
      cell: (row) => {
        return (
          <SelectDropDown
            menuPlacement="bottom"
            options={row.raritiesList || []}
            noOptionsMessage="No Printing Found"
            placeHolder="Printings"
            defaultPageSize={row.rarity}
            handleChange={(selectedOption) =>
              handleRaritiesChange(
                row.id,
                selectedOption,
                addTradeBatchInventory,
                handleAddTradeBatchInventory
              )
            }
            menuPortalTarget=""
            value={row.rarity}
          />
        );
      },
      sortable: false,
      sortDirection: null,
    },

    {
      id: "type",
      name: "Type",
      selector: (row) => row.productType,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row.productType}</span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "category",
      name: "Category",
      selector: (row) => row.consoleName,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row.consoleName}</span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "marketPrice",
      name: "Market Price",
      selector: (row) => row.marketPrice,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">
            {parseToThousandSeperatorDecimalNumber(row.marketPrice)}
          </span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "quantity",
      name: "Quantity",
      selector: (row) => row?.inStockQuantity ?? 0,
      cell: (row) => {
        return (
          <SimpleNumberField
            minimumValue={1}
            isConvertToDecimal={false}
            value={row?.inStockQuantity ?? 0}
            maxValue={inventoryConstants.THRESHOLD_MAX_CASH_VALUE}
            className="minimum-number-field max-input-width "
            onChange={(value) => {
              if (value !== 0) {
                onChangeQuantity(value, row.id);
              }
            }}
            isHandleKeyDown={true}
          />
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "Trade",
      name: "Trade",
      selector: (row) => row.tradeOffer,
      cell: (row) => {
        return (
          <input
            min={0}
            type="number"
            value={row.tradeOffer}
            onFocus={handleEmptyOnFocus}
            className="input-textfield editable-inventory-cell-max-size"
            onChange={(e) => onChangeTradeOffer(e.target.value, row.id)}
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value, true); // Convert to number
              onChangeTradeOffer(inputValue, row.id);
            }}
          ></input>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "Cash",
      name: "Cash",
      selector: (row) => row.cashOffer,
      cell: (row) => {
        return (
          <input
            min={0}
            type="number"
            value={row.cashOffer}
            onFocus={handleEmptyOnFocus}
            onChange={(e) => onChangeCashOffer(e.target.value, row.id)}
            className="input-textfield editable-inventory-cell-max-size"
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
              onChangeCashOffer(inputValue, row.id);
            }}
          ></input>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "stockPrice",
      name: "Stock Price",
      selector: (row) => row.inStockPrice,
      cell: (row) => {
        return (
          <input
            min={0}
            type="number"
            value={row.inStockPrice}
            onFocus={handleEmptyOnFocus}
            onChange={(e) => onChangeStockPrice(e.target.value, row.id)}
            className="input-textfield editable-inventory-cell-max-size"
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
              onChangeStockPrice(inputValue, row.id);
            }}
          ></input>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "inStockPriceAnd",
      name: "",
      cell: (row) => {
        return getProductIfExist(row, onChangeStockPrice);
      },
      sortable: false,
      width: "8%",
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper"
              onClick={() => onDeleteItem(row.id)}
            >
              <DeleteIcon className="cursor-pointer text-primary" />
            </button>
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper ps-0 pe-0"
              onClick={() => {
                handleEditCardClick(row);
              }}
            >
              <EditIcon className="cursor-pointer text-primary" />
            </button>

            {/* delete icon button */}
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  //-------UseStates
  const [columns, setColumns] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [initialRatio, setInitialRatio] = useState(
    globalTradeinMargin?.cashMarginPercentage /
      globalTradeinMargin?.tradeinMarginPercentage
  );
  const [addTradeModal, setAddTradeModal] = useState(false);
  const [editTradeObject, setEditTradeObject] = useState({});
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [isBulkModal, setIsBulkModal] = useState(false);
  const [bulkUpdateCondition, setBulkUpdateCondition] = useState("");
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [rowsToUpdateCondition, setRowsToUpdateCondition] = useState([]);
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [pricechartingProductImage, setPricechartingProductImage] =
    useState("");
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );
  const [cashTradeOfferValue, setCashTradeOfferValue] = useState({
    cashOfferValue: 0,
    tradeOfferValue: 0,
  });
  const [gridState, setGridState] = useState({
    pageSize: 500,
    pageIndex: 0,
    sortBy: [],
  });

  //-------initial Product DataObject
  const [productDataObject, setProductDataObject] = useState(
    TRADE_BATCH_DEFAULT_DATA
  );

  //-------useref
  const asyncSelectRef = useRef();

  //-------handle onchnage any product quantity
  const onChangeQuantity = (newQuantity, Id) => {
    newQuantity = Number(newQuantity);
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        return { ...item, inStockQuantity: newQuantity };
      }
      return item;
    });
    handleAddTradeBatchInventory(updatedInventory);
  };

  //-------handle onchnage product stock price
  const onChangeStockPrice = (newPrice, Id) => {
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        const updatedItem = { ...item, inStockPrice: newPrice };
        // Calculate new offers based on newPrice or marketPrice
        if (newPrice !== 0) {
          // Calculate new offers based on newPrice or marketPrice
          // const priceToUse =
          //   item.marketPrice === 0
          //     ? newPrice
          //     : Math.min(newPrice, item.marketPrice);
          const priceToUse = Math.min(newPrice, item.marketPrice);

          const { cashOffer, tradeOffer } = calculateOfferPrices(
            priceToUse,
            item?.tradeInMarginTypeObject.marginObject.cashMarginPercentage,
            item?.tradeInMarginTypeObject.marginObject.tradeinMarginPercentage
          );
          updatedItem.cashOffer = convertToFixedPrecision(cashOffer);
          updatedItem.tradeOffer = convertToFixedPrecision(tradeOffer);
        }
        return updatedItem;
      }
      return item;
    });
    handleAddTradeBatchInventory(updatedInventory);
  };

  //-------handle delete item from batch
  const onDeleteItem = (Id) => {
    const updatedInventory = addTradeBatchInventory.filter(
      (item) => item.id !== Id
    );

    handleAddTradeBatchInventory(
      updatedInventory?.map((item, index) => ({ ...item, id: index })) || []
    );
  };

  //-------handle onchnage of product cash offer
  const onChangeCashOffer = (newOffer, Id) => {
    // newOffer = Number(newOffer);
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        const calculateRatio =
          item?.tradeInMarginTypeObject?.marginObject?.tradeinMarginPercentage /
          item?.tradeInMarginTypeObject?.marginObject?.cashMarginPercentage;
        const tradeOfferPercentage = convertToFixedPrecision(
          (item?.editedRatio ? item?.editedRatio : calculateRatio) *
            convertToFixedPrecision(newOffer)
        );
        return {
          ...item,
          tradeOffer: parseToDecimalNumber(tradeOfferPercentage, true),
          cashOffer: newOffer,
        };
      }
      return item;
    });
    handleAddTradeBatchInventory(
      handleBatchInventoryPercentage(updatedInventory)
    );
  };

  const handleHideBulkModal = () => {
    setIsBulkModal(!isBulkModal);
  };

  const handleBulkUpdateCondition = () => {
    const sameProductType = rowsToUpdateCondition.every(
      (item, index, array) => {
        return index === 0 || item["productType"] === array[0]["productType"];
      }
    );

    if (sameProductType) {
      setIsBulkModal(!isBulkModal);
    } else {
      customToast("Selected rows has not same product Type", toastType.ERROR);
    }
  };

  const handleBulkSubmit = () => {
    if (bulkUpdateCondition) {
      const bulkUpdateItemIds = rowsToUpdateCondition.map((item) => item.id);
      activateSpinner(inventoryConstants.BULK_UPDATE);

      handleBulkConditionChange(
        markupPresets,
        bulkUpdateCondition?.value,
        bulkUpdateItemIds,
        true,
        addTradeBatchInventory,
        handleAddTradeBatchInventory,
        tradeinMargin,
        handleBatchInventoryPercentage
      );

      setIsBulkModal(false);
      setRowsToUpdateCondition([]);
      setBulkUpdateCondition("");
      setToggleClearRows(!toggledClearRows);
      deactivateSpinner(inventoryConstants.BULK_UPDATE);
    } else {
      customToast("Condition not selected", toastType.ERROR);
    }
  };
  //-------handle onchange product trade offer
  const onChangeTradeOffer = (newOffer, Id) => {
    // newOffer = Number(newOffer);
    const updatedInventory = addTradeBatchInventory.map((item) => {
      if (item.id === Id) {
        const calculateRatio =
          item?.tradeInMarginTypeObject?.marginObject?.tradeinMarginPercentage /
          item?.tradeInMarginTypeObject?.marginObject?.cashMarginPercentage;
        const cashOfferCalculated = convertToFixedPrecision(
          convertToFixedPrecision(newOffer) /
            (item?.editedRatio ? item?.editedRatio : calculateRatio)
        );
        return {
          ...item,
          tradeOffer: newOffer,
          cashOffer: parseToDecimalNumber(cashOfferCalculated, true),
        };
      }
      return item;
    });
    handleAddTradeBatchInventory(
      handleBatchInventoryPercentage(updatedInventory)
    );
  };

  //--------handle total cash offer value change
  const handleCashOfferValueChange = (value) => {
    let isInvalid = addTradeBatchInventory.some((inv) => {
      return (
        Number(inv.inStockQuantity) === 0 ||
        Number(inv.cashOffer) === 0 ||
        inv.cashOffer === "" ||
        Number(inv.tradeOffer) === 0 ||
        inv.tradeOffer === ""
      );
    });

    if (isInvalid) {
      customToast(
        "Quantity & Stock Price & cash or trade Offer Cannot be 0",
        toastType.ERROR
      );
      return;
    }
    const inputValue = convertToFixedPrecision(value);
    setCashTradeOfferValue({
      ...cashTradeOfferValue,
      cashOfferValue: inputValue,
    });
    const updatedBatchInventory = addTradeBatchInventory.map((item) => {
      const { cashPercentagePerItem, inStockQuantity } = item;
      let cashOffer =
        (inputValue * cashPercentagePerItem) / 100 / inStockQuantity;

      cashOffer =
        cashOffer <= inventoryConstants.DEFAULT_OFFER_VALUE
          ? inventoryConstants.DEFAULT_OFFER_VALUE
          : cashOffer;

      return {
        ...item,
        cashOffer: convertToFixedPrecision(cashOffer),
        tradeOffer: convertToFixedPrecision(initialRatio * cashOffer),
      };
    });
    handleAddTradeBatchInventory(updatedBatchInventory);
  };

  //-------handle total trade offer value change
  const handleTradeOfferValueChange = (value) => {
    let isInvalid = addTradeBatchInventory.some((inv) => {
      return (
        Number(inv.inStockQuantity) === 0 ||
        Number(inv.cashOffer) === 0 ||
        inv.cashOffer === "" ||
        Number(inv.tradeOffer) === 0 ||
        inv.tradeOffer === ""
      );
    });

    if (isInvalid) {
      customToast(
        "Quantity & Stock Price & cash or trade Offer Cannot be 0",
        toastType.ERROR
      );
      return;
    }
    const inputValue = convertToFixedPrecision(value);
    setCashTradeOfferValue({
      ...cashTradeOfferValue,
      tradeOfferValue: inputValue,
    });
    const updatedBatchInventory = addTradeBatchInventory.map((item) => {
      const { tradePercentagePerItem, inStockQuantity } = item;

      let tradeOffer =
        (inputValue * tradePercentagePerItem) / 100 / inStockQuantity;

      tradeOffer =
        tradeOffer <= inventoryConstants.DEFAULT_OFFER_VALUE
          ? inventoryConstants.DEFAULT_OFFER_VALUE
          : tradeOffer;

      return {
        ...item,
        cashOffer: convertToFixedPrecision(tradeOffer / initialRatio),
        tradeOffer: convertToFixedPrecision(tradeOffer),
      };
    });
    handleAddTradeBatchInventory(updatedBatchInventory);
  };

  //--------handle submit add batch inventory
  const handleSubmitAddBatchInventory = async () => {
    // validate required fields
    let isInvalid = addTradeBatchInventory.some((inv) => {
      return (
        Number(inv.inStockQuantity) === 0 ||
        Number(inv.inStockPrice) === 0 ||
        Number(inv.cashOffer) === 0 ||
        inv.cashOffer === "" ||
        Number(inv.tradeOffer) === 0 ||
        inv.tradeOffer === ""
      );
    });
    if (isInvalid) {
      customToast(
        "Quantity & Stock Price & cash or trade Offer Cannot be 0",
        toastType.ERROR
      );
      return;
    }

    let tempInitialSku = tempSku;
    activateSpinner(transactionConstants.ADD_TRADE_BATCH_ITEM);
    let i = 0;
    let updatedNewTradeData = newTradeData;
    // const margedBacthInventory = mergeBatchProducts(addTradeBatchInventory);
    const margedBacthInventory = addTradeBatchInventory;
    for (const product of margedBacthInventory) {
      try {
        let productImage;
        if (product.apiSource === inventoryConstants.PRICECHARTING_API) {
          productImage = await batchPricechartingProductImage(
            `${process.env.REACT_APP_PRICECHARTING_IFRAME_URL}${stringToSlug(
              product.consoleName
            )}/${stringToSlug(product.productName)}`
          );
        } else {
          productImage = product?.imgUrl;
        }

        const productData = {
          sku: [`${transactionConstants.BATCH_TRADE} ${tempInitialSku++}`],
          product_id: product.productId,
          product_name: product.productName,
          category_name: product.consoleName,
          store: {
            id: currentStore.id,
            name: currentStore.storeName,
          },
          date_added: formatDate(new Date()),
          price: {
            unit_purchase_price: product.costOfGoods,
            unit_sell_price: product.inStockPrice || 0,
            quantity: product.inStockQuantity,
            type: product.productCondition,
            marketPrice: convertToFixedPrecision(product?.marketPrice) || 0,
          },
          productType: product.productType,
          upc: product.upc,
          additionalCheckList: [],
          tags: product?.tags ? product.tags.map((tag) => tag.label) : [],
          cardRarity: product?.rarity?.label || "",
          cardNumber: "",
          apiSource: product.apiSource,
          cashOffer: product.cashOffer,
          tradeOffer: product.tradeOffer,
          ratio: initialRatio,
          batch: "batch",
          imgUrl: productImage,
          tcgPlayerUrl: product.tcgPlayerUrl,
          epid: product.epid,
          subcategory:
            product.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
              ? getProductSubCategory(productDataObject.genre)
              : "",
          description: "",
        };

        updatedNewTradeData = getUpdatedCartItems(
          updatedNewTradeData,
          productData,
          null
        );
      } catch (error) {
        customToast(error, toastType.ERROR);
      }
    }
    // setting the state only once after all the batch items are added in cart (increment the existing ones and create new if does not exist already)
    addToCart(updatedNewTradeData, true);
    generateTempSku(tempInitialSku);
    deactivateSpinner(transactionConstants.ADD_TRADE_BATCH_ITEM);
    handleCloseModal(true);
  };

  //-------handle close modal
  const handleCloseModal = (isModalSubmit = false) => {
    setIsTradeBatchInventoryModal(false);
    // if (!isModalSubmit && addTradeBatchInventory.length) {
    //   handleDraftModal(transactionConstants.ADD_BATCH_TRADE_DRAFT_MODAL);
    // } else {
    //   handleAddTradeBatchInventory([]);
    // }
    if (isModalSubmit) {
      handleAddTradeBatchInventory([]);
    }

    emptyPriceChartingProductObject();
    setSelectedRarity("");
    setRarities([]);
    setPricechartingProductImage("");
  };
  //-------handle add trade modal open
  const handleAddTradeModalOpen = () => {
    toggleAddTradeModal();
  };
  const scrollToTop = () => {
    var tableElement = document.getElementsByClassName("rdt_TableBody")[0];
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  //-------Handle table sort
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  //-------toggle add trade modal
  const toggleAddTradeModal = useCallback(() => {
    setAddTradeModal((prevValue) => !prevValue);
  }, []);

  //-------handle batch trade product edit button click
  const handleEditCardClick = (productDetail) => {
    setIsEdit(true);
    const product = {
      id: productDetail?.productId,
      sourceApi: productDetail?.apiSource,
      genre: productDetail?.genre,
      name: productDetail?.productName,
      price: productDetail?.marketPrice,
      productType: productDetail?.productType,
      category_name: productDetail?.consoleName,
      imgUrl: "",
    };
    if (productDetail.rarity?.label) {
      setEditTradeObject({
        ...productDetail,
        marketPrice: productDetail.marketPrice,
        suggestedSellPrice: productDetail.suggestedSellPrice,
      });
    } else {
      setEditTradeObject({
        ...productDetail,
        marketPrice: usdToOriginal(productDetail.marketPrice),
        suggestedSellPrice: usdToOriginal(productDetail.suggestedSellPrice),
      });
    }
    handleAddTradeModalOpen();

    if (product) {
      if (product?.sourceApi === inventoryConstants.PRICECHARTING_API) {
        getPricechartingProductById(product, product?.id);
      }
      if (product?.sourceApi === inventoryConstants.POKEMON_API) {
        pokemon.card.find(product?.id).then((card) => {
          setPokemonProductInReducer(product, card || "");
        });
      }
      if (product?.sourceApi === inventoryConstants.SCRYFALL_API) {
        getScryFallProductById(product, product?.id);
      }
    }
  };

  //-------handle add to card batch trade
  const addToCartInBatchTrade = useCallback(
    (editProduct) => {
      const index = addTradeBatchInventory.findIndex(
        (item) => item.id === editProduct.id
      );

      if (index !== -1) {
        const updatedInventory = [
          ...addTradeBatchInventory.slice(0, index),
          ...addTradeBatchInventory.slice(index + 1),
        ];
        updatedInventory.splice(index, 0, editProduct);

        handleAddTradeBatchInventory(updatedInventory);
        setEditTradeObject("");
      }
    },
    [addTradeBatchInventory]
  );

  const handleRowSelectionChange = useCallback((state) => {
    setRowsToUpdateCondition(state.selectedRows);
  }, []);

  const handleBatchInventoryPercentage = (batchInventory) => {
    // Calculate the total cash offer
    const totalCashOffer = batchInventory.reduce((acc, currentInv) => {
      return Number(currentInv.cashOffer * currentInv.inStockQuantity) + acc;
    }, 0);

    // Calculate the total trade offer
    const totalTradeOffer = batchInventory.reduce((acc, currentInv) => {
      return Number(currentInv.tradeOffer * currentInv.inStockQuantity) + acc;
    }, 0);

    // Map through each inventory item to calculate and set both cash and trade percentages
    const updatedInventory = batchInventory.map((item) => {
      const itemTotalCashOffer = Number(item.cashOffer * item.inStockQuantity);
      const cashPercentagePerItem =
        totalCashOffer > 0 ? (itemTotalCashOffer / totalCashOffer) * 100 : 0;

      const itemTotalTradeOffer = Number(
        item.tradeOffer * item.inStockQuantity
      );
      const tradePercentagePerItem =
        totalTradeOffer > 0 ? (itemTotalTradeOffer / totalTradeOffer) * 100 : 0;
      return {
        ...item,
        cashPercentagePerItem: convertToFixedPrecision(cashPercentagePerItem),
        tradePercentagePerItem: convertToFixedPrecision(tradePercentagePerItem),
      };
    });
    return updatedInventory;
  };

  //-------useeffects

  //-------maintain ratio between trade and cash offer on
  //-------market price change or stock price change
  useEffect(() => {
    const marketPrice = convertToFixedPrecision(productDataObject.marketPrice);
    if (marketPrice == 0) {
      const ratio =
        globalTradeinMargin?.cashMarginPercentage /
        globalTradeinMargin?.tradeinMarginPercentage;
      setInitialRatio(ratio);
    } else {
      const initialCashValue =
        marketPrice * (globalTradeinMargin?.cashMarginPercentage / 100);
      const initialTradeValue =
        marketPrice * (globalTradeinMargin?.tradeinMarginPercentage / 100);
      const initialRatio = initialTradeValue / initialCashValue;
      setInitialRatio(initialRatio);
    }
  }, [globalTradeinMargin, productDataObject]);

  //-------set product image affter getting from backend hit
  useEffect(() => {
    setProductDataObject({
      ...productDataObject,
      imgUrl: pricechartingProductImage,
    });
  }, [pricechartingProductImage]);

  //-------update product condition and type
  useEffect(() => {
    if (priceChartingProductInfo && isTradeBatchInventoryModal && !isEdit) {
      activateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
      setDefaultProductDataObject(
        markupPresets,
        setRarities,
        currentStore,
        inventoryActions,
        productDataObject,
        addTradeBatchInventory,
        setSelectedRarity,
        tradeinMargin,
        false,
        setProductDataObject,
        handleAddTradeBatchInventory,
        priceChartingProductInfo,
        handleBatchInventoryPercentage
      );
      deactivateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
    }
  }, [priceChartingProductInfo]);

  useEffect(() => {
    setColumns([...columnHeaders]);
    // Calculate the total cash offer
    const totalCashOffer = addTradeBatchInventory.reduce((acc, currentInv) => {
      return Number(currentInv.cashOffer * currentInv.inStockQuantity) + acc;
    }, 0);

    // Calculate the total trade offer
    const totalTradeOffer = addTradeBatchInventory.reduce((acc, currentInv) => {
      return Number(currentInv.tradeOffer * currentInv.inStockQuantity) + acc;
    }, 0);
    setCashTradeOfferValue({
      cashOfferValue: convertToFixedPrecision(totalCashOffer),
      tradeOfferValue: convertToFixedPrecision(totalTradeOffer),
    });
  }, [addTradeBatchInventory, productDataObject, initialRatio]);

  useEffect(() => {
    scrollToTop();
  }, [addTradeBatchInventory?.length]);
  return (
    <>
      <Modal
        size="lg"
        centered
        backdrop="static"
        animation={true}
        onHide={handleCloseModal}
        dialogClassName="modal-90w"
        show={isTradeBatchInventoryModal}
        className={`add-inventory-modal ${isModal && "d-none"}`}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">
              Batch Trade-In
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body py-0">
          {/* search inventory */}
          <Row className="m-0">
            <Col md={10} className="mb-0 ps-md-0">
              <SearchInventory
                customTypes={customTypes}
                asyncSelectRef={asyncSelectRef}
                customCategories={customCategories}
              />
            </Col>
            {
              rowsToUpdateCondition?.length ? (
                <Col md={2} className="d-flex justify-content-end pe-md-0">
                  <Button
                    label={buttonNameConstants.BULK_UPDATE}
                    type="button"
                    handleClick={handleBulkUpdateCondition}
                  />
                </Col>
              ) : (
                ""
              )
              // ) : addTradeBatchInventory.length ? (
              //   <Col md={2} className="d-flex justify-content-end pe-md-0">
              //     <Button
              //       label={buttonNameConstants.SAVE_BATCH}
              //       type="button"
              //       handleClick={() => setIsTradeBatchInventoryModal(false)}
              //     />
              //   </Col>
              // ) : (
              // ""
            }
          </Row>
          {/* main body */}
          <Row>
            <EditableReactDataTableComponent
              selectableRows
              columns={columns}
              onSort={onTableSort}
              gridState={gridState}
              data={[...addTradeBatchInventory].reverse()}
              setGridState={setGridState}
              total={addTradeBatchInventory?.length}
              clearSelectedRows={toggledClearRows}
              defaultSortFieldId={defaultSortFieldId}
              handleRowSelectionChange={handleRowSelectionChange}
            />
          </Row>
          {/*Modal footer */}
          <AddTradeBatchFooter
            spinnerArray={spinnerArray}
            closeModal={handleCloseModal}
            cashTradeOfferValue={cashTradeOfferValue}
            handleCashOfferValueChange={handleCashOfferValueChange}
            handleTradeOfferValueChange={handleTradeOfferValueChange}
            handleSubmitAddBatchInventory={handleSubmitAddBatchInventory}
            data={{
              totalItems: addTradeBatchInventory.reduce((acc, currentInv) => {
                return Number(currentInv.inStockQuantity) + acc;
              }, 0),
              totalMarketPrice: addTradeBatchInventory.reduce(
                (acc, currentInv) => {
                  return (
                    Number(
                      currentInv.marketPrice * currentInv.inStockQuantity
                    ) + acc
                  );
                },
                0
              ),
              totalPrice: addTradeBatchInventory.reduce((acc, currentInv) => {
                return (
                  Number(currentInv.inStockPrice * currentInv.inStockQuantity) +
                  acc
                );
              }, 0),
              totalCost: addTradeBatchInventory.reduce((acc, currentInv) => {
                return Number(currentInv.costOfGoods) + acc;
              }, 0),
              totalCashOffer: addTradeBatchInventory.reduce(
                (acc, currentInv) => {
                  return (
                    Number(currentInv.cashOffer * currentInv.inStockQuantity) +
                    acc
                  );
                },
                0
              ),
              totalTradeOffer: addTradeBatchInventory.reduce(
                (acc, currentInv) => {
                  return (
                    Number(currentInv.tradeOffer * currentInv.inStockQuantity) +
                    acc
                  );
                },
                0
              ),
            }}
          />
        </Modal.Body>
      </Modal>
      <AddTradeBatchEdit
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        tradeDiscount={tradeDiscount}
        addTradeModal={addTradeModal}
        addToCart={addToCartInBatchTrade}
        editTradeObject={editTradeObject}
        setEditTradeObject={setEditTradeObject}
        toggleAddTradeModal={toggleAddTradeModal}
        priceChartingProductInfo={priceChartingProductInfo}
      />
      <Modal
        show={isBulkModal}
        size="sm"
        onHide={handleHideBulkModal}
        animation={true}
        centered
        backdrop="static"
        className={"add-inventory-modal bulk-modal"}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">Bulk Update</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body d-flex ">
          <Row className="m-0">
            <Col md={4} className="ps-md-0 d-flex align-items-center mb-3">
              Condition
            </Col>
            <Col md={8} className="pe-md-0  mb-3">
              <SelectDropDown
                menuPortalTarget=""
                menuPlacement="bottom"
                handleChange={(e) => {
                  setBulkUpdateCondition(e);
                }}
                options={
                  rowsToUpdateCondition[0]?.productType ===
                  PRODUCT_TYPES_ENUMS.VIDEO_GAME
                    ? inventoryConstants.VIDEO_GAME_CONDITION
                    : rowsToUpdateCondition[0]?.productType ===
                      PRODUCT_TYPES_ENUMS.TRADING_CARD
                    ? inventoryConstants.TRADING_CARD_CONDITION
                    : inventoryConstants.OTHER_CONDITION
                }
                value={bulkUpdateCondition}
              />
            </Col>
            <Col md={6} className="ps-md-0">
              <Button
                label={buttonNameConstants.CANCEL}
                className="w-50"
                type="button"
                handleClick={() => {
                  setIsBulkModal(false);
                }}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
              />
            </Col>
            <Col md={6}>
              <Button
                label={buttonNameConstants.UPDATE}
                className="w-50"
                type="button"
                handleClick={handleBulkSubmit}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  transaction: state.transaction,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  productTags: state.inventory.productTags,
  newTradeData: state.transaction.newTradeData,
  markupPresets: state.tradeDiscount.markupPresets,
  tradeinMargin: state.tradeDiscount.tradeinMargin,
  addTradeBatchInventory: state.transaction.addTradeBatchInventory,
  priceChartingProductInfo: state.inventory.priceChartingProductInfo,
  globalTradeinMargin: state.tradeDiscount.tradeinMargin?.globalMargin,
  isTradeBatchInventoryModal: state.transaction.isTradeBatchInventoryModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  generateTempSku: (data) => dispatch(transactionActions.generateTempSku(data)),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
  setIsTradeBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type:
        transactionConstants.SET_IS_TRADE_BATCH_INVENTORY +
        globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  setAddTradeBatchInventory: (data, storeId) =>
    dispatch(transactionActions.setAddTradeBatchInventory(data, storeId)),
});

//-------Export Add Trade Batch Component
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeBatch);

import { updateObject } from "../../shared/utility";
import { globalConstants, customerConstants } from "../../constants";

//-------InitialState of the reducer
const initialState = {
  allCustomers: [],
  duplicateCustomers: [],
  customerById: {},
};

//-------Export user Reducer
export const customer = (state = initialState, action) => {
  switch (action.type) {
    //-------Get all customers success case
    case customerConstants.GET_ALL_CUSTOMERS + globalConstants.SUCCESS:
      return updateObject(state, {
        allCustomers: action.data || [],
      });
    //-------Get all customers failure case
    case customerConstants.GET_ALL_CUSTOMERS + globalConstants.FAILURE:
      return updateObject(state, {
        allCustomers: [],
      });
    //-------Get all customers failure case
    case customerConstants.BULK_UPLOAD_CUSTOMERS + globalConstants.SUCCESS:
      return updateObject(state, {
        duplicateCustomers: action.data,
      });
    //-------Get GET_CUSTOMER_BY_ID success case
    case customerConstants.GET_CUSTOMER_BY_ID + globalConstants.SUCCESS:
      return updateObject(state, {
        customerById: action.data || [],
      });
    //-------EMPTY_CUSTOMER_BY_ID success case
    case customerConstants.EMPTY_CUSTOMER_BY_ID + globalConstants.SUCCESS:
      return updateObject(state, {
        customerById: {},
      });
    //-------Default State

    default:
      return state;
  }
};

import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";
import CustomSelectField from "../customSelectField/CustomSelectField";

//-------InputTextField component start here
const MultiSelectField = (props) => {
  const { name, options, placeHolder, label } = props;
  //-------Return InputTextField Component
  return (
    <div>
      <label className="field-label" htmlFor={name}>
        {label && label}
      </label>
      <div>
        <Field
          name={name}
          id={name}
          placeholder={placeHolder}
          isMulti={true}
          component={CustomSelectField}
          options={options}
        />
      </div>
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------InputTextField component end here
};

//-------Export InputTextField Component
export default MultiSelectField;

import React from "react";
import { connect } from "react-redux";
import { Outlet, useLocation } from "react-router";

import SidebarBody from "./sidebar/SidebarBody";
import SidebarHeader from "./sidebar/SidebarHeader";
import NestedSidebar from "./sidebar/NestedSidebar";
import { ROLES, routesPathConstants } from "../../constants";
import TopbarUserContainer from "../../shared/components/topbar/TopbarUserContainer";

const AppLayout = ({
  isSidebarOpen,
  isNestedSidebarOpen,
  isStoreActive,
  role,
  spinnerArray,
  ...props
}) => {
  const location = useLocation();

  return (
    <>
      <div className="page-wrapper">
        <div className="row m-0 pe-3 topbar system-tob-bar">
          <div className="col-12  d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-4 topbar-logo-select-store-wrapper">
              <SidebarHeader />
            </div>
            <TopbarUserContainer />
          </div>
        </div>
        <div className={`d-flex`}>
          <div
            className={`sidebar sidebar-wrapper ${
              !isStoreActive && role !== ROLES.ADMIN && "store-inactive"
            }`}
          >
            <SidebarBody />
            <NestedSidebar />
          </div>
          <div
            className={`outlet-container ps-2 pe-1 ${
              isNestedSidebarOpen &&
              ![
                routesPathConstants.CUSTOMERS,
                routesPathConstants.DASHBOARD,
                routesPathConstants.PRINTER_SETTINGS,
                routesPathConstants.BILLING,
                routesPathConstants.PAYOUTS,
                routesPathConstants.USER_INFORMATION,
                routesPathConstants.CUSTOMERS_EDIT,
                routesPathConstants.CUSTOMERS_ADD,
                routesPathConstants.SETTINGS_USERS,
                routesPathConstants.SETTINGS_USERS_EDIT_USER,
                routesPathConstants.ADD_NEW_USER,
              ].includes(location.pathname) &&
              "sidebar-opened-outlet-container"
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  role: state.authentication.user.role,
  spinnerArray: state.system.spinnerArray,
  isSidebarOpen: state.system.isSidebarOpen,
  isNestedSidebarOpen: state.system.isNestedSidebarOpen,
  isStoreActive: state.store.currentStore.isStoreActive,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);

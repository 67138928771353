import { Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";

import { inventoryService } from "../../../../services";
import Button from "../../../../shared/components/Buttons/Button";
import {
  customToast,
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  getCustomTypesOptionList,
  getTypeOrCategoryObject,
  isSpinnerEnabled,
} from "../../../../shared/utility";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  toastType,
  inventoryConstants,
  transactionConstants,
} from "../../../../constants";
import { systemActions } from "../../../../redux/actions";
import { addSaleCustomItemValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import SelectField from "../../../../shared/components/selectField/SelectField";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";

const AddCustomItem = (props) => {
  const {
    customTypes,
    currentStore,
    spinnerArray,
    addItemInCart,
    customItemModal,
    activateSpinner,
    customCategories,
    deactivateSpinner,
    customSubCategories,
    toggleCustomItemModal,
  } = props;

  //-------initial values for custom item
  const initialValues = {
    itemName: transactionConstants.CUSTOM_DEFAULT_TITLE,
    quantity: 1,
    cogs: 0,
    price: 0,
    productType: globalConstants.EMPTY_STRING,
    category_name: globalConstants.EMPTY_STRING,
    subcategory: globalConstants.EMPTY_STRING,
  };

  //-------handle hide modal
  const handleHideModal = () => {
    toggleCustomItemModal();
  };

  //-------get Sku Number
  const getSkuNumber = async () => {
    return inventoryService.getSkuNumber(currentStore?.id).then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };

  const handleCustomItemSubmit = async (values) => {
    activateSpinner(transactionConstants.ADD_CUSTOM_ITEM);
    const skuNumber = await getSkuNumber();
    if (skuNumber) {
      const miscellaneousItem = {
        ...values,
        id: globalConstants.EMPTY_STRING,
        item: values.itemName,
        max: 9999,
        type:
          getTypeOrCategoryObject(customTypes, values.productType)
            ?.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
            ? inventoryConstants.VIDEO_GAME_CONDITION[0].value
            : getTypeOrCategoryObject(customTypes, values.productType)
                ?.productType === PRODUCT_TYPES_ENUMS.TRADING_CARD
            ? inventoryConstants.TRADING_CARD_CONDITION[0].value
            : inventoryConstants.OTHER_CONDITION[0].value,
        sku: [skuNumber],
        tcgPlayerUrl: globalConstants.EMPTY_STRING,
        epid: globalConstants.EMPTY_STRING,
        tags: [],
        productType:
          getTypeOrCategoryObject(customTypes, values.productType)
            ?.productType || globalConstants.EMPTY_STRING,
        category:
          getTypeOrCategoryObject(customCategories, values.category_name)
            ?.name || transactionConstants.CUSTOM_SALE,
        subcategory:
          getTypeOrCategoryObject(customSubCategories, values.subCategory)
            ?.name || globalConstants.EMPTY_STRING,
        isCustomItem: true,
      };
      addItemInCart(miscellaneousItem);
    }
    handleHideModal();
    deactivateSpinner(transactionConstants.ADD_CUSTOM_ITEM);
  };

  const handleTypeChange = (option, setFieldValue) => {
    setFieldValue("productType", option);
    setFieldValue("category_name", globalConstants.EMPTY_STRING);
    setFieldValue("subCategory", globalConstants.EMPTY_STRING);
  };

  const handleCategoryChange = (option, setFieldValue) => {
    setFieldValue("category_name", option);
    setFieldValue("subCategory", globalConstants.EMPTY_STRING);
  };

  return (
    <Modal
      show={customItemModal}
      size="md"
      onHide={
        isSpinnerEnabled(spinnerArray, transactionConstants.ADD_CUSTOM_ITEM)
          ? ""
          : handleHideModal
      }
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">Custom Item</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Formik
          initialValues={initialValues}
          onSubmit={handleCustomItemSubmit}
          validate={addSaleCustomItemValidationSchema}
        >
          {({ resetForm, setFieldValue, values }) => (
            <Form>
              <Row className="m-0">
                <Col md={12} className="mb-2">
                  <InputTextField
                    name="itemName"
                    label="Item Title"
                    placeHolder="Enter Item Title"
                  />
                </Col>
                <Col md={4} className="mb-2">
                  <InputNumberField
                    label="Qty"
                    name="quantity"
                    placeHolder="0"
                    className="add-inventory-quantity-field"
                    maxValue={9999}
                    isConvertToDecimal={false}
                    step={"1"}
                    isHandleKeyDown={true}
                  />
                </Col>
                <Col md={8} className="mb-2">
                  <SelectField
                    label="Type"
                    name="productType"
                    placeHolder={"Select Type"}
                    options={getCustomTypesOptionList(customTypes).filter(
                      (item) => item.label !== PRODUCT_TYPES_ENUMS.ALL
                    )}
                    onChangeValue={(option) =>
                      handleTypeChange(option, setFieldValue)
                    }
                  />
                </Col>
                <Col md={6} className="mb-2">
                  <SelectField
                    label="Category (Optional)"
                    name="category_name"
                    placeHolder={"Select Category"}
                    options={getCustomCategoriesOptionList(
                      customCategories,
                      values.productType
                    )}
                    onChangeValue={(option) =>
                      handleCategoryChange(option, setFieldValue)
                    }
                    isClearable={true}
                  />
                </Col>
                <Col md={6} className="mb-2">
                  <SelectField
                    label="Sub-Category (Optional)"
                    name="subcategory"
                    placeHolder={"Select Sub-Category..."}
                    options={getCustomSubCategoriesOptionList(
                      customSubCategories,
                      values.category_name,
                      null,
                      values.productType,
                      false,
                      customCategories,
                      customTypes
                    )}
                    isClearable={true}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  <InputNumberField
                    label="Cost of Goods"
                    name="cogs"
                    placeHolder="0.00"
                    className="add-inventory-quantity-field"
                    maxValue={99999}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  <InputNumberField
                    label="Sell Price"
                    name="price"
                    placeHolder="0.00"
                    className="add-inventory-quantity-field"
                    maxValue={99999}
                  />
                </Col>
                <Col md={12} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      resetForm();
                      handleHideModal();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                    isDisabled={isSpinnerEnabled(
                      spinnerArray,
                      transactionConstants.ADD_CUSTOM_ITEM
                    )}
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.ADD_TO_CART}
                    isDisabled={isSpinnerEnabled(
                      spinnerArray,
                      transactionConstants.ADD_CUSTOM_ITEM
                    )}
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(AddCustomItem);

import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";

import { customerDropdownStyle } from "../../../assets/scss/style";
import {
  BoxIcon,
  BendArrowIcon,
  CategoryIcon,
  ClockIcon,
  TransactionIcon,
  InventoryConditionTypeIcon,
  SearchIcon,
  AddIcon,
} from "../../../assets/icons/iconsProvider";
import {
  categoryDropdownConstants,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../constants";
import Button from "../Buttons/Button";

const CustomerDropdown = ({
  options,
  handleChange,
  noOptionsMessage,
  placeholder,
  disabled,
  isSearchable = true,
  SelectIconImage,
  isClearable = true,
  className,
  defaultValue = globalConstants.EMPTY_STRING,
  value,
  dropdownIndicator = true,
  handleAddCustomerClick,
  selectedCustomerFlag,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const CustomInput = (props) => (
    <div>
      <components.Input {...props} />
      {SelectIconImage === categoryDropdownConstants.CLOCK_ICON ? (
        <ClockIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.BOX_ICON ? (
        <BoxIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.TRANSACTION_ICON ? (
        <TransactionIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.INVENTORY_LIST_ICON ? (
        <InventoryConditionTypeIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.BEND_ICON ? (
        <BendArrowIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.INVENTORY_LIST_ICON ? (
        <InventoryConditionTypeIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.TRANSACTION_ICON ? (
        <TransactionIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.SEARCH_ICON ? (
        <SearchIcon className="dropdown-icon" />
      ) : (
        <CategoryIcon className="dropdown-icon" />
      )}
    </div>
  );

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            label={buttonNameConstants.ADD_CUSTOMER}
            buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
            IconImage={AddIcon}
            handleClick={handleAddCustomerClick}
          />
        </div>

        {props.children}
      </components.MenuList>
    );
  };
  useEffect(() => {
    if (selectedCustomerFlag) {
      setIsMenuOpen(false);
    }
  }, [selectedCustomerFlag]);

  return (
    <>
      <Select
        className={`w-100 ${className}`}
        options={options}
        isDisabled={disabled}
        value={value}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={
          dropdownIndicator
            ? {
                IndicatorSeparator: () => null,
                Input: CustomInput,
                MenuList: SelectMenuButton,
              }
            : {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => "",
                Input: CustomInput,
                MenuList: SelectMenuButton,
              }
        }
        styles={customerDropdownStyle}
        onChange={handleChange}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        isClearable={isClearable}
        defaultValue={defaultValue}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        menuPortalTarget={document.querySelector("body")}
      />
    </>
  );
};

export default CustomerDropdown;

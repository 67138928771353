import React, { useEffect, useRef, useState } from "react";
import {
  AsendingSortingIcon,
  DefaultSortingIcon,
  DesendingSortingIcon,
} from "../../../assets/icons/iconsProvider";
import { globalConstants, gridSortingConstants } from "../../../constants";

export default (props) => {
  const [ascSort, setAscSort] = useState(gridSortingConstants.INACTIVE);
  const [descSort, setDescSort] = useState(gridSortingConstants.INACTIVE);
  const [noSort, setNoSort] = useState(gridSortingConstants.INACTIVE);
  const refButton = useRef(null);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const onSortChanged = () => {
    setAscSort(
      props.column.isSortAscending()
        ? gridSortingConstants.ACTIVE
        : gridSortingConstants.INACTIVE
    );
    setDescSort(
      props.column.isSortDescending()
        ? gridSortingConstants.ACTIVE
        : gridSortingConstants.INACTIVE
    );
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending()
        ? gridSortingConstants.ACTIVE
        : gridSortingConstants.INACTIVE
    );
  };

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
    if (props.gridState) {
      props.updateGridState({
        ...props.gridState,
        sortFilter: !order
          ? globalConstants.EMPTY_STRING
          : `${props.column.colId} ${order}`,
      });
    }
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
  }, []);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        className="customHeaderMenuButton"
        onClick={() => onMenuClicked()}
      >
        <i className={`fa ${props.menuIcon}`}></i>
      </div>
    );
  }

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <div className="d-flex align-items-center">
        {ascSort === gridSortingConstants.ACTIVE && (
          <div
            onClick={(event) =>
              onSortRequested(gridSortingConstants.DESCENDING, event)
            }
            onTouchEnd={(event) =>
              onSortRequested(gridSortingConstants.ASCENDING, event)
            }
            className={`customSortDownLabel ${ascSort}`}
          >
            <AsendingSortingIcon className="ms-1 cursor-pointer" />
          </div>
        )}
        {descSort === gridSortingConstants.ACTIVE && (
          <div
            onClick={(event) =>
              onSortRequested(gridSortingConstants.DEFAULT, event)
            }
            onTouchEnd={(event) =>
              onSortRequested(gridSortingConstants.DESCENDING, event)
            }
            className={`customSortUpLabel ${descSort}`}
          >
            <DesendingSortingIcon className="ms-1 cursor-pointer" />
          </div>
        )}
        {noSort === gridSortingConstants.ACTIVE && (
          <div
            onClick={(event) =>
              onSortRequested(gridSortingConstants.ASCENDING, event)
            }
            onTouchEnd={(event) =>
              onSortRequested(gridSortingConstants.DEFAULT, event)
            }
            className={`customSortRemoveLabel ${noSort}`}
          >
            <DefaultSortingIcon className="ms-1 cursor-pointer" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      {menu}
      <div className="customHeaderLabel">{props.displayName}</div>
      {sort}
    </div>
  );
};

import { getRequest, postRequest, putRequest } from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get all system users from server
const getAvailablePrinters = (storeId) => {
  return getRequest(`printer-configurations/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get all system users from server
const addNewPrinter = (data) => {
  return postRequest(`printer-configurations/${data.store.id}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get all system users from server
const setDefaultPrinter = (data) => {
  return putRequest(`printer-configurations`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export system user Services
export const printerServices = {
  getAvailablePrinters,
  addNewPrinter,
  setDefaultPrinter,
};

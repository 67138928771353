import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { userActions } from "../../../../redux/actions";
import { decryptObject } from "../../../../system/encryption";
import Topbar from "../../../../shared/components/topbar/Topbar";
import Button from "../../../../shared/components/Buttons/Button";
import SelectField from "../../../../shared/components/selectField/SelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputPasswordField from "../../../../shared/components/inputPasswordField/InputPasswordField";
import {
  userConstants,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  ROLES,
} from "../../../../constants";
import {
  addUserValidationSchema,
  addUserWithPasswordValidationSchema,
} from "../../../../shared/validationSchema/validationSchema";
import MultiSelectField from "../../../../shared/components/multiSelectField/MultiSelectField";
import { isSpinnerEnabled } from "../../../../shared/utility";

//-------AddNewUser component start here
const AddNewUser = (props) => {
  const { addNewUser, updateUser, store, user, spinnerArray } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isEditPassword, setIsEditPassword] = useState(false);

  //-------useState
  const { currentStore, stores } = store;

  const storeList = stores.filter(
    (store) => store.companyId === currentStore.companyId
  );
  //-------Initial state of user form
  const initialValues = {
    role: state?.data?.role || globalConstants.EMPTY_STRING,
    firstName: state?.data?.firstName || globalConstants.EMPTY_STRING,
    lastName: state?.data?.lastName || globalConstants.EMPTY_STRING,
    email: state?.data?.email || globalConstants.EMPTY_STRING,
    password: state?.isEdit
      ? decryptObject(
          state?.data?.password ?? globalConstants.EMPTY_STRING,
          process.env.REACT_APP_PASSWORD_ENCRYPTED_KEY
        )
      : globalConstants.EMPTY_STRING,
    companyUserId: state?.data?.companyUserId || globalConstants.EMPTY_STRING,
    companyId:
      state?.data?.companyId ||
      currentStore?.companyId ||
      globalConstants.EMPTY_STRING,
    stores:
      state?.data?.role === ROLES.EMPLOYEE
        ? state?.data?.stores.map((st) => st.id) || globalConstants.EMPTY_STRING
        : [],
    confirmPassword: globalConstants.EMPTY_STRING,
  };

  //-------handle submit for add new user form
  const handleSubmit = (data) => {
    delete data["confirmPassword"];
    if (state?.isEdit) {
      updateUser({
        ...data,
        id: state?.data?.id,
        email: data.email.toLowerCase(),
        password: isEditPassword ? data.password : globalConstants.EMPTY_STRING,
        stores:
          data.role === ROLES.EMPLOYEE
            ? data.stores.map((uuid) => {
                const store = stores.find((store) => store.id === uuid);
                return store ? { id: store.id, name: store.storeName } : null;
              })
            : [],
      });
    } else {
      addNewUser({
        ...data,
        email: data.email.toLowerCase(),
        companyUserId: state?.data?.companyUserId || 0,
        stores:
          data.role === ROLES.EMPLOYEE
            ? data.stores.map((uuid) => {
                const store = stores.find((store) => store.id === uuid);
                return store ? { id: store.id, name: store.storeName } : null;
              })
            : [],
      });
    }
  };

  //-------handle cancel button click
  const handleCancelButton = () => {
    navigate(routesPathConstants.SETTINGS_USERS);
  };

  const handleEditPasswordClick = (setFieldValue) => {
    setIsEditPassword(!isEditPassword);
    if (isEditPassword) {
      const newPassword = decryptObject(
        state?.data?.password ?? globalConstants.EMPTY_STRING,
        process.env.REACT_APP_PASSWORD_ENCRYPTED_KEY
      );
      setFieldValue("password", newPassword);
    } else {
      setFieldValue("password", globalConstants.EMPTY_STRING);
    }
  };

  useEffect(() => {
    if (state.currentStore.id !== currentStore.id) {
      navigate(routesPathConstants.SETTINGS_USERS);
    }
    // eslint-disable-next-line
  }, [currentStore]);

  //-------Return AddNewUser Component
  return (
    <>
      <Topbar
        title={state?.isEdit ? "Edit Employee" : "New Employee"}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.SETTINGS_USERS,
        }}
      />
      <Row className="m-0 w-100">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={
            isEditPassword
              ? addUserWithPasswordValidationSchema
              : addUserValidationSchema
          }
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Row className="m-0">
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="firstName"
                    label="First Name"
                    placeHolder="Enter First Name"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="lastName"
                    label="Last Name"
                    placeHolder="Enter Last Name"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectField
                    label="Role"
                    name="role"
                    placeHolder={"Select Role"}
                    options={
                      user?.role === ROLES.STORE_MANAGER
                        ? userConstants.USER_ROLES.filter(
                            (item) => item.value !== ROLES.STORE_OWNER
                          )
                        : userConstants.USER_ROLES
                    }
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="email"
                    label="Email"
                    placeHolder="Enter Email"
                  />
                </Col>
                <Col md={6} className="mb-3 d-flex gap-2">
                  <InputPasswordField
                    name="password"
                    label="Pin"
                    placeHolder="Enter Pin"
                    className="w-100"
                    isDisabled={state?.isEdit && !isEditPassword}
                    maxLength={6}
                  />{" "}
                  {state?.isEdit
                    ? !isEditPassword && (
                        <Button
                          type="button"
                          label={buttonNameConstants.EDIT}
                          handleClick={() => {
                            handleEditPasswordClick(setFieldValue);
                          }}
                          className="edit-password-button"
                        />
                      )
                    : ""}
                </Col>
                {isEditPassword ? (
                  <Col md={6} className="mb-3">
                    <div className="d-flex w-100 gap-2">
                      <InputPasswordField
                        name="confirmPassword"
                        label="Confirm Pin"
                        className="w-100"
                        placeHolder="Enter Confirm Pin"
                        maxLength={6}
                      />
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        handleClick={() => {
                          handleEditPasswordClick(setFieldValue);
                        }}
                        className="edit-password-button"
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                      ></Button>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="companyUserId"
                    label="Employee ID"
                    placeHolder="Employee ID (Auto Generated)"
                    disabled={true}
                  />
                </Col>
                {values.role === ROLES.EMPLOYEE && (
                  <>
                    <Col md={6} className="mb-3">
                      <MultiSelectField
                        label="Store"
                        name="stores"
                        placeHolder={"Select Store"}
                        options={storeList?.map((store) => {
                          return {
                            value: store.id,
                            label: store.storeName,
                          };
                        })}
                      />
                    </Col>
                    <Col md={6}></Col>
                  </>
                )}
                {isEditPassword && <Col md={6}></Col>}
                <Col md={6} className="mb-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      handleCancelButton();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                    isDisabled={
                      isSpinnerEnabled(spinnerArray, userConstants.ADD_USER) ||
                      isSpinnerEnabled(spinnerArray, userConstants.UPDATE_USER)
                    }
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={
                      state?.isEdit
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.SAVE
                    }
                    isDisabled={
                      isSpinnerEnabled(spinnerArray, userConstants.ADD_USER) ||
                      isSpinnerEnabled(spinnerArray, userConstants.UPDATE_USER)
                    }
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  ); //-------AddNewUser component end here
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  store: state.store,
  user: state.authentication.user,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addNewUser: (data) => dispatch(userActions.addNewUser(data)),
  updateUser: (data) => dispatch(userActions.updateUser(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddNewUser);

import React from "react";
import { Field } from "formik";

const RadioButton = ({ radioButtons, name }) => {
  return (
    <div
      role="group"
      aria-labelledby="my-radio-group"
      className="d-flex align-items-center gap-2"
    >
      {radioButtons.map((button) => {
        return (
          <label className="d-flex align-items-center gap-2 cursor-pointer">
            <Field
              type="radio"
              name={name}
              value={button}
              className="cursor-pointer radio-button"
            />
            {button}
          </label>
        );
      })}
    </div>
  );
};

export default RadioButton;

import qz from "qz-tray";
import { KEYUTIL, KJUR, hextorstr, stob64 } from "jsrsasign";

import {
  inventoryConstants,
  toastMessages,
  toastType,
} from "../../../constants";
import { customToast } from "../../utility";
import { printZebraLabel } from "../ZebraPrinter/zebraPrinter";

qz.security.setCertificatePromise(function (resolve, reject) {
  resolve(process.env.REACT_APP_QZ_DIGITAL_CERTIFICATE);
});

var privateKey = process.env.REACT_APP_QZ_PRIVATE_KEY;

qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    try {
      var pk = KEYUTIL.getKey(privateKey);
      var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
      sig.init(pk);
      sig.updateString(toSign);
      var hex = sig.sign();
      resolve(stob64(hextorstr(hex)));
    } catch (err) {
      console.error(err);
      reject(err);
    }
  };
});

// Function to connect to QZ Tray
export const connectToQZTray = () => {
  return qz.websocket
    .connect()
    .then(() => {})
    .catch((error) => {
      console.log("Connect To QZ Error : ", error);
    });
};

// Function to disconnect from QZ Tray
export const disconnectFromQZTray = () => {
  return qz.websocket
    .disconnect()
    .then(() => {})
    .catch((error) => {
      console.log("Disconnect To QZ Error : ", error);
    });
};

export const printTestReceiptData = (printer, receiptData) => {
  connectToQZTray().then(async () => {
    await qz.printers
      .find(printer)
      .then(function (printer) {
        // Print EPoS data to the default printer
        var configuration = qz.configs.create(printer);
        qz.print(configuration, receiptData)
          .then(() => {
            disconnectFromQZTray();
          })
          .catch(function (error) {
            customToast(error.toString(), toastType.ERROR);
            console.error("Error printing:", error);
          });
      })
      .catch(function (error) {
        customToast(error.toString(), toastType.ERROR);
        console.error("Error during find printer:", error);
      });
  });
};

//Function to print receipt data
export const printReceiptData = async (receiptData, printer) => {
  if (printer) {
    printTestReceiptData(printer.generalSettings.productName, receiptData);
  } else {
    customToast(toastMessages.NO_PRINTER_DEVICE_FOUND, toastType.ERROR);
  }
};

export const printZebraData = async (
  labelData,
  labelDesignType,
  storeLogo,
  printer,
  deactivateSpinner
) => {
  if (!printer) {
    return customToast(toastMessages.NO_PRINTER_DEVICE_FOUND, toastType.ERROR);
  }

  deactivateSpinner(inventoryConstants.LABELS_PRINTING);
  try {
    await connectToQZTray();
    const printerName = await qz.printers.find(printer?.printerName);
    // Print EPoS data to the default printer
    let configuration = qz.configs.create(printerName);
    for (let i = 0; i < labelData.length; i++) {
      const { printQuantity } = labelData[i];
      for (let j = 0; j < printQuantity; j++) {
        const label = printZebraLabel(labelDesignType, labelData[i], storeLogo);
        try {
          await qz.print(configuration, label);
        } catch (error) {
          customToast(error.toString(), toastType.ERROR);
          console.error("Error printing:", error);
        }
      }
    }
  } catch (error) {
    customToast(error.toString(), toastType.ERROR);
    console.error("Error during find printer:", error);
  } finally {
    disconnectFromQZTray();
  }
};

import { companyService } from "../../services";
import { customToast } from "../../shared/utility";
import { custom, success } from "./actionsUtilities";
import { systemActions } from "../actions";
import {
  toastType,
  toastMessages,
  systemConstants,
  companyContants,
  routesPathConstants,
} from "../../constants";

//-------Add new customer
const addNewCompany = (data, redirect = false) => {
  return (dispatch) => {
    dispatch(
      custom(companyContants.ADD_COMPANY, systemConstants.SPINNER_ACTIVATE)
    );
    companyService
      .addNewCompany(data)
      .then(
        (response) => {
          customToast(
            toastMessages.COMPANY_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          if (redirect) {
            dispatch(
              systemActions.pageRedireaction(
                routesPathConstants.ADMIN_COMPANIES
              )
            );
          }
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.ADMIN_COMPANIES)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            companyContants.ADD_COMPANY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
//-------Get all customer
const getAllCompanies = () => {
  return (dispatch) => {
    dispatch(
      custom(
        companyContants.GET_ALL_COMPANIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    companyService
      .getAllCompanies()
      .then(
        (response) => {
          dispatch(success(response, companyContants.GET_ALL_COMPANIES));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            companyContants.GET_ALL_COMPANIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete Customer
const deleteCompany = (companyId) => {
  return (dispatch) => {
    dispatch(
      custom(companyContants.DELETE_COMPANY, systemConstants.SPINNER_ACTIVATE)
    );
    companyService
      .deleteCompany(companyId)
      .then(
        () => {
          customToast(
            toastMessages.COMPANY_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getAllCompanies());
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            companyContants.DELETE_COMPANY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Update Customer
const updateCompany = (data) => {
  return (dispatch) => {
    dispatch(
      custom(companyContants.UPDATE_COMPANY, systemConstants.SPINNER_ACTIVATE)
    );
    companyService
      .updateCompany(data)
      .then(
        (response) => {
          customToast(
            toastMessages.COMPANY_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.ADMIN_COMPANIES)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            companyContants.UPDATE_COMPANY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Company Actions
export const companyActions = {
  addNewCompany,
  getAllCompanies,
  deleteCompany,
  updateCompany,
};

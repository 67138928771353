import {
  postRequest,
  getRequest,
  deleteRequest,
  putRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get all devices
const getAllStoreDevices = () => {
  return getRequest(`get-store-payment-devices`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add new device
const addDevice = (data) => {
  return postRequest("add-payment-device", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add new device
const updateDevice = (id, data) => {
  return putRequest(`update-store-payment-devices/${id}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------delete device
const deleteDevice = (data) => {
  return deleteRequest(`add-payment-device?deviceId=${data}`).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Check device status
const checkDeviceStatus = (serialNumber) => {
  return getRequest(`get-connected-poi/?serialNumber=${serialNumber}`).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Check device status
const getStoreDevices = (storeId) => {
  return getRequest(`get-store-payment-devices/?storeId=${storeId}`).then(
    (response) => response.data.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Export device Services
export const deviceService = {
  addDevice,
  deleteDevice,
  getAllStoreDevices,
  checkDeviceStatus,
  updateDevice,
  getStoreDevices,
};

import React, { useState, useEffect } from "react";
import {
  StepDownNumberIcon,
  StepUpNumberIcon,
} from "../../../assets/icons/iconsProvider";

const VerticalInputNumberField = (props) => {
  const {
    sku,
    placeHolder,
    IconImage,
    type = "text",
    pattern,
    label,
    disabled = false,
    className,
    value: propValue,
    onChange,
    maxValue: propMaxValue,
  } = props;

  // State to store the value of the input field
  const [value, setValue] = useState(propValue || 0);
  const [maxValue, setMaxValue] = useState(propMaxValue || Infinity);
  useEffect(() => {
    // Update local state when the propValue changes
    setValue(propValue || 0);
  }, [propValue]);

  // Function to handle incrementing the value
  const handleIncrement = () => {
    const fieldValue = Number(value);
    if (fieldValue < maxValue) {
      setValue(fieldValue + 1);
      onChange(sku, fieldValue + 1);
    }
  };

  // Function to handle decrementing the value
  const handleDecrement = () => {
    const fieldValue = Number(value);
    if (fieldValue > 0) {
      setValue(fieldValue - 1);
      onChange(sku, fieldValue - 1);
    }
  };
  const inputWidth = value?.toString().length;
  return (
    <div className="vertical-number-field-container">
      <button type="button" disabled={disabled} onClick={handleIncrement}>
        <StepUpNumberIcon className="p-0" />
      </button>
      <input
        className={`vertical-number-field ${
          inputWidth === 2
            ? "vertical-number-field-2-digit"
            : inputWidth === 3
            ? "vertical-number-field-3-digit"
            : inputWidth === 4
            ? "vertical-number-field-4-digit"
            : ""
        } `}
        autoComplete="off"
        placeholder={placeHolder}
        type="number"
        value={value}
        onChange={(e) => {
          const newValue = parseInt(e.target.value) || 0;
          if (newValue <= maxValue) {
            setValue(newValue);
            onChange(sku, newValue);
          }
        }}
      />
      <button type="button" disabled={disabled} onClick={handleDecrement}>
        <StepDownNumberIcon className="p-0" />
      </button>
    </div>
  );
};

export default VerticalInputNumberField;

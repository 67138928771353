export const systemUserConstants = {
  GET_ALL_SYSTEM_USERS: "GET_ALL_SYSTEM_USERS",
  GET_SYSTEM_USER_BY_ID: "GET_SYSTEM_USER_BY_ID",
  ADD_NEW_SYSTEM_USER: "ADD_NEW_SYSTEM_USER",
  DELETE_SYSTEM_USER: "DELETE_SYSTEM_USER",
  UPDATE_SYSTEM_USER: "UPDATE_SYSTEM_USER",
  ADMIN_SYSTEMUSERS: "ADMIN_SYSTEMUSERS",
  USER_ROLES: [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "store-owner",
      label: "Store Owner",
    },
  ],
};

import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";
import { HideIcon, ShowIcon } from "../../../assets/icons/iconsProvider";

//-------InputPasswordField component start here
const InputPasswordField = (props) => {
  const {
    name,
    placeHolder,
    IconImage,
    label,
    className = "",
    isDisabled = false,
    maxLength = 50,
    autoComplete = "new-password",
  } = props;
  //-------Usestates
  const [PasswordHide, setHidePassword] = useState({
    icon: <ShowIcon className="password-show-icon" />,
    showPassword: false,
  });

  //-------Handle password hide click
  const handlePasswordHideClick = () => {
    setHidePassword({
      ...PasswordHide,
      showPassword: !PasswordHide.showPassword,
      icon: PasswordHide.showPassword ? <ShowIcon /> : <HideIcon />,
    });
  };

  //-------Return InputPasswordField Component
  return (
    <div className={` ${className}`}>
      <label className="field-label" htmlFor={name}>
        {label && label}
      </label>
      <div
        className={`input-field-wrapper  ${
          isDisabled && "disabled-input-field"
        }`}
      >
        {IconImage && <IconImage className="icon-image" />}
        <Field
          className={`input-textfield ${
            IconImage || "input-textfield-without-icon"
          } ${isDisabled && "disabled-input-field"}`}
          type={PasswordHide.showPassword ? "text" : "password"}
          id={name}
          name={name}
          placeholder={placeHolder}
          maxLength={maxLength}
          disabled={isDisabled}
          autoComplete={autoComplete}
        />
        <button
          type="button"
          onClick={handlePasswordHideClick}
          className="passwordHideIcon"
        >
          {PasswordHide.icon}
        </button>
      </div>
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------InputPasswordField component end here
};

//-------Export InputPasswordField Component
export default InputPasswordField;

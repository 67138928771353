import {
  postRequest,
  getRequest,
  deleteRequest,
  putRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Add new customer
const addNewCustomer = (data) => {
  return postRequest(`customer/${data.companyId}/${data.store.id}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Get all Customer from server
const getAllCustomer = (companyId, storeId) => {
  return getRequest(`customer/${companyId}/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Delete Customer
const deleteCustomer = (id, companyId, storeId) => {
  return deleteRequest(`customer/${companyId}/${storeId}/${id}`).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update user
const updateCustomer = (data) => {
  return putRequest(
    `customer/${data.companyId}/${data.store.id}/${data.id}`,
    data
  ).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update user
const bulkUploadCustomers = (companyId, storeId, data) => {
  return postRequest(
    `bulk-upload-customers/${companyId}/${storeId}`,
    data
  ).then(
    (response) => response?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Get getCustomerById from server
const getCustomerById = (companyId, storeId, customerId) => {
  return getRequest(
    `customer/${companyId}/${storeId}/${customerId}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export customer Services
export const customerService = {
  addNewCustomer,
  getAllCustomer,
  deleteCustomer,
  updateCustomer,
  bulkUploadCustomers,
  getCustomerById,
};

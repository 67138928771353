import { customToast } from "../../shared/utility";
import { custom, success } from "./actionsUtilities";
import { tradeInDiscountService } from "../../services";
import { systemActions, storeActions } from "../actions";
import {
  toastType,
  toastMessages,
  systemConstants,
  tradeInDiscount,
  globalConstants,
  routesPathConstants,
} from "../../constants";

//-------------------------------------------------------------- TradeIn portion-----------------------------------------------------------------
//-------getMargin
const getMargin = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(tradeInDiscount.GET_ALL_MARGIN, systemConstants.SPINNER_ACTIVATE)
    );
    tradeInDiscountService
      .getMargin(storeId)
      .then(
        (response) => {
          dispatch(success(response, tradeInDiscount.GET_ALL_MARGIN));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.GET_ALL_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------addGlobalMargin
const addGlobalMargin = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.ADD_GLOBAL_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .addGlobalMargin(storeId, data)
      .then(
        (response) => {
          customToast(toastMessages.GLOBAL_MARGIN_ADDED, toastType.SUCCESS);
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.ADD_GLOBAL_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const addGlobalMarkupPercentage = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.ADD_CATEGORY_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .addMarkupPercentage(storeId, data)
      .then(
        (response) => {
          customToast(toastMessages.GLOBAL_MARKUP_UPDATED, toastType.SUCCESS);
          dispatch(getMarkup(data.store.id));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.ADD_CATEGORY_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const addMarkupPercentage = (
  storeId,
  data,
  toggleCustomTradeModal,
  resetSearch
) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.ADD_CATEGORY_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .addMarkupPercentage(storeId, data)
      .then(
        (response) => {
          customToast(
            toastMessages.CUSTOM_MARKUP_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
          toggleCustomTradeModal(true);
        },
        (error) => {
          customToast(error, toastType.ERROR);
          resetSearch();
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.ADD_CATEGORY_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const addCategoryMargin = (
  storeId,
  data,
  toggleCustomTradeModal,
  resetSearch
) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.ADD_CATEGORY_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .addCategoryMargin(storeId, data)
      .then(
        (response) => {
          customToast(
            toastMessages.CUSTOM_TRADE_IN_MARGIN_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getMargin(storeId));
          toggleCustomTradeModal(true);
        },
        (error) => {
          customToast(error, toastType.ERROR);
          resetSearch();
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.ADD_CATEGORY_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
//-------Delete Customer
const deleteCategoryMargin = (storeId, id) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.DELETE_CATEGORY_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .deleteCategoryMargin(storeId, id)
      .then(
        () => {
          customToast(
            toastMessages.CATEGORY_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getMargin(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.DELETE_CATEGORY_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete Customer
const deleteCustomMarkup = (storeId, id) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.DELETE_CATEGORY_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .deleteCustomMarkup(storeId, id)
      .then(
        () => {
          customToast(
            toastMessages.MARKUP_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getMarkup(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.DELETE_CATEGORY_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------updateCategoryMargin
const updateCategoryMargin = (storeId, data, toggleCustomTradeModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.UPDATE_CATEGORY_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .updateCategoryMargin(storeId, data)
      .then(
        (response) => {
          customToast(
            toastMessages.CATEGORY_UPDATE_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getMargin(storeId));
          toggleCustomTradeModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.UPDATE_CATEGORY_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------updateCustomMarkup
const updateCustomMarkup = (storeId, data, toggleCustomTradeModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.UPDATE_CATEGORY_MARGIN,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .updateCustomMarkup(storeId, data)
      .then(
        (response) => {
          customToast(
            toastMessages.MARKUP_UPDATE_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
          toggleCustomTradeModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.UPDATE_CATEGORY_MARGIN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------getMarkup
const getMarkup = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(tradeInDiscount.GET_ALL_MARKUP, systemConstants.SPINNER_ACTIVATE)
    );
    tradeInDiscountService
      .getMarkup(storeId)
      .then(
        (response) => {
          dispatch(success(response, tradeInDiscount.GET_ALL_MARKUP));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.GET_ALL_MARKUP,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//addMonthlyBudget
const addMonthlyBudget = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.ADD_MONTHLY_BUDGET,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .addMonthlyBudget(storeId, data)
      .then(
        (response) => {
          customToast(toastMessages.MONTHLY_BUDGET_ADDED, toastType.SUCCESS);
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.ADD_MONTHLY_BUDGET,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

// ---------------------------------------------------------------discount ---------------------------------------------------------------
const addCategoryTypeDiscount = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(tradeInDiscount.ADD_DISCOUNT, systemConstants.SPINNER_ACTIVATE)
    );
    tradeInDiscountService
      .addCategoryTypeDiscount(storeId, data)
      .then(
        (response) => {
          customToast(toastMessages.CATEGORY_DISCOUNT_ADDED, toastType.SUCCESS);
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
          dispatch(
            systemActions.pageRedireaction(
              routesPathConstants.SETTINGS_DISCOUNT
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.ADD_DISCOUNT,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
//-------Delete
const deleteCategoryTypeDiscount = (storeId, discountId) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.DELETE_CATEGORYTYPE_DISCOUNT,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .deleteCategoryTypeDiscount(storeId, discountId)
      .then(
        () => {
          customToast(toastMessages.DELETED_SUCCESSFULLY, toastType.SUCCESS);
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.DELETE_CATEGORYTYPE_DISCOUNT,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------updateCategoryTypeDiscount
const updateCategoryTypeDiscount = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        tradeInDiscount.UPDATE_CATEGORYTYPE_DISCOUNT,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    tradeInDiscountService
      .updateCategoryTypeDiscount(storeId, data)
      .then(
        (response) => {
          customToast(
            toastMessages.CATEGORY_DISCOUNT_UPDATE_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(
              routesPathConstants.SETTINGS_DISCOUNT
            )
          );
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.UPDATE_CATEGORYTYPE_DISCOUNT,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get All getAllDiscount
const getAllDiscount = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(tradeInDiscount.GET_ALL_DISCOUNT, systemConstants.SPINNER_ACTIVATE)
    );
    tradeInDiscountService
      .getAllDiscount(storeId)
      .then(
        (response) => {
          dispatch(success(response, tradeInDiscount.GET_ALL_DISCOUNT));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            tradeInDiscount.GET_ALL_DISCOUNT,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Authentication Actions
export const tradeInDiscountActions = {
  addGlobalMargin,
  addCategoryMargin,
  deleteCategoryMargin,
  updateCategoryMargin,
  addMonthlyBudget,
  addCategoryTypeDiscount,
  deleteCategoryTypeDiscount,
  updateCategoryTypeDiscount,
  getAllDiscount,
  deleteCustomMarkup,
  updateCustomMarkup,
  getMarkup,
  addMarkupPercentage,
  addGlobalMarkupPercentage,
  getMargin,
};

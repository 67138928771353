import { customerService } from "../../services";
import { custom, success } from "./actionsUtilities";
import { customToast, downloadCSV } from "../../shared/utility";
import { systemActions, transactionActions } from "../actions";
import {
  toastType,
  toastMessages,
  systemConstants,
  customerConstants,
  routesPathConstants,
} from "../../constants";

//-------Add new customer
const addNewCustomer = (data, redirect = false) => {
  return (dispatch) => {
    dispatch(
      custom(customerConstants.ADD_CUSTOMER, systemConstants.SPINNER_ACTIVATE)
    );
    customerService
      .addNewCustomer(data)
      .then(
        (response) => {
          customToast(
            toastMessages.CUSTOMER_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          if (redirect) {
            dispatch(
              systemActions.pageRedireaction(routesPathConstants.CUSTOMERS)
            );
          }
          dispatch(getAllCustomer(data.companyId, data?.store?.id));
          dispatch(transactionActions.toggleAddCustomerModal(false));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.ADD_CUSTOMER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
//-------Get all customer
const getAllCustomer = (companyId, storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.GET_ALL_CUSTOMERS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    customerService
      .getAllCustomer(companyId, storeId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_ALL_CUSTOMERS));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.GET_ALL_CUSTOMERS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete Customer
const deleteCustomer = (id, companyId, storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.DELETE_CUSTOMER,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    customerService
      .deleteCustomer(id, companyId, storeId)
      .then(
        () => {
          customToast(
            toastMessages.CUSTOMER_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getAllCustomer(companyId, storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.DELETE_CUSTOMER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Update Customer
const updateCustomer = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.UPDATE_CUSTOMER,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    customerService
      .updateCustomer(data)
      .then(
        (response) => {
          customToast(
            toastMessages.CUSTOMER_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.CUSTOMERS)
          );
          dispatch(getAllCustomer(data.companyId, data?.store?.id));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.UPDATE_CUSTOMER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get customer by Id
const getCustomerById = (companyId, storeId, customerId) => {
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.GET_CUSTOMER_BY_ID,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    customerService
      .getCustomerById(companyId, storeId, customerId)
      .then(
        (response) => {
          dispatch(success(response, customerConstants.GET_CUSTOMER_BY_ID));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.GET_CUSTOMER_BY_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
//-------emptyCustomerById
const emptyCustomerById = () => {
  return (dispatch) => {
    dispatch(success({}, customerConstants.EMPTY_CUSTOMER_BY_ID));
  };
};

//-------Add new customer
const bulkUploadCustomers = (companyId, storeId, importCustomerMisc) => {
  const { validateCsvDataRes, handleHideModal } = importCustomerMisc;
  return (dispatch) => {
    dispatch(
      custom(
        customerConstants.BULK_UPLOAD_CUSTOMERS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    customerService
      .bulkUploadCustomers(companyId, storeId, validateCsvDataRes.validData)
      .then(
        (response) => {
          // showing toast and handeling download csv for invalid records
          let toastMessage =
            validateCsvDataRes.validData.length +
            customerConstants.CUSTOMERS_IMPORTED_SUCCESSFULLY;
          if (validateCsvDataRes.invalidData.length || response.data.length) {
            toastMessage =
              validateCsvDataRes.validData.length -
              response.data.length +
              customerConstants.CUSTOMERS_IMPORTED_SUCCESSFULLY +
              "; " +
              (validateCsvDataRes.invalidData.length + response.data.length) +
              customerConstants.CUSTOMERS_NOT_IMPORTED;
            // downloading the csv file of invalid records
            downloadCSV(
              [
                // front end filtered invalid data
                ...validateCsvDataRes.invalidData.map(
                  (invalidCustomerRecord) => ({
                    ...invalidCustomerRecord,
                  })
                ),
                // mapping the backend values used in front end csv file
                ...response.data.map((invalidCustomerRecord) => {
                  return {
                    "First Name": invalidCustomerRecord.firstName,
                    "Last Name": invalidCustomerRecord.lastName,
                    Email: invalidCustomerRecord.email,
                    "Phone Number": invalidCustomerRecord.mobile,
                    "Driver's License": invalidCustomerRecord.driverLicense,
                    "Street 1": invalidCustomerRecord.line1,
                    "Street 2": invalidCustomerRecord.line2,
                    City: invalidCustomerRecord.city,
                    State: invalidCustomerRecord.state,
                    Zip: invalidCustomerRecord.zip,
                    "Customer Credit": invalidCustomerRecord.currentBalance,
                    "Rejection Reason": invalidCustomerRecord.errorMessage,
                  };
                }),
              ],
              "Invalid_Customers"
            );
          }
          customToast(toastMessage, toastType.SUCCESS);
          dispatch(getAllCustomer(companyId, storeId));
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            customerConstants.BULK_UPLOAD_CUSTOMERS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const emptyDuplicateRecords = () => {
  return (dispatch) => {
    dispatch(success([], customerConstants.BULK_UPLOAD_CUSTOMERS));
  };
};

//-------Export Authentication Actions
export const customerActions = {
  addNewCustomer,
  getAllCustomer,
  deleteCustomer,
  updateCustomer,
  getCustomerById,
  emptyCustomerById,
  bulkUploadCustomers,
  emptyDuplicateRecords,
};

import React, { useRef } from "react";
import Select from "react-select";

import { selectFieldStyle } from "../../../assets/scss/style";

const SelectDropDown = ({
  options,
  handleChange,
  defaultPageSize,
  className,
  menuPlacement = "top",
  noOptionsMessage = "No Data Found",
  placeHolder = "",
  isClearable = false,
  styles = "",
  isSearchable = false,
  menuPortalTarget = document.querySelector("body"),
  value,
  label = "",
  isDisabled = false,
}) => {
  const selectRef = useRef(null);
  return (
    <div className={isDisabled && "disable-dropdown-icon"}>
      {label && <label className="field-label">{label}</label>}
      <Select
        ref={selectRef}
        className={className}
        options={options}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        isSearchable={isSearchable}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={styles || selectFieldStyle}
        onChange={(option) => {
          handleChange(option);
          selectRef.current?.blur();
        }}
        menuPlacement={menuPlacement}
        defaultValue={defaultPageSize}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        placeholder={placeHolder}
        isClearable={isClearable}
        menuPortalTarget={menuPortalTarget}
        value={value}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectDropDown;

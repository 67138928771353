export const sidebarSelectFieldStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: "3em",
    borderRadius: "1em",
    fontSize: ".5em",
    boxShadow: "none",
    borderColor: state.isFocused ? "#6a18ed" : "transparent",
    backgroundColor: "transparent",
    border: "1px solid white",
    marginTop: "0.5em",
    marginBottom: "0.5em",
    marginRight: "0.5em",
    marginLeft: "0.5em",
    "&:hover": {
      border: "1px solid #6a18ed",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "20em",
    zIndex: "20",
    "::-webkit-scrollbar": {
      width: ".3em",
      height: ".3em",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#b7b5ba",
      borderRadius: "100px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#6a18ed",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: "20",
    borderRadius: ".75em",
    overflow: "hidden",
    padding: ".75em",
    width: "15rem",
  }),
  option: (base, state) => ({
    ...base,
    borderRadius: "0.5em",
    marginBottom: ".25em",
    marginTop: ".25em",
    backgroundColor: state.isSelected ? "#6a18ed" : "transparent",
    color: state.isSelected ? "#fcfcfc" : "#6b6970",
    "&:hover": {
      color: "#222024",
      backgroundColor: "rgb(106, 24, 237, 0.3)",
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .3s",
    color: "white",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    "&:hover": {
      color: "white",
    },
  }),
  placeholder: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: "white",
      fontSize: ".5em",
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
    width: "8em",
    fontSize: ".9em",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: ".3em",
    paddingLeft: ".3em",
    div: {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
  }),
};

import React from "react";
import { Modal, Row, Col } from "react-bootstrap";

import ProductImage from "../../../../assets/images/productimage.png";
import Button from "../../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";

const ShrinkItemModal = (props) => {
  const {
    itemShrinkModal,
    handleNoButtonClick,
    handleYesButtonClick,
    toggleItemShrinkModal,
  } = props;

  return (
    <Modal
      size="md"
      animation={true}
      centered
      backdrop="static"
      show={itemShrinkModal}
      onHide={toggleItemShrinkModal}
    >
      <Modal.Body>
        <Row className="m-0 p-0">
          <Col md={3} className="img-Div-stolen d-flex p-0">
            <img src={ProductImage} alt="" />
          </Col>
          <Col md={9} className="m-0 pe-0">
            <Row className="m-0 p-0 h-100">
              <Col md={12} className="p-0 mt-2 stolen-card-text ">
                Was these Products lost or stolen?
              </Col>
              <Col md={12} className="mt-auto p-0 d-flex gap-2 ">
                <Button
                  type="button"
                  label={buttonNameConstants.NO}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  className="w-100"
                  handleClick={() => {
                    handleNoButtonClick();
                  }}
                ></Button>
                <Button
                  type="button"
                  className="w-100"
                  handleClick={() => {
                    handleYesButtonClick();
                  }}
                  label={buttonNameConstants.YES}
                ></Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ShrinkItemModal;

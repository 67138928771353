import CustomTooltip from "../toolTipComponent/CustomTooltip";

const TableCustomHeader = ({ id, label, tooltopText, className = "" }) => (
  <>
    <CustomTooltip
      className={`tooltip-for-text ${className}`}
      anchorSelect={`#${id}`}
    >
      {tooltopText}
    </CustomTooltip>
    <span className="d-flex " id={id}>
      {label}
    </span>
  </>
);

export default TableCustomHeader;

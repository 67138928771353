import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { dashboardConstants } from "../../../constants";

const DashboardIconCardSkeleton = ({
  cardStyle = dashboardConstants.HORIZONTAL_CARD_STYLE,
}) => {
  return cardStyle === dashboardConstants.HORIZONTAL_CARD_STYLE ? (
    <Row className="">
      <Col md={12}>
        <Skeleton className="dashboard-icon-skeleton" />
      </Col>
      <Col md={6} className="card-wrapper-wallet d-flex flex-column">
        <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
        <Skeleton className="heading-skeleton dashboard-card-heading" />
      </Col>
      <Col md={6} className="d-flex align-items-center justify-content-end">
        <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
      </Col>
    </Row>
  ) : cardStyle === dashboardConstants.VERTICAL_CARD_STYLE ||
    cardStyle === dashboardConstants.VERTICAL_CARD_STYLE_2ND ? (
    <div
      className={`square-card-wrapper square-card-wrapper-min-height  ${
        cardStyle === dashboardConstants.VERTICAL_CARD_STYLE
          ? "me-md-1"
          : cardStyle === dashboardConstants.VERTICAL_CARD_STYLE_2ND
          ? "ms-md-1"
          : ""
      } mb-2`}
    >
      <Skeleton className="dashboard-icon-skeleton" />
      <div className="d-flex flex-column justify-content-center">
        <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
        <Skeleton className="heading-skeleton dashboard-card-heading" />
      </div>
      <div className="d-flex gap-1 align-items-center">
        <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
      </div>
    </div>
  ) : cardStyle === dashboardConstants.REMAINING_CARD_STYLE ? (
    <div className={`dashboard-card-wrapper`}>
      <Row className="m-0">
        <Col
          md={6}
          className="card-wrapper-wallet remaing-purchase-power-min-height  d-flex flex-column justify-content-center align-items-start p-0"
        >
          <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
          <Skeleton className="heading-skeleton dashboard-card-heading" />
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center">
          <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
        </Col>
      </Row>
    </div>
  ) : cardStyle === dashboardConstants.GRAPH_CARD_STYLE ? (
    <Col md={12}>
      <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
      <div className="dashboard-chart-wrapper">
        <Skeleton className="graph-skeleton" />
      </div>
      <div className="d-flex justify-content-end w-75 ms-auto gap-2 ">
        <Skeleton className="heading-skeleton dashboard-card-heading" />
        <Skeleton className="heading-skeleton dashboard-card-heading" />
      </div>
    </Col>
  ) : cardStyle === dashboardConstants.TOP_SELLING_ITEMS ? (
    <Row className="m-0">
      <Col md={12} className="mb-1">
        <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
      </Col>
      <Col md={12}>
        <Skeleton className="graph-skeleton" />
      </Col>
    </Row>
  ) : (
    ""
  );
};

export default DashboardIconCardSkeleton;

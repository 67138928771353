import React, { useState } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";

import { stringToPascal } from "../../utility";
import UserDropdown from "./UserDropdown";
import NotificationMenu from "./NotificationsMenu";
import { InfoIcon, UserIcon } from "../../../assets/icons/iconsProvider";

const TopbarUserContainer = (props) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <div className="d-flex justify-content-center align-items-center">
      <NotificationMenu />
      <a
        href="http://collectpos.com/"
        className="topbar-info-icon"
        target="_blank"
      >
        <InfoIcon />
      </a>
      <p className="p-0 m-0 px-3">
        {stringToPascal(props?.user?.firstName.toUpperCase() ?? "loading...")}
      </p>
      <UserIcon setIsShown={setIsShown} isShown={isShown} />
      <Tooltip
        id="user-dropdown"
        noArrow={true}
        className="tooltip user-dropdown-con"
        place="bottom-end"
        clickable
        render={() => (
          <UserDropdown
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          />
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopbarUserContainer);

import { updateObject } from "../../shared/utility";
import { globalConstants, itemOrganizationConstants } from "../../constants";

const initialState = {
  customTypes: [],
  customCategories: [],
  customSubCategories: [],
};

export const itemOrganization = (state = initialState, action) => {
  switch (action.type) {
    //-------get custom product types
    case itemOrganizationConstants.GET_CUSTOM_PRODUCT_TYPES +
      globalConstants.SUCCESS:
      return updateObject(state, {
        customTypes: action.data,
      });
    //-------get custom categories
    case itemOrganizationConstants.GET_CUSTOM_CATEGORIES +
      globalConstants.SUCCESS:
      return updateObject(state, {
        customCategories: action.data,
      }); //-------get custom sub categories
    case itemOrganizationConstants.GET_CUSTOM_SUB_CATEGORIES +
      globalConstants.SUCCESS:
      return updateObject(state, {
        customSubCategories: action.data,
      });
    //-------Default State
    default:
      return state;
  }
};

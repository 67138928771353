import React from "react";

import { parseToThousandSeperatorDecimalNumber } from "../../utility";
import { tradeInDiscountConstant } from "../../../constants";

const TableCustomPriceRangeField = ({ row }) => {
  // Check if both minPriceRange and maxPriceRange are zero
  return (
    <>
      {row.minPriceRange === tradeInDiscountConstant.DEFAULT_MIN_RANGE &&
      row.maxPriceRange === tradeInDiscountConstant.DEFAULT_MAX_RANGE ? (
        <span className="users-cell-max-size ps-4">All</span>
      ) : (
        <>
          <span className="users-cell-max-size">
            ${parseToThousandSeperatorDecimalNumber(row.minPriceRange)}
          </span>
          -
          <span className="users-cell-max-size ps-1">
            ${parseToThousandSeperatorDecimalNumber(row.maxPriceRange)}
          </span>
        </>
      )}
    </>
  );
};

export default TableCustomPriceRangeField;

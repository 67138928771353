import { encodeQueryParam, stringToBase64 } from "../shared/utility";
import { putRequest } from "../system/serverCall";
import {
  deleteRequestWithData,
  getRequest,
  postRequest,
  pricechartingApiRequest,
  scryfallApiRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

const pricechartingBaseUrl = process.env.REACT_APP_PRICECHARTING_BASE_URL;
const scryfallBaseUrl = process.env.REACT_APP_SCRYFALL_BASE_URL;
const priceChartingApiKey = process.env.REACT_APP_PRICECHARTING_API_KEY;

//-------Get Store Details from server
const getAllInventory = (storeId) => {
  return getRequest(`store-inventory/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------update inventory
const updateInventory = (storeId, data) => {
  return putRequest(`inventory?store=${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------qualityShrink inventory
const quantityShrink = (data) => {
  return postRequest(`shrink-tracking`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
const getInventoryHistory = (storeId, type, startDate, endDate, cat) => {
  return getRequest(
    `transaction-history/${storeId}?type=${type}&startDate=${startDate}&endDate=${endDate}${
      cat ? `&category=${cat}` : ""
    }`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Store Inventory summary
const getStoreInventorySummary = (storeId) => {
  return getRequest(`inventory-summary-store/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Store Inventory summary
const deleteInventory = (data, storeId) => {
  return deleteRequestWithData(`inventory?store=${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Product data search from priceCharting Api
const getPricechartingApiProducts = (queryData) => {
  return pricechartingApiRequest(
    `${pricechartingBaseUrl}products?t=${priceChartingApiKey}&q=${queryData}`
  ).then(
    (response) => response?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Product data search from priceCharting Api
const getPricechartingProductById = (id) => {
  return pricechartingApiRequest(
    `${pricechartingBaseUrl}product?t=${priceChartingApiKey}&id=${id}`
  ).then(
    (response) => response?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getInventoryByPricechartingId = (storeId, data) => {
  return postRequest(
    `inventory-by-pricecharting-id/?store=${storeId}`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const addInventory = (data) => {
  return postRequest(`inventory?store=${data?.store?.id}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getSkuNumber = (storeId) => {
  return getRequest(`get-sku/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getTags = (storeId) => {
  return getRequest(`tags/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const addDefaultTags = (storeId, data) => {
  return postRequest(`tags/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Product data search from priceCharting Api
const getscryfallApiProducts = (queryData) => {
  return scryfallApiRequest(
    `${scryfallBaseUrl}cards/search?q=${queryData}&unique=prints`
  ).then(
    (response) => response?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Get Product data search from priceCharting Api
const getscryfallApiProductById = (cardId) => {
  return scryfallApiRequest(`${scryfallBaseUrl}cards/${cardId}`).then(
    (response) => response?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const addBatchInventory = (data) => {
  return postRequest(`inventory-batch/${data[0].store.id}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const addBatchInventoryDraft = (data) => {
  return postRequest(
    `inventory-batch/${data?.store?.id}/?isInventoryDraft=true`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const importFileInventory = (data) => {
  if (data.length) {
    return postRequest(
      `inventory-batch/${data[0].store.id}?isImportApiCall=true`,
      data
    ).then(
      (response) => response?.data?.data,
      (error) => serverResponseHandler.handleHTTPResponses(error.response)
    );
  } else {
    // if no valid inventory

    return new Promise((resolve, reject) => {
      const data = {
        reqInvalidInventory: [],
        updatedInventory: [],
      };
      // You can perform asynchronous operations here if needed
      // Once your asynchronous operations are done, you can resolve the Promise
      resolve(data);
    });
  }
};

const addPriceChangeThreshold = (data) => {
  return postRequest(`threshold/${data.store.id}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getPriceChangeThreshold = (storeId) => {
  return getRequest(`threshold/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getPriceChanges = (storeId) => {
  return getRequest(`price-changes/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const dismissProduct = (storeId, data) => {
  return putRequest(`price-changes/${storeId}?isDismissUpdate=true`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
const handleThresholdCsvData = (storeId) => {
  return postRequest(`price-changes/${storeId}`, null).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const updatePriceChangeProductPrices = (storeId, data) => {
  return putRequest(`inventory-batch/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const removeProductFromPriceChanges = (storeId, data) => {
  return putRequest(`price-changes/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getPricechartingProductImage = (data) => {
  return postRequest(
    `inventory-by-pricecharting-id?store=null&isUrl=true`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getPaginatedInventoryHistory = (storeId, gridState) => {
  return getRequest(
    `transaction-history/${storeId}?search=${
      gridState.searchQuery
    }&pageNumber=${gridState.pageIndex}&pageSize=${
      gridState.pageSize
    }&filters=${stringToBase64(gridState.filters)}&sortFilter=${stringToBase64(
      gridState.sortFilter
    )}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getPaginatedInventory = (storeId, gridState) => {
  return getRequest(
    `store-inventory-serverside/${storeId}/?search=${
      gridState.searchQuery
    }&pageSize=${gridState.pageSize}&pageNumber=${
      gridState.pageIndex
    }&filters=${stringToBase64(gridState.filters)}&sortFilter=${stringToBase64(
      gridState.sortFilter
    )}`
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Export inventory Services
export const inventoryService = {
  getAllInventory,
  updateInventory,
  quantityShrink,
  getInventoryHistory,
  getStoreInventorySummary,
  deleteInventory,
  getPricechartingApiProducts,
  getPricechartingProductById,
  addInventory,
  getInventoryByPricechartingId,
  getSkuNumber,
  getTags,
  getscryfallApiProducts,
  getscryfallApiProductById,
  addBatchInventory,
  importFileInventory,
  addPriceChangeThreshold,
  getPriceChangeThreshold,
  getPriceChanges,
  dismissProduct,
  handleThresholdCsvData,
  updatePriceChangeProductPrices,
  removeProductFromPriceChanges,
  getPricechartingProductImage,
  addBatchInventoryDraft,
  getPaginatedInventory,
  getPaginatedInventoryHistory,
  addDefaultTags,
};

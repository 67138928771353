import React from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import MainWrapper from "../MainWrapper";
import routes from "../../../system/routes";
import AppLayout from "../../layout/AppLayout";
import {
  ROLES,
  routesPathConstants,
  STORE_OWNER_NOT_ALLOWED_ROUTES,
  STORE_MANAGER_NOT_ALLOWED_ROUTES,
  EMPLOYEE_NOT_ALLOED_ROUTES,
} from "../../../constants";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../system/globalEnums";

const RouterWrapper = ({ isStoreActive, activeCardPaymentType, ...props }) => {
  const { role } = props.user;

  // as dashboard is not allowed to employee, so update the default and unknown route for employee
  if (role === ROLES.EMPLOYEE) {
    routes.forEach((route) => {
      if (
        (route.path === routesPathConstants.DEFAULT_PATH ||
          route.path === routesPathConstants.UNKNOWN_PATH) &&
        route.protected
      ) {
        route.component = <Navigate to={routesPathConstants.SELL_AND_TRADE} />;
      }
    });
  }

  const privateRoutes = (
    <>
      {routes
        .filter((route) => {
          // STORE OWNER NOT ALLOWED
          return role !== ROLES.ADMIN
            ? STORE_OWNER_NOT_ALLOWED_ROUTES.includes(route.path)
              ? false
              : true
            : true;
        })
        .filter((route) => {
          // STORE MANAGER NOT ALLOWED ROUTES
          return role !== ROLES.ADMIN && role !== ROLES.STORE_OWNER
            ? STORE_MANAGER_NOT_ALLOWED_ROUTES.includes(route.path)
              ? false
              : true
            : true;
        })
        .filter((route) => {
          // EMPLOYEE NOT ALLOWED ROUTES
          return role !== ROLES.ADMIN &&
            role !== ROLES.STORE_OWNER &&
            role !== ROLES.STORE_MANAGER
            ? EMPLOYEE_NOT_ALLOED_ROUTES.includes(route.path)
              ? false
              : true
            : true;
        })
        .filter((route) => {
          // if store is inActive only allow these routes, at first render the isStoreActive is undefined so in this all the routes are allowd
          return isStoreActive === undefined ||
            isStoreActive ||
            role === ROLES.ADMIN
            ? true
            : route.path === routesPathConstants.BILLING ||
              route.path === routesPathConstants.PAYOUTS ||
              route.path === routesPathConstants.USER_INFORMATION ||
              route.path === routesPathConstants.DASHBOARD ||
              route.path === routesPathConstants.UNKNOWN_PATH ||
              route.path === routesPathConstants.DEFAULT_PATH
            ? true
            : false;
        })
        .filter((route) => {
          return route.path.toLowerCase() ===
            routesPathConstants.SETTINGS_DEVICES.toLowerCase()
            ? activeCardPaymentType ===
                STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE || false
            : true;
        })
        .map(
          (route, index) =>
            route.protected && (
              <Route key={index} path={route.path} element={route.component} />
            )
        )}
    </>
  );

  const publicRoutes = (
    <>
      {routes.map(
        (route, index) =>
          !route.protected && (
            <Route key={index} path={route.path} element={route.component} />
          )
      )}
    </>
  );

  return (
    <MainWrapper>
      <Routes>
        {props.isAuthenticated ? (
          <Route path="/" element={<AppLayout />}>
            {privateRoutes}
          </Route>
        ) : (
          publicRoutes
        )}
      </Routes>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    user: state.authentication.user,
    isStoreActive: state.store.currentStore.isStoreActive,
    activeCardPaymentType: state.store.currentStore.activeCardPaymentType,
  };
};

export default connect(mapStateToProps)(React.memo(RouterWrapper));

import { deviceService } from "../../services";
import { customToast } from "../../shared/utility";
import { custom, failure, success } from "./actionsUtilities";
import {
  systemConstants,
  deviceConstants,
  toastType,
  toastMessages,
  routesPathConstants,
} from "../../constants";
import { systemActions } from "./system.action";

//-------Get all devices
const getAllStoreDevices = () => {
  return (dispatch) => {
    dispatch(
      custom(deviceConstants.GET_ALL_DEVICES, systemConstants.SPINNER_ACTIVATE)
    );
    deviceService
      .getAllStoreDevices()
      .then(
        (response) => {
          dispatch(success(response || [], deviceConstants.GET_ALL_DEVICES));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.GET_ALL_DEVICES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get all devices
const getStoreDevices = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        deviceConstants.GET_STORE_DEVICES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    deviceService
      .getStoreDevices(storeId)
      .then(
        (response) => {
          dispatch(success(response || [], deviceConstants.GET_STORE_DEVICES));
        },
        (error) => {
          dispatch(failure([], deviceConstants.GET_STORE_DEVICES));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.GET_STORE_DEVICES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add new device
const addDevice = (data) => {
  return (dispatch) => {
    dispatch(
      custom(deviceConstants.ADD_DEVICE, systemConstants.SPINNER_ACTIVATE)
    );
    deviceService
      .addDevice(data)
      .then(
        () => {
          customToast(
            toastMessages.DEVICE_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.ADMIN_DEVICES)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(deviceConstants.ADD_DEVICE, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

//-------Add new device
const updateDevice = (id, data) => {
  return (dispatch) => {
    dispatch(
      custom(deviceConstants.UPDATE_DEVICE, systemConstants.SPINNER_ACTIVATE)
    );
    deviceService
      .updateDevice(id, data)
      .then(
        () => {
          customToast(
            toastMessages.DEVICE_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getAllStoreDevices());
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.ADMIN_DEVICES)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.UPDATE_DEVICE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add new device
const updateDeviceName = (id, data) => {
  return (dispatch) => {
    dispatch(
      custom(deviceConstants.UPDATE_DEVICE, systemConstants.SPINNER_ACTIVATE)
    );
    deviceService
      .updateDevice(id, data)
      .then(
        () => {
          customToast(
            toastMessages.DEVICE_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getStoreDevices(data.store.id));
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.SETTINGS_DEVICES)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.UPDATE_DEVICE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete device
const deleteDevice = (data) => {
  return (dispatch) => {
    dispatch(
      custom(deviceConstants.DELETE_DEVICE, systemConstants.SPINNER_ACTIVATE)
    );
    deviceService
      .deleteDevice(data)
      .then(
        () => {
          customToast(
            toastMessages.DEVICE_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getAllStoreDevices());
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.DELETE_DEVICE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete device
const checkDeviceStatus = (serialNumber) => {
  return (dispatch) => {
    dispatch(
      custom(
        deviceConstants.CHECK_DEVICE_STATUS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    deviceService
      .checkDeviceStatus(serialNumber)
      .then(
        (response) => {
          if (response.data.data) {
            if (response.data.data.POIStatus[0].POIState === "CONNECTED") {
              customToast(
                `Device is ${response.data.data.POIStatus[0].POIState}`,
                toastType.SUCCESS
              );
            } else {
              customToast(
                `Device is ${response.data.data.POIStatus[0].POIState}`,
                toastType.ERROR
              );
            }
          } else {
            customToast(toastMessages.NO_DEVICE_FOUND, toastType.ERROR);
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.CHECK_DEVICE_STATUS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Authentication Actions
export const deviceActions = {
  addDevice,
  deleteDevice,
  getAllStoreDevices,
  checkDeviceStatus,
  updateDevice,
  getStoreDevices,
  updateDeviceName,
};

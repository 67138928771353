import {
  BILLING_TYPES_ENUMS,
  STORE_CARD_PAYMENT_TYPE_ENUMS,
} from "../system/globalEnums";

export const storeConstants = {
  GET_STORES: "GET_STORES",
  ADD_STORE: "ADD_STORE",
  GET_STORE_OWNERS: "GET_STORE_OWNERS",
  SET_CURRENT_STORE: "SET_CURRENT_STORE",
  UPLOAD_LOGO: "UPLOAD_LOGO",
  UPDATE_RECEIPT_DETAILS: "UPDATE_RECEIPT_DETAILS",
  BILLING_TYPE: [
    {
      value: BILLING_TYPES_ENUMS.MONTHLY,
      label: BILLING_TYPES_ENUMS.MONTHLY,
    },
    {
      value: BILLING_TYPES_ENUMS.YEARLY,
      label: BILLING_TYPES_ENUMS.YEARLY,
    },
  ],
  GET_USER_NOTIFICATIONS: "GET_USER_NOTIFICATIONS",
  PAYOUT_INFORMATION: "PAYOUT_INFORMATION",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  STORE_TYPE: [
    {
      value: STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE,
      label: "Verifone",
    },
    {
      value: STORE_CARD_PAYMENT_TYPE_ENUMS.THIRD_PARTY,
      label: "Third-Party",
    },
  ],
};

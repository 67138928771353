import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";
import CustomSelectField from "../customSelectField/CustomSelectField";

//-------SelectField component start here
const SelectField = (props) => {
  const {
    name,
    options,
    placeHolder,
    label,
    isSearchable = true,
    onChangeValue,
    isDisabled = false,
    isClearable = false,
    isSelectedValueAsPlaceholder = true,
  } = props;
  //-------Return InputTextField Component
  return (
    <div>
      {label && (
        <label className="field-label" htmlFor={name}>
          {label}
        </label>
      )}
      <div>
        <Field
          name={name}
          id={name}
          isSearchable={isSearchable}
          placeholder={placeHolder}
          isMulti={false}
          component={CustomSelectField}
          options={options}
          onChangeValue={onChangeValue}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isSelectedValueAsPlaceholder={isSelectedValueAsPlaceholder}
        />
      </div>
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------SelectField component end here
};

//-------Export SelectField Component
export default SelectField;

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";

import Button from "../../../../shared/components/Buttons/Button";
import SearchField from "../../../../shared/components/searchField/SearchField";
import {
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
} from "../../../../constants";
import { parseToThousandSeperatorDecimalNumber } from "../../../../shared/utility";

const GiftCardPayment = ({
  amountToPay,
  transactionData,
  setPaymentMethod,
  handleGiftCardPayment,
  giftCardDetails,
  getGiftCardDetails,
  emptyGiftCardDetails,
}) => {
  const [giftCardItem, setGiftCardItem] = useState({
    barcode: globalConstants.EMPTY_STRING,
  });
  const getGiftCard = async () => {
    getGiftCardDetails(transactionData?.store?.id, giftCardItem.barcode);
  };
  const debouncedMakeHttpRequest = useDebouncedCallback(getGiftCard, 2000);

  const handleSearchFieldChange = (value) => {
    setGiftCardItem({ ...giftCardItem, barcode: value });
    debouncedMakeHttpRequest();
  };
  //-------handle hide modal
  const handleCancelPayment = () => {
    setGiftCardItem({
      barcode: globalConstants.EMPTY_STRING,
    });
    emptyGiftCardDetails();
  };
  return (
    <div className="payment-cash-card-wrapper">
      <Row className="m-0">
        <Col
          md={12}
          className="d-flex justify-content-between align-items-center"
        >
          <span className="cash-heading">Gift Card</span>
          {amountToPay < transactionData.totalAmountToPay && (
            <span className="item-count-chip">
              ${parseToThousandSeperatorDecimalNumber(amountToPay)}
            </span>
          )}
        </Col>
        <Col md={12} className="mt-3">
          <div className="receipt-total-wrapper d-flex flex-column gap-1">
            <span>Receipt Total</span>
            <span>${Number(transactionData.totalAmountToPay).toFixed(2)}</span>
          </div>
        </Col>
        <Col md={12} className="mt-3">
          <div className=" d-flex flex-column gap-1">
            <SearchField
              placeHolder="Enter/Scan Gift Card Barcode"
              className="barcode-payment-field"
              onChange={(e) => handleSearchFieldChange(e.target.value)}
            />
          </div>
        </Col>
        {giftCardDetails && (
          <Col
            md={12}
            className="mt-3 d-flex justify-content-between align-items-center balance-wrapper"
          >
            <span>Balance</span>
            <span>
              $
              {parseToThousandSeperatorDecimalNumber(
                giftCardDetails?.giftCardBalance
              )}
            </span>
          </Col>
        )}
        <Col md={12} className="mb-3 d-flex gap-2 mt-3">
          <Button
            type="button"
            label={buttonNameConstants.BACK}
            handleClick={() => {
              setPaymentMethod("");
              handleCancelPayment();
            }}
            buttonType={buttonTypeConstants.GHOST_BUTTON}
            className="w-100"
          ></Button>
          <Button
            type="button"
            className="w-100"
            label={buttonNameConstants.COMPLETE}
            handleClick={() => handleGiftCardPayment()}
            isDisabled={
              giftCardDetails && giftCardDetails.giftCardBalance > 0
                ? false
                : true
            }
          ></Button>
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardPayment;

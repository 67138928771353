import pokemon from "pokemontcgsdk";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import BarcodeReader from "react-barcode-reader";
import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash"; // Import lodash here

import { inventoryService } from "../../../../services";
import { inventoryActions } from "../../../../redux/actions";
import SearchField from "../../../../shared/components/searchField/SearchField";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import {
  createCombinedList,
  getDefaultFilterType,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  handleFilterPricechartingSearchResult,
  getCustomSubCategoriesOptionList,
  handleGetFilterLoadOptions,
} from "../../../../shared/utility";
import {
  CrossIcon,
  SearchIcon,
  BlueBoxIcon,
  LoadingIndicator,
} from "../../../../assets/icons/iconsProvider";
import {
  globalConstants,
  inventoryConstants,
  categoryDropdownConstants,
} from "../../../../constants";
import {
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";

pokemon.configure({ apiKey: process.env.REACT_APP_POKEMON_API_KEY });

const SearchInventory = (props) => {
  //-------props
  const {
    customTypes,
    setSearchText,
    productReturn,
    asyncSelectRef,
    setProductsList,
    customCategories,
    customSubCategories,
    defaultProductType = PRODUCT_TYPES_ENUMS.VIDEO_GAME,
  } = props;

  const currentSearchValue = useRef("");
  const [loading, setloading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(customTypes, defaultProductType)
  );
  const [productCategory, setProductCategory] = useState(
    globalConstants.EMPTY_STRING
  );
  const [productSubCategory, setProductSubCategory] = useState(
    globalConstants.EMPTY_STRING
  );

  //------create select option list
  const createSelectOptionList = (filteredOptions) => {
    const selectOptions = filteredOptions?.map((option) => ({
      value: {
        id: option.id,
        sourceApi: option.sourceApi,
        genre: option.genre,
        name: option.name,
        price: option.price,
        productType: option.productType,
        category_name: option.category,
        imgUrl: "",
        upc: option.upc,
      },
      label: (
        <div className="search-option-wrapper">
          <span className="console-name-react-select">{option.category}</span>
          <span>{option.name}</span>
        </div>
      ),
    }));
    return selectOptions;
  };

  //-------load option
  const loadOptions = async (
    inputValue,
    searchProductType,
    productCategory,
    productSubCategory,
    callback
  ) => {
    const searchValue = inputValue;
    let priceChartingOptions = [];
    let pokemonOptions = [];
    let scryfallOptions = [];
    if (
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.OTHER
    ) {
      priceChartingOptions = await handleFilterPricechartingSearchResult(
        searchValue,
        inventoryService,
        searchProductType,
        productCategory,
        productSubCategory
      );
    }
    if (
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD
    ) {
      const seachPokemonCrad = searchValue.replaceAll(" ", "*");
      pokemonOptions = await pokemon.card
        .where({
          q: `name:${seachPokemonCrad} OR subtypes:${seachPokemonCrad} OR subtypes:${seachPokemonCrad} OR set:${seachPokemonCrad}`,
        })
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD
    ) {
      scryfallOptions = await inventoryService
        .getscryfallApiProducts(searchValue)
        .then(
          (response) => {
            return response?.data;
          },
          (error) => {
            //customToast(error, toastType.ERROR);
            return [];
          }
        );
    }

    const consoleNames = customCategories
      .filter((item) => !item.isCustom)
      ?.map((obj) => obj.name);
    const filteredPriceChartingOptions =
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL
        ? priceChartingOptions.filter(
            (obj) =>
              consoleNames.includes(obj["console-name"]) ||
              obj.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          )
        : searchProductType?.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME
        ? priceChartingOptions.filter((obj) =>
            consoleNames.includes(obj["console-name"])
          )
        : searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD
        ? priceChartingOptions.filter(
            (obj) => obj.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          )
        : [];

    const combinedList = [
      ...createCombinedList(
        filteredPriceChartingOptions,
        inventoryConstants.PRICECHARTING_API
      ),
      ...createCombinedList(
        pokemonOptions || [],
        inventoryConstants.POKEMON_API
      ),
      ...createCombinedList(
        scryfallOptions || [],
        inventoryConstants.SCRYFALL_API
      ),
    ];
    const combinedListWithProductType = createProductType(combinedList);
    let filteredOptions = handleGetFilterLoadOptions(
      combinedListWithProductType,
      searchProductType,
      productCategory,
      productSubCategory
    );

    if (
      currentSearchValue.current === inputValue ||
      currentSearchValue.current === ""
    ) {
      if (currentSearchValue.current !== "") {
        const selectedOptions = createSelectOptionList(filteredOptions);
        setloading(false);
        productReturn(selectedOptions, searchData);
      } else {
        // This block is executed if the search was cleared while fetching data
        // Optionally, clear the results or handle as needed
        setProductsList([]);
        setloading(false);
      }
    }
  };

  //---------handle scan
  const handleScan = async (data) => {
    if (asyncSelectRef.current) {
      asyncSelectRef.current.clearValue();
    }
    setSearchText(data);
    setSearchData(data);
    if (data) {
      debounce(data, searchProductType, productCategory, productSubCategory);
      setloading(true);
      currentSearchValue.current = data;
    }
  };

  const handleError = (error) => {};

  const createProductType = (product) => {
    const newProductObject = product.map((item) => {
      if (
        item.genre === TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
        item.genre === TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ||
        item.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
      ) {
        return { ...item, productType: PRODUCT_TYPES_ENUMS.TRADING_CARD };
      } else {
        return { ...item, productType: PRODUCT_TYPES_ENUMS.VIDEO_GAME };
      }
    });
    return newProductObject;
  };

  // eslint-disable-next-line
  const debounce = useCallback(
    _.debounce(
      (value, searchProductType, productCategory, productSubCategory) => {
        loadOptions(
          value,
          searchProductType,
          productCategory,
          productSubCategory
        );
      },
      2000
    ),
    []
  );
  const clearSearchText = () => {
    debounce.cancel();
    currentSearchValue.current = "";
    setSearchData("");
    setSearchText("");
    setProductsList([]);
    setloading(false);
  };
  useEffect(() => {
    if (searchData && searchData.trim() !== "") {
      setloading(true);
      debounce(
        searchData,
        searchProductType,
        productCategory,
        productSubCategory
      );
    } // eslint-disable-next-line
  }, [searchProductType, productCategory, productSubCategory]);

  useEffect(
    () => {
      setSearchProductType(
        getDefaultFilterType(customTypes, defaultProductType)
      );
    }, // eslint-disable-next-line
    [customTypes]
  );
  return (
    <>
      <div>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>
      <Row>
        <Col md={12} className="mt-2 mb-2">
          <SearchField
            value={searchData}
            IconImage={SearchIcon}
            BlueBoxIcon={BlueBoxIcon}
            clearSearchText={clearSearchText}
            CrossIcon={searchData ? CrossIcon : ""}
            placeHolder="Search Item Name, UPC or SKU"
            LoadingIndicator={loading && LoadingIndicator}
            onChange={(e) => {
              const inputValue = e.target.value;
              currentSearchValue.current = inputValue; // Update ref to the current input value
              if (!inputValue) {
                setSearchData(inputValue);
                setSearchText(inputValue);
                setProductsList([]);
                setloading(false);
                return;
              }
              debounce(
                e.target.value,
                searchProductType,
                productCategory,
                productSubCategory
              );
              setSearchData(e.target.value);
              setSearchText(e.target.value);
              setloading(true);
            }}
          />
        </Col>
        <Col md={12} className="d-flex align-items-center gap-2 ">
          <CategoriesDropdown
            placeholder="Type"
            isClearable={false}
            className="type-dropdown"
            noOptionsMessage="No Type Found"
            SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
            value={searchProductType}
            options={getCustomTypesOptionList(
              customTypes.filter((item) => !item.isCustom)
            )}
            handleChange={(selectedOption) => {
              setSearchProductType(selectedOption);
              setProductCategory(globalConstants.EMPTY_STRING);
              setProductSubCategory(globalConstants.EMPTY_STRING);
            }}
          />
          <CategoriesDropdown
            placeholder="Category"
            value={productCategory}
            className="category-dropdown"
            noOptionsMessage="No Category Found"
            SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
            options={getCustomCategoriesOptionList(
              customCategories.filter((item) => !item.isCustom),
              searchProductType?.value,
              searchProductType?.label
            )}
            handleChange={(selectedOption) => {
              setProductCategory(
                selectedOption || globalConstants.EMPTY_STRING
              );
            }}
          />
          <CategoriesDropdown
            options={getCustomSubCategoriesOptionList(
              customSubCategories,
              productCategory?.value,
              searchProductType?.label,
              undefined,
              true
            )}
            value={productSubCategory}
            handleChange={(selectedOption) => {
              setProductSubCategory(
                selectedOption || globalConstants.EMPTY_STRING
              );
            }}
            className="category-dropdown"
            noOptionsMessage="No SubCategory Found"
            placeholder="Sub-Category"
            SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
          />
        </Col>
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customSubCategories: state.itemOrganization.customSubCategories,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(SearchInventory);

export const editableTableSelectFieldStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: "3em",
    maxWidth: "18em",
    borderRadius: "0.75em",
    boxShadow:
      "0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01)",
    backgroundColor: "#f4f4f4",
    borderColor: state.isFocused ? "#6a18ed" : "transparent",
    "&:hover": {
      border: "1px solid #6a18ed",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "20em",
    "::-webkit-scrollbar": {
      width: ".3em",
      height: ".3em",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#b7b5ba",
      borderRadius: "100px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#6a18ed",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 5,
    borderRadius: ".75em",
    overflow: "hidden",
    padding: ".75em",
  }),
  option: (base, state) => ({
    ...base,
    borderRadius: "0.5em",
    marginBottom: ".25em",
    marginTop: ".25em",
    backgroundColor: state.isSelected ? "#6a18ed" : "transparent",
    color: state.isSelected ? "#fcfcfc" : "#6b6970",
    "&:hover": {
      color: "#222024",
      backgroundColor: "rgb(106, 24, 237, 0.3)",
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .3s",
    color: state.isFocused ? "#6a18ed" : "#6b6970",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      opacity: "0.5",
    };
  },
};

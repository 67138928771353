import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import React, { useEffect, useState, useRef } from "react";

import { deviceActions } from "../../../redux/actions";
import Button from "../../../shared/components/Buttons/Button";
import Topbar from "../../../shared/components/topbar/Topbar";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import SelectDropDown from "../../../shared/components/selectDropdown/SelectDropdown";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import { editableTableSelectFieldStyle } from "../../../assets/scss/style/editableTableSelectField";
import {
  toastType,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  deviceConstants,
} from "../../../constants";
import {
  AddIcon,
  TickIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  ActionDotsIcons,
  CheckStatusIcon,
  BlueBoxIcon,
} from "../../../assets/icons/iconsProvider";
import {
  customToast,
  handleHighlightSelectedRow,
  handleDehighlightSelectedRow,
  isSpinnerEnabled,
} from "../../../shared/utility";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------AdminDevices component start here
const AdminDevices = (props) => {
  const {
    store,
    device,
    deleteDevice,
    updateDevice,
    spinnerArray,
    checkDeviceStatus,
    getAllStoreDevices,
  } = props;
  //------use Navigate to navigate to other screens
  const navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rowBeingEditing, setRowBeingEditing] = useState({});
  const [gridState, setGridState] = useState(defaultGridState);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();

  //-------useRef
  let numOfRenders = useRef(0);
  //-------device reducer
  const { allDevices } = device;
  //-------store Reducer
  const { stores } = store;

  //-------Handle AdminDevices edit button click
  const handleEditClick = (row) => {
    navigate(routesPathConstants.ADMIN_DEVICES_EDIT, {
      state: {
        data: row,
        isEdit: true,
      },
    });
  };

  //-------Handle AdminDevices delete button click
  const handleDeleteClick = (row) => {
    if (row?.id) {
      deleteDevice(row.id);
      // reset pagination
      numOfRenders.current++;
    }
  };

  //-------Handle add button click
  const handleAddClick = () => {
    navigate(routesPathConstants.ADMIN_DEVICES_ADD, {
      state: {
        isEdit: false,
      },
    });
  };

  //-------handle Row Double Click
  const handleRowDoubleClick = (row) => {
    if (rowBeingEditing?.id) {
      handleDehighlightSelectedRow(rowBeingEditing?.id);
      setRowBeingEditing({});
    } else {
      handleHighlightSelectedRow(row);
      handleDehighlightSelectedRow(rowBeingEditing?.id);
      setRowBeingEditing(row);
    }
  };

  //-------handle Edit Cancel Click
  const handleEditCancelClick = (row) => {
    handleDehighlightSelectedRow(rowBeingEditing?.id);
    setRowBeingEditing({});
  };

  //-------handle Check Device Status
  const handleCheckStatus = (row) => {
    if (row) {
      checkDeviceStatus(row.serialNumber);
    }
  };

  //-------handle Edit Submit Click
  const handleEditSubmitClick = () => {
    const { id, serialNumber, poiId, store } = rowBeingEditing;

    if (!serialNumber || !poiId) {
      const message = !serialNumber
        ? toastMessages.SERIAL_NUMBER_REQUIRED
        : toastMessages.POIID_REQUIRED;

      customToast(message, toastType.ERROR);
      return;
    }

    if (id) {
      updateDevice(id, {
        serialNumber,
        poiId,
        store: {
          id: store.id,
          storeName: store.storeName,
        },
      });

      handleDehighlightSelectedRow(id);
      setRowBeingEditing({});
    }
  };

  //-------Hanlde Table Sorting
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );
    setDefaultSortFieldId(column.id);
  };

  //-------Update GridState
  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------Handle searchfield
  const handleFilterData = (e) => {
    const filterList = allDevices.filter((row) => {
      return (
        String(row.serialNumber + row.poiId + row.store.storeName)
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset pagination
    numOfRenders.current++;
    return filterList;
  };
  const clearSearchText = () => {
    setSearchText("");
  };
  //-------define column headers for the devices table
  const columnHeaders = [
    {
      id: "storeName",
      name: "Store Name",
      selector: (row) => row.store.storeName,
      cell: (row) => {
        return row.id === rowBeingEditing?.id ? (
          <SelectDropDown
            options={stores?.map((store) => ({
              value: store.id,
              label: store.storeName,
            }))}
            menuPlacement="bottom"
            noOptionsMessage="No Store Found"
            placeholder="Select Store"
            defaultPageSize={{
              value: row?.store?.id,
              label: row?.store?.storeName,
            }}
            styles={editableTableSelectFieldStyle}
            handleChange={(e) => {
              setRowBeingEditing({
                ...rowBeingEditing,
                store: {
                  id: e.value,
                  storeName: e.label,
                },
              });
            }}
          />
        ) : (
          <span
            className="inventory-cell-max-size"
            onClick={() => {
              handleRowDoubleClick(row);
            }}
          >
            {row?.store.storeName}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "serialNumber",
      name: "Serial Number",
      selector: (row) => row.serialNumber,
      cell: (row) => {
        return row.id === rowBeingEditing?.id ? (
          <input
            type="text"
            defaultValue={row?.serialNumber}
            className="input-textfield input-textfield-without-icon  editable-table-row-fields"
            onChange={(e) => {
              setRowBeingEditing(() => ({
                ...rowBeingEditing,
                serialNumber: e.target.value,
              }));
            }}
          ></input>
        ) : (
          <span
            className="inventory-cell-max-size"
            onClick={() => {
              handleRowDoubleClick(row);
            }}
          >
            {row.serialNumber}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "poiId",
      name: "Nickname",
      selector: (row) => row.poiId,
      cell: (row) => {
        return row.id === rowBeingEditing?.id ? (
          <input
            type="text"
            defaultValue={row?.poiId}
            className="input-textfield input-textfield-without-icon  editable-table-row-fields"
            onChange={(e) => {
              setRowBeingEditing(() => ({
                ...rowBeingEditing,
                poiId: e.target.value,
              }));
            }}
          ></input>
        ) : (
          <span
            className="inventory-cell-max-size"
            onClick={() => {
              handleRowDoubleClick(row);
            }}
          >
            {row.poiId}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return row.id === rowBeingEditing?.id ? (
          <>
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleEditSubmitClick(row)}
            >
              <TickIcon className="cursor-pointer text-primary" />
            </button>
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleEditCancelClick(row)}
            >
              <CrossIcon className="cursor-pointer" />
            </button>
          </>
        ) : (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                className="w-100"
                IconImage={EditIcon}
                label={buttonNameConstants.EDIT}
                handleClick={() => handleEditClick(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
              <Button
                className="w-100"
                IconImage={DeleteIcon}
                label={buttonNameConstants.DELETE}
                handleClick={() => handleDeleteClick(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
              <Button
                className="w-100"
                IconImage={CheckStatusIcon}
                label={buttonNameConstants.CHECK_STATUS}
                handleClick={() => handleCheckStatus(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];
  //-------useEffects
  useEffect(() => {
    getAllStoreDevices();
    // eslint-disable-next-line
  }, []);

  //-------useEffects
  useEffect(() => {
    setColumns(columnHeaders);
    // eslint-disable-next-line
  }, [rowBeingEditing]);

  //-------Return AdminDevices Component
  return (
    <>
      <Topbar title="Devices"></Topbar>
      <Row className="m-0 w-100 user-screen-wrapper">
        {isSpinnerEnabled(spinnerArray, deviceConstants.GET_ALL_DEVICES) ? (
          <>
            <Col md={5}>
              <DropdownSkeleton width={"100%"} />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-center gap-3"
            >
              <DropdownSkeleton />
            </Col>
          </>
        ) : (
          <>
            <Col md={5}>
              <SearchField
                placeHolder="Search"
                IconImage={SearchIcon}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                CrossIcon={searchText ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                BlueBoxIcon={BlueBoxIcon}
              />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-center gap-3"
            >
              <Button
                IconImage={AddIcon}
                handleClick={handleAddClick}
                label={buttonNameConstants.ADD}
              />
            </Col>
          </>
        )}
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          columns={columns}
          onSort={onTableSort}
          gridState={gridState}
          className="editable-table customer-table-height"
          key={numOfRenders.current}
          setGridState={updateGridState}
          defaultSortFieldId={defaultSortFieldId}
          data={searchText ? handleFilterData() : allDevices}
          total={searchText ? handleFilterData()?.length : allDevices?.length}
          onRowClicked={(row) => {
            handleRowDoubleClick(row);
          }}
        />
      </Row>
    </>
  );
}; //-------AdminDevices component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  device: state.device,
  store: state.store,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllStoreDevices: () => dispatch(deviceActions.getAllStoreDevices()),
  deleteDevice: (data) => {
    dispatch(deviceActions.deleteDevice(data));
  },
  checkDeviceStatus: (serialNumber) =>
    dispatch(deviceActions.checkDeviceStatus(serialNumber)),
  updateDevice: (id, data) => dispatch(deviceActions.updateDevice(id, data)),
});

//-------Export AdminDevices Component
export default connect(mapStateToProps, mapDispatchToProps)(AdminDevices);

export const dashboardConstants = {
  GET_AT_A_GLANCE: "GET_AT_A_GLANCE",
  UPDATE_PUCHASING_POWER: "UPDATE_PUCHASING_POWER",
  GET_STORE_HISTORY: "GET_STORE_HISTORY",
  GRAPH_START_DATE: "GRAPH_START_DATE",
  GRAPH_END_DATE: "GRAPH_END_DATE",
  DAILY: "daily",
  UPDATE_SALE_TAX: "UPDATE_SALE_TAX",
  HORIZONTAL_CARD_STYLE: "HORIZONTAL_CARD_STYLE",
  VERTICAL_CARD_STYLE: "VERTICAL_CARD_STYLE",
  REMAINING_CARD_STYLE: "REMAINING_CARD_STYLE",
  VERTICAL_CARD_STYLE_2ND: "VERTICAL_CARD_STYLE_2ND",
  GRAPH_CARD_STYLE: "GRAPH_CARD_STYLE",
  TOP_SELLING_ITEMS: "TOP_SELLING_ITEMS",
};

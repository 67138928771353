import Select from "react-select";
import {
  multiSelectFieldStyle,
  selectFieldStyle,
} from "../../../assets/scss/style";

const CustomSelectField = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder = "Select",
  onChangeValue,
  isDisabled,
  isClearable,
  isSelectedValueAsPlaceholder = true,
  isSearchable = true,
}) => {
  function onChange(option) {
    form.setFieldValue(
      field.name,
      option ? option.map((item) => item.value) : []
    );
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  const handleSelecValueChange = (option) => {
    form.setFieldValue(field.name, option?.value);
    if (onChangeValue) {
      onChangeValue(option?.value);
    }
  };

  if (!isMulti) {
    return (
      <Select
        options={options}
        name={field.name}
        value={
          options && isSelectedValueAsPlaceholder
            ? options.find((option) => option.value === field.value)
            : ""
        }
        onChange={(option) => handleSelecValueChange(option)}
        onBlur={field.onBlur}
        placeholder={placeholder}
        styles={selectFieldStyle}
        components={{
          IndicatorSeparator: () => null,
        }}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
      />
    );
  } else {
    return (
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        name={field.name}
        value={getValue()}
        onChange={onChange}
        options={options}
        isMulti={true}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={multiSelectFieldStyle}
        isSearchable={isSearchable}
      />
    );
  }
};

export default CustomSelectField;

import React, { useState, useEffect } from "react";
import CSVReader from "react-csv-reader";
import { Modal, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { customToast } from "../../../../shared/utility";
import Button from "../../../../shared/components/Buttons/Button";
import {
  UploadCsvIcon,
  DefaultSortingIcon,
  AsendingSortingIcon,
  DesendingSortingIcon,
} from "../../../../assets/icons/iconsProvider";
import {
  toastType,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";

const ImportTransactionHistory = (props) => {
  const {
    currentStore,
    emptyDuplicateRecords,
    duplicateHistoryRecords,
    bulkUploadTransactionHistory,
    importTransactionHistoryModal,
    toggleImportTransactionHistoryModal,
  } = props;

  //-------useState
  const [columns, setColumns] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [importDuplicateRecords, setImportDuplicateRecords] = useState("");

  const uploadCustomers = (csvData) => {
    let errors = [];
    let duplicateRecords = [];
    let transactionHistory = [];
    let required = [
      "Number",
      "Type",
      "Date",
      "Grand Total",
      "Cart QTY",
      "Pay Type",
      "Customer",
      "Store",
    ];

    //-------Get Headers of the csv file
    const headers = Object.keys(csvData[0]);

    //-------Check required headers found in the file
    required.forEach((key) => {
      if (!headers.includes(key)) {
        errors.push(`${key} is not found in file`);
      }
    });

    //-------If any headers missing show toast
    if (errors.length > 0) {
      customToast(errors[0], toastType.ERROR);
      return;
    }

    //-------If all headers found preapare data to send
    transactionHistory = csvData.map((item) => ({
      fileImportRecordId: item["Number"],
      type: item["Type"],
      date: item["Date"],
      grandTotal: item["Grand Total"],
      cartQty: item["Cart QTY"],
      payType: item["Pay Type"],
      customer: item["Customer"],
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
    }));

    //-------check if there any duplicate records in the file
    let allProductIds = transactionHistory.map(
      (record) => record.fileImportRecordId
    );
    allProductIds.forEach((value, index) => {
      allProductIds.lastIndexOf(value) !== index &&
        duplicateRecords.push(index);
    });

    //-------show duplicate data if found
    if (duplicateRecords?.length > 0) {
      setImportDuplicateRecords(
        duplicateRecords.map((duplicateIndex) => {
          return transactionHistory[duplicateIndex];
        })
      );
      customToast(toastMessages.DUPLICATE_NUMBER_RECORDS, toastType.ERROR);
      return;
    }

    handleHideModal();
    //-------if no duplicate data found upload the file
    bulkUploadTransactionHistory(currentStore?.id, transactionHistory);
  };

  //-------handle file upload
  const handleFileLoaded = (data, fileInfo) => {
    if (fileInfo.type === "text/csv") {
      uploadCustomers(data);
      data = null;
    } else {
      customToast(toastMessages.ONLY_CSV_ALLOWED, toastType.ERROR);
    }
  };

  //-------handle Hide Modal
  const handleHideModal = () => {
    toggleImportTransactionHistoryModal();
    emptyDuplicateRecords();
    setTimeout(() => {
      setImportDuplicateRecords("");
    }, 500);
  };

  //-------columns headers for shoing dublicate records
  const columnHeaders = [
    {
      id: "fileImportRecordId",
      name: "Number",
      selector: (row) => row.fileImportRecordId,
      cell: (row) => (
        <span className="duplicaterecords-cell-max-size">
          {row.fileImportRecordId}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "type",
      name: "Type",
      selector: (row) => row.type,
      cell: (row) => (
        <span className="duplicaterecords-cell-max-size w-100 text-left">{`${row.type}`}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
  ];

  //-------handle sorting of duplicate records
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  //-------format columns to send to data table with sorting icons
  const formattedColumns = columns.map((column) => {
    if (column.sortable) {
      return {
        ...column,
        name: (
          <div className=" d-flex w-100">
            {column.name}
            <div className="ml-2 d-flex justify-content-center align-items-center">
              {column.sortDirection === null && (
                <DefaultSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.ASC && (
                <AsendingSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.DESC && (
                <DesendingSortingIcon className="ms-1 cursor-pointer" />
              )}
            </div>
          </div>
        ),
      };
    } else {
      return column;
    }
  });

  //-------duplicate records find from backend
  useEffect(() => {
    if (duplicateHistoryRecords?.length > 0) {
      toggleImportTransactionHistoryModal();
      setImportDuplicateRecords(duplicateHistoryRecords);
    }
  }, [duplicateHistoryRecords]);

  //-------useEffect to set updated columns with actions
  useEffect(() => {
    if (currentStore) {
      setColumns(columnHeaders);
    }
  }, [currentStore]);

  return (
    <Modal
      show={importTransactionHistoryModal}
      size={importDuplicateRecords ? "lg" : "md"}
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Import Transaction History
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        {importDuplicateRecords?.length > 0 ? (
          <Row className="m-0">
            <Col md={12} className="px-0">
              Duplicate Records :
              <span className="fw-bold">
                {importDuplicateRecords?.length || 0}
              </span>
            </Col>
            <Col md={12} className="px-0">
              <DataTable
                className="import-customer-data-table"
                columns={formattedColumns}
                data={importDuplicateRecords}
                fixedHeader={true}
                onSort={onTableSort}
                defaultSortFieldId={defaultSortFieldId}
              />
            </Col>
          </Row>
        ) : (
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <label
                className="d-flex flex-column justify-content-center align-items-center gap-2 upload-csv-wrapper"
                style={{ height: "fit-content" }}
              >
                <UploadCsvIcon />
                Click or drag file to this area to upload
                <CSVReader
                  inputId="CSVReader"
                  key={fileInputKey}
                  inputStyle={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    opacity: 0,
                  }}
                  onFileLoaded={handleFileLoaded}
                  parserOptions={{ header: true, skipEmptyLines: true }}
                  accept=".csv"
                />
              </label>
            </Col>
            <Col md={7}></Col>
            <Col
              md={5}
              className="mt-3 d-flex justify-content-end align-items-center"
            >
              <Button
                type="button"
                label={buttonNameConstants.CANCEL}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                handleClick={() => handleHideModal()}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImportTransactionHistory;

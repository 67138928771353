import React from "react";
import Select, { components } from "react-select";

import { categoriesDropdownStyle } from "../../../assets/scss/style";
import {
  BoxIcon,
  BendArrowIcon,
  CategoryIcon,
  ClockIcon,
  TransactionIcon,
  InventoryConditionTypeIcon,
  SearchIcon,
  PosDeviceIcon,
} from "../../../assets/icons/iconsProvider";
import { categoryDropdownConstants, globalConstants } from "../../../constants";

const CategoriesDropdown = ({
  options,
  handleChange,
  noOptionsMessage,
  placeholder,
  disabled,
  isSearchable = true,
  SelectIconImage,
  isClearable = true,
  className,
  defaultValue = globalConstants.EMPTY_STRING,
  value,
  dropdownIndicator = true,
  label,
}) => {
  const CustomInput = (props) => (
    <div>
      <components.Input {...props} />
      {SelectIconImage === categoryDropdownConstants.CLOCK_ICON ? (
        <ClockIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.BOX_ICON ? (
        <BoxIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.TRANSACTION_ICON ? (
        <TransactionIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.INVENTORY_LIST_ICON ? (
        <InventoryConditionTypeIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.BEND_ICON ? (
        <BendArrowIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.INVENTORY_LIST_ICON ? (
        <InventoryConditionTypeIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.TRANSACTION_ICON ? (
        <TransactionIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.SEARCH_ICON ? (
        <SearchIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.POS_DEVICE_ICON ? (
        <PosDeviceIcon className="dropdown-icon" />
      ) : (
        <CategoryIcon className="dropdown-icon" />
      )}
    </div>
  );

  return (
    <div
      className={`category-dropdowns ${disabled && "disable-dropdown-icon"}`}
    >
      {label && <label className="field-label">{label}</label>}
      <Select
        className={`w-100 ${className}`}
        options={options}
        isDisabled={disabled}
        value={value}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={
          dropdownIndicator
            ? {
                IndicatorSeparator: () => null,
                Input: CustomInput,
              }
            : {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => "",
                Input: CustomInput,
              }
        }
        styles={categoriesDropdownStyle}
        onChange={handleChange}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        isClearable={isClearable}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default CategoriesDropdown;

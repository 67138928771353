import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import {
  isSpinnerEnabled,
  parseToDecimalNumber,
} from "../../../shared/utility";
import {
  storeActions,
  companyActions,
  systemActions,
} from "../../../redux/actions";
import SelectField from "../../../shared/components/selectField/SelectField";
import SwitchButton from "../../../shared/components/SwitchButton/SwitchButton";
import SelectFieldCity from "../../../shared/components/selectField/SelectFieldCity";
import InputTextField from "../../../shared/components/inputTextField/InputTextField";
import SelectFieldState from "../../../shared/components/selectField/SelectFieldState";
import ConfirmationModal from "../../Inventory/Items/ConfirmationModal/ConfirmationModal";
import InputNumberField from "../../../shared/components/inputNumberField/InputNumberField";
import {
  addStoreAsCompanyValidationSchema,
  addStoreValidationSchema,
} from "../../../shared/validationSchema/validationSchema";
import InputGenericField from "../../../shared/components/inputGenericField/InputGenericField";
import {
  storeConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  globalConstants,
  companyContants,
} from "../../../constants";
import { companyService } from "../../../services";
import InputPasswordField from "../../../shared/components/inputPasswordField/InputPasswordField";
import { decryptObject } from "../../../system/encryption";

const EditStore = (props) => {
  const { state } = useLocation();
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [isStoreActive, setIsStoreActive] = useState(state?.isStoreActive);
  const [storeCompany, setStoreCompany] = useState("");
  const [isCompanyStore, setIsCompanyStore] = useState(false);
  const navigate = useNavigate();
  const { companies } = props.company;
  const initialValues = {
    ...state,
    companyId: state.companyId,
    ownerNamePlaceholder: "",
    zip: state.zip,
    city: state.city,
    line2: state.line2,
    state: state.state,
    line1: state.line1,
    storeName: state.storeName,
    owner: state.owner || [],
    phoneNumber: state.phoneNumber,
    connectionTo: state.connectionTo,
    defaultTax: parseToDecimalNumber(state.defaultTax),
    billingType: state.billingType || storeConstants.BILLING_TYPE[0].value,
    billingAmount: parseToDecimalNumber(state.billingAmount || 0),
    activeCardPaymentType:
      state.activeCardPaymentType || storeConstants.STORE_TYPE[0].value,
    domainUrl:
      (storeCompany?.isDummyCompany && storeCompany?.domainUrl) ||
      globalConstants.EMPTY_STRING,
    email:
      (storeCompany?.isDummyCompany && storeCompany?.email) ||
      globalConstants.EMPTY_STRING,
    password:
      (storeCompany?.isDummyCompany &&
        decryptObject(
          storeCompany?.password,
          process.env.REACT_APP_PASSWORD_ENCRYPTED_KEY
        )) ||
      globalConstants.EMPTY_STRING,
    ownerNamePlaceholder:
      (storeCompany?.isDummyCompany && storeCompany?.ownerNamePlaceholder) ||
      globalConstants.EMPTY_STRING,
  };

  const handleSubmit = async (data) => {
    let dataToSend = {
      ...data,
      id: data.id,
      zip: data.zip,
      city: data.city,
      line1: data.line1,
      line2: data.line2,
      state: data.state,
      showOnLabels: false,
      showOnReceipt: false,
      storeName: data.storeName,
      defaultTax: data.defaultTax,
      isStoreActive: isStoreActive,
      phoneNumber: data.phoneNumber,
      billingType: data.billingType,
      connectionTo: data.connectionTo,
      isDummyCompany: !isCompanyStore,
      billingAmount: data.billingAmount,
      logo: globalConstants.EMPTY_STRING,
      returnPolicy: globalConstants.EMPTY_STRING,
      activeCardPaymentType: data.activeCardPaymentType,
      companyId:
        !isCompanyStore && !storeCompany.isDummyCompany
          ? companyContants.CREATE_DUMMY_COMPANY
          : data.companyId,
    };
    if (!isCompanyStore) {
      dataToSend = {
        ...dataToSend,
        companyMetaData: {
          email: data.email,
          password: data.password,
          ownerNamePlaceholder: data.ownerNamePlaceholder,
        },
      };
    }

    props.editStore(dataToSend);
  };
  const handleCancelSubscription = () => {
    toggleConfirmationModal();
  };

  const handleStoreActiveChange = (id, value) => {
    setIsStoreActive(value);
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  const handleConfirmYesButtonClick = () => {
    toggleConfirmationModal();
    props.cancelStripeSubscription({
      storeId: state.id,
      stripeSubscriptionId: state.stripeSubscriptionId,
    });
    navigate(routesPathConstants.ADMIN_STORES);
  };

  const handleIsCompanyStoreChange = (id, value, setFieldValue) => {
    setIsCompanyStore(value);
    setFieldValue("companyId", "");
  };
  useEffect(() => {
    props.getStoreOwners();
    props.getCompanies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    companyService.getCompanyById(state?.companyId).then(
      (response) => {
        setStoreCompany(response);
        setIsCompanyStore(!response?.isDummyCompany);
      },
      (error) => {}
    );
  }, []);

  return (
    <>
      <Topbar
        title={"Edit Store"}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.ADMIN_STORES,
        }}
      >
        <Button
          type="button"
          buttonType={buttonTypeConstants.RED_BUTTON}
          className="w-25 "
          label={buttonNameConstants.CANCEL_SUBSCRIPTION}
          handleClick={handleCancelSubscription}
          isDisabled={!state.isSubscribed}
        ></Button>
      </Topbar>
      <Row className="m-0 w-100 user-information-wrapper container-scrollbar">
        <Formik
          initialValues={initialValues}
          validate={
            isCompanyStore
              ? addStoreValidationSchema
              : addStoreAsCompanyValidationSchema
          }
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form className="px-0">
              <Row className="m-0">
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="storeName"
                    placeHolder="Enter Store Name"
                    label="Store Name"
                    disabled={false}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputGenericField
                    name="phoneNumber"
                    type="tel"
                    placeHolder="(000) 000-0000"
                    label="Phone Number"
                    inputMask="(999) 999-9999"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="line1"
                    type="address"
                    placeHolder="Enter Street 1"
                    label="Street 1"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="line2"
                    type="address"
                    placeHolder="Enter Street 2"
                    label="Street 2"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectFieldCity defaultValue={state.city} />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectFieldState />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField name="zip" placeHolder="00000" label="Zip" />
                </Col>
                <Col md={6} className="mb-3">
                  <InputNumberField
                    name="defaultTax"
                    label="Default Tax (%)"
                    placeHolder="00"
                    type="number"
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    label="Billing Type"
                    name="billingType"
                    placeHolder={"Select Biling Type"}
                    options={storeConstants.BILLING_TYPE}
                    isSearchable={false}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputNumberField
                    name="billingAmount"
                    label="Billing Amount"
                    placeHolder="00"
                    type="number"
                  />
                </Col>{" "}
                <Col md={6}>
                  <SelectField
                    label="Store Type"
                    name="activeCardPaymentType"
                    placeHolder={"Select Store Type"}
                    options={storeConstants.STORE_TYPE}
                    isSearchable={false}
                  />
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-end py-md-2 mt-2 gap-3"
                >
                  <SwitchButton
                    onSwitchChange={handleStoreActiveChange}
                    label={"Active"}
                    id="storeActiveSwitch"
                    isSwitchOn={isStoreActive}
                  />
                  <SwitchButton
                    onSwitchChange={(id, value) =>
                      handleIsCompanyStoreChange(id, value, setFieldValue)
                    }
                    label={"Company Store"}
                    id="isCompanySoreSwitch"
                    isSwitchOn={isCompanyStore}
                  />
                </Col>
                {isCompanyStore ? (
                  <>
                    <Col md={6} className="dmt-2 ">
                      <SelectField
                        label="Company"
                        name="companyId"
                        placeHolder={"Select Company..."}
                        options={
                          companies?.map((company) => ({
                            value: company.id,
                            label: company.companyName,
                          })) || []
                        }
                      />
                    </Col>
                    <Col md={6}></Col>
                  </>
                ) : (
                  <>
                    <Col md={6} className="mb-3">
                      <InputTextField
                        name="email"
                        label="Email"
                        placeHolder="Enter Email"
                        disabled={storeCompany.isDummyCompany}
                      />
                    </Col>
                    <Col md={6} className="mb-3 d-flex gap-2">
                      <InputPasswordField
                        name="password"
                        label="Password"
                        placeHolder="Enter Password"
                        className="w-100"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <InputTextField
                        name="ownerNamePlaceholder"
                        placeHolder="Enter Owner Name"
                        label="Owner Name"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <InputTextField
                        name="domainUrl"
                        label="Domain Url"
                        placeHolder={"Auto Generated"}
                        disabled={true}
                      />
                    </Col>
                  </>
                )}
                <Col md={6} className="my-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                    handleClick={() =>
                      navigate(routesPathConstants.ADMIN_STORES)
                    }
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      storeConstants.ADD_STORE
                    )}
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.UPDATE}
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      storeConstants.ADD_STORE
                    )}
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
      <ConfirmationModal
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
        message={"Are you sure you want to cancel the subscription?"}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  company: state.company,
  user: state.authentication.user,
  storeOwners: state.store.storeOwners,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  editStore: (data) => dispatch(storeActions.editStore(data)),
  getStoreOwners: () => dispatch(storeActions.getStoreOwners()),
  cancelStripeSubscription: (data) =>
    dispatch(storeActions.cancelStripeSubscription(data)),
  getCompanies: () => dispatch(companyActions.getAllCompanies()),
  activateSpinner: () => dispatch(systemActions.activateSpinner()),
  deactivateSpinner: () => dispatch(systemActions.deactivateSpinner()),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(EditStore);

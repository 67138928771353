import { customToast } from "../../shared/utility";
import { success, custom } from "./actionsUtilities";
import { ItemOrganizationService } from "../../services";
import {
  toastType,
  systemConstants,
  itemOrganizationConstants,
  toastMessages,
} from "../../constants";

//-------Get Custom ProductTypes
const getCustomProductTypes = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.GET_CUSTOM_PRODUCT_TYPES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.getCustomProductTypes(storeId)
      .then(
        (response) => {
          dispatch(
            success(
              response.productTypes || [],
              itemOrganizationConstants.GET_CUSTOM_PRODUCT_TYPES
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.GET_CUSTOM_PRODUCT_TYPES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get Custom Categories
const getCustomCategories = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.GET_CUSTOM_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.getCustomCategories(storeId)
      .then(
        (response) => {
          dispatch(
            success(
              response || [],
              itemOrganizationConstants.GET_CUSTOM_CATEGORIES
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.GET_CUSTOM_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get Custom Sub Categories
const getCustomSubCategories = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.GET_CUSTOM_SUB_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.getCustomSubCategories(storeId)
      .then(
        (response) => {
          dispatch(
            success(
              response.subcategory || [],
              itemOrganizationConstants.GET_CUSTOM_SUB_CATEGORIES
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.GET_CUSTOM_SUB_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add Custom ProductTypes
const addCustomProductTypes = (storeId, data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.ADD_CUSTOM_PRODUCT_TYPES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.addCustomProductType(storeId, data)
      .then(
        (response) => {
          dispatch(
            success(
              response || [],
              itemOrganizationConstants.ADD_CUSTOM_PRODUCT_TYPES
            )
          );
          dispatch(getCustomProductTypes(storeId));
          customToast(toastMessages.TYPE_ADDED_SUCCESSFULLY, toastType.SUCCESS);
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.ADD_CUSTOM_PRODUCT_TYPES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add Custom Categories
const addCustomCategories = (storeId, data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.ADD_CUSTOM_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.addCustomCategory(storeId, data)
      .then(
        (response) => {
          dispatch(
            success(
              response || [],
              itemOrganizationConstants.ADD_CUSTOM_CATEGORIES
            )
          );
          customToast(
            toastMessages.CATEGORY_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getCustomCategories(storeId));
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.ADD_CUSTOM_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add Custom Sub Categories
const addCustomSubCategories = (storeId, data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.ADD_CUSTOM_SUB_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.addCustomSubCategory(storeId, data)
      .then(
        (response) => {
          dispatch(getCustomSubCategories(storeId));
          customToast(
            toastMessages.SUB_CATEGORY_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.ADD_CUSTOM_SUB_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Delete Custom ProductTypes
const deleteCustomProductTypes = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.DELETE_CUSTOM_PRODUCT_TYPES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.deleteCustomProductType(storeId, data)
      .then(
        (response) => {
          dispatch(getCustomProductTypes(storeId));
          customToast(
            toastMessages.TYPE_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.DELETE_CUSTOM_PRODUCT_TYPES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------delete Custom Categories
const deleteCustomCategories = (storeId, categoryId) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.DELETE_CUSTOM_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.deleteCustomCategory(storeId, categoryId)
      .then(
        (response) => {
          dispatch(getCustomCategories(storeId));
          customToast(
            toastMessages.CUSTOM_CATEGORY_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.DELETE_CUSTOM_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------delete Custom Sub Categories
const deleteCustomSubCategories = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.DELETE_CUSTOM_SUB_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.deleteCustomSubCategory(storeId, data)
      .then(
        (response) => {
          dispatch(getCustomSubCategories(storeId));
          customToast(
            toastMessages.SUB_CATEGORY_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.DELETE_CUSTOM_SUB_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add Custom ProductTypes
const updateCustomProductTypes = (storeId, data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.UPDATE_CUSTOM_PRODUCT_TYPES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.updateCustomProductType(storeId, data)
      .then(
        (response) => {
          dispatch(getCustomProductTypes(storeId));
          customToast(
            toastMessages.TYPE_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.UPDATE_CUSTOM_PRODUCT_TYPES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add Custom Categories
const updateCustomCategories = (storeId, data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.UPDATE_CUSTOM_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.updateCustomCategory(storeId, data.id, data)
      .then(
        (response) => {
          dispatch(getCustomCategories(storeId));
          customToast(
            toastMessages.CATEGORY_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.UPDATE_CUSTOM_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Add Custom Sub Categories
const updateCustomSubCategories = (storeId, data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        itemOrganizationConstants.UPDATE_CUSTOM_SUB_CATEGORIES,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    ItemOrganizationService.updateCustomSubCategory(storeId, data)
      .then(
        (response) => {
          dispatch(getCustomSubCategories(storeId));
          customToast(
            toastMessages.SUB_CATEGORY_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            itemOrganizationConstants.UPDATE_CUSTOM_SUB_CATEGORIES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

export const itemOrganizationActions = {
  getCustomCategories,
  addCustomCategories,
  addCustomProductTypes,
  getCustomProductTypes,
  getCustomSubCategories,
  updateCustomCategories,
  addCustomSubCategories,
  deleteCustomCategories,
  deleteCustomProductTypes,
  updateCustomProductTypes,
  deleteCustomSubCategories,
  updateCustomSubCategories,
};

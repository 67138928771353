export const userConstants = {
  GET_USER_BY_ID: "GET_USER_BY_ID",
  GET_ALL_USERS: "GET_ALL_USERS",
  ADD_USER: "ADD_USER",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_INFORMATION: "UPDATE_USER_INFORMATION ",
  USER_ROLES: [
    {
      value: "store-owner",
      label: "Store Owner",
    },
    {
      value: "store-manager",
      label: "Store Manager",
    },
    {
      value: "employee",
      label: "Store Employee",
    },
  ],
};

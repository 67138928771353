import { updateObject } from "../../shared/utility";
import { companyContants, globalConstants } from "../../constants";

//-------InitialState of the reducer
const initialState = {
  companies: [],
};

//-------Export company Reducer
export const company = (state = initialState, action) => {
  switch (action.type) {
    //-------Get all companies success case
    case companyContants.GET_ALL_COMPANIES + globalConstants.SUCCESS:
      return updateObject(state, {
        companies: action.data || [],
      });
    //-------Get all companies failure case
    case companyContants.GET_ALL_COMPANIES + globalConstants.FAILURE:
      return updateObject(state, {
        companies: [],
      });

    //-------Default State

    default:
      return state;
  }
};

import { updateObject } from "../../shared/utility";
import { systemUserConstants, globalConstants } from "../../constants";

//-------InitialState of the reducer
const initialState = {
  allSystemUsers: [],
  totalSystemUsers: 0,
};

//-------Export systemUser Reducer
export const systemUser = (state = initialState, action) => {
  switch (action.type) {
    //-------Get all users success case
    case systemUserConstants.GET_ALL_SYSTEM_USERS + globalConstants.SUCCESS:
      return updateObject(state, {
        allSystemUsers: action.data,
        totalSystemUsers: action.data.length,
      });
    //-------Get all users failure case
    case systemUserConstants.GET_ALL_SYSTEM_USERS + globalConstants.FAILURE:
      return updateObject(state, {
        allSystemUsers: [],
        totalSystemUsers: 0,
      });
    //-------Default State
    default:
      return state;
  }
};

import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";

import {
  companyActions,
  storeActions,
  systemActions,
} from "../../../redux/actions";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import SelectField from "../../../shared/components/selectField/SelectField";
import SwitchButton from "../../../shared/components/SwitchButton/SwitchButton";
import SelectFieldCity from "../../../shared/components/selectField/SelectFieldCity";
import InputTextField from "../../../shared/components/inputTextField/InputTextField";
import SelectFieldState from "../../../shared/components/selectField/SelectFieldState";
import InputNumberField from "../../../shared/components/inputNumberField/InputNumberField";
import {
  addStoreAsCompanyValidationSchema,
  addStoreValidationSchema,
} from "../../../shared/validationSchema/validationSchema";
import InputGenericField from "../../../shared/components/inputGenericField/InputGenericField";
import {
  storeConstants,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  toastType,
  companyContants,
} from "../../../constants";
import InputPasswordField from "../../../shared/components/inputPasswordField/InputPasswordField";
import { companyService } from "../../../services";
import { customToast, isSpinnerEnabled } from "../../../shared/utility";

const AddStore = (props) => {
  const [isStoreActive, setIsStoreActive] = useState(true);
  const [isCompanyStore, setIsCompanyStore] = useState(true);
  const navigate = useNavigate();
  const initialValues = {
    zip: "",
    city: "",
    owner: [],
    line1: "",
    line2: "",
    state: "",
    storeName: "",
    defaultTax: "",
    phoneNumber: "",
    connectionTo: "",
    billingAmount: 0,
    email: "",
    password: "",
    companyId: "",
    ownerNamePlaceholder: "",
    isDummyCompany: false,
    activeCardPaymentType: storeConstants.STORE_TYPE[0].value,
    billingType: storeConstants.BILLING_TYPE[0].value,
    domainUrl: globalConstants.EMPTY_STRING,
  };
  const { spinnerArray } = props;
  const { companies } = props.company;
  const handleSubmit = async (data) => {
    let dataToSend = {
      zip: data.zip,
      city: data.city,
      line1: data.line1,
      line2: data.line2,
      state: data.state,
      showOnLabels: false,
      showOnReceipt: false,
      storeName: data.storeName,
      defaultTax: data.defaultTax,
      isStoreActive: isStoreActive,
      phoneNumber: data.phoneNumber,
      billingType: data.billingType,
      connectionTo: data.connectionTo,
      isDummyCompany: !isCompanyStore,
      billingAmount: data.billingAmount,
      logo: globalConstants.EMPTY_STRING,
      returnPolicy: globalConstants.EMPTY_STRING,
      activeCardPaymentType: data.activeCardPaymentType,
      companyId: !isCompanyStore
        ? companyContants.CREATE_DUMMY_COMPANY
        : data.companyId,
    };
    if (!isCompanyStore) {
      dataToSend = {
        ...dataToSend,
        companyMetaData: {
          email: data.email,
          password: data.password,
          ownerNamePlaceholder: data.ownerNamePlaceholder,
        },
      };
    }
    props.addStore(dataToSend);
  };

  const handleStoreActiveChange = (id, value) => {
    setIsStoreActive(value);
  };

  const handleIsCompanyStoreChange = (id, value, setFieldValue) => {
    setIsCompanyStore(value);
    setFieldValue("companyId", "");
  };

  useEffect(() => {
    props.getStoreOwners();
    props.getCompanies();
  }, []);

  return (
    <>
      <Topbar
        title={"New Store"}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.ADMIN_STORES,
        }}
      />
      <Row className="m-0 w-100 user-information-wrapper container-scrollbar">
        <Formik
          initialValues={initialValues}
          validate={
            isCompanyStore
              ? addStoreValidationSchema
              : addStoreAsCompanyValidationSchema
          }
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form className="px-0">
              <Row className="m-0">
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="storeName"
                    placeHolder="Enter Store Name"
                    label="Store Name"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputGenericField
                    name="phoneNumber"
                    type="tel"
                    placeHolder="(000) 000-0000"
                    label="Phone Number"
                    inputMask="(999) 999-9999"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="line1"
                    type="address"
                    placeHolder="Enter Street 1"
                    label="Street 1"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="line2"
                    type="address"
                    placeHolder="Enter Street 2"
                    label="Street 2"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectFieldCity />
                </Col>
                <Col md={6} className="mb-3">
                  <SelectFieldState />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField name="zip" placeHolder="00000" label="Zip" />
                </Col>
                <Col md={6} className="mb-3">
                  <InputNumberField
                    name="defaultTax"
                    label="Default Tax (%)"
                    placeHolder="00"
                    type="number"
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    label="Billing Type"
                    name="billingType"
                    placeHolder={"Select Biling Type"}
                    options={storeConstants.BILLING_TYPE}
                    isSearchable={false}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputNumberField
                    name="billingAmount"
                    label="Billing Amount"
                    placeHolder="00"
                    type="number"
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    label="Store Type"
                    name="activeCardPaymentType"
                    placeHolder={"Select Store Type"}
                    options={storeConstants.STORE_TYPE}
                    isSearchable={false}
                  />
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-end py-md-2 mt-2 gap-3"
                >
                  <SwitchButton
                    onSwitchChange={handleStoreActiveChange}
                    label={"Active"}
                    id="storeActiveSwitch"
                    isSwitchOn={isStoreActive}
                  />
                  <SwitchButton
                    onSwitchChange={(id, value) =>
                      handleIsCompanyStoreChange(id, value, setFieldValue)
                    }
                    label={"Company Store"}
                    id="isCompanySoreSwitch"
                    isSwitchOn={isCompanyStore}
                  />
                </Col>
                {isCompanyStore ? (
                  <>
                    <Col md={6} className="dmt-2 ">
                      <SelectField
                        label="Company"
                        name="companyId"
                        placeHolder={"Select Company..."}
                        options={
                          companies?.map((company) => ({
                            value: company.id,
                            label: company.companyName,
                          })) || []
                        }
                      />
                    </Col>
                    <Col md={6}></Col>
                  </>
                ) : (
                  <>
                    <Col md={6} className="mb-3">
                      <InputTextField
                        name="email"
                        label="Email"
                        placeHolder="Enter Email"
                      />
                    </Col>
                    <Col md={6} className="mb-3 d-flex gap-2">
                      <InputPasswordField
                        name="password"
                        label="Password"
                        placeHolder="Enter Password"
                        className="w-100"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <InputTextField
                        name="ownerNamePlaceholder"
                        placeHolder="Enter Owner Name"
                        label="Owner Name"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <InputTextField
                        name="domainUrl"
                        label="Domain Url"
                        placeHolder={"Auto Generated"}
                        disabled={true}
                      />
                    </Col>
                  </>
                )}

                <Col md={6} className="my-3 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                    handleClick={() =>
                      navigate(routesPathConstants.ADMIN_STORES)
                    }
                    isDisabled={isSpinnerEnabled(
                      spinnerArray,
                      storeConstants.ADD_STORE
                    )}
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.SAVE}
                    isDisabled={isSpinnerEnabled(
                      spinnerArray,
                      storeConstants.ADD_STORE
                    )}
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  company: state.company,
  user: state.authentication.user,
  storeOwners: state.store.storeOwners,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addStore: (data) => dispatch(storeActions.addStore(data)),
  getStoreOwners: () => dispatch(storeActions.getStoreOwners()),
  getCompanies: () => dispatch(companyActions.getAllCompanies()),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddStore);

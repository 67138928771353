import {
  postRequest,
  getRequest,
  deleteRequest,
  putRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get all system users from server
const getAllSystemUsers = (isSystemUser) => {
  return getRequest(`users/${isSystemUser}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get system user by id from server
const getSystemUserById = (id) => {
  return getRequest(`users/${id}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add new system user
const addNewSystemUser = (data) => {
  return postRequest("register", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update system user
const updateSystemUser = (data, isSystemUser) => {
  return putRequest(`users/${isSystemUser}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete system user
const deleteSystemUser = (id, storeId, isSystemUser) => {
  return deleteRequest(`users/${isSystemUser}/${id}/?storeId=${storeId}`).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export system user Services
export const systemUserService = {
  getAllSystemUsers,
  addNewSystemUser,
  getSystemUserById,
  deleteSystemUser,
  updateSystemUser,
};

import { custom } from "./actionsUtilities";
import { systemConstants } from "../../constants";

//-------activate spinner
const activateSpinner = (data) => {
  return (dispatch) => {
    dispatch(custom(data, systemConstants.SPINNER_ACTIVATE));
  };
};

//-------Deactivate Spinner
const deactivateSpinner = (data) => {
  return (dispatch) => {
    dispatch(custom(data, systemConstants.SPINNER_DEACTIVATE));
  };
};

//-------Page Redireaction
const pageRedireaction = (path) => {
  return (dispatch) => {
    dispatch(custom(path, systemConstants.TOGGLE_REDIRECT_PAGE));
  };
};
export const systemActions = {
  activateSpinner,
  deactivateSpinner,
  pageRedireaction,
};

import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import React, { useEffect, useState, useRef } from "react";

import { deviceActions } from "../../../redux/actions";
import Button from "../../../shared/components/Buttons/Button";
import Topbar from "../../../shared/components/topbar/Topbar";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import {
  globalConstants,
  deviceConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
} from "../../../constants";
import {
  EditIcon,
  CrossIcon,
  SearchIcon,
  BlueBoxIcon,
  CheckStatusIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";
import { isSpinnerEnabled } from "../../../shared/utility";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------Devices component start here
const Devices = (props) => {
  const {
    spinnerArray,
    currentStore,
    storeDevices,
    getStoreDevices,
    checkDeviceStatus,
  } = props;
  //------use Navigate to navigate to other screens
  const navigate = useNavigate();

  //-------UseStates
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [gridState, setGridState] = useState(defaultGridState);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();

  //-------useRef
  let numOfRenders = useRef(0);
  //-------device reducer
  //-------store Reducer

  //-------handle Check Device Status
  const handleCheckStatus = (row) => {
    if (row) {
      checkDeviceStatus(row.serialNumber);
    }
  };

  //-------Hanlde Table Sorting
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );
    setDefaultSortFieldId(column.id);
  };

  //-------Update GridState
  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------Handle Devices edit button click
  const handleEditClick = (row) => {
    navigate(routesPathConstants.SETTINGS_DEVICES_EDIT, {
      state: {
        data: row,
        isEdit: true,
        currentStore: currentStore,
      },
    });
  };

  //-------Handle searchfield
  const handleFilterData = (e) => {
    const filterList = storeDevices.filter((row) => {
      return (
        String(row.serialNumber + row.poiId)
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset pagination
    numOfRenders.current++;
    return filterList;
  };

  //-------define column headers for the devices table
  const columnHeaders = [
    {
      id: "serialNumber",
      name: "Serial Number",
      selector: (row) => row.serialNumber,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row.serialNumber}</span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "poiId",
      name: "Nickname",
      selector: (row) => row.poiId,
      cell: (row) => {
        return <span className="inventory-cell-max-size">{row.poiId}</span>;
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                className="w-100"
                IconImage={EditIcon}
                label={buttonNameConstants.EDIT}
                handleClick={() => handleEditClick(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
              <Button
                className="w-100"
                IconImage={CheckStatusIcon}
                label={buttonNameConstants.CHECK_STATUS}
                handleClick={() => handleCheckStatus(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];
  const clearSearchText = () => {
    setSearchText("");
  };
  useEffect(() => {
    if (currentStore) {
      getStoreDevices(currentStore.id);
    } // eslint-disable-next-line
  }, [currentStore]);
  //-------useEffects
  useEffect(() => {
    setColumns(columnHeaders);
    // eslint-disable-next-line
  }, [currentStore]);

  //-------Return AdminDevices Component
  return (
    <>
      <Topbar title="Devices"></Topbar>
      <Row className="m-0 w-100 user-screen-wrapper">
        <Col md={5}>
          {isSpinnerEnabled(spinnerArray, deviceConstants.GET_STORE_DEVICES) ? (
            <DropdownSkeleton width="100%" />
          ) : (
            <SearchField
              placeHolder="Search"
              IconImage={SearchIcon}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              CrossIcon={searchText ? CrossIcon : ""}
              clearSearchText={clearSearchText}
              BlueBoxIcon={BlueBoxIcon}
            />
          )}
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          columns={columns}
          onSort={onTableSort}
          gridState={gridState}
          key={numOfRenders.current}
          setGridState={updateGridState}
          defaultSortFieldId={defaultSortFieldId}
          data={searchText ? handleFilterData() : storeDevices}
          total={searchText ? handleFilterData()?.length : storeDevices?.length}
        />
      </Row>
    </>
  );
}; //-------AdminDevices component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  storeDevices: state.device.storeDevices,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  checkDeviceStatus: (serialNumber) =>
    dispatch(deviceActions.checkDeviceStatus(serialNumber)),

  getStoreDevices: (storeId) =>
    dispatch(deviceActions.getStoreDevices(storeId)),
});

//-------Export AdminDevices Component
export default connect(mapStateToProps, mapDispatchToProps)(Devices);

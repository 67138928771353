import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

import { TopbarPrinterIcon } from "../../../assets/icons/iconsProvider";
import { TopbarUserInfoIcon } from "../../../assets/icons/iconsProvider";
import { TopbarBillingIcon } from "../../../assets/icons/iconsProvider";
import { TopbarPayoutsIcon } from "../../../assets/icons/iconsProvider";
import { TopbarLogoutIcon } from "../../../assets/icons/iconsProvider";
import { routesPathConstants } from "../../../constants/routesPath.constant";
import { clearStorage } from "../../../system/storage";
import { ROLES } from "../../../constants";

const UserDropdown = (props) => {
  const navigate = useNavigate();
  const { role, isStoreActive } = props;

  return (
    <div
      className=""
      onMouseEnter={() => props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave()}
    >
      <ul className="d-flex justify-content-end align-items-end flex-column">
        <li
          onClick={() =>
            navigate(routesPathConstants.SETTINGS_PRINTER_SETTINGS)
          }
        >
          {(isStoreActive || role === ROLES.ADMIN) && (
            <>
              <TopbarPrinterIcon className="pe-1" /> Printer Settings
            </>
          )}
        </li>
        <li onClick={() => navigate(routesPathConstants.USER_INFORMATION)}>
          <TopbarUserInfoIcon className="pe-1" />
          User Information
        </li>
        {(role === ROLES.ADMIN || role === ROLES.STORE_OWNER) && (
          <>
            {/* <li onClick={() => navigate(routesPathConstants.BILLING)}>
              <TopbarBillingIcon className="pe-1" />
              Billing
            </li> */}
            <li onClick={() => navigate(routesPathConstants.PAYOUTS)}>
              <TopbarPayoutsIcon className="pe-1" />
              Finances
            </li>
          </>
        )}

        <li onClick={() => clearStorage()}>
          <TopbarLogoutIcon className="pe-1" />
          Log Out
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.authentication.user.role,
  isStoreActive: state.store.currentStore.isStoreActive,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);

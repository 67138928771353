import Select, { components } from "react-select";
import {
  multiSelectFieldStyle,
  categoriesDropdownStyle,
} from "../../../assets/scss/style";
import { categoryDropdownConstants } from "../../../constants";
import {
  ClockIcon,
  InventoryConditionTypeIcon,
  CategoryIcon,
} from "../../../assets/icons/iconsProvider";

const CustomIconSelectField = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder = "Select",
  SelectIconImage,
  className,
  noOptionsMessage,
  isClearable,
  customOnChange,
  isDisabled,
}) => {
  function onChange(option) {
    form.setFieldValue(
      field.name,
      option ? option.map((item) => item.value) : []
    );
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  const CustomInput = (props) => (
    <div>
      <components.Input {...props} />
      {SelectIconImage === categoryDropdownConstants.CLOCK_ICON ? (
        <ClockIcon className="dropdown-icon" />
      ) : SelectIconImage === categoryDropdownConstants.INVENTORY_LIST_ICON ? (
        <InventoryConditionTypeIcon className="dropdown-icon" />
      ) : (
        <CategoryIcon className="dropdown-icon" />
      )}
    </div>
  );

  const handleSingleSelectChange = (option) => {
    form.setFieldValue(field.name, option?.value);
    if (customOnChange) {
      customOnChange(option);
    }
  };

  if (!isMulti) {
    return (
      <Select
        options={options}
        name={field.name}
        value={
          options
            ? options.find((option) => option.value === field.value) || ""
            : ""
        }
        onChange={(option) => handleSingleSelectChange(option)}
        onBlur={field.onBlur}
        placeholder={placeholder}
        styles={categoriesDropdownStyle}
        components={{
          IndicatorSeparator: () => null,
          Input: CustomInput,
        }}
        className={className}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
    );
  } else {
    return (
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        name={field.name}
        value={getValue()}
        onChange={onChange}
        options={options}
        isMulti={true}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={multiSelectFieldStyle}
        isDisabled={isDisabled}
      />
    );
  }
};

export default CustomIconSelectField;

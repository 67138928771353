import {
  CUSTOM_FILTER_ENUMS,
  GLOBAL_FILTER_ENUMS,
  PRICE_CHANGE_FILTER_ENUMS,
} from "../system/globalEnums";

export const priceChangesConstants = {
  GLOBAL_FILTER_TYPE_OPTIONS: [
    {
      value: GLOBAL_FILTER_ENUMS.BY_PERCENTAGE.value,
      label: GLOBAL_FILTER_ENUMS.BY_PERCENTAGE.label,
    },
    {
      value: GLOBAL_FILTER_ENUMS.BY_VALUE.value,
      label: GLOBAL_FILTER_ENUMS.BY_VALUE.label,
    },
  ],

  CUSTOM_FILTER_TYPE_OPTIONS: [
    {
      value: CUSTOM_FILTER_ENUMS.BY_CATEGORY.value,
      label: CUSTOM_FILTER_ENUMS.BY_CATEGORY.label,
    },
    {
      value: CUSTOM_FILTER_ENUMS.BY_PRICE_RANGE.value,
      label: CUSTOM_FILTER_ENUMS.BY_PRICE_RANGE.label,
    },
  ],

  FILTER_OPTIONS: [
    {
      value: PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_PERCENTAGE.value,
      label: PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_PERCENTAGE.label,
    },
    {
      value: PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_VALUE.value,
      label: PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_VALUE.label,
    },
    {
      value: PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_PERCENTAGE.value,
      label: PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_PERCENTAGE.label,
    },
    {
      value: PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_VALUE.value,
      label: PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_VALUE.label,
    },
  ],
};

import React from "react";
import { Field } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";

//-------CheckboxField component start here
const CheckboxField = (props) => {
  const { name, label } = props;

  //-------Return CheckboxField Component
  return (
    <div>
      <div className="checkbox-field-wrapper">
        <Field name={name} className="checkbox-field" type="checkbox" />
        <label>{label}</label>
      </div>
    </div>
  ); //-------CheckboxField component end here
};

//-------Export CheckboxField Component
export default CheckboxField;

import React from "react";
import { Tooltip } from "react-tooltip";

const CustomTooltip = ({
  children,
  anchorSelect,
  id,
  key,
  className,
  openOnClick = false,
  place = "bottom-end",
}) => {
  return (
    <Tooltip
      anchorSelect={anchorSelect}
      place={place}
      noArrow={true}
      clickable
      openOnClick={openOnClick}
      className={`tooltip px-0 ${className}`}
      id={id}
      key={key}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;

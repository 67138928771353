import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";

import { authenticationActions } from "../../redux/actions";
import Button from "../../shared/components/Buttons/Button";
import {
  authenticationConstants,
  buttonNameConstants,
  systemConstants,
} from "../../constants";
import InputTextField from "../../shared/components/inputTextField/InputTextField";
import { loginValidationSchema } from "../../shared/validationSchema/validationSchema";
import InputPasswordField from "../../shared/components/inputPasswordField/InputPasswordField";
import { SIDEBAR_BUTTONS } from "../../constants";
import { EmailIcon, PasswordIcon } from "../../assets/icons/iconsProvider";
import { isSpinnerEnabled } from "../../shared/utility";

//-------Login component start here
const Login = (props) => {
  const initialValues = {
    email: "",
    password: "",
  };

  //-------Handle login click
  const handleLogin = (values) => {
    props.handleLoginClick({
      email: values.email.toLowerCase(),
      password: values.password,
    });
    props.setActiveSidebarBtn(SIDEBAR_BUTTONS.DASHBOARD);
  };

  //-------Return Login Component
  return (
    <div>
      <div className=" login-card-wrapper row d-flex justify-content-center align-items-center m-0 p-4 min-vh-100 min-vw-100">
        <div className="login-card col-sm-8 col-md-6 col-lg-4 p-5 d-flex flex-column justify-content-center align-items-center rounded-4">
          <h1>POS</h1>
          <p>Sign in to continue</p>
          <Formik
            initialValues={initialValues}
            validate={loginValidationSchema}
            onSubmit={handleLogin}
          >
            <Form className="login-form-wrapper">
              <Row className="w-100 m-0">
                <Col xs={12} className="p-0 mb-3">
                  <InputTextField
                    type="email"
                    name="email"
                    label="Email"
                    placeHolder="Email Address"
                    IconImage={EmailIcon}
                  />
                </Col>
                <Col xs={12} className="p-0 mb-3">
                  <InputPasswordField
                    type="password"
                    name="password"
                    label="Password"
                    placeHolder="Password"
                    IconImage={PasswordIcon}
                    autoComplete="on"
                  />
                </Col>
                <Col xs={12} className="p-0 mt-3 ">
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.LOGIN}
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      authenticationConstants.USER_LOGIN
                    )}
                  />
                </Col>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}; //-------Login component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  handleLoginClick: (data) => dispatch(authenticationActions.login(data)),
  setActiveSidebarBtn: (data) =>
    dispatch({ type: systemConstants.SET_ACTIVE_SIDEBAR_BTN, data: data }),
});

//-------Export Login Component
export default connect(mapStateToProps, mapDispatchToProps)(Login);

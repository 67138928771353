import React from "react";
import { Row, Col } from "react-bootstrap"; // Assuming you're using React Bootstrap

import VerticalInputNumberField from "../inputNumberField/VerticalInputNumberField";
import {
  EditIcon,
  DeleteIcon,
  SearchIcon,
  DollarIcon,
  PaymentIcon,
} from "../../../assets/icons/iconsProvider";
import { inventoryConstants, transactionConstants } from "../../../constants";
import {
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../system/globalEnums";
import {
  getShortestSku,
  handleViewEbaySales,
  handleViewTCGPlayer,
} from "../../utility";
const ReturnChartItem = ({
  product,
  handleInputChange,
  deleteProduct,
  handleViewPriceCharting,
  toggleIframe,
}) => {
  return (
    <Row key={product.sku} className="return-chart-div mb-3">
      <Col md={1} className="m-0 p-0">
        <VerticalInputNumberField
          sku={`${product.sku}`}
          placeholder={product.placeholder}
          value={product.editQTY}
          maxValue={product.maxValue}
          onChange={handleInputChange}
        />
      </Col>
      <Col
        md={6}
        className="m-0 ps-3 pt-1 pb-1 d-flex flex-column justify-content-between p-0"
      >
        <p className="return-product-name">
          {product?.itemName?.length > 12
            ? `${product.itemName.substring(0, 12)}...`
            : product.itemName}
        </p>
        <p className="return-product-sku-num">#{getShortestSku(product.sku)}</p>
      </Col>
      <Col
        md={4}
        className="m-0 d-flex flex-column align-items-center justify-content-center p-0"
      >
        <p className="return-amount-chip"> $ {product.priceAfterDiscount}</p>
      </Col>
      <Col
        md={1}
        className="m-0 d-flex flex-column align-items-center justify-content-center p-0"
      >
        <div
          onClick={() => deleteProduct(product.sku)}
          style={{ cursor: "pointer" }}
        >
          <DeleteIcon />
        </div>
      </Col>
      {/* Consider moving or reworking this section as needed */}
      {product.category !== transactionConstants.MISCELLANEOUS_SALE_ITEM &&
      product.category !== transactionConstants.MISCELLANEOUS ? (
        product.category === TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
        product.category === TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ? (
          <Col className="m-0 d-flex align-items-center gap-3 mt-3 p-0" md={12}>
            <span
              onClick={() => {
                handleViewTCGPlayer({ tcgPlayerUrl: product.tcgPlayerUrl });
              }}
              className="price-charting-text-return cursor-pointer"
            >
              View TcgPlayer
            </span>
          </Col>
        ) : (
          <Col className="m-0 d-flex align-items-center gap-3 mt-3 p-0" md={12}>
            <span
              onClick={() => {
                handleViewPriceCharting(product?.itemName, product?.category);
                toggleIframe();
              }}
              className="price-charting-text-return cursor-pointer"
            >
              Pricecharting
            </span>
            <span
              onClick={() =>
                handleViewEbaySales(
                  product?.itemName,
                  product?.category,
                  product?.subcategory
                )
              }
              className="price-charting-text-return cursor-pointer"
            >
              eBay Sales
            </span>
          </Col>
        )
      ) : (
        ""
      )}
    </Row>
  );
};

export default ReturnChartItem;

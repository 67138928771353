import React from "react";
import { Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";

const CustomInputComponent = (props) => (
  //   <input className={props.className} {...props} />
  <InputMask
    value={props.value}
    type={props.type}
    mask="(999) 999-9999"
    maskChar=""
    name={props.name}
    id={props.name}
    className={props.className}
    onChange={() => props.onChange()}
    {...props}
  />
);

const InputGenericField = (props) => {
  const {
    name,
    placeHolder,
    IconImage,
    type = "text",
    inputMask,
    label,
    className,
  } = props;
  return (
    <div>
      {label && (
        <label className="field-label" htmlFor={name}>
          {label && label}
        </label>
      )}
      {type === "tel" ? (
        <div className="input-field-wrapper">
          <Field name={name} id={name}>
            {({ field, form, value }) => (
              <InputMask
                {...field}
                defaultValue={value ? value : form.initialValues.phoneNumber}
                type={props.type}
                mask={inputMask}
                maskChar=""
                name={name}
                id={name}
                placeholder={placeHolder}
                alwaysShowMask={false}
                className={`input-textfield ${
                  IconImage || "input-textfield-without-icon"
                }  ${className}`}
                onChange={(event) =>
                  form.setFieldValue(props.name, event.target.value)
                }
              />
            )}
          </Field>
        </div>
      ) : (
        <div className="input-field-wrapper">
          {IconImage && <IconImage className="icon-image" />}
          <Field
            className={`input-textfield ${
              IconImage || "input-textfield-without-icon"
            }`}
            type={type}
            id={name}
            name={name}
            placeholder={placeHolder}
            autoComplete="off"
          />
        </div>
      )}
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  );
};

export default InputGenericField;

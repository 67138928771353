import React from "react";
import { Formik, Form } from "formik";
import { Col, Modal, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import IconSelectField from "../../../../shared/components/selectField/IconSelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import {
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
  toastMessages,
  toastType,
  transactionConstants,
} from "../../../../constants";
import { addCustomCategoryValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import { PRODUCT_TYPES_ENUMS } from "../../../../system/globalEnums";
import { customToast } from "../../../../shared/utility";

const AddCustomCategory = ({
  storeId,
  customTypes,
  AddCustomCategory,
  categoryModalFormData,
  isCustomCategoryModal,
  updateCustomCategories,
  setCategoryModalFormData,
  toggleCustomCategoryModal,
}) => {
  const initialValues = {
    categoryName: categoryModalFormData?.name || globalConstants.EMPTY_STRING,
    type: categoryModalFormData?.productTypeId || globalConstants.EMPTY_STRING,
  };

  const handleHideModal = () => {
    toggleCustomCategoryModal();
    setCategoryModalFormData(globalConstants.EMPTY_STRING);
  };

  const handleAddCustomTypeSubmit = (values) => {
    if (
      values?.categoryName &&
      values?.categoryName === transactionConstants.CUSTOM_SALE
    ) {
      customToast(toastMessages.CUSTOM_SALE_KEYWORD_RESERVED, toastType.ERROR);
      return;
    }

    if (categoryModalFormData?.id) {
      updateCustomCategories(
        storeId,
        {
          name: values.categoryName,
          storeId: storeId,
          productTypeId: values.type,
          productTypeName: customTypes.find((type) => type.id === values.type)
            .productType,
          isCustom: true,
          isActive: true,
          id: categoryModalFormData.id,
        },
        handleHideModal
      );
    } else {
      AddCustomCategory(
        storeId,
        {
          name: values.categoryName,
          storeId: storeId,
          productTypeId: values.type,
          productTypeName: customTypes.find((type) => type.id === values.type)
            .productType,
          isCustom: true,
        },
        handleHideModal
      );
    }
  };

  return (
    <Modal
      show={isCustomCategoryModal}
      size="md"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className={`add-inventory-modal`}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            {categoryModalFormData?.id
              ? "Update Custom Category"
              : "Add Custom Category"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body d-flex ">
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddCustomTypeSubmit}
          validate={addCustomCategoryValidationSchema}
        >
          {({ values }) => (
            <Form className="w-100">
              <Row className="m-0 w-100">
                <Col
                  md={4}
                  className="d-flex align-items-center text-muted mb-3 field-label-name"
                >
                  Select Type:
                </Col>
                <Col md={8} className="mb-3">
                  <IconSelectField
                    name="type"
                    options={
                      customTypes
                        ?.map((item) => ({
                          value: item.id,
                          label: item.productType,
                        }))
                        .filter(
                          (item) => item.label !== PRODUCT_TYPES_ENUMS.ALL
                        ) || []
                    }
                    placeHolder="Type"
                    isClearable={false}
                  />
                </Col>
                <Col
                  md={4}
                  className="d-flex align-items-center text-muted mb-3 field-label-name"
                >
                  Create Category:
                </Col>
                <Col md={8} className="mb-3">
                  <InputTextField
                    name="categoryName"
                    placeHolder="Category..."
                  />
                </Col>
                <Col md={12} className="d-flex align-items-center gap-3">
                  <Button
                    type="button"
                    className={"w-100"}
                    handleClick={handleHideModal}
                    label={buttonNameConstants.CANCEL}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                  />
                  <Button
                    className={"w-100"}
                    type="submit"
                    label={
                      categoryModalFormData?.id
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.ADD
                    }
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddCustomCategory;

import { routesPathConstants, printerConstants } from "../constants";

//-------Set item to session storage
export const setItemToStorage = (keyname, value) => {
  return sessionStorage.setItem(keyname, value);
};

//-------Get item from session storage
export const getItemFromStorage = (keyname) => {
  return sessionStorage.getItem(keyname);
};

//-------Remove item to session storage
export const removeItemFromStorage = (keyname) => {
  return sessionStorage.removeItem(keyname);
};

//-------Is item In session storage
export const isItemInStorage = (keyname) => {
  return sessionStorage.hasOwnProperty(keyname);
};

//-------Set item to local storage
export const setItemToLocalStorage = (keyname, value) => {
  return localStorage.setItem(keyname, value);
};

//-------Is item In local storage
export const isItemInLocalStorage = (keyname) => {
  return localStorage.hasOwnProperty(keyname);
};

//-------Get item local session storage
export const getItemFromLocalStorage = (keyname) => {
  return localStorage.getItem(keyname);
};

//-------Clear all session storage
export const clearStorage = () => {
  sessionStorage.clear();
  let localDeviceIp = getItemFromLocalStorage(printerConstants.LOCAL_DEVICE_IP);
  if (localDeviceIp) {
    localStorage.clear();
    setItemToLocalStorage(printerConstants.LOCAL_DEVICE_IP, localDeviceIp);
  } else {
    localStorage.clear();
  }
  window.location.href = routesPathConstants.DEFAULT_PATH;
};

import React, { useEffect, useState } from "react";
import { getThresholdFilterList } from "../../../../shared/utility";

const FilterTags = ({ thresholdData }) => {
  const [filterTagsList, setFilterTagsList] = useState([]);

  useEffect(() => {
    setFilterTagsList(getThresholdFilterList(thresholdData));
  }, [thresholdData]);

  return filterTagsList?.length ? (
    <div className="d-flex align-items-center gap-3">
      <span className="price-change-filter-tag filter-tags-heading">
        Custom Filters:
      </span>
      <div className="d-flex align-items-center gap-3 overflow-auto scrollableWrapper py-1">
        {filterTagsList?.map((tag) => {
          return (
            <div className="price-change-filter-tag">
              {!tag.isCategory
                ? `${tag.categoryName}  |  ${
                    !tag.valueType ? `${tag.value} %` : `$${tag.value}`
                  }`
                : `$${tag.min} - $${tag.max}  |  ${
                    !tag.valueType ? `${tag.value} %` : `$${tag.value}`
                  }`}
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    ""
  );
};

export default FilterTags;

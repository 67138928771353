import React, { useState } from "react";

const SwitchButton = ({
  label,
  onSwitchChange,
  id,
  className,
  isSwitchOn = false,
  isDisabled = false,
}) => {
  const handleChange = (event) => {
    if (onSwitchChange) {
      onSwitchChange(id, event.target.checked);
    }
  };

  return (
    <div
      className={`d-flex align-items-center form-check form-switch accent-color ${className} `}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id={`switch-${id}`}
        checked={isSwitchOn}
        onChange={handleChange}
        disabled={isDisabled}
      />
      {label && (
        <label className="form-check-label" htmlFor={`switch-${id}`}>
          {label}
        </label>
      )}
    </div>
  );
};

export default SwitchButton;

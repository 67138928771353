import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";

import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import {
  PRODUCT_TYPES_ENUMS,
  SET_BY_MARGIN_ENUMS,
} from "../../../system/globalEnums";
import SelectField from "../../../shared/components/selectField/SelectField";
import SearchField from "../../../shared/components/searchField/SearchField";
import SwitchButton from "../../../shared/components/SwitchButton/SwitchButton";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import InputNumberField from "../../../shared/components/inputNumberField/InputNumberField";
import TableCustomAppliesToField from "../../../shared/components/table/TableCustomAppliesToField";
import TableCustomPriceRangeField from "../../../shared/components/table/TableCustomPriceRangeField";

import { tradeInDiscountActions } from "../../../redux/actions";

import {
  toastType,
  MARGIN_TYPES,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  SET_BY_MARGIN_OPTIONS,
  tradeInDiscountConstant,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  DollarIcon,
  SearchIcon,
  BlueBoxIcon,
  PercentageIcon,
  ActionDotsIcons,
  DefaultSortingIcon,
  AsendingSortingIcon,
  DesendingSortingIcon,
} from "../../../assets/icons/iconsProvider";
import {
  tradeInsModalValidationSchema,
  tradeInsGlobalValidationSchema,
  tradeInsMonthlyValidationSchema,
  tradeInsModalWithOutCheckValidationSchema,
} from "../../../shared/validationSchema/validationSchema";
import {
  customToast,
  filterApplyToSearchOptions,
  handleMapSelectedOptionsInEdit,
  handleCategorySelect,
} from "../../../shared/utility";
import TradeFilters from "../../../shared/components/FilterDropdowns/TradeFilters";
const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------TradeIns component start here
const TradeIns = (props) => {
  const {
    currentStore,
    tradeinMargin,
    addGlobalMargin,
    addMonthlyBudget,
    itemOrganization,
    addCategoryMargin,
    updateCategoryMargin,
    deleteCategoryMargin,
  } = props;

  //-----reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  const [searchText, setSearchText] = useState("");
  const [modalCheckBoxStatus, setModalCheckBoxStatus] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [defaultCategorySortFieldId, setDefaultCategorySortFieldId] =
    useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const [show, setShow] = useState(false);
  const [editCategory, setEditCategory] = useState("");
  const [searchCategories, setSearchCategories] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [resetSearchCategories, setResetSearchCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filterProductType, setFilterProductType] = useState("");
  const [filterProductCategory, setFilterProductCategory] = useState("");
  const [setByFilter, setSetByFilter] = useState("");
  let numOfRenders = useRef(0);
  const initialGlobalValues = {
    cashMargin:
      tradeinMargin?.globalMargin?.cashMarginPercentage ||
      globalConstants.EMPTY_STRING,
    tradeMargin:
      tradeinMargin?.globalMargin?.tradeinMarginPercentage ||
      globalConstants.EMPTY_STRING,
  };

  const [updatedRowsData, setupdatedRowsData] = useState([]);
  const initialModalValues = {
    id: editCategory?.id || globalConstants.EMPTY_STRING,
    cashOffer:
      editCategory?.cashMarginPercentage || globalConstants.EMPTY_STRING,
    tradeOffer:
      editCategory?.tradeinMarginPercentage || globalConstants.EMPTY_STRING,
    minPrice:
      Number(editCategory?.minPriceRange) >= 0
        ? editCategory?.minPriceRange
        : "0.00",
    maxPrice:
      Number(editCategory?.maxPriceRange) >= 0
        ? editCategory?.maxPriceRange
        : "0.00",
    setBy: editCategory?.setBy || SET_BY_MARGIN_ENUMS.TYPE.value,
  };
  const initialValues = {
    monthlyPrice: currentStore?.budget || globalConstants.EMPTY_STRING,
  };
  const handleSwitchChange = (id, checkboxStatus) => {
    setModalCheckBoxStatus(checkboxStatus);
  };
  //-------Handle TradeIns edit click
  const handleEditClick = (row) => {
    setIsEdit(true);
    setEditCategory({
      ...row,
      setBy:
        row.marginType === SET_BY_MARGIN_ENUMS.CATEGORY.value
          ? SET_BY_MARGIN_ENUMS.CATEGORY.value
          : row.marginType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
          ? SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
          : SET_BY_MARGIN_ENUMS.TYPE.value,
    });
    handleSetByChange(
      row.marginType === SET_BY_MARGIN_ENUMS.CATEGORY.value
        ? SET_BY_MARGIN_ENUMS.CATEGORY.value
        : row.marginType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
        ? SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
        : SET_BY_MARGIN_ENUMS.TYPE.value
    );
    toggleCustomTradeModal();
    setSelectedCategories(
      handleMapSelectedOptionsInEdit(row.marginGroup, row.marginType)
    );

    if (row.minPriceRange >= 0 && row.maxPriceRange >= 0) {
      setModalCheckBoxStatus(true);
    }
    const filterCategory = filterApplyToSearchOptions(
      row.marginType,
      customTypes.filter(
        (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
      ),
      customCategories,
      customSubCategories,
      filterProductType,
      filterProductCategory,
      selectedCategories
    );

    const categoriesNames = row.marginGroup.map((row) => {
      return row.marginType === SET_BY_MARGIN_ENUMS.CATEGORY.value
        ? row.categoryName
        : row.marginType === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
        ? row.subcategoryName
        : row.productType;
    });

    setSearchCategories(
      filterCategory.filter((category) => {
        return !categoriesNames.includes(category.name);
      })
    );
  };

  //-------Handle TradeIns delete click
  const handleDeleteClick = (row) => {
    deleteCategoryMargin(currentStore?.id, [String(row.id)]);
  };

  //-------Handle add button click
  const handleAddClick = () => {
    setResetSearchCategories(true);
    toggleCustomTradeModal();
    handleSetByChange(SET_BY_MARGIN_ENUMS.TYPE.value);
  };
  const toggleCustomTradeModal = (resetSearch) => {
    setShow(!show);
    setModalCheckBoxStatus(false);
    setSelectedCategories([]);
    if (resetSearch || isEdit || resetSearchCategories) {
      setResetSearchCategories(false);
      setIsEdit(false);
      setEditCategory("");
    }
  };

  const removeSelectedCategory = (categoryToRemove) => {
    setSelectedCategories(
      selectedCategories.filter(
        (category) => category.name !== categoryToRemove
      )
    );

    const fullCategoryObject = selectedCategories.find(
      (category) => category.name === categoryToRemove
    );

    if (fullCategoryObject) {
      setSearchCategories(
        [...searchCategories, fullCategoryObject].sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      );
    }
  };

  const globalHandleSubmit = (data, { resetForm }) => {
    const dataToSend = {
      marginType: MARGIN_TYPES.GLOBAL,
      cashMarginPercentage: data.cashMargin,
      tradeinMarginPercentage: data.tradeMargin,
      store: {
        name: currentStore.storeName,
        id: currentStore.id,
      },
    };
    addGlobalMargin(currentStore?.id, dataToSend);
    resetForm();
  };

  const modalHandleSubmit = (data) => {
    if (selectedCategories?.length <= 0) {
      customToast(toastMessages.PLEASE_SELECT_CATEGORIES, toastType.ERROR);
      return;
    }

    let dataToSend = [];
    if (data.setBy === SET_BY_MARGIN_ENUMS.TYPE.value) {
      const updatedSelectedCategories = selectedCategories.map((items) => {
        const newObject = {
          productType: items.name,
          cashMarginPercentage: data.cashOffer,
          tradeinMarginPercentage: data.tradeOffer,
          minPriceRange: modalCheckBoxStatus
            ? data.minPrice
            : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
          maxPriceRange: modalCheckBoxStatus
            ? data.maxPrice
            : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        };
        return newObject;
      });
      dataToSend = {
        id: data.id,
        marginType: SET_BY_MARGIN_ENUMS.TYPE.value,
        cashMarginPercentage: data.cashOffer,
        tradeinMarginPercentage: data.tradeOffer,
        minPriceRange: modalCheckBoxStatus
          ? data.minPrice
          : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
        maxPriceRange: modalCheckBoxStatus
          ? data.maxPrice
          : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        marginGroup: updatedSelectedCategories,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      };
    } else if (data.setBy === SET_BY_MARGIN_ENUMS.CATEGORY.value) {
      const updatedSelectedCategories = selectedCategories.map((items) => {
        const newObject = {
          categoryId: items.id,
          categoryName: items.name,
        };
        return newObject;
      });
      dataToSend = {
        id: data.id,
        marginType: SET_BY_MARGIN_ENUMS.CATEGORY.value,
        cashMarginPercentage: data.cashOffer,
        tradeinMarginPercentage: data.tradeOffer,
        minPriceRange: modalCheckBoxStatus
          ? data.minPrice
          : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
        maxPriceRange: modalCheckBoxStatus
          ? data.maxPrice
          : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        marginGroup: updatedSelectedCategories,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      };
    } else if (data.setBy === SET_BY_MARGIN_ENUMS.SUBCATEGORY.value) {
      const updatedSelectedCategories = selectedCategories.map((items) => {
        const newObject = {
          categoryId: items.id,
          categoryName: items.name,
          parentCategoryId: items.parentCategoryId,
          parentCategoryName: items.parentCategoryName,
        };
        return newObject;
      });
      dataToSend = {
        id: data.id,
        marginType: SET_BY_MARGIN_ENUMS.SUBCATEGORY.value,
        cashMarginPercentage: data.cashOffer,
        tradeinMarginPercentage: data.tradeOffer,
        minPriceRange: modalCheckBoxStatus
          ? data.minPrice
          : tradeInDiscountConstant.DEFAULT_MIN_RANGE,
        maxPriceRange: modalCheckBoxStatus
          ? data.maxPrice
          : tradeInDiscountConstant.DEFAULT_MAX_RANGE,
        marginGroup: updatedSelectedCategories,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      };
    }

    if (isEdit) {
      updateCategoryMargin(
        currentStore?.id,
        dataToSend,
        toggleCustomTradeModal
      );
    } else {
      addCategoryMargin(
        currentStore?.id,
        dataToSend,
        toggleCustomTradeModal,
        resetSearch
      );
    }
  };
  const HandleSubmit = (data) => {
    addMonthlyBudget(currentStore?.id, {
      budget: data.monthlyPrice,
    });
  };

  const columnHeaders = [
    {
      id: "setBy",
      name: "Set By",
      selector: (row) => row.marginType,
      cell: (row) => {
        return (
          <span className="users-cell-max-size">
            {
              SET_BY_MARGIN_OPTIONS.find(
                (item) => item.value === row.marginType
              ).label
            }
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "appliesTo",
      name: "Applies To",
      selector: (row) => row.marginGroup,
      cell: (row) => {
        return (
          <TableCustomAppliesToField
            row={row}
            groupArray={row.marginGroup}
            groupType={row.marginType}
          />
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "priceRange",
      name: "Price Range",
      selector: (row) => row.maxPriceRange,
      cell: (row) => {
        return <TableCustomPriceRangeField row={row} />;
      },
      sortable: true,
      sortDirection: null,
    },

    {
      id: "cashPercentage",
      name: "Cash Percentage",
      selector: (row) => row.cashMarginPercentage,
      cell: (row) => (
        <span className="users-cell-max-size">{row.cashMarginPercentage}%</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "tradePercentage",
      name: "Trade Percentage",
      selector: (row) => row.tradeinMarginPercentage,
      cell: (row) => (
        <span className="users-cell-max-size">
          {row.tradeinMarginPercentage}%
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                label={buttonNameConstants.EDIT}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={EditIcon}
                handleClick={() => handleEditClick(row)}
              />
              <Button
                label={buttonNameConstants.DELETE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={DeleteIcon}
                handleClick={() => handleDeleteClick(row)}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  const [columns, setColumns] = useState([]);

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const onCategoryTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultCategorySortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = updatedRowsData.filter((row) => {
      return (
        String(
          row.categoryName +
            row.maxPriceRange +
            row.minPriceRange +
            row.cashMarginPercentage +
            row.tradeinMarginPercentage
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    numOfRenders.current++;
    return filterList;
  };

  const categoriesColumn = [
    {
      id: "categoryName",
      name: "Category Name",
      selector: (row) => row.name,
      cell: (row) => <span className="users-cell-max-size">{row.name}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            {
              <button
                type="button"
                onClick={() => {
                  removeSelectedCategory(row?.name);
                }}
                id={`tool${row.id}`}
                className="action-Button-Wrapper"
              >
                <DeleteIcon className="cursor-pointer" />
              </button>
            }
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];
  const formattedColumns = categoriesColumn.map((column) => {
    if (column.sortable) {
      return {
        ...column,
        name: (
          <div className=" d-flex w-100">
            {column.name}
            <div className="ml-2 d-flex justify-content-center align-items-center">
              {column.sortDirection === null && (
                <DefaultSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.ASC && (
                <AsendingSortingIcon className="ms-1 cursor-pointer" />
              )}
              {column.sortDirection === globalConstants.SORT.DESC && (
                <DesendingSortingIcon className="ms-1 cursor-pointer" />
              )}
            </div>
          </div>
        ),
      };
    } else {
      return column;
    }
  });

  const handleSetByChange = (option) => {
    setSelectedCategories([]);
    setSetByFilter(option);
    setSearchCategories(
      filterApplyToSearchOptions(
        option,
        customTypes.filter(
          (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
        ),
        customCategories,
        customSubCategories,
        filterProductType,
        filterProductCategory,
        selectedCategories
      )
    );
  };

  useEffect(() => {
    setSearchCategories(
      filterApplyToSearchOptions(
        setByFilter,
        customTypes.filter(
          (item) => item.productType !== PRODUCT_TYPES_ENUMS.ALL
        ),
        customCategories,
        customSubCategories,
        filterProductType,
        filterProductCategory,
        selectedCategories
      )
    ); // eslint-disable-next-line
  }, [setByFilter, filterProductType, filterProductCategory]);

  useEffect(() => {
    setColumns(columnHeaders);
    // eslint-disable-next-line
  }, [currentStore]);

  useEffect(() => {
    handleSetByChange(SET_BY_MARGIN_ENUMS.TYPE.value);
    // eslint-disable-next-line
  }, [customCategories]);

  useEffect(() => {
    setupdatedRowsData([
      ...(tradeinMargin?.categoryMargins || []),
      ...(tradeinMargin?.subcategoryMargins || []),
      ...(tradeinMargin?.productTypeMargins || []),
    ]);
  }, [tradeinMargin]);

  const clearSearchText = () => {
    setSearchText("");
  };
  const resetSearch = () => {
    setResetSearchCategories(true);
  };

  //-------Return TradeIns Component
  return (
    <>
      <Topbar title="Trade-Ins"></Topbar>
      <Row className="m-0 w-100">
        <Col md={12} className="tradeIns-heading">
          Custom Trade-In Margin
        </Col>
        <Col md={8} className="p-0">
          <Row className="m-0 h-100  user-screen-wrapper w-100 min-vh-100 ">
            <Row className="m-0  p-0 w-100 user-screen-wrapper">
              <Col md={9} className="">
                <SearchField
                  onChange={(e) => setSearchText(e.target.value)}
                  IconImage={SearchIcon}
                  placeHolder="Search"
                  value={searchText}
                  CrossIcon={searchText ? CrossIcon : ""}
                  clearSearchText={clearSearchText}
                  BlueBoxIcon={BlueBoxIcon}
                />
              </Col>
              <Col
                md={3}
                className="d-flex justify-content-end align-items-center gap-3"
              >
                <Button
                  label={buttonNameConstants.ADD}
                  IconImage={AddIcon}
                  handleClick={handleAddClick}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row className="m-0 p-0  w-100 h-100 user-screen-wrapper  pt-3">
              <DataTableComponent
                className="tradeIn-table-height"
                key={numOfRenders.current}
                columns={columns}
                data={searchText ? handleFilterData() : updatedRowsData}
                onSort={onTableSort}
                defaultSortFieldId={defaultSortFieldId}
                gridState={gridState}
                setGridState={updateGridState}
                total={
                  searchText
                    ? handleFilterData()?.length
                    : updatedRowsData?.length
                }
              />
            </Row>
          </Row>
        </Col>
        <Col md={4}>
          <Row className="m-0 user-screen-wrapper container-scrollbar">
            <Formik
              initialValues={initialGlobalValues}
              validate={tradeInsGlobalValidationSchema}
              onSubmit={globalHandleSubmit}
              enableReinitialize
            >
              {({ resetForm }) => (
                <Form className="px-0">
                  <Row className="global-trade-div">
                    <p className="tradeIns-heading p-0">
                      Global Trade-in Margin
                    </p>
                    <Col md={12} className="mb-3  p-0">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="cashMargin"
                        placeHolder="35 %"
                        type="number"
                        label="Cash Margin"
                        maxValue={100}
                        IconImage={PercentageIcon}
                      />
                    </Col>
                    <Col md={12} className="mb-3  p-0">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="tradeMargin"
                        placeHolder="35 %"
                        type="number"
                        label="Trade Margin"
                        maxValue={100}
                        IconImage={PercentageIcon}
                      />
                    </Col>
                    <Col
                      md={12}
                      className="d-flex mt-2 p-0 justify-content-end align-items-center gap-3"
                    >
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        handleClick={() => {
                          // handleCancelButton();
                          resetForm();
                        }}
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                        className="min-width-btn-trads"
                      ></Button>
                      <Button
                        type="submit"
                        label={buttonNameConstants.SAVE}
                        handleClick={() => {
                          // handleCancelButton();
                        }}
                        buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        className="min-width-btn-trads"
                      ></Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Row>
          <Row className="m-0 mt-3">
            <Formik
              initialValues={initialValues}
              validate={tradeInsMonthlyValidationSchema}
              onSubmit={HandleSubmit}
              enableReinitialize
            >
              {({ resetForm }) => (
                <Form className="px-0">
                  <Row className="global-trade-div">
                    <p className="tradeIns-heading mb-1 p-0">Monthly Budget</p>
                    <p className="monthly-budget-heading p-0">
                      Resets on the first day of the month
                    </p>
                    <Col md={12} className="mb-3  p-0">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="monthlyPrice"
                        placeHolder="$5,000.00"
                        type="number"
                        IconImage={DollarIcon}
                      />
                    </Col>

                    <Col
                      md={12}
                      className="d-flex mt-2 p-0 justify-content-end align-items-center gap-3"
                    >
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        handleClick={() => {
                          // handleCancelButton();
                          resetForm();
                        }}
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                        className="min-width-btn-trads"
                      ></Button>
                      <Button
                        type="submit"
                        label={buttonNameConstants.SAVE}
                        handleClick={() => {
                          // handleCancelButton();
                        }}
                        buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        className="min-width-btn-trads"
                      ></Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Row>
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={toggleCustomTradeModal}
        size="md"
        animation={true}
        centered
        backdrop="static"
        className="add-inventory-modal"
      >
        <Formik
          initialValues={initialModalValues}
          validate={
            modalCheckBoxStatus
              ? tradeInsModalValidationSchema
              : tradeInsModalWithOutCheckValidationSchema
          }
          onSubmit={modalHandleSubmit}
        >
          {({ values }) => (
            <Form className="px-0">
              <Modal.Header closeButton>
                <Modal.Title>
                  {!isEdit
                    ? "Add Custom Trade-in Margin"
                    : "Edit Custom Trade-in Margin"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pt-0">
                <Row className="m-0 p-0">
                  <Col md={12} className="p-0 mb-3">
                    <SelectField
                      name="setBy"
                      label="Set By:"
                      options={SET_BY_MARGIN_OPTIONS}
                      isSearchable={false}
                      placeHolder={"Set By..."}
                      onChangeValue={handleSetByChange}
                      isDisabled={isEdit}
                    />
                  </Col>
                  <p className="p-0">
                    Apply to{" "}
                    {
                      SET_BY_MARGIN_OPTIONS.find(
                        (option) => option.value === values.setBy
                      ).label
                    }
                    :
                  </p>
                  <TradeFilters
                    setBy={values.setBy}
                    customTypes={customTypes}
                    customCategories={customCategories}
                    filterProductType={filterProductType}
                    setFilterProductType={setFilterProductType}
                    setFilterProductCategory={setFilterProductCategory}
                    filterProductCategory={filterProductCategory}
                  />
                  {
                    <Col md={12} className="p-0 position-relative">
                      <SelectField
                        name="searchOptions"
                        options={searchCategories.map((category) => ({
                          value: category,
                          label: category?.name,
                        }))}
                        isSearchable={true}
                        isSelectedValueAsPlaceholder={false}
                        placeHolder={
                          values.setBy === SET_BY_MARGIN_ENUMS.TYPE.value
                            ? "Search Types"
                            : "Search Categories"
                        }
                        onChangeValue={(option) => {
                          handleCategorySelect(
                            option,
                            setByFilter,
                            searchCategories,
                            setSearchCategories,
                            selectedCategories,
                            setSelectedCategories,
                            filterProductCategory
                          );
                        }}
                        isDisabled={isEdit}
                      />
                    </Col>
                  }
                  <Col md={12} className="p-0 mt-2">
                    <DataTable
                      className="categories-data-table"
                      columns={formattedColumns}
                      data={selectedCategories}
                      fixedHeader={true}
                      onSort={onCategoryTableSort}
                      defaultSortFieldId={defaultCategorySortFieldId}
                    />
                  </Col>
                  <Col md={5} className="mt-3 mb-3 p-0">
                    <SwitchButton
                      onSwitchChange={handleSwitchChange}
                      label={"Set Price Range"}
                      id="setpricerange"
                      className="switch-alignment"
                      isSwitchOn={modalCheckBoxStatus}
                    />
                  </Col>
                  <Col
                    md={7}
                    className="d-flex gap-2 mt-2 mb-2 p-0 justify-content-between"
                  >
                    {modalCheckBoxStatus && (
                      <>
                        <Col md={6} className=" ps-0 ">
                          <InputNumberField
                            name="minPrice"
                            placeHolder="Min Price"
                            type="number"
                          />
                        </Col>
                        <Col md={6} className=" pe-0 ">
                          <InputNumberField
                            name="maxPrice"
                            placeHolder="Max Price"
                            type="number"
                          />
                        </Col>
                      </>
                    )}
                  </Col>
                  <Row className=" m-0 offer-percentage-div ">
                    <Col
                      className=" ps-0 d-flex align-items-center  m-0 mb-3 "
                      md={8}
                    >
                      <span className="trade-offer-text m-0">
                        {" "}
                        Cash Offer Percentage:
                      </span>
                    </Col>
                    <Col md={4} className=" pe-0 mb-3 ">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="cashOffer"
                        placeHolder="35 %"
                        type="number"
                        maxValue={100}
                        IconImage={PercentageIcon}
                      />
                    </Col>
                    <Col
                      className=" ps-0 d-flex align-items-center  m-0 "
                      md={8}
                    >
                      <span className="trade-offer-text m-0">
                        {" "}
                        Trade Offer Percentage:
                      </span>
                    </Col>
                    <Col md={4} className=" pe-0 ">
                      <InputNumberField
                        className="input-field-background-tradeIns"
                        name="tradeOffer"
                        placeHolder="35 %"
                        type="number"
                        maxValue={100}
                        IconImage={PercentageIcon}
                      />
                    </Col>
                  </Row>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Col md={12} className=" m-0 d-flex gap-2">
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={toggleCustomTradeModal}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={
                      isEdit
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.SAVE
                    }
                  ></Button>
                </Col>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}; //-------TradeIns component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  tradeinMargin: state.tradeDiscount.tradeinMargin,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addGlobalMargin: (storeId, data) => {
    dispatch(tradeInDiscountActions.addGlobalMargin(storeId, data));
  },
  addCategoryMargin: (storeId, data, toggleCustomTradeModal, resetSearch) => {
    dispatch(
      tradeInDiscountActions.addCategoryMargin(
        storeId,
        data,
        toggleCustomTradeModal,
        resetSearch
      )
    );
  },
  updateCategoryMargin: (storeId, data, toggleCustomTradeModal) => {
    dispatch(
      tradeInDiscountActions.updateCategoryMargin(
        storeId,
        data,
        toggleCustomTradeModal
      )
    );
  },
  deleteCategoryMargin: (storeId, id) => {
    dispatch(tradeInDiscountActions.deleteCategoryMargin(storeId, id));
  },
  addMonthlyBudget: (storeId, data) => {
    dispatch(tradeInDiscountActions.addMonthlyBudget(storeId, data));
  },
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(TradeIns);

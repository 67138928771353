import moment from "moment";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import React, { useEffect, useState, useRef, useMemo } from "react";

import {
  LoadingIndicator,
  getDefaultFilterType,
  parseToThousandSeperatorDecimalNumber,
} from "../../../shared/utility";
import {
  inventoryActions,
  itemOrganizationActions,
} from "../../../redux/actions";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import DynamicDropDownList from "./DynamicDropdownList/DynamicDropdownList";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import SelectDropDown from "../../../shared/components/selectDropdown/SelectDropdown";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import SimpleNumberField from "../../../shared/components/inputNumberField/SimpleNumberField";
import FilterDropdowns from "../../../shared/components/FilterDropdowns/FilterDropdowns";
import {
  toastType,
  toastMessages,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  priceChangesConstants,
} from "../../../constants";
import {
  EditIcon,
  CrossIcon,
  ActionDotsIcons,
  FilterIcon,
  BlueBoxIcon,
  SearchIcon,
} from "../../../assets/icons/iconsProvider";
import {
  PRICE_CHANGE_FILTER_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../system/globalEnums";
import PriceChangesFilter from "./PriceChangesFilter/PriceChangesFilter";
import SearchField from "../../../shared/components/searchField/SearchField";
import FilterTags from "./PriceChangesFilter/FilterTags";

//-------default price changes table grid state
const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

const PriceChanges = (props) => {
  //-------Destructure Props
  const {
    currentStore,
    thresholdData,
    dismissProduct,
    getPriceChanges,
    itemOrganization,
    priceChangesData,
    getPriceChangeThreshold,
    addPriceChangeThreshold,
  } = props;

  const navigate = useNavigate();

  //-------Destructure Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  //-------UseRef
  let numOfRenders = useRef(0);

  //-------UseStates
  const [byDate, setByDate] = useState([]);
  const [byMonth, setByMonth] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState();
  const [isFilterModal, setIsFilterModal] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  // const [data, setData] = useState(priceChangesData.priceChangesProducts);
  const [gridState, setGridState] = useState(defaultGridState);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [thresholdFilterList, setThresholdFilterList] = useState([]);
  const [timeFilter, setTimeFilter] = useState(globalConstants.DAILY);
  const [globalValue, setGlobalValue] = useState(0);
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL)
  );
  const [searchProductCategory, setSearchProductCategory] = useState("");
  const [searchProductSubCategory, setSearchProductSubCategory] = useState("");
  const [thresholdFilter, setThresholdFilter] = useState(
    priceChangesConstants.FILTER_OPTIONS[thresholdData?.activeThreshold || 0]
  );
  const [graphData, setGraphData] = useState(
    priceChangesData?.priceChangesGraphData || []
  );

  const toggleFilterModal = () => {
    setIsFilterModal(!isFilterModal);
  };

  //-------Deafult Column Headers Fro Price Changes Table
  const columnHeaders = [
    {
      id: "product_name",
      name: "Name",
      selector: (row) => row.product_name,
      cell: (row) => {
        return (
          <span
            className={`inventory-cell-max-size ${
              row.price.isDismiss && "price-changes-dismissed"
            }`}
          >
            {row.product_name}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "condition",
      name: "Condition",
      selector: (row) => row.price.type,
      cell: (row) => {
        return (
          <span
            className={`inventory-cell-max-size ${
              row.price.isDismiss && "price-changes-dismissed"
            }`}
          >
            {row?.price?.type}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "productType",
      name: "Type",
      selector: (row) => row.productType,
      cell: (row) => {
        return (
          <span
            className={`inventory-cell-max-size ${
              row.price.isDismiss && "price-changes-dismissed"
            }`}
          >
            {row.productType}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "category_name",
      name: "Category",
      selector: (row) => row.category_name,
      cell: (row) => {
        return (
          <span
            className={`inventory-cell-max-size ${
              row.price.isDismiss && "price-changes-dismissed"
            }`}
          >
            {row.category_name}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "unit_sell_price",
      name: (
        <TableCustomHeader
          id="unit_sell_price"
          label="Unit Sel..."
          tooltopText="Unit Sell Price"
        />
      ),
      selector: (row) => row.price.unit_sell_price,
      cell: (row) => (
        <span
          className={`inventory-cell-max-size ${
            row.price.isDismiss && "price-changes-dismissed"
          }`}
        >
          ${parseToThousandSeperatorDecimalNumber(row.price?.unit_sell_price)}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "currentMarketPrice",
      name: (
        <TableCustomHeader
          label="Current M..."
          id="currentMarketPrice"
          tooltopText="Current Market Price"
        />
      ),
      selector: (row) => row.price.marketPrice,
      cell: (row) => (
        <span
          className={`inventory-cell-max-size ${
            row.price.isDismiss && "price-changes-dismissed"
          }`}
        >
          ${parseToThousandSeperatorDecimalNumber(row.price?.marketPrice)}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "percentageDifference",
      name: (
        <TableCustomHeader
          id="percentageDifference"
          label="Perce..."
          tooltopText="Percentage Difference"
        />
      ),
      selector: (row) => row.price.percentageDifference,
      cell: (row) => (
        <span
          className={`inventory-cell-max-size 
          ${row.price.isDismiss && "price-changes-dismissed "}
          ${row.price?.percentageDifference < 0 ? " text-red" : "text-purple"}
          `}
        >
          {`${parseToThousandSeperatorDecimalNumber(
            row.price?.percentageDifference
          )}%`}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "cashDifference",
      name: (
        <TableCustomHeader
          id="cashDifference"
          label="Cash..."
          tooltopText="Cash Difference"
        />
      ),
      selector: (row) => row.price.cashDifference,
      cell: (row) => (
        <span
          className={`inventory-cell-max-size 
            ${row.price.isDismiss && "price-changes-dismissed"}
            ${row.price?.cashDifference < 0 ? " text-red" : "text-purple"}
            `}
        >
          {`$${parseToThousandSeperatorDecimalNumber(
            row.price?.cashDifference
          )}`}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                className="w-100"
                IconImage={EditIcon}
                label={buttonNameConstants.CHANGE_PRICE}
                handleClick={() => handleEditClick(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
              <Button
                className="w-100"
                IconImage={CrossIcon}
                label={buttonNameConstants.DISMISS}
                handleClick={() => handleDismissProduct(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "10%",
    },
  ];

  const handleSearchInputChange = (value) => {
    setIsSearchLoading(true);
    setDebouncedSearchText(value);
    handleDebouncedSearch(value);
  };

  const handleDebouncedSearch = useDebouncedCallback((value) => {
    setSearchText(value);
    setIsSearchLoading(false);
  }, 1000);

  const clearSearchText = () => {
    handleSearchInputChange("");
  };
  // //------Get Configuration Data for price changes graph
  // const getLineChartConfiguration = (filter) => {
  //   let labels = [];
  //   let data = [];
  //   const lastDate = moment().subtract(1, "days");
  //   const firstDate = moment().subtract(7, "days");
  //   return {
  //     options: {
  //       chart: {
  //         height: 350,
  //         type: "area",
  //       },
  //       colors: ["#6A18ED", "rgba(106, 24, 237, .3)"],
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         curve: "smooth",
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       markers: {
  //         size: 5,
  //       },
  //       xaxis: {
  //         type: "datetime",
  //         name: "Sales",
  //         min: new Date(firstDate.format("YYYY-MM-DD")).getTime(),
  //         max: new Date(lastDate.format("YYYY-MM-DD")).getTime(),
  //         labels: {
  //           datetimeUTC: false,
  //           datetimeFormatter: {
  //             year: "yyyy",
  //             month: "MMM 'yy",
  //             day: "dd MMM",
  //             hour: "hh:mm tt",
  //           },
  //         },
  //       },
  //     },
  //     series: [
  //       {
  //         name: "Total COGS",

  //         data:
  //           timeFilter === globalConstants.DAILY
  //             ? graphData?.map((js) => {
  //                 return {
  //                   x: new Date(js.date_added).getTime(),
  //                   y: Number(js?.totalCogs ?? 0).toFixed(2),
  //                 };
  //               })
  //             : timeFilter === globalConstants.MONTHLY
  //             ? byDate.map((js) => {
  //                 return {
  //                   x: new Date(js.date_added).getTime(),
  //                   y: Number(js.totalCogs).toFixed(2),
  //                 };
  //               })
  //             : byMonth.map((js) => {
  //                 return {
  //                   x: js.date_added,
  //                   y: Number(js.totalCogs).toFixed(2),
  //                 };
  //               }),
  //       },
  //       {
  //         name: "Total Market Price",

  //         data:
  //           timeFilter === globalConstants.DAILY
  //             ? graphData?.map((js) => {
  //                 return {
  //                   x: new Date(js.date_added).getTime(),
  //                   y: Number(js?.totalMarketPrice ?? 0).toFixed(2),
  //                 };
  //               })
  //             : timeFilter === globalConstants.MONTHLY
  //             ? byDate.map((js) => {
  //                 return {
  //                   x: new Date(js.date_added).getTime(),
  //                   y: Number(js.totalMarketPrice).toFixed(2),
  //                 };
  //               })
  //             : byMonth.map((js) => {
  //                 return {
  //                   x: js.date_added,
  //                   y: Number(js.totalMarketPrice).toFixed(2),
  //                 };
  //               }),
  //       },
  //     ],
  //   };
  // };

  // //-------get options and series for price changes graph
  // const { options, series } = getLineChartConfiguration();

  //-------handle dismiss all products
  const handleDismissAll = () => {
    if (priceChangesData.priceChangesProducts?.length > 0) {
      dismissProduct(
        currentStore?.id,
        priceChangesData.priceChangesProducts?.map((item) => item.id)
      );
    }
  };

  //-------handle edit product click
  const handleEditClick = (row) => {
    navigate(routesPathConstants.CHANGE_PRICE, {
      state: {
        data: row,
        currentStore: currentStore,
      },
    });
  };

  //-------handle dismiss single product
  const handleDismissProduct = (row) => {
    dismissProduct(currentStore?.id, [row.id]);
  };

  //-------Handle table sort
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  //-------Update gridState
  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------Handle searchfield
  const handleFilterData = (e) => {
    const filterList = gridData?.filter((row) => {
      return (
        String(
          row.product_name +
            row.condition +
            row.category_name +
            row.productType +
            row.price.unit_sell_price +
            row.price.cashDifference +
            row.price.percentageDifference +
            row.price.marketPrice
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset pagination
    numOfRenders.current++;
    return filterList;
  };

  // //-------handle save threshold
  // const handleSaveThresholdClick = () => {
  //   if (!thresholdFilterList.some((item) => item.selectedOption === null)) {
  //     const dataToSend = {
  //       id: thresholdData?.id || inventoryConstants.INITIAL_THRESHOLD_ID,
  //       byCategoryAndPercent:
  //         thresholdFilter.value ===
  //         PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_PERCENTAGE.value
  //           ? thresholdFilterList.map((item) => ({
  //               categoryId: item.selectedOption.value,
  //               categoryName: item.selectedOption.label,
  //               value: item.minimumThreshold,
  //             }))
  //           : thresholdData?.byCategoryAndPercent || [],
  //       byCategoryAndValue:
  //         thresholdFilter.value ===
  //         PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_VALUE.value
  //           ? thresholdFilterList.map((item) => ({
  //               categoryId: item.selectedOption.value,
  //               categoryName: item.selectedOption.label,
  //               value: item.minimumThreshold,
  //             }))
  //           : thresholdData?.byCategoryAndValue || [],
  //       byPriceRangeAndPercent:
  //         thresholdFilter.value ===
  //         PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_PERCENTAGE.value
  //           ? thresholdFilterList.map((item) => ({
  //               min: item.selectedOption.value.min,
  //               max: item.selectedOption.value.max,
  //               value: item.minimumThreshold,
  //             }))
  //           : thresholdData?.byPriceRangeAndPercent || [],
  //       byPriceRangeAndValue:
  //         thresholdFilter.value ===
  //         PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_VALUE.value
  //           ? thresholdFilterList.map((item) => ({
  //               min: item.selectedOption.value.min,
  //               max: item.selectedOption.value.max,
  //               value: item.minimumThreshold,
  //             }))
  //           : thresholdData?.byPriceRangeAndValue || [],
  //       global: {
  //         byCategoryAndPercent: {
  //           value:
  //             thresholdFilter.value ===
  //             PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_PERCENTAGE.value
  //               ? globalValue
  //               : thresholdData?.global?.byCategoryAndPercent?.value || 0,
  //         },
  //         byCategoryAndValue: {
  //           value:
  //             thresholdFilter.value ===
  //             PRICE_CHANGE_FILTER_ENUMS?.BY_CATEGORY_AND_VALUE?.value
  //               ? globalValue
  //               : thresholdData?.global?.byCategoryAndValue?.value || 0,
  //         },
  //         byPriceRangeAndPercent: {
  //           value:
  //             thresholdFilter.value ===
  //             PRICE_CHANGE_FILTER_ENUMS?.BY_PRICE_RANGE_AND_PERCENTAGE?.value
  //               ? globalValue
  //               : thresholdData?.global.byPriceRangeAndPercent?.value || 0,
  //         },
  //         byPriceRangeAndValue: {
  //           value:
  //             thresholdFilter.value ===
  //             PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_VALUE.value
  //               ? globalValue
  //               : thresholdData?.global?.byPriceRangeAndValue?.value || 0,
  //         },
  //       },
  //       activeThreshold: Number(thresholdFilter.value),
  //       activeThresholdValueType:
  //         thresholdFilter.value ===
  //           PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_PERCENTAGE.value ||
  //         thresholdFilter.value ===
  //           PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_PERCENTAGE.value
  //           ? 0
  //           : 1,
  //       store: {
  //         id: currentStore?.id,
  //         name: currentStore?.storeName,
  //       },
  //     };

  //     if (dataToSend.id === inventoryConstants.INITIAL_THRESHOLD_ID) {
  //       delete dataToSend["id"];
  //     }
  //     addPriceChangeThreshold(dataToSend);
  //   } else {
  //     customToast(toastMessages.PLEASE_SELECT_ALL_OPTIONS, toastType.ERROR);
  //   }
  // };

  // //-------hanlde on array dropdown filter change
  // const onDropDownValueChange = (filterList) => {
  //   setThresholdFilterList(filterList);
  // };

  // //-------handle filter change
  // const handleThresholdFilterChange = (value) => {
  //   setThresholdFilter(value);
  // };

  //-------useeffect

  const sortByIsDismiss = (a, b) => {
    return a.price.isDismiss - b.price.isDismiss;
  };

  const gridData = useMemo(() => {
    // let filteredData = allInventory;
    let filteredData = priceChangesData.priceChangesProducts;

    if (searchProductType?.label !== PRODUCT_TYPES_ENUMS.ALL) {
      filteredData = filteredData?.filter(
        (item) => item.productType === searchProductType?.label
      );
    }

    if (searchProductCategory?.value) {
      filteredData = filteredData?.filter(
        (item) => item.category_name === searchProductCategory?.label
      );
    }

    if (searchProductSubCategory?.value) {
      filteredData = filteredData?.filter(
        (item) => item.subcategory === searchProductSubCategory?.label
      );
    }

    filteredData = filteredData?.sort(sortByIsDismiss);

    return filteredData;
  }, [
    searchProductType,
    searchProductCategory,
    searchProductSubCategory,
    priceChangesData.priceChangesProducts,
  ]);

  useEffect(() => {
    if (currentStore?.id) {
      setColumns(columnHeaders);
      getPriceChangeThreshold(currentStore?.id);
      getPriceChanges(currentStore?.id);
    }
  }, [currentStore?.id]);

  useEffect(() => {
    setColumns(columnHeaders);
  }, [thresholdFilter]);

  useEffect(() => {
    if (priceChangesData?.priceChangesGraphData?.length > 0) {
      const updatedGraphData =
        priceChangesData?.priceChangesGraphData
          .reverse()
          .map((item, index) => ({
            ...item,
            date_added: moment()
              .subtract(index + 1, "days")
              .format("YYYY-MM-DD"),
          })) || [];

      setGraphData(updatedGraphData);
    } else {
      setGraphData([]);
    }
  }, [priceChangesData]);

  useEffect(() => {
    setGlobalValue(
      thresholdFilter.value ===
        PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_PERCENTAGE.value
        ? thresholdData?.global?.byCategoryAndPercent?.value || 0
        : thresholdFilter.value ===
          PRICE_CHANGE_FILTER_ENUMS.BY_CATEGORY_AND_VALUE.value
        ? thresholdData?.global?.byCategoryAndValue?.value || 0
        : thresholdFilter.value ===
          PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_PERCENTAGE.value
        ? thresholdData?.global?.byPriceRangeAndPercent?.value || 0
        : thresholdFilter.value ===
          PRICE_CHANGE_FILTER_ENUMS.BY_PRICE_RANGE_AND_VALUE.value
        ? thresholdData?.global?.byPriceRangeAndValue?.value || 0
        : 0
    );
  }, [thresholdFilter]);

  useEffect(() => {
    setSearchProductType(
      getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL)
    );
  }, [customTypes]);

  return (
    <>
      <Topbar
        title="Price Changes"
        // countChip={true}
        // exportButton={true}
        // isDismissAll={true}
        // IconImage={FilterIcon}
        // handleClick={toggleFilterModal}
        // totalCustomers={gridData?.length || 0}
        // label={buttonNameConstants.FILTER_SETTINGS}
        // handleDismissAllClick={handleDismissAll}
      >
        <div className="price-change-filter-tag">
          Minimum Difference:{" "}
          {thresholdData?.global?.valueType ?? true ? "$" : "%"}
          {parseToThousandSeperatorDecimalNumber(
            thresholdData?.global?.value ?? 0
          )}
        </div>
        <Button
          label={buttonNameConstants.MANAGE}
          handleClick={toggleFilterModal}
        />
      </Topbar>
      <Row className="m-0 h-100">
        <Col md={6} className="mb-3">
          <SearchField
            onChange={(e) => handleSearchInputChange(e.target.value)}
            IconImage={SearchIcon}
            placeHolder="Search"
            value={debouncedSearchText}
            CrossIcon={debouncedSearchText ? CrossIcon : ""}
            clearSearchText={clearSearchText}
            BlueBoxIcon={BlueBoxIcon}
            LoadingIndicator={isSearchLoading && LoadingIndicator}
          />
        </Col>
        <Col md={6} className="ps-0">
          <FilterDropdowns
            customTypes={customTypes}
            customCategories={customCategories}
            customSubCategories={customSubCategories}
            setSearchProductType={setSearchProductType}
            setSearchProductCategory={setSearchProductCategory}
            setSearchProductSubCategory={setSearchProductSubCategory}
            defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
          />
        </Col>
        <Col md={12} className="mb-3">
          <FilterTags thresholdData={thresholdData} />
        </Col>
        <Col md={12} className="px-0 h-100">
          <Row className="m-0 w-100 h-100 user-screen-wrapper">
            <DataTableComponent
              data={searchText ? handleFilterData() : gridData}
              columns={columns}
              onSort={onTableSort}
              total={gridData?.length}
              gridState={gridState}
              key={numOfRenders.current}
              setGridState={updateGridState}
              className={
                thresholdData?.byCategory?.length ||
                thresholdData?.byPriceRange?.length
                  ? "pricechanges-filter-table-height"
                  : "pricechanges-table-height"
              }
              defaultSortFieldId={defaultSortFieldId}
            />
          </Row>
        </Col>
        {/* <Col md={4} className="px-0">
          <Row className="m-0">
            <Col md={12} className="  pb-3">
              <div className="price-changes-graph-wrapper">
                <ApexCharts
                  type="area"
                  height={215}
                  series={series}
                  options={options}
                />
              </div>
            </Col>
            <Col md={12} className="pt-2">
              <div className="threshold-filter-wrapper">
                <Row className="m-0">
                  <Col md={8} className="d-flex align-items-center">
                    <span className="price-chart-card-heading">
                      Minimum Thresholds
                    </span>
                  </Col>
                  <Col
                    md={4}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Button
                      label={buttonNameConstants.SAVE}
                      className="price-changes-card-button"
                      handleClick={() => {
                        handleSaveThresholdClick();
                      }}
                    />
                  </Col>
                  <Col md={12} className="py-1">
                    <SelectDropDown
                      menuPlacement="auto"
                      options={priceChangesConstants.FILTER_OPTIONS}
                      defaultPageSize={
                        priceChangesConstants.FILTER_OPTIONS[
                          thresholdData?.activeThreshold || 0
                        ]
                      }
                      handleChange={(value) =>
                        handleThresholdFilterChange(value)
                      }
                    />
                  </Col>

                  <Col md={6} className="mt-2">
                    <div className="global-field-heading">Global</div>
                  </Col>
                  <Col md={6} className="mt-2">
                    <SimpleNumberField
                      value={globalValue}
                      placeHolder="Minimum"
                      className="minimum-number-field"
                      maxValue={inventoryConstants.THRESHOLD_MAX_CASH_VALUE}
                      onChange={(value) => {
                        setGlobalValue(value);
                      }}
                    />
                  </Col>
                  <Col md={12} className="mt-2">
                    <DynamicDropDownList
                      thresholdData={thresholdData}
                      thresholdFilter={thresholdFilter}
                      allCategories={allCategories || []}
                      onDropDownValueChange={onDropDownValueChange}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
      {isFilterModal && (
        <PriceChangesFilter
          customTypes={customTypes}
          currentStore={currentStore}
          isFilterModal={isFilterModal}
          thresholdData={thresholdData}
          customCategories={customCategories}
          toggleFilterModal={toggleFilterModal}
          addPriceChangeThreshold={addPriceChangeThreshold}
        />
      )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  thresholdData: state.inventory.thresholdData,
  priceChangesData: state.inventory.priceChangesData,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPriceChangeThreshold: (storeId) =>
    dispatch(inventoryActions.getPriceChangeThreshold(storeId)),
  addPriceChangeThreshold: (data, handleHideModal) =>
    dispatch(inventoryActions.addPriceChangeThreshold(data, handleHideModal)),
  dismissProduct: (storeId, data) =>
    dispatch(inventoryActions.dismissProduct(storeId, data)),
  getPriceChanges: (storeId) =>
    dispatch(inventoryActions.getPriceChanges(storeId)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(PriceChanges);

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { SIDEBAR_BUTTONS } from "../../../constants";
import {
  systemConstants,
  ROLES,
  globalConstants,
  routesPathConstants,
} from "../../../constants";
import SidebarFooter from "./SidebarFooter";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../system/globalEnums";
import {
  AdminIcon,
  CustomersIcon,
  DashboardIcon,
  EmployeesIcon,
  InventoryIcon,
  SellTradeIcon,
  SettingsIcon,
} from "../../../assets/icons/iconsProvider";

const SidebarBody = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { activeSidebarBtn, setActiveSidebarBtn } = props;

  useEffect(() => {
    let path = String(pathname).toLowerCase();
    switch (true) {
      case path.includes(
        String(
          SIDEBAR_BUTTONS.SELL_AND_TRADE.replaceAll(
            "_",
            globalConstants.EMPTY_STRING
          )
        ).toLowerCase()
      ):
        setActiveSidebarBtn(SIDEBAR_BUTTONS.SELL_AND_TRADE);
        break;
      case path.includes(String(SIDEBAR_BUTTONS.INVENTORY).toLowerCase()):
        setActiveSidebarBtn(SIDEBAR_BUTTONS.INVENTORY);
        break;
      case path.includes(String(SIDEBAR_BUTTONS.EMPLOYEES).toLowerCase()):
        setActiveSidebarBtn(SIDEBAR_BUTTONS.EMPLOYEES);
        break;
      case path.includes(String(SIDEBAR_BUTTONS.CUSTOMERS).toLowerCase()):
        setActiveSidebarBtn(SIDEBAR_BUTTONS.CUSTOMERS);
        break;
      case path.includes(String(SIDEBAR_BUTTONS.SETTINGS).toLowerCase()):
        setActiveSidebarBtn(SIDEBAR_BUTTONS.SETTINGS);
        break;
      case path.includes(String(SIDEBAR_BUTTONS.ADMIN).toLowerCase()):
        setActiveSidebarBtn(SIDEBAR_BUTTONS.ADMIN);
        break;
      default:
        setActiveSidebarBtn(SIDEBAR_BUTTONS.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <div className="d-flex flex-column h-100">
      <ul className="m-0 p-0 d-flex flex-column justify-content-start align-items-center">
        {props.user.role !== ROLES.EMPLOYEE && (
          <li
            className={`${
              activeSidebarBtn === SIDEBAR_BUTTONS.DASHBOARD &&
              "active-sidebar-btn"
            } d-flex justify-content-center align-items-center flex-column`}
            onClick={() => {
              navigate(routesPathConstants.DASHBOARD);
            }}
          >
            <DashboardIcon />
            Dashboard
          </li>
        )}
        <li
          className={`${
            activeSidebarBtn === SIDEBAR_BUTTONS.SELL_AND_TRADE &&
            "active-sidebar-btn"
          } d-flex justify-content-center align-items-center flex-column`}
          onClick={() => {
            navigate(routesPathConstants.SELL_AND_TRADE);
          }}
        >
          <SellTradeIcon />
          Sell/Trade
        </li>
        <li
          className={`${
            activeSidebarBtn === SIDEBAR_BUTTONS.INVENTORY &&
            "active-sidebar-btn"
          } d-flex justify-content-center align-items-center flex-column`}
          onClick={() => {
            navigate(routesPathConstants.INVENTORY);
          }}
        >
          <InventoryIcon />
          Inventory
        </li>

        <li
          className={`${
            activeSidebarBtn === SIDEBAR_BUTTONS.CUSTOMERS &&
            "active-sidebar-btn"
          } d-flex justify-content-center align-items-center flex-column`}
          onClick={() => {
            navigate(routesPathConstants.CUSTOMERS);
          }}
        >
          <CustomersIcon />
          Customers
        </li>
        {props.user.role !== ROLES.EMPLOYEE && (
          <li
            className={`${
              activeSidebarBtn === SIDEBAR_BUTTONS.EMPLOYEES &&
              "active-sidebar-btn"
            } d-flex justify-content-center align-items-center flex-column`}
            onClick={() => {
              navigate(routesPathConstants.SETTINGS_USERS);
            }}
          >
            <EmployeesIcon />
            Employee
          </li>
        )}
        <li
          className={`${
            activeSidebarBtn === SIDEBAR_BUTTONS.SETTINGS &&
            "active-sidebar-btn"
          } d-flex justify-content-center align-items-center flex-column`}
          onClick={() => {
            navigate(
              props.user.role === ROLES.EMPLOYEE
                ? routesPathConstants.SETTINGS_PRINTER_SETTINGS
                : props.currentStore.activeCardPaymentType ===
                  STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE
                ? routesPathConstants.SETTINGS_DEVICES
                : routesPathConstants.SETTINGS_DISCOUNT
            );
          }}
        >
          <SettingsIcon />
          Settings
        </li>
        {props.user.role === ROLES.ADMIN && (
          <li
            className={`${
              activeSidebarBtn === SIDEBAR_BUTTONS.ADMIN && "active-sidebar-btn"
            } d-flex justify-content-center align-items-center flex-column`}
            onClick={() => {
              navigate(routesPathConstants.ADMIN);
            }}
          >
            <AdminIcon />
            Admin
          </li>
        )}
      </ul>
      <SidebarFooter />
    </div>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  activeSidebarBtn: state.system.activeSidebarBtn,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setActiveSidebarBtn: (data) =>
    dispatch({ type: systemConstants.SET_ACTIVE_SIDEBAR_BTN, data: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarBody);

import React from "react";
import Draggable from "react-draggable";
import { useScreenWidth, useScreenHeight } from "../../hooks/windowResize";
import { TailSpin } from "react-loader-spinner";
import { connect } from "react-redux";

const DraggableNotification = (props) => {
  const { isInventoryImporting } = props;
  const [screenWidth, setScreenWidth] = useScreenWidth();
  const [screenHeight, setScreenHeight] = useScreenHeight();

  const notificationWidth = 220;
  const notificationHeight = 70;

  const handleBounds = () => {
    return {
      left: 10,
      top: 10,
      right: screenWidth - notificationWidth,
      bottom: screenHeight - notificationHeight,
    };
  };

  return (
    isInventoryImporting.isVisible && (
      <Draggable
        handle=".draggable-notification"
        bounds={handleBounds()}
        defaultPosition={{
          x: screenWidth - notificationWidth,
          y: screenHeight - notificationHeight,
        }}
      >
        <div className="draggable-notification d-flex justify-content-center align-items-center gap-3">
          <TailSpin
            type="TailSpin"
            color="#6a18ed"
            height={25}
            width={25}
            strokeWidth={4}
          />
          <p className="m-0">{isInventoryImporting.message}</p>
        </div>
      </Draggable>
    )
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isInventoryImporting: state.inventory.isInventoryImporting,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

//-------Export AddNewUser Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggableNotification);

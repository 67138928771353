import React, { useState } from "react";
import { connect } from "react-redux";

import Button from "../Buttons/Button";
import { buttonNameConstants } from "../../../constants";

import { printerActions } from "../../../redux/actions";

const ToastPrintLabel = (props) => {
  const { userNotifications, setPrintLabelModal } = props;

  const handleClickPrintToastButton = () => {
    setPrintLabelModal(true);
  };

  return (
    <>
      <Button
        handleClick={handleClickPrintToastButton}
        label={buttonNameConstants.PRINT_LABEL}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userNotifications: state.store.userNotifications,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setPrintLabelModal: (data) =>
    dispatch(printerActions.setPrintLabelModal(data)),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(ToastPrintLabel);

import React from "react";

//-------SearchField component start here
const SearchField = (props) => {
  const {
    onChange,
    IconImage,
    placeHolder,
    className,
    BlueBoxIcon,
    LoadingIndicator,
    value,
    isFocus = false,
    CrossIcon,
    clearSearchText,
    isDisabled = false,
    onClick,
    onBlur,
  } = props;
  return (
    <div className="input-field-wrapper w-100">
      {IconImage && <IconImage className="icon-image" />}
      <input
        className={`input-textfield ${className} ${
          IconImage || "input-textfield-without-icon"
        } ${className && className}`}
        type="text"
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        value={value}
        placeholder={placeHolder}
        autoComplete="off"
        autoFocus={isFocus}
        disabled={isDisabled}
      />
      {LoadingIndicator && <LoadingIndicator />}

      {value
        ? CrossIcon && (
            <span className="icon-image" onClick={clearSearchText}>
              <CrossIcon />
            </span>
          )
        : BlueBoxIcon && <BlueBoxIcon className="icon-image" />}
    </div>
  );
}; //-------SearchField component end here

//-------Export SearchField Component
export default SearchField;

import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const DropdownSkeleton = ({
  width = "12em",
  height = "3em",
  borderRadius = ".75em",
}) => {
  return (
    <Skeleton
      style={{ width: width, height: height, borderRadius: borderRadius }}
    />
  );
};

export default DropdownSkeleton;

import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.css";
import DraggableNotification from "./shared/components/draggableNotification/DraggableNotification";
import { sentryInit } from "./system/sentry";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";

import "./assets/scss/index.scss";
import App from "./container/App/App";
import store from "./system/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
sentryInit();
root.render(
  <Fragment>
    {/* Main Redux Store */}
    <Provider store={store}>
      <DraggableNotification />
      <ToastContainer className="toast-message-popup" />
      <SkeletonTheme>
        <App />
      </SkeletonTheme>
    </Provider>
  </Fragment>
);

import React, { useState } from "react";

import { DeleteIcon } from "../../../assets/icons/iconsProvider";
import VerticalNumberField from "../inputNumberField/VerticalNumberField";
import {
  getProductDetailCardTags,
  getShortestSku,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";
import { inventoryConstants, transactionConstants } from "../../../constants";

const ProductCartCard = ({
  productName,
  skuNumber,
  price,
  handleDeleteCartItem,
  quantity,
  max,
  handleQuantityChange,
  discountPercentage,
  actualPrice,
  handleDiscountChange,
  isDiscountApplied,
  handlePriceChange,
  category,
  productObject,
  isNewTransaction = false,
}) => {
  const handleLocalQuantityChange = (sku, newQuantity) => {
    handleQuantityChange(sku, newQuantity);
  };

  return (
    <div className="cart-card d-flex align-items-center gap-2 justify-content-between mb-2">
      <VerticalNumberField
        sku={skuNumber}
        quantity={quantity}
        maxValue={max}
        onChange={handleLocalQuantityChange}
      />
      <div
        className={`cart-card-name-wrapper ${
          !discountPercentage > 0 && "cart-card-name-without-discount"
        } d-flex flex-column gap-1`}
      >
        <span>{productName}</span>
        <span className="d-flex align-items-center gap-2">
          {category === transactionConstants.MISCELLANEOUS_SALE_ITEM
            ? transactionConstants.MISCELLANEOUS
            : category}
          {isNewTransaction && ` (${productObject.type}) `}
          {isNewTransaction &&
            getProductDetailCardTags(
              productObject.tags?.filter(
                (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
              ),
              skuNumber
            )}
        </span>
        <span>{`#${getShortestSku(skuNumber)}`}</span>
      </div>
      {discountPercentage > 0 ? (
        <div className="cart-card-discount-wrapper d-flex justify-content-center align-items-center gap-2">
          {isDiscountApplied && (
            <span className="text-decoration-line-through">
              ${parseToThousandSeperatorDecimalNumber(actualPrice)}
            </span>
          )}
          <span
            className={`cursor-pointer ${
              !isDiscountApplied
                ? "opacity-50 text-decoration-line-through"
                : "discounted-amount"
            }`}
            onClick={() => handleDiscountChange(skuNumber)}
          >
            -{discountPercentage}%
          </span>
        </div>
      ) : (
        ""
      )}

      <div className=" d-flex justify-content-center align-items-center gap-2">
        <div className="amount-to-paid-field-wrapper product-cart-card-price">
          <span className="dollar-icon-alignment">$</span>
          <input
            type="number"
            className="amount-to-paid-field"
            value={price}
            step={0.01}
            onChange={(e) => {
              handlePriceChange(skuNumber, e.target.value);
            }}
            onBlur={(e) => {
              const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
              handlePriceChange(skuNumber, inputValue);
            }}
            max={999999}
            min={1}
          />
        </div>
        <button
          type="button"
          className="cart-card-delete-button"
          onClick={() => handleDeleteCartItem(skuNumber)}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};

export default ProductCartCard;

import { printerServices } from "../../services";
import { customToast } from "../../shared/utility";
import { custom, failure, success } from "./actionsUtilities";
import {
  systemConstants,
  toastType,
  printerConstants,
  globalConstants,
  toastMessages,
} from "../../constants";
import { getItemFromLocalStorage } from "../../system/storage";

//-------get All store printers
const getAvailablePrinters = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        printerConstants.GET_AVAILABLE_PRINTERS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    printerServices
      .getAvailablePrinters(storeId)
      .then(
        (response) => {
          const responseObject = {
            avialablePrinters: response,
            defaultReceiptPrinter:
              response?.find(
                (printer) =>
                  printer.isDefault === true &&
                  printer.printerType === printerConstants.RECEIPT_PRINTER &&
                  printer.localDeviceIp ===
                    getItemFromLocalStorage(printerConstants.LOCAL_DEVICE_IP)
              ) || globalConstants.EMPTY_STRING,
            defaultZeraPrinter:
              response?.find(
                (printer) =>
                  printer.isDefault === true &&
                  printer.printerType === printerConstants.ZEBRA_PRINTER &&
                  printer.localDeviceIp ===
                    getItemFromLocalStorage(printerConstants.LOCAL_DEVICE_IP)
              ) || globalConstants.EMPTY_STRING,
          };
          dispatch(
            success(responseObject, printerConstants.GET_AVAILABLE_PRINTERS)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(failure([], printerConstants.GET_AVAILABLE_PRINTERS));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            printerConstants.GET_AVAILABLE_PRINTERS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------add new printer
const addNewPrinter = (data, storeId) => {
  return (dispatch) => {
    dispatch(
      custom(printerConstants.ADD_NEW_PRINTER, systemConstants.SPINNER_ACTIVATE)
    );
    printerServices
      .addNewPrinter(data)
      .then(
        (response) => {
          customToast(
            toastMessages.SUCCESSFULLY_ADD_NEW_PRINTER,
            toastType.SUCCESS
          );
          dispatch(getAvailablePrinters(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            printerConstants.ADD_NEW_PRINTER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------set default printer
const setDefaultPrinter = (data, storeId) => {
  return (dispatch) => {
    dispatch(
      custom(printerConstants.ADD_NEW_PRINTER, systemConstants.SPINNER_ACTIVATE)
    );
    printerServices
      .setDefaultPrinter(data)
      .then(
        (response) => {
          customToast(
            toastMessages.SET_DEFAULT_PRINTER_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getAvailablePrinters(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            printerConstants.ADD_NEW_PRINTER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const setPrintLabelModal = (data) => {
  return (dispatch) => {
    dispatch(custom(data, printerConstants.SET_PRINT_LABEL_MODAL));
  };
};

//-------Export Authentication Actions
export const printerActions = {
  getAvailablePrinters,
  addNewPrinter,
  setDefaultPrinter,
  setPrintLabelModal,
};

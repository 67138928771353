import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputGenericField from "../../../../shared/components/inputGenericField/InputGenericField";
import { addCustomerValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import SelectFieldCity from "../../../../shared/components/selectField/SelectFieldCity";
import SelectFieldState from "../../../../shared/components/selectField/SelectFieldState";
import Button from "../../../../shared/components/Buttons/Button";
import { customerActions, transactionActions } from "../../../../redux/actions";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";

const AddNewCustomerModal = (props) => {
  const {
    addCustomerModal,
    store,
    addNewCustomer,
    toggleAddCustomerModal,
    selectedCustomer,
  } = props;

  const { currentStore } = store;

  const initialValues = {
    firstName: globalConstants.EMPTY_STRING,
    lastName: globalConstants.EMPTY_STRING,
    email: globalConstants.EMPTY_STRING,
    mobile: globalConstants.EMPTY_STRING,
    driverLicense: globalConstants.EMPTY_STRING,
    line1: globalConstants.EMPTY_STRING,
    line2: globalConstants.EMPTY_STRING,
    city: globalConstants.EMPTY_STRING,
    state: globalConstants.EMPTY_STRING,
    zip: globalConstants.EMPTY_STRING,
    currentBalance: 0,
    isSmsAlert: false,
    isEmailAlert: false,
    productNotification: [],
    companyCustomerId: 0,
  };

  const handleHideModal = () => {
    toggleAddCustomerModal(false);
  };

  const handleSubmit = (data) => {
    selectedCustomer(true);
    addNewCustomer({
      ...data,
      store: {
        id: currentStore?.id,
        name: currentStore?.storeName,
      },
      companyId: currentStore?.companyId,
    });
  };

  return (
    <Modal
      show={addCustomerModal}
      size="lg"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">New Customer</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Formik
          initialValues={initialValues}
          validate={addCustomerValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ resetForm }) => (
            <Form className="">
              <Row className="m-0  p-0">
                <Row className="p-0">
                  <p className="form-portion-heading">Personal Information</p>
                  <Col md={6} className="mb-3 ">
                    <InputTextField
                      name="firstName"
                      label="First Name"
                      placeHolder="First Name"
                    />
                  </Col>
                  <Col md={6} className="mb-3 ">
                    <InputTextField
                      name="lastName"
                      label="Last Name"
                      placeHolder="Last Name"
                    />
                  </Col>
                  <Col md={6} className="mb-3 ">
                    <InputTextField
                      name="email"
                      label="Email"
                      placeHolder="Email"
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <InputGenericField
                      name="mobile"
                      type="tel"
                      placeHolder="Phone Number"
                      label="Phone Number"
                      inputMask="(999) 999-9999"
                    />
                  </Col>
                  <Col md={6} className="mb-3  ">
                    <InputTextField
                      name="driverLicense"
                      label="Driver License"
                      placeHolder="Driver License"
                    />
                  </Col>
                </Row>
                <Row className="p-0 mt-3">
                  <p className="form-portion-heading ">Address</p>
                  <Col md={6} className="mb-3 ">
                    <InputTextField
                      name="line1"
                      label="Street 1"
                      placeHolder="Enter Street 1"
                    />
                  </Col>
                  <Col md={6} className="mb-3 ">
                    <InputTextField
                      name="line2"
                      label="Street 2"
                      placeHolder="Enter Street 2"
                    />
                  </Col>
                  <Col md={6} className="mb-3 ">
                    <SelectFieldCity />
                  </Col>
                  <Col md={6} className="mb-3">
                    <SelectFieldState />
                  </Col>
                  <Col md={6} className="mb-3  ">
                    <InputTextField
                      name="zip"
                      placeHolder="00000"
                      label="Zip"
                    />
                  </Col>
                </Row>
                <Row className="p-0 mt-3">
                  <p className="form-portion-heading ">Customer Credit</p>
                  <Col md={8} className="mb-3 ">
                    <InputNumberField
                      name="currentBalance"
                      label="Current Balance"
                      placeHolder="Current Balance"
                      IconImage={DollarIcon}
                    />
                  </Col>
                  <Col md={4}></Col>
                  <Col md={8} className="mt-4 mb-3  d-flex gap-2 ">
                    <Button
                      type="button"
                      label={buttonNameConstants.CANCEL}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      className="w-100"
                      handleClick={() => {
                        resetForm();
                        handleHideModal();
                      }}
                    ></Button>
                    <Button
                      type="submit"
                      className="w-100"
                      label={buttonNameConstants.SAVE}
                    ></Button>
                  </Col>
                </Row>
                {/* <Col md={7}></Col> */}
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

// reducer
const mapStateToProps = (state) => ({
  store: state.store,
  customer: state.customer,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addNewCustomer: (data) => dispatch(customerActions.addNewCustomer(data)),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
});

//-------Export AddNewUser Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewCustomerModal);

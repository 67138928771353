export const inputTagDropDownStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: "2.5em",
    maxHeight: "2.5em",
    borderRadius: "100px",
    boxShadow: "none",
    marginLeft: "0px",
    marginRight: "0px",
    minWidth: "15em",
    maxWidth: "15em",
    backgroundColor: "#fcfafc",
    borderColor: state.isFocused ? "#6a18ed" : "#b7b5ba",
    "&:hover": {
      border: "1px solid #6a18ed",
      "dropdown-icon": {
        color: "red",
      },
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 5,
    borderRadius: ".75em",
    overflow: "hidden",
    padding: ".75em",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "20em",
    "::-webkit-scrollbar": {
      width: ".3em",
      height: ".3em",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#b7b5ba",
      borderRadius: "100px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#6a18ed",
    },
  }),
  option: (base, state) => ({
    ...base,
    borderRadius: "0.5em",
    marginBottom: ".25em",
    marginTop: ".25em",
    backgroundColor: state.isSelected ? "#6a18ed" : "transparent",
    color: state.isSelected ? "#fcfcfc" : "#6b6970",
    "&:hover": {
      color: "#222024",
      backgroundColor: "rgb(106, 24, 237, 0.3)",
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: ".3em",
    paddingLeft: ".3em",
    div: {
      padding: "0px",
    },
  }),
};

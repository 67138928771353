import {
  deleteRequest,
  deleteRequestWithData,
  getRequest,
  postRequest,
  putRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get Product Types
const getCustomProductTypes = (storeId) => {
  return getRequest(`product-types/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Custom Categories
const getCustomCategories = (storeId) => {
  return getRequest(`category/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Custom Sub Categories
const getCustomSubCategories = (storeId) => {
  return getRequest(`subcategory/${storeId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add Product Types
const addCustomProductType = (storeId, data) => {
  return postRequest(`product-types/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add Custom Categories
const addCustomCategory = (storeId, data) => {
  return postRequest(`category/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add Custom Sub Categories
const addCustomSubCategory = (storeId, data) => {
  return postRequest(`subcategory/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete Product Types
const deleteCustomProductType = (storeId, data) => {
  return deleteRequestWithData(`product-types/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete Custom Categories
const deleteCustomCategory = (storeId, categoryId) => {
  return deleteRequest(`category/${storeId}/${categoryId}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete Custom Sub Categories
const deleteCustomSubCategory = (storeId, data) => {
  return deleteRequestWithData(`subcategory/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update Product Types
const updateCustomProductType = (storeId, data) => {
  return putRequest(`product-types/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update Custom Categories
const updateCustomCategory = (storeId, categoryId, data) => {
  return putRequest(`category/${storeId}/${categoryId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Update Custom Sub Categories
const updateCustomSubCategory = (storeId, data) => {
  return putRequest(`subcategory/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export inventory Services
export const ItemOrganizationService = {
  addCustomCategory,
  getCustomCategories,
  addCustomSubCategory,
  deleteCustomCategory,
  updateCustomCategory,
  addCustomProductType,
  getCustomProductTypes,
  getCustomSubCategories,
  deleteCustomProductType,
  deleteCustomSubCategory,
  updateCustomProductType,
  updateCustomSubCategory,
};

import pokemon from "pokemontcgsdk";
import { connect } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { Row, Col } from "react-bootstrap";
import Select, { components } from "react-select";
import BarcodeReader from "react-barcode-reader";
import React, { useState } from "react";

import { inventoryService } from "../../../../services";
import {
  CustomInput,
  customToast,
  isTradingCard,
  LoadingIndicator,
  createCombinedList,
  getDefaultFilterType,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  handleFilterPricechartingSearchResult,
  handleGetFilterLoadOptions,
  stringifyObject,
} from "../../../../shared/utility";
import { inventoryActions } from "../../../../redux/actions";
import {
  asyncSelectFieldStyle,
  categoriesDropdownStyle,
} from "../../../../assets/scss/style";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import {
  toastType,
  globalConstants,
  inventoryConstants,
  categoryDropdownConstants,
} from "../../../../constants";
import {
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";

pokemon.configure({ apiKey: process.env.REACT_APP_POKEMON_API_KEY });

const SearchInventory = (props) => {
  //-------props
  const {
    customTypes,
    asyncSelectRef,
    customCategories,
    customSubCategories,
    getScryFallProductById,
    isFilterRequire = true,
    dropdownIndicator = true,
    setPokemonProductInReducer,
    getPricechartingProductById,
    handleMenuProductClick = false,
    placeHolder = "Search inventory...",
    defaultProductType = PRODUCT_TYPES_ENUMS.VIDEO_GAME,
  } = props;
  const [scanData, setScanData] = useState(placeHolder);
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(customTypes, defaultProductType)
  );
  const [productCategory, setProductCategory] = useState(
    globalConstants.EMPTY_STRING
  );
  const [productSubCategory, setProductSubCategory] = useState(
    globalConstants.EMPTY_STRING
  );
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  //------create select option list
  const createSelectOptionList = (filteredOptions) => {
    const selectOptions = filteredOptions?.map((option) => ({
      value: {
        product_id: option.id,
        sourceApi: option.sourceApi,
        genre: option.genre,
        product_name: option.name,
        category_name: option.category,
        productType: option.productType,
        imgUrl: "",
        upc: option.upc,
      },
      label: (
        <div className="search-option-wrapper">
          <span className="console-name-react-select">{option.category}</span>
          <span>{option.name}</span>
        </div>
      ),
    }));
    return selectOptions;
  };

  const getProductTypes = (products) => {
    const newProductObject = products.map((item) => {
      if (isTradingCard(item)) {
        return { ...item, productType: PRODUCT_TYPES_ENUMS.TRADING_CARD };
      } else {
        return { ...item, productType: PRODUCT_TYPES_ENUMS.VIDEO_GAME };
      }
    });
    return newProductObject;
  };
  //-------load option
  const loadOptions = useDebouncedCallback(async (inputValue) => {
    setIsloading(true);
    const searchValue = inputValue;
    let priceChartingOptions = [];
    let pokemonOptions = [];
    let scryfallOptions = [];
    if (
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.OTHER
    ) {
      priceChartingOptions = await handleFilterPricechartingSearchResult(
        searchValue,
        inventoryService,
        searchProductType,
        productCategory,
        productSubCategory
      );
    }
    if (
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD
    ) {
      const seachPokemonCrad = searchValue.replaceAll(" ", "*");
      pokemonOptions = await pokemon.card
        .where({
          q: `name:${seachPokemonCrad} OR subtypes:${seachPokemonCrad} OR subtypes:${seachPokemonCrad} OR set:${seachPokemonCrad}`,
        })
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL ||
      searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD
    ) {
      scryfallOptions = await inventoryService
        .getscryfallApiProducts(searchValue)
        .then(
          (response) => {
            return response?.data;
          },
          (error) => {
            //customToast(error, toastType.ERROR);
            return [];
          }
        );
    }

    const consoleNames = customCategories
      .filter((item) => !item.isCustom)
      ?.map((obj) => obj.name);
    const filteredPriceChartingOptions =
      searchProductType?.label === PRODUCT_TYPES_ENUMS.ALL
        ? priceChartingOptions.filter(
            (obj) =>
              consoleNames.includes(obj["console-name"]) ||
              obj.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          )
        : searchProductType?.label === PRODUCT_TYPES_ENUMS.VIDEO_GAME
        ? priceChartingOptions.filter((obj) =>
            consoleNames.includes(obj["console-name"])
          )
        : searchProductType?.label === PRODUCT_TYPES_ENUMS.TRADING_CARD
        ? priceChartingOptions.filter(
            (obj) => obj.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          )
        : [];
    const combinedList = [
      ...createCombinedList(
        filteredPriceChartingOptions,
        inventoryConstants.PRICECHARTING_API
      ),
      ...createCombinedList(
        pokemonOptions || [],
        inventoryConstants.POKEMON_API
      ),
      ...createCombinedList(
        scryfallOptions || [],
        inventoryConstants.SCRYFALL_API
      ),
    ];

    let filteredOptions = handleGetFilterLoadOptions(
      combinedList,
      searchProductType,
      productCategory,
      productSubCategory
    );
    const productsWithType = getProductTypes(filteredOptions);
    const selectedOptions = createSelectOptionList(productsWithType);
    if (
      selectedOptions.length === 1 &&
      selectedOptions[0].value.upc === inputValue
    ) {
      handleItemSelect(selectedOptions[0]);
    } else {
      setOptions(selectedOptions);
    }
    setIsloading(false);
  }, 1000);

  //---------handle scan
  const handleScan = async (data) => {
    if (asyncSelectRef.current) {
      asyncSelectRef.current.clearValue();
    }
    setScanData(data);
    if (data) {
      const priceChartingOptions = await inventoryService
        .getPricechartingApiProducts(data)
        .then(
          (response) => {
            return response?.products;
          },
          (error) => {
            customToast(error, toastType.ERROR);
            return [];
          }
        );
      if (priceChartingOptions?.length > 0) {
        const product = {
          value: priceChartingOptions.map((item) => ({
            product_id: item.id,
            name: item[inventoryConstants.PRICECHARTING_PRODUCT_NAME],
            category:
              item[inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME],
            sourceApi: inventoryConstants.PRICECHARTING_API,
            genre: item.genre,
          }))[0],
          label: "upc",
        };
        handleItemSelect(product);
      } else {
        customToast("Barcode doesn't exist in inventory", toastType.ERROR);
      }
    }
  };

  const handleError = (error) => {};

  //-------handle item select
  const handleItemSelect = (product) => {
    if (product?.value) {
      setInputValue("");
      setOptions([]);
      if (handleMenuProductClick) {
        handleMenuProductClick(product.value);
      } else {
        if (
          product?.value?.sourceApi === inventoryConstants.PRICECHARTING_API
        ) {
          getPricechartingProductById(product?.value, product.value.product_id);
        } else if (
          product?.value?.sourceApi === inventoryConstants.POKEMON_API
        ) {
          pokemon.card.find(product.value.product_id).then((card) => {
            setPokemonProductInReducer(product?.value, card || "");
          });
        } else if (
          product?.value?.sourceApi === inventoryConstants.SCRYFALL_API
        ) {
          getScryFallProductById(product?.value, product.value.product_id);
        }
      }
    }
  };

  const handleInputValueChange = (value, { action }) => {
    if (action === "input-change") {
      setInputValue(value);
      if (value) {
        setIsloading(true);
        loadOptions(value);
      }
    }
  };

  const handleOnFocus = (e) => {
    setOptions([]);
    if (e.target.value) {
      setIsloading(true);
      loadOptions(e.target.value);
    }
  };

  return (
    <>
      <div>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>
      <Row>
        <Col md={isFilterRequire ? 6 : 12}>
          <Select
            value={""}
            options={options}
            className={`w-100`}
            ref={asyncSelectRef}
            filterOption={false}
            isLoading={isLoading}
            placeholder={scanData}
            openMenuOnFocus={true}
            inputValue={inputValue}
            onFocus={handleOnFocus}
            getOptionLabel={(e) => e.label}
            styles={categoriesDropdownStyle}
            onInputChange={handleInputValueChange}
            getOptionValue={(e) => stringifyObject(e.value)}
            onChange={(selectedOption) => handleItemSelect(selectedOption)}
            components={
              dropdownIndicator
                ? {
                    IndicatorSeparator: () => null,
                    Input: CustomInput,
                    LoadingIndicator,
                  }
                : {
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => "",
                    Input: CustomInput,
                    LoadingIndicator,
                  }
            }
            noOptionsMessage={() => {
              return "No Product Found";
            }}
          />
        </Col>
        {isFilterRequire && (
          <>
            <Col md={2} className="mb-3 pe-md-0">
              <CategoriesDropdown
                placeholder="Type"
                isClearable={false}
                noOptionsMessage="No Type Found"
                options={getCustomTypesOptionList(
                  customTypes.filter((item) => !item.isCustom)
                )}
                SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
                handleChange={(selectedOption) => {
                  setSearchProductType(selectedOption);
                  setProductCategory(globalConstants.EMPTY_STRING);
                  asyncSelectRef?.current?.focus();
                }}
                defaultValue={getDefaultFilterType(
                  customTypes,
                  defaultProductType
                )}
              />
            </Col>
            <Col md={2} className="mb-3 pe-md-0">
              <CategoriesDropdown
                placeholder="Category"
                value={productCategory}
                options={getCustomCategoriesOptionList(
                  customCategories.filter((item) => !item.isCustom),
                  searchProductType?.value,
                  searchProductType?.label
                )}
                noOptionsMessage="No Category Found"
                SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
                handleChange={(selectedOption) => {
                  setProductCategory(
                    selectedOption || globalConstants.EMPTY_STRING
                  );
                  setProductSubCategory(globalConstants.EMPTY_STRING);
                  asyncSelectRef?.current?.focus();
                }}
              />
            </Col>
            <Col md={2} className="mb-3 pe-md-0">
              <CategoriesDropdown
                options={getCustomSubCategoriesOptionList(
                  customSubCategories,
                  productCategory?.value,
                  searchProductType?.label,
                  undefined,
                  true
                )}
                value={productSubCategory}
                handleChange={(selectedOption) => {
                  setProductSubCategory(
                    selectedOption || globalConstants.EMPTY_STRING
                  );
                  asyncSelectRef?.current?.focus();
                }}
                noOptionsMessage="No SubCategory Found"
                placeholder="Sub-Category"
                SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customSubCategories: state.itemOrganization.customSubCategories,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(SearchInventory);

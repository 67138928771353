import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";
import CustomIconSelectField from "../customSelectField/CustomIconSelectField";

//-------SelectField component start here
const IconSelectField = (props) => {
  const {
    name,
    options,
    placeHolder,
    label,
    isSearchable = true,
    SelectIconImage,
    className,
    noOptionsMessage,
    isClearable = true,
    customOnChange,
    isDisabled = false,
  } = props;
  //-------Return InputTextField Component
  return (
    <div className={`${isDisabled && "disabled-selectfield"}`}>
      {label && (
        <label className="field-label" htmlFor={name}>
          {label}
        </label>
      )}
      <div>
        <Field
          name={name}
          id={name}
          isSearchable={isSearchable}
          placeholder={placeHolder}
          isMulti={false}
          component={CustomIconSelectField}
          options={options}
          SelectIconImage={SelectIconImage}
          className={className}
          noOptionsMessage={noOptionsMessage}
          isClearable={isClearable}
          customOnChange={customOnChange}
          isDisabled={isDisabled}
        />
      </div>
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------SelectField component end here
};

//-------Export SelectField Component
export default IconSelectField;

export const datepickerConstants = {
  SET_END_DATE: "Set End Date",
  START_DATE: "Start Date:",
  END_DATE: "End Date:",
  DAILY: "daily",
  WEEKLY: "weekly",
  BIWEEKLY: "biweekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
  CUSTOM: "custom",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  MONTHS: "months",
  YYYY: "YYYY",
  MMMM_YYYY: "MMMM YYYY",
};

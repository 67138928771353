import { storeActions } from "./store.action";
import { dashboardService } from "../../services";
import { customToast } from "../../shared/utility";
import { custom, success } from "./actionsUtilities";
import {
  toastType,
  toastMessages,
  systemConstants,
  deviceConstants,
  globalConstants,
  dashboardConstants,
} from "../../constants";

//-------Get get at a glance
const getAtAGlance = (selectedStore, start, end, category, productType) => {
  return (dispatch) => {
    dispatch(
      custom(
        dashboardConstants.GET_AT_A_GLANCE,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    dashboardService
      .getAtAGlance(selectedStore, start, end, category, productType)
      .then(
        (response) => {
          dispatch(success(response || [], dashboardConstants.GET_AT_A_GLANCE));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            dashboardConstants.GET_AT_A_GLANCE,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get store stats
const getStoreStats = (start, end) => {
  return (dispatch) => {
    dispatch(
      custom(deviceConstants.GET_ALL_DEVICES, systemConstants.SPINNER_ACTIVATE)
    );
    dashboardService
      .getStoreStats(start, end)
      .then(
        (response) => {
          dispatch(success(response || [], deviceConstants.GET_ALL_DEVICES));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.GET_ALL_DEVICES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get store transaction history
const getStoreTransactionHistory = (storeId, type, start, end) => {
  return (dispatch) => {
    dispatch(
      custom(
        dashboardConstants.GET_STORE_HISTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    dashboardService
      .getStoreTransactionHistory(storeId, type, start, end)
      .then(
        (response) => {
          dispatch(
            success(response || [], dashboardConstants.GET_STORE_HISTORY)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            dashboardConstants.GET_STORE_HISTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get store transaction history
const getStatsByCategory = (selectedStore, type, start, end) => {
  return (dispatch) => {
    dispatch(
      custom(deviceConstants.GET_ALL_DEVICES, systemConstants.SPINNER_ACTIVATE)
    );
    dashboardService
      .getStatsByCategory(selectedStore, type, start, end)
      .then(
        (response) => {
          dispatch(success(response || [], deviceConstants.GET_ALL_DEVICES));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            deviceConstants.GET_ALL_DEVICES,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get store transaction history
const editPurchasingPower = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        dashboardConstants.UPDATE_PUCHASING_POWER,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    dashboardService
      .editPurchasingPower(storeId, data)
      .then(
        (response) => {
          customToast(
            toastMessages.STORE_PURCHASING_POWER_UPDATED,
            toastType.SUCCESS
          );
          dispatch(storeActions.getStores(globalConstants.EMPTY_STRING));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            dashboardConstants.UPDATE_PUCHASING_POWER,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Authentication Actions
export const dashboardActions = {
  getAtAGlance,
  getStoreStats,
  getStatsByCategory,
  getStoreTransactionHistory,
  editPurchasingPower,
};

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import Button from "../../../shared/components/Buttons/Button";

import { useNavigate } from "react-router";

import ImportUser from "./ImportUser/ImportUser";
import { userActions } from "../../../redux/actions";
import { isSpinnerEnabled } from "../../../shared/utility";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import Topbar from "../../../shared/components/topbar/Topbar";
import {
  userConstants,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  BlueBoxIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------Users component start here
const Users = (props) => {
  const {
    user,
    deleteUser,
    getAllUsers,
    currentStore,
    loggedInUser,
    spinnerArray,
  } = props;
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  let numOfRenders = useRef(0);
  //-------User reducer
  const { allUsers } = user;
  const [searchText, setSearchText] = useState("");

  //-------Handle user edit click
  const handleEditClick = (row) => {
    navigate(routesPathConstants.SETTINGS_USERS_EDIT_USER, {
      state: {
        data: row,
        isEdit: true,
        currentStore: currentStore,
      },
    });
  };

  //-------Handle user delete click
  const handleDeleteClick = (row) => {
    if (row?.id) {
      deleteUser(row.id, currentStore?.id, loggedInUser);
      // reset the pagination
      numOfRenders.current++;
    }
  };

  //-------Handle add button click
  const handleAddClick = () => {
    navigate(routesPathConstants.ADD_NEW_USER, {
      state: {
        isEdit: false,
        currentStore: currentStore,
      },
    });
  };

  const columnHeaders = [
    {
      id: "lastName",
      name: "Last Name",
      selector: (row) => row.lastName,
      cell: (row) => (
        <span className="users-cell-max-size">{row.lastName}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "firstName",
      name: "First Name",
      selector: (row) => row.firstName,
      cell: (row) => (
        <span className="users-cell-max-size">{row.firstName}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "role",
      name: "Role",
      selector: (row) => row.role,
      cell: (row) => {
        const matchingRole = userConstants.USER_ROLES.find(
          (role) => role.value === row.role
        );
        return matchingRole.label;
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <span className="users-cell-max-size">{row.email}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                label={buttonNameConstants.EDIT}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={EditIcon}
                handleClick={() => handleEditClick(row)}
                // isDisabled={
                //   role === ROLES.EMPLOYEE ||
                //   (role === ROLES.STORE_MANAGER &&
                //     row.role === ROLES.STORE_MANAGER &&
                //     row.id !== loggedInUser.id)
                // }
              />
              <Button
                label={buttonNameConstants.DELETE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={DeleteIcon}
                handleClick={() => handleDeleteClick(row)}
                // isDisabled={
                //   role === ROLES.EMPLOYEE ||
                //   (role === ROLES.STORE_MANAGER &&
                //     row.role === ROLES.STORE_MANAGER &&
                //     row.id !== loggedInUser.id)
                // }
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  const [columns, setColumns] = useState(columnHeaders);

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------useEffects
  useEffect(() => {
    if (currentStore) {
      getAllUsers(currentStore?.id);
      setColumns(columnHeaders);
    }
    // eslint-disable-next-line
  }, [currentStore]);

  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = allUsers.filter((row) => {
      return (
        String(row.role + row.firstName + row.lastName + row.email)
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    numOfRenders.current++;
    return filterList;
  };
  const clearSearchText = () => {
    setSearchText("");
  };
  //-------Return Users Component
  return (
    <>
      <Topbar title="Employees"></Topbar>
      {isSpinnerEnabled(spinnerArray, userConstants.GET_ALL_USERS) ? (
        <Row className="m-0 w-100 user-screen-wrapper">
          <Col md={5}>
            <DropdownSkeleton width="100%" />
          </Col>
          <Col md={3}></Col>
          <Col
            md={4}
            className="d-flex justify-content-end align-items-center gap-3"
          >
            <DropdownSkeleton />
          </Col>
        </Row>
      ) : (
        <Row className="m-0 w-100 user-screen-wrapper">
          <Col md={5}>
            <SearchField
              onChange={(e) => setSearchText(e.target.value)}
              IconImage={SearchIcon}
              placeHolder="Search"
              value={searchText}
              CrossIcon={searchText ? CrossIcon : ""}
              clearSearchText={clearSearchText}
              BlueBoxIcon={BlueBoxIcon}
            />
          </Col>
          <Col md={3}></Col>
          <Col
            md={4}
            className="d-flex justify-content-end align-items-center gap-3"
          >
            {/* <Button
            label={buttonNameConstants.IMPORT_USERS}
            IconImage={AddIcon}
            handleClick={() => {
              setIsModal(true);
            }}
          /> */}
            <Button
              label={buttonNameConstants.ADD}
              IconImage={AddIcon}
              handleClick={handleAddClick}
            />
          </Col>
        </Row>
      )}

      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          className="customer-table-height"
          key={numOfRenders.current}
          columns={columns}
          data={searchText ? handleFilterData() : allUsers}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={searchText ? handleFilterData()?.length : allUsers?.length}
        />
      </Row>
      {isModal && (
        <ImportUser isModal={isModal} setIsModal={setIsModal}></ImportUser>
      )}
    </>
  );
}; //-------Users component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.user,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  loggedInUser: state.authentication.user,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllUsers: (id) => dispatch(userActions.getAllUsers(id)),
  deleteUser: (id, storeId, currentUser) => {
    dispatch(userActions.deleteUser(id, storeId, currentUser));
  },
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Users);

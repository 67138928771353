import { updateObject } from "../../shared/utility";
import { userConstants, globalConstants } from "../../constants";

//-------InitialState of the reducer
const initialState = {
  allUsers: [],
};

//-------Export user Reducer
export const user = (state = initialState, action) => {
  switch (action.type) {
    //-------Get all users success case
    case userConstants.GET_ALL_USERS + globalConstants.SUCCESS:
      return updateObject(state, {
        allUsers: action.data || [],
      });
    //-------Get all users failure case
    case userConstants.GET_ALL_USERS + globalConstants.FAILURE:
      return updateObject(state, {
        allUsers: [],
      });
    //-------Default State
    default:
      return state;
  }
};

import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import CategoriesDropdown from "../selectDropdown/CategoriesDropdown";
import { globalConstants, categoryDropdownConstants } from "../../../constants";
import {
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  getCustomTypesOptionList,
  getDefaultFilterType,
} from "../../utility";

const FilterDropdowns = (props) => {
  //-------props
  const {
    customTypes,
    customCategories,
    customSubCategories,
    setSearchProductType,
    setSearchProductCategory,
    setSearchProductSubCategory,
    defaultProductType = {},
    isDisabledAll = false,
  } = props;
  //-------Usestate
  const [productType, setProductType] = useState(
    getDefaultFilterType(customTypes, defaultProductType)
  );
  const [productCategory, setProductCategory] = useState(
    globalConstants.EMPTY_STRING
  );
  const [productSubCategory, setProductSubCategory] = useState(
    globalConstants.EMPTY_STRING
  );

  useEffect(() => {
    setProductType(getDefaultFilterType(customTypes, defaultProductType)); // eslint-disable-next-line
  }, [customTypes]);

  return (
    <Row className="m-0 w-100">
      <Col md={4} className="mb-3 pe-md-0 ps-md-0">
        <CategoriesDropdown
          options={getCustomTypesOptionList(customTypes)}
          handleChange={(selectedOption) => {
            setProductType(selectedOption);
            setSearchProductType(selectedOption);
            setProductCategory(globalConstants.EMPTY_STRING);
            setProductSubCategory(globalConstants.EMPTY_STRING);
            setSearchProductCategory(globalConstants.EMPTY_STRING);
            setSearchProductSubCategory(globalConstants.EMPTY_STRING);
          }}
          value={productType}
          noOptionsMessage="No Type Found"
          placeholder="Type"
          SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
          isClearable={false}
          disabled={isDisabledAll}
        />
      </Col>
      <Col md={4} className="mb-3 pe-md-0">
        <CategoriesDropdown
          options={getCustomCategoriesOptionList(
            customCategories,
            productType.value,
            productType.label
          )}
          value={productCategory}
          handleChange={(selectedOption) => {
            setProductCategory(selectedOption || globalConstants.EMPTY_STRING);
            setSearchProductCategory(
              selectedOption || globalConstants.EMPTY_STRING
            );
            setProductSubCategory(globalConstants.EMPTY_STRING);
            setSearchProductSubCategory(globalConstants.EMPTY_STRING);
          }}
          noOptionsMessage="No Category Found"
          placeholder="Category"
          SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
          disabled={isDisabledAll}
        />
      </Col>
      <Col md={4} className="mb-3 pe-md-0">
        <CategoriesDropdown
          options={getCustomSubCategoriesOptionList(
            customSubCategories,
            productCategory?.value,
            productType.label,
            productType.value,
            false,
            customCategories
          )}
          value={productSubCategory}
          handleChange={(selectedOption) => {
            setProductSubCategory(
              selectedOption || globalConstants.EMPTY_STRING
            );
            setSearchProductSubCategory(
              selectedOption || globalConstants.EMPTY_STRING
            );
          }}
          noOptionsMessage="No SubCategory Found"
          placeholder="Sub-Category"
          SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
          disabled={isDisabledAll}
        />
      </Col>
    </Row>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdowns);

import moment from "moment";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import { inventoryService } from "../../../../services";
import SearchInventory from "../SearchInventory/SearchInventory";
import Button from "../../../../shared/components/Buttons/Button";
import ProductImage from "../../../../assets/images/productimage.png";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import InputTextArea from "../../../../shared/components/inputTextArea/InputTextArea";
import IconSelectField from "../../../../shared/components/selectField/IconSelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import {
  systemActions,
  inventoryActions,
  tradeInDiscountActions,
  itemOrganizationActions,
} from "../../../../redux/actions";
import {
  addCustomItemValidationSchema,
  addNewIventoryValidationSchema,
} from "../../../../shared/validationSchema/validationSchema";
import {
  PRODUCT_TYPES_ENUMS,
  PRODUCT_CONDITIONS_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import {
  customToast,
  isTagsEqual,
  convertToUsd,
  stringToSlug,
  getShortestSku,
  handleViewTCGPlayer,
  handleViewEbaySales,
  changePriceBaseOnTags,
  convertPricesToNumbers,
  handleViewPriceCharting,
  uploadCustomProductImage,
  getPricechartingProductImage,
  handleConditionsAndTags,
  getPresetStockPrice,
  handleConditionChange,
  getProductSubCategory,
  getTypeOrCategoryObject,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import {
  toastType,
  toastMessages,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  INVENTORY_PRODUCT_DEFAULT_DATA_OBJECT,
} from "../../../../constants";
import {
  DollarIcon,
  ExpandTabIcon,
  SidebarToggleIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";

const AddInventoryItem = (props) => {
  //-------Destructure props
  const {
    inventory,
    customTypes,
    currentStore,
    addInventory,
    spinnerArray,
    tradeDiscount,
    addCustomItem,
    isAddModalOpen,
    toggleAddModal,
    activateSpinner,
    customCategories,
    deactivateSpinner,
    customSubCategories,
    addInventoryItemModal,
    toggleAddInventoryItemModal,
    getInventoryByPricechartingId,
    emptyPriceChartingProductObject,
  } = props;

  //-------inventory reducer
  const {
    productTags,
    allCategories,
    inventoryProducts,
    priceChartingProductInfo,
  } = inventory;

  //-------preset prices
  const { markupPresets } = tradeDiscount;
  //------UseRef
  const hiddenFileInput = useRef(null);

  //-------useState
  const [productUrl, setProductUrl] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [customProductSelectedTags, setCustomProductSelectedTags] = useState(
    []
  );
  const [conditionList, setConditionList] = useState([]);
  const [productCondition, setProductCondition] = useState();
  const [productTagsList, setProductTagsList] = useState([]);
  const [isCustomItemTab, setIsCustomItemTab] = useState(false);
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [productType, setProductType] = useState(PRODUCT_TYPES_ENUMS.ALL);
  const [isTradiingCardWarningText, setIsTradiingCardWarningText] =
    useState(false);
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );
  const [pricechartingProductImage, setPricechartingProductImage] =
    useState("");
  const [productDataObject, setProductDataObject] = useState(
    INVENTORY_PRODUCT_DEFAULT_DATA_OBJECT
  );

  //-------useref
  const asyncSelectRef = useRef();

  //-------inital values for customItem
  const customItemInitialValues = {
    quantity: 1,
    stockPrice: 0,
    costOfGoods: 0,
    upc: globalConstants.EMPTY_STRING,
    type: globalConstants.EMPTY_STRING,
    title: globalConstants.EMPTY_STRING,
    condition: globalConstants.EMPTY_STRING,
    category: globalConstants.EMPTY_STRING,
    subCategory: globalConstants.EMPTY_STRING,
    description: globalConstants.EMPTY_STRING,
  };

  //-------initial values
  const initialValues = {
    quantity: 1,
    box:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX)
          ? true
          : false
        : false,
    manual:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL)
          ? false
          : true
        : productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)
          ? true
          : false
        : false,
    cables:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES)
        ? false
        : true,
    controller:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE
        ? selectedTags?.includes(
            inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
          )
          ? false
          : true
        : false,
    costOfGoods: productDataObject.costOfGoods || globalConstants.EMPTY_STRING,
    inStockPrice:
      productDataObject.inStorePrice || productDataObject.inStockPrice || 0,
  };

  //-------get Sku Number
  const getSkuNumber = async () => {
    return inventoryService.getSkuNumber(currentStore?.id).then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };

  //-------onselectedtagchange
  const onSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag.label);
    changePriceBaseOnTags(
      selectedTagList,
      productDataObject,
      setProductDataObject,
      priceChartingProductInfo,
      inventoryConstants,
      PRODUCT_CONDITIONS_ENUMS,
      TRADING_CARD_CATEGORIES_ENUMS,
      productCondition
    );
    setSelectedTags(selectedTagList);
  };

  //-------onselectedtagchange
  const onCustomProductSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag.label);
    setCustomProductSelectedTags(selectedTagList);
  };

  const toggleCustomItemTab = () => {
    setIsCustomItemTab(!isCustomItemTab);
  };

  //-------handle inventory submit
  const handleAddInvetorySubmit = async (values) => {
    if (!productDataObject?.productName) {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    } else {
      const skuNumber = (productDataObject.skuNumber?.length &&
        productDataObject.skuNumber) || [await getSkuNumber()];
      addInventory({
        product_id: productDataObject.productId,
        product_name: productDataObject.productName,
        category_name: productDataObject.consoleName,
        subcategory:
          productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
            ? getProductSubCategory(productDataObject.genre)
            : globalConstants.EMPTY_STRING,
        description: "",
        sku: skuNumber,
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        date_added: new Date(),
        price: {
          unit_purchase_price: values.costOfGoods,
          unit_sell_price: values.inStockPrice,
          quantity: values.quantity,
          type: productCondition?.value,
          marketPrice: convertToUsd(productDataObject?.marketPrice) || 0,
        },
        productType: productType,
        upc: productDataObject.upc,
        additionalCheckList: [],
        tags: selectedTags?.map((tag) => tag),
        cardRarity: selectedRarity?.label || "",
        cardNumber: productDataObject?.cardNumber || "",
        apiSource: priceChartingProductInfo?.productMetaData?.sourceApi,
        imgUrl: productDataObject?.imgUrl,
        epid: productDataObject.epid || globalConstants.EMPTY_STRING,
        tcgPlayerUrl:
          productDataObject.tcgPlayerUrl || globalConstants.EMPTY_STRING,
      });
    }
  };

  //-------hide modal
  const handleHideModal = () => {
    toggleAddInventoryItemModal();
    toggleAddModal(false);
    clearAddInventoryForm();
    setIsCustomItemTab(false);
  };

  //-------empty product data object
  const emptyProductDataObject = () => {
    setProductDataObject(INVENTORY_PRODUCT_DEFAULT_DATA_OBJECT);
  };

  //------find product details
  const findInventoryProductDetail = (condition) => {
    if (inventoryProducts?.length > 0) {
      const findProduct = inventoryProducts.find((product) => {
        if (
          product.price.type === condition &&
          isTagsEqual(product.tags || [], selectedTags || [])
        ) {
          if (
            priceChartingProductInfo?.productMetaData?.sourceApi ===
              inventoryConstants.POKEMON_API ||
            priceChartingProductInfo?.productMetaData?.sourceApi ===
              inventoryConstants.SCRYFALL_API
          ) {
            if (product.cardRarity === selectedRarity.label) {
              return product;
            }
          } else {
            return product;
          }
        }
      });
      setProductDataObject({
        ...productDataObject,
        inStockQuantity: findProduct?.price?.quantity || 0,
        skuNumber: findProduct?.sku || [],
        inStorePrice: findProduct?.price?.unit_sell_price || 0,
        inStockPrice:
          findProduct?.price?.unit_sell_price ||
          getPresetStockPrice(
            productType,
            productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
              productDataObject.consoleName ===
                TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
              ? convertToUsd(productDataObject.marketPrice)
              : productDataObject.marketPrice,
            markupPresets,
            productDataObject
          ),
        costOfGoods: findProduct?.price?.unit_purchase_price || 0,
        averageBuyPrice: findProduct?.price?.averageBuyPrice || 0,
        averageSellPrice: findProduct?.price?.averageSellPrice || 0,
        maxBuyPrice: findProduct?.price?.maxBuyPrice || 0,
      });
      return;
    }
    setProductDataObject({
      ...productDataObject,
      inStorePrice: 0,
      inStockPrice: getPresetStockPrice(
        productType,
        productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
          productDataObject.consoleName ===
            TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          ? convertToUsd(productDataObject.marketPrice)
          : productDataObject.marketPrice,
        markupPresets,
        productDataObject
      ),
    });
  };

  //-------clear form
  const clearAddInventoryForm = () => {
    setConditionList([]);
    setProductTagsList([]);
    emptyProductDataObject();
    emptyPriceChartingProductObject();
    setProductUrl(globalConstants.EMPTY_STRING);
    setSelectedRarity(globalConstants.EMPTY_STRING);
    setProductCondition(globalConstants.EMPTY_STRING);
    setPricechartingProductImage(globalConstants.EMPTY_STRING);
  };

  //-------handle rarity onchange
  const handleRaritiesChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedRarity(selectedOption);
      setProductDataObject({
        ...productDataObject,
        marketPrice: selectedOption?.value?.value,
      });
    }
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  //------handle upload button
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = async () => {
              activateSpinner(inventoryConstants.UPLOAD_IMAGE);
              const productImageUrl = await uploadCustomProductImage(file);
              setProductDataObject({
                ...productDataObject,
                imgUrl: productImageUrl,
              });
              deactivateSpinner(inventoryConstants.UPLOAD_IMAGE);
            };
          };
          reader.readAsDataURL(file);
        }
      } else {
        customToast(
          "Only .jpg, .jpeg and .png files are allowed",
          toastType.ERROR
        );
        e.target.value = null;
      }
    }
  };

  const handleCustomItemSubmit = (values) => {
    const sendToData = {
      upc: "",
      tags: [
        ...(customProductSelectedTags || []),
        inventoryConstants.CUSTOM_ITEM_TAG,
      ],
      cardRarity: "",
      cardNumber: "",
      description: "",
      tcgPlayerUrl: "",
      additionalCheckList: [],
      product_name: values.title,
      sku: [inventoryConstants.AUTO_GENERATED],
      apiSource: inventoryConstants.CUSTOM_ITEM,
      product_id: inventoryConstants.CUSTOM_ITEM,
      date_added: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      category_name: getTypeOrCategoryObject(customCategories, values.category)
        ?.name,
      subcategory:
        getTypeOrCategoryObject(customSubCategories, values.subCategory)
          ?.name || "",
      store: {
        id: currentStore.id,
        name: currentStore.storeName,
      },
      price: {
        unit_purchase_price: values.costOfGoods,
        unit_sell_price: values.stockPrice,
        quantity: values.quantity,
        type: values.condition,
        marketPrice: 0,
      },
      productType: getTypeOrCategoryObject(customTypes, values.type)
        ?.productType,
    };
    addCustomItem(
      [sendToData],
      toastMessages.PORDUCT_ADDED_SUCCESSFULLY,
      handleHideModal
    );
  };

  const handleCategoryChange = (option, setFieldValue) => {
    setFieldValue("category", option.value);
    setFieldValue("subCategory", globalConstants.EMPTY_STRING);
  };

  const handleTypeChange = (option, setFieldValue) => {
    setFieldValue("type", option.value);
    setFieldValue("category", globalConstants.EMPTY_STRING);
    setFieldValue("condition", globalConstants.EMPTY_STRING);
    setFieldValue("subCategory", globalConstants.EMPTY_STRING);
  };

  //-------UseEffect
  useEffect(() => {
    if (currentStore) {
      emptyPriceChartingProductObject();
    }
  }, [currentStore]);

  useEffect(() => {
    clearAddInventoryForm();
  }, [isAddModalOpen]);

  //-------update product condition and type
  useEffect(() => {
    if (addInventoryItemModal) {
      if (priceChartingProductInfo?.productObject) {
        if (
          priceChartingProductInfo.productMetaData.sourceApi ===
          inventoryConstants.PRICECHARTING_API
        ) {
          getPricechartingProductImage(
            `${process.env.REACT_APP_PRICECHARTING_IFRAME_URL}${stringToSlug(
              priceChartingProductInfo?.productObject[
                inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
              ]
            )}/${stringToSlug(
              priceChartingProductInfo?.productObject[
                inventoryConstants.PRICECHARTING_PRODUCT_NAME
              ]
            )}`,
            setPricechartingProductImage
          );
          if (
            priceChartingProductInfo.productMetaData.genre ===
            TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          ) {
            setSelectedRarity("");
            setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
            setConditionList(inventoryConstants.TRADING_CARD_CONDITION);
            setProductCondition(inventoryConstants.TRADING_CARD_CONDITION[0]);
            setProductDataObject({
              ...productDataObject,
              productName:
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_NAME
                ],
              consoleName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
              marketPrice:
                priceChartingProductInfo.productObject[
                  inventoryConstants.LOOSE_PRICE
                ] || 0,
              suggestedSellPrice:
                priceChartingProductInfo.productObject[
                  inventoryConstants.RETAIL_LOOSE_PRICE
                ] || 0,
              gameStopBuyPrice:
                priceChartingProductInfo.productObject[
                  inventoryConstants.GAMESTOP_PRICE
                ],
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              costOfGoods: 0,
              inStockPrice: 0,
              inStorePrice: 0,
              inStockQuantity: 0,
              skuNumber: [],
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              cardNumber: 0,
              tcgPlayerUrl: globalConstants.EMPTY_STRING,
              imgUrl: globalConstants.EMPTY_STRING,
              epid:
                priceChartingProductInfo.productObject?.epid ||
                globalConstants.EMPTY_STRING,
            });
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
              productName:
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_NAME
                ],
            });
          } else {
            if (
              priceChartingProductInfo.productMetaData.genre ===
              TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
            ) {
              setSelectedRarity("");
              setProductType(PRODUCT_TYPES_ENUMS.VIDEO_GAME);
              handleConditionsAndTags(
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
                ],
                priceChartingProductInfo.productObject["genre"],
                productTags,
                setConditionList,
                setProductCondition,
                setProductTagsList,
                inventoryConstants.VIDEO_GAME_SYSTEM_CONDITION
              );
            } else {
              setSelectedRarity("");
              setProductType(PRODUCT_TYPES_ENUMS.VIDEO_GAME);

              handleConditionsAndTags(
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
                ],
                priceChartingProductInfo.productObject["genre"],
                productTags,
                setConditionList,
                setProductCondition,
                setProductTagsList,
                inventoryConstants.VIDEO_GAME_CONDITION
              );
            }
            setProductDataObject({
              ...productDataObject,
              productName:
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_NAME
                ],
              consoleName:
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
                ],
              marketPrice:
                priceChartingProductInfo.productObject[
                  inventoryConstants.COMPLETE_IN_BOX_PRICE
                ],
              suggestedSellPrice:
                priceChartingProductInfo.productObject[
                  inventoryConstants.RETAIL_COMPLETE_IN_BOX_PRICE
                ],
              gameStopBuyPrice:
                priceChartingProductInfo.productObject[
                  inventoryConstants.GAMESTOP_PRICE
                ],
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              costOfGoods: 0,
              inStockPrice: 0,
              inStorePrice: 0,
              inStockQuantity: 0,
              skuNumber: [],
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              cardNumber: 0,
              tcgPlayerUrl: globalConstants.EMPTY_STRING,
              imgUrl: globalConstants.EMPTY_STRING,
              epid:
                priceChartingProductInfo.productObject?.epid ||
                globalConstants.EMPTY_STRING,
            });
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName:
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME
                ],
              productName:
                priceChartingProductInfo.productObject[
                  inventoryConstants.PRICECHARTING_PRODUCT_NAME
                ],
            });
          }
        } else if (
          priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.POKEMON_API ||
          priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.SCRYFALL_API
        ) {
          setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
          setConditionList(inventoryConstants.TRADING_CARD_CONDITION);
          setProductCondition(inventoryConstants.TRADING_CARD_CONDITION[0]);
          setProductTagsList([]);
          if (
            priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.POKEMON_API
          ) {
            const raritiesList = Object.keys(
              priceChartingProductInfo.productObject.tcgplayer.prices
            ).map((key, index) => {
              const value = `${JSON.stringify(
                priceChartingProductInfo.productObject.tcgplayer.prices[key]
                  .market
              )}`;
              return { label: key, value: { key: index, value: value } };
            });

            setRarities(raritiesList);
            setSelectedRarity(raritiesList[0]);

            const pokemonProductName = `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set.name} - ${priceChartingProductInfo.productObject.number}/${priceChartingProductInfo.productObject.set.printedTotal})`;
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
              productName: pokemonProductName,
            });
            setProductDataObject({
              ...productDataObject,
              productName: pokemonProductName,
              consoleName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              marketPrice: raritiesList[0]?.value.value || 0,
              suggestedSellPrice: 0,
              cardNumber: priceChartingProductInfo.productObject.number,
              imgUrl:
                priceChartingProductInfo.productObject.images.small ||
                globalConstants.EMPTY_STRING,
              tcgPlayerUrl:
                priceChartingProductInfo.productObject.tcgplayer.url,
              costOfGoods: 0,
              inStockPrice: 0,
              inStorePrice: 0,
              inStockQuantity: 0,
              skuNumber: [],
              gameStopBuyPrice: 0,
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              epid: globalConstants.EMPTY_STRING,
            });
          } else if (
            priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.SCRYFALL_API
          ) {
            const priceList = convertPricesToNumbers(
              priceChartingProductInfo.productObject.prices
            );
            const raritiesList = Object.keys(priceList).map((key, index) => {
              const value = `${JSON.stringify(priceList[key])}`;
              return {
                label: key,
                value: { key: index, value: value === "null" ? 0 : value },
              };
            });

            setRarities(raritiesList);
            setSelectedRarity(raritiesList[0]);
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
              productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
            });
            setProductDataObject({
              ...productDataObject,
              productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
              consoleName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              marketPrice: raritiesList[0]?.value.value || 0,
              suggestedSellPrice: 0,
              cardNumber:
                priceChartingProductInfo.productObject.collector_number,
              tcgPlayerUrl:
                priceChartingProductInfo.productObject.purchase_uris
                  ?.tcgplayer || globalConstants.EMPTY_STRING,
              imgUrl: "",
              costOfGoods: 0,
              inStockPrice: 0,
              inStorePrice: 0,
              inStockQuantity: 0,
              skuNumber: [],
              gameStopBuyPrice: 0,
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              imgUrl:
                priceChartingProductInfo.productObject.image_uris?.small ||
                globalConstants.EMPTY_STRING,
              epid: globalConstants.EMPTY_STRING,
            });
          }
        }
      }
      setProductUrl("");
    }
  }, [priceChartingProductInfo.productObject]);

  useEffect(() => {
    setProductDataObject({
      ...productDataObject,
      imgUrl: pricechartingProductImage,
    });
  }, [pricechartingProductImage]);

  useEffect(() => {
    if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
      findInventoryProductDetail(PRODUCT_CONDITIONS_ENUMS.COMPLETE);
    } else if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.NEAR_MINT) {
      findInventoryProductDetail(PRODUCT_CONDITIONS_ENUMS.NEAR_MINT);
    }
  }, [inventoryProducts]);

  useEffect(() => {
    if (productCondition?.value) {
      findInventoryProductDetail(productCondition?.value);
    }
  }, [selectedTags, selectedRarity, productCondition]);

  useEffect(() => {
    const tradingCardConditions = inventoryConstants.TRADING_CARD_CONDITION.map(
      (item) => item.value
    );

    if (
      tradingCardConditions.includes(productCondition?.value) &&
      productCondition?.value !== PRODUCT_CONDITIONS_ENUMS.NEAR_MINT
    ) {
      setIsTradiingCardWarningText(true);
    } else {
      setIsTradiingCardWarningText(false);
    }
  }, [productCondition]);

  return (
    <Modal
      show={addInventoryItemModal}
      size="xl"
      onHide={
        isSpinnerEnabled(spinnerArray, inventoryConstants.ADD_INVENTORY)
          ? ""
          : handleHideModal
      }
      animation={true}
      centered
      backdrop="static"
      className={`add-inventory-modal ${productUrl ? "iframe-opened" : ""}`}
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">Add Inventory</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body d-flex ">
        <Row className={`m-0 ${productUrl ? "w-50" : "w-100"}`}>
          <Col md={12} className="add-inventory-heading text-muted px-0 pb-2">
            Search from Marketplace
          </Col>
          <Col md={12} className="mb-3 ps-md-0">
            <SearchInventory
              customTypes={customTypes}
              asyncSelectRef={asyncSelectRef}
              customCategories={customCategories}
            />
          </Col>
          {priceChartingProductInfo && (
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleAddInvetorySubmit}
              validate={addNewIventoryValidationSchema}
            >
              {({ values }) => (
                <Form>
                  <>
                    <Col xs={12} className="add-inventory-hero-wrapper px-0">
                      <Row className="m-0">
                        <Col md={8} className="d-flex gap-3">
                          <div className="add-inventory-product-image">
                            <img
                              src={productDataObject?.imgUrl || ProductImage}
                            />
                            <input
                              type="file"
                              className="d-none"
                              accept=".png, .jpg, .jpeg"
                              onChange={handleImageChange}
                              ref={hiddenFileInput}
                            />
                            <button
                              onClick={handleReplaceButtonClick}
                              type="button"
                              className="replace-logo-button"
                            >
                              <LogoImageReplaceIcon className="replace-icon" />
                            </button>
                          </div>

                          <div className="d-flex flex-column justify-content-between align-items-start product-content-wrapper">
                            <span>
                              {productDataObject.productName || "Product Name"}
                            </span>
                            <span>
                              {productDataObject.consoleName || "Console Name"}
                            </span>
                            <span>
                              {`#${
                                getShortestSku(productDataObject?.skuNumber) ||
                                "Auto Generated"
                              }
                        `}
                            </span>
                            <span>{`In Stock: ${productDataObject.inStockQuantity}`}</span>
                          </div>
                        </Col>

                        {priceChartingProductInfo?.productMetaData
                          ?.sourceApi ===
                        inventoryConstants.PRICECHARTING_API ? (
                          <Col
                            md={4}
                            className="d-flex flex-column align-items-center gap-2"
                          >
                            <Button
                              type="button"
                              label={buttonNameConstants.VIEW_EBAY_SALES}
                              className="w-100"
                              handleClick={() =>
                                handleViewEbaySales(
                                  productDataObject?.productName,
                                  productDataObject?.consoleName,
                                  productDataObject?.genre
                                )
                              }
                              buttonType={buttonTypeConstants.LIGHT_PURPLE}
                            />
                            <Button
                              type="button"
                              label={buttonNameConstants.VIEW_PRICE_CHARTING}
                              className="w-100"
                              handleClick={() =>
                                handleViewPriceCharting(
                                  priceChartingProductInfo,
                                  setProductUrl
                                )
                              }
                              buttonType={buttonTypeConstants.LIGHT_PURPLE}
                            />
                          </Col>
                        ) : priceChartingProductInfo?.productMetaData
                            ?.sourceApi === inventoryConstants.SCRYFALL_API ||
                          priceChartingProductInfo?.productMetaData
                            ?.sourceApi === inventoryConstants.POKEMON_API ? (
                          <Col
                            md={4}
                            className="d-flex flex-column align-items-center gap-2"
                          >
                            <Button
                              type="button"
                              label={buttonNameConstants.VIEW_TCG_PLAYER}
                              className="w-100"
                              handleClick={() =>
                                handleViewTCGPlayer(productDataObject)
                              }
                              buttonType={buttonTypeConstants.LIGHT_PURPLE}
                              isDisabled={!productDataObject?.tcgPlayerUrl}
                            />
                          </Col>
                        ) : null}

                        <Col
                          md={12}
                          className="d-flex justify-content-start flex-wrap gap-3 mt-3 pt-2"
                        >
                          <span className="quantity-top-margin">Quantity:</span>
                          <InputNumberField
                            name="quantity"
                            placeHolder="0"
                            className="add-inventory-quantity-field"
                            isConvertToDecimal={false}
                            step={"1"}
                            isHandleKeyDown={true}
                          />
                          <CategoriesDropdown
                            options={conditionList}
                            handleChange={(selectedOption) =>
                              handleConditionChange(
                                selectedOption,
                                setProductCondition,
                                priceChartingProductInfo,
                                productTags,
                                setProductTagsList,
                                setProductDataObject,
                                productDataObject,
                                setSelectedTags
                              )
                            }
                            noOptionsMessage="No Condition Found"
                            placeholder="Condition"
                            SelectIconImage={
                              categoryDropdownConstants.INVENTORY_LIST_ICON
                            }
                            isClearable={false}
                            className="add-inventory-dropdowns-category condition-dropdown"
                            value={productCondition}
                          />
                          {productDataObject?.genre ===
                            TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
                          productDataObject?.genre ===
                            TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ? (
                            <CategoriesDropdown
                              options={rarities || []}
                              handleChange={(selectedOption) =>
                                handleRaritiesChange(selectedOption)
                              }
                              noOptionsMessage="No Printing Found"
                              placeholder="Printings"
                              SelectIconImage={
                                categoryDropdownConstants.INVENTORY_LIST_ICON
                              }
                              isClearable={false}
                              className="add-inventory-dropdowns-category condition-dropdown"
                              value={selectedRarity}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        {productCondition?.value ===
                          PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                        productCondition?.value ===
                          PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                          <Col
                            md={12}
                            className="d-flex align-items-center gap-3 my-2"
                          >
                            {productDataObject.genre ===
                            TRADING_CARD_CATEGORIES_ENUMS.SYSTEM ? (
                              productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.COMPLETE ? (
                                <>
                                  <CheckboxField name="box" label="Box" />
                                  <CheckboxField name="cables" label="Cables" />
                                  <CheckboxField
                                    name="controller"
                                    label="Controller"
                                  />
                                </>
                              ) : (
                                ""
                              )
                            ) : [
                                ...inventoryConstants.NINTENDO_CATEGORIES,
                                ...inventoryConstants.SKYLANDER_CATEGORIES,
                              ].includes(productDataObject.consoleName) ? (
                              ""
                            ) : (
                              <CheckboxField name="manual" label="Manual" />
                            )}
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col
                          md={12}
                          className="my-2 d-flex align-items-center gap-4"
                        >
                          <ProductTagList
                            tagsList={productTagsList || []}
                            suggestedArrayList={productTags}
                            onSelectTagsChange={onSelectTagsChange}
                            selectedManual={{
                              isChecked: values.manual,
                              value:
                                productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.COMPLETE
                                  ? inventoryConstants.PRODUCT_TAGS
                                      .MISSING_MANUAL
                                  : productCondition?.value ===
                                    PRODUCT_CONDITIONS_ENUMS.LOOSE
                                  ? inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
                                  : "",
                            }}
                            selectedBox={{
                              isChecked: values.box,
                              value:
                                productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.COMPLETE
                                  ? inventoryConstants.PRODUCT_TAGS.WITH_BOX
                                  : "",
                            }}
                            selectedWires={{
                              isChecked: values.cables,
                              value:
                                productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.COMPLETE
                                  ? inventoryConstants.PRODUCT_TAGS
                                      .MISSING_WIRES
                                  : "",
                            }}
                            selectedController={{
                              isChecked: values.controller,
                              value:
                                productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.COMPLETE
                                  ? inventoryConstants.PRODUCT_TAGS
                                      .MISSING_CONTROLLER
                                  : "",
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} className="m-0 p-0 mt-4">
                      <Row className="m-0">
                        <Col
                          md={4}
                          className="add-inventory-prices-detail-wrapper d-flex flex-column align-items-lg-stretch gap-3"
                        >
                          <div>
                            <span>GameStop Buy Price</span>
                            <span>{`$${parseToThousandSeperatorDecimalNumber(
                              convertToUsd(productDataObject.gameStopBuyPrice)
                            )}`}</span>
                          </div>
                          <div>
                            <span>Average Buy Price</span>
                            <span>{`$${parseToThousandSeperatorDecimalNumber(
                              productDataObject.averageBuyPrice || 0
                            )}`}</span>
                          </div>
                          <div>
                            <span>Max Buy Price</span>
                            <span>{`$${parseToThousandSeperatorDecimalNumber(
                              productDataObject.maxBuyPrice || 0
                            )}`}</span>
                          </div>
                          <div>
                            <span>Average Sell Price</span>
                            <span>{`$${parseToThousandSeperatorDecimalNumber(
                              productDataObject.averageSellPrice || 0
                            )}`}</span>
                          </div>
                        </Col>
                        <Col md={8}>
                          <Row className="m-0 h-100">
                            <Col xs={12} className="d-flex flex-column gap-1">
                              <div className="w-100 d-flex justify-content-between add-inventory-price-heading">
                                <span>Market Price: </span>

                                <span>
                                  {`$${parseToThousandSeperatorDecimalNumber(
                                    selectedRarity
                                      ? productDataObject?.marketPrice &&
                                        productDataObject?.marketPrice !== null
                                        ? productDataObject?.marketPrice
                                        : 0
                                      : convertToUsd(
                                          productDataObject.marketPrice
                                        )
                                  )}`}
                                </span>
                              </div>
                              {isTradiingCardWarningText && (
                                <div className="w-100 d-flex justify-content-end trading-card-warning-text">
                                  <span>
                                    Price is only for Near-Mint condition
                                  </span>
                                </div>
                              )}
                              <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                                <span>Suggested sell price: </span>
                                <span>
                                  {`$${parseToThousandSeperatorDecimalNumber(
                                    convertToUsd(
                                      productDataObject.suggestedSellPrice
                                    )
                                  )}`}
                                </span>
                              </div>
                              <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                                <span>In-Stock Price: </span>
                                <span>
                                  {`$${parseToThousandSeperatorDecimalNumber(
                                    productDataObject.inStorePrice || 0
                                  )}`}
                                </span>
                              </div>
                            </Col>
                            <Col xs={12} className="d-flex flex-column gap-1">
                              <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                                <span>Cost of Goods: </span>
                                <InputNumberField
                                  name="costOfGoods"
                                  placeHolder="0.00"
                                  className="add-inventory-price-field"
                                  IconImage={DollarIcon}
                                />
                              </div>
                            </Col>
                            <Col xs={12} className="d-flex flex-column gap-3">
                              <div className="add-inventory-stock-price-wrapper">
                                <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                                  <span>Stock Price </span>
                                </div>
                                <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                                  <span>In-Store: </span>
                                  <InputNumberField
                                    name="inStockPrice"
                                    placeHolder="0.00"
                                    className="add-inventory-price-field"
                                    IconImage={DollarIcon}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col
                              md={12}
                              className="d-flex align-items-end gap-2"
                            >
                              <Button
                                label={buttonNameConstants.CANCEL}
                                className="w-100"
                                type="button"
                                handleClick={() => handleHideModal()}
                                buttonType={buttonTypeConstants.GHOST_BUTTON}
                                isDisabled={isSpinnerEnabled(
                                  spinnerArray,
                                  inventoryConstants.ADD_INVENTORY
                                )}
                              />
                              <Button
                                label={buttonNameConstants.ADD_TO_INVENTORY}
                                className="w-100"
                                type="submit"
                                isDisabled={isSpinnerEnabled(
                                  spinnerArray,
                                  inventoryConstants.ADD_INVENTORY
                                )}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </>
                </Form>
              )}
            </Formik>
          )}
          {!priceChartingProductInfo && (
            <>
              <Col md={12} className="px-0 d-flex justify-content-center">
                <hr className="w-75" />
              </Col>
              <Formik
                initialValues={customItemInitialValues}
                validate={addCustomItemValidationSchema}
                onSubmit={handleCustomItemSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form className="px-0">
                    <Col
                      md={12}
                      className="add-inventory-hero-wrapper my-2 py-4 px"
                    >
                      <Row className="m-0">
                        <Col
                          md={9}
                          className="mb-3"
                          onClick={() => {
                            setIsCustomItemTab(true);
                          }}
                        >
                          <InputTextField
                            name="title"
                            label="Title"
                            placeHolder="Enter Title"
                            className="border"
                          />
                        </Col>
                        <Col md={3} className="mb-3">
                          <InputNumberField
                            label="Quantity"
                            name="quantity"
                            placeHolder="0"
                            className="add-inventory-quantity-field"
                            isConvertToDecimal={false}
                            step={"1"}
                            isHandleKeyDown={true}
                            isBorder={true}
                          />
                        </Col>
                        <Col md={12} className="mb-3">
                          <InputTextArea
                            name="description"
                            label="Description (Optional)"
                            placeHolder="Enter Description"
                            className={"border"}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {!isCustomItemTab && (
                      <Col
                        md={12}
                        className="d-flex justify-content-center align-items-center py-2"
                        onClick={toggleCustomItemTab}
                      >
                        <button type="button" className="expand-tab-button">
                          <ExpandTabIcon />
                        </button>
                      </Col>
                    )}
                    {isCustomItemTab && (
                      <>
                        <Col
                          md={12}
                          className="add-inventory-hero-wrapper my-3"
                        >
                          <Row className="m-0">
                            <Col
                              md={12}
                              className="mb-2 tab-heading text-muted"
                            >
                              Product Categorization
                            </Col>

                            <Col md={3} className="mb-2">
                              <IconSelectField
                                label="Type"
                                options={getCustomTypesOptionList(
                                  customTypes
                                ).filter(
                                  (item) =>
                                    item.label !== PRODUCT_TYPES_ENUMS.ALL
                                )}
                                placeHolder="Type..."
                                name="type"
                                customOnChange={(option) =>
                                  handleTypeChange(option, setFieldValue)
                                }
                                isClearable={false}
                              />
                            </Col>
                            <Col md={3} className="mb-2">
                              <IconSelectField
                                label="Category"
                                options={getCustomCategoriesOptionList(
                                  customCategories,
                                  values.type
                                )}
                                name="category"
                                placeHolder="Category..."
                                isClearable={false}
                                customOnChange={(option) =>
                                  handleCategoryChange(option, setFieldValue)
                                }
                              />
                            </Col>
                            <Col md={3} className="mb-2">
                              <IconSelectField
                                label="Sub-Category"
                                options={getCustomSubCategoriesOptionList(
                                  customSubCategories,
                                  values.category,
                                  null,
                                  values.type,
                                  false,
                                  customCategories,
                                  customTypes
                                )}
                                placeHolder="Sub-Category..."
                                name="subCategory"
                              />
                            </Col>
                            <Col md={3} className="mb-2">
                              <IconSelectField
                                label="Condition"
                                options={
                                  getTypeOrCategoryObject(
                                    customTypes,
                                    values.type
                                  )?.productType ===
                                  PRODUCT_TYPES_ENUMS.VIDEO_GAME
                                    ? [
                                        ...inventoryConstants.VIDEO_GAME_CONDITION,
                                        {
                                          value: PRODUCT_CONDITIONS_ENUMS.USED,
                                          label: PRODUCT_CONDITIONS_ENUMS.USED,
                                        },
                                      ]
                                    : getTypeOrCategoryObject(
                                        customTypes,
                                        values.type
                                      )?.productType ===
                                      PRODUCT_TYPES_ENUMS.TRADING_CARD
                                    ? [
                                        ...inventoryConstants.TRADING_CARD_CONDITION,
                                        ...inventoryConstants.OTHER_CONDITION,
                                      ]
                                    : inventoryConstants.OTHER_CONDITION
                                }
                                placeHolder="Condition..."
                                name="condition"
                                isClearable={false}
                              />
                            </Col>
                            <Col md={12} className="mb-3">
                              <InputTextField
                                name="upc"
                                label="UPC (Optional)"
                                placeHolder="111000294854"
                                className="border"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={12}
                          className="add-inventory-hero-wrapper my-3"
                        >
                          <Row className="m-0">
                            <Col
                              md={12}
                              className="mb-2 tab-heading text-muted"
                            >
                              Product Tags
                            </Col>
                            <Col md={12}>
                              <ProductTagList
                                tagsList={[]}
                                suggestedArrayList={productTags}
                                onSelectTagsChange={
                                  onCustomProductSelectTagsChange
                                }
                                customProductTags={true}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={12}
                          className="add-inventory-hero-wrapper my-3"
                        >
                          <Row className="m-0">
                            <Col
                              md={12}
                              className="mb-2 tab-heading text-muted"
                            >
                              Pricing
                            </Col>
                            <Col md={6} className="mb-2">
                              <InputNumberField
                                label="Stock Price"
                                name="stockPrice"
                                placeHolder="0.00"
                                className="add-inventory-quantity-field"
                                isBorder={true}
                                IconImage={DollarIcon}
                              />
                            </Col>
                            <Col md={6} className="mb-2">
                              <InputNumberField
                                label="Cost of Goods"
                                name="costOfGoods"
                                placeHolder="0.00"
                                className="add-inventory-quantity-field"
                                isBorder={true}
                                IconImage={DollarIcon}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          md={6}
                          className="d-flex align-items-end gap-2 ms-auto"
                        >
                          <Button
                            label={buttonNameConstants.CANCEL}
                            className="w-100"
                            type="button"
                            handleClick={() => handleHideModal()}
                            buttonType={buttonTypeConstants.GHOST_BUTTON}
                            isDisabled={isSpinnerEnabled(
                              spinnerArray,
                              inventoryConstants.ADD_INVENTORY
                            )}
                          />
                          <Button
                            label={buttonNameConstants.ADD_TO_INVENTORY}
                            className="w-100"
                            type="submit"
                            isDisabled={isSpinnerEnabled(
                              spinnerArray,
                              inventoryConstants.ADD_INVENTORY
                            )}
                          />
                        </Col>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Row>
        {productUrl && (
          <Row className="m-0 w-50">
            <Col md={12}>
              {productUrl && (
                <iframe
                  title="Product Iframe"
                  src={productUrl}
                  className="iframe-website"
                />
              )}
            </Col>
          </Row>
        )}
      </Modal.Body>

      {productUrl && (
        <button
          type="button"
          onClick={() => {
            setProductUrl("");
          }}
          className="iframe-toogle-button"
        >
          <SidebarToggleIcon className={"iframe-toggle-icon"} />
        </button>
      )}
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  isAddModalOpen: state.inventory.isAddModalOpen,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addInventory: (data) => dispatch(inventoryActions.addInventory(data)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  toggleAddModal: (value) => dispatch(inventoryActions.toggleAddModal(value)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryItem);

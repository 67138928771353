import React from "react";

const TransactionOverViewCard = ({ label, data }) => {
  return (
    <div className="transaction-overview-card">
      <p className="transaction-overview-card-heading">{label}</p>
      <p className="transaction-overview-card-description">{data}</p>
    </div>
  );
};

export default TransactionOverViewCard;

import React from "react";

const InventoryOverviewThreeCards = ({ className, data = [{}], ...props }) => {
  return (
    <div className={`inventory-batch-card d-flex flex-row p-3 ${className}`}>
      <div className="inventory-inner-batch-card w-50 me-2">
        <p className="inner-card-heading">{data[0]?.label ?? "Label"}</p>
        <p className="inner-card-description">{data[0]?.value ?? "Value"}</p>
      </div>
      <div className="inventory-inner-batch-card w-50 ms-2">
        <p className="inner-card-heading">{data[1]?.label ?? "Label"}</p>
        <p className="inner-card-description">{data[1]?.value ?? "Value"}</p>
      </div>
      <div className="inventory-inner-batch-card w-50 ms-2">
        <p className="inner-card-heading">{data[2]?.label ?? "Label"}</p>
        <p className="inner-card-description">{data[2]?.value ?? "Value"}</p>
      </div>
    </div>
  );
};

export default InventoryOverviewThreeCards;

import React from "react";
import CollectPosLogo from "../../../assets/images/collectPosLogo.svg";

const SidebarFooter = () => {
  return (
    <div className="mt-auto d-flex justify-content-center align-items-center sidebar-footer-wrapper">
      <img
        src={CollectPosLogo}
        alt="collect-pos-logo"
        className="collect-pos-footer-logo"
      />
    </div>
  );
};

export default SidebarFooter;
